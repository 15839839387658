import React, { Component } from 'react'

import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';


//import SideBars from './SideBars.js';


export default class Register1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      password: "",
      phonenumber: "",

      formErrors: {
        username: "",
        email: "",
        password: "",
        phonenumber: "",
        cpwd: ""
      },
      formValidity: {
        username: false,
        phonenumber: false,
        email: false,
        password: false,
        cpwd: ""
      },
      formValues: {
        username: "",
        email: "",
        password: "",
        phonenumber: "",
        cpwd: ""
      },
      isSubmitting: false
    };
    this.initialState = this.state;
  }


  componentDidMount() {
    loadCaptchaEnginge(6);
  };

  handleValidation = target => {

    const { name, value } = target;
    const fieldValidationErrors = this.state.formErrors;
    const validity = this.state.formValidity;
    const isEmail = name === "email";
    const isPassword = name === "password";
    const isCpwd = name === "cpwd";
    const isPhone = name === "phonenumber";
    const isUsername = name === "username";
    const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    validity[name] = value.length > 0;
    fieldValidationErrors[name] = validity[name]
      ? ""
      : `${name} is required and cannot be empty`;
    if (validity[name]) {
      if (isEmail) {
        validity[name] = emailTest.test(value);
        fieldValidationErrors[name] = validity[name]
          ? ""
          : `${name} should be a valid email address`;
      }
      if (isPassword) {
        validity[name] = value.length >= 3;
        fieldValidationErrors[name] = validity[name]
          ? ""
          : `${name} should be 5 characters minimum`;
      }

      if (isPhone) {
        validity[name] = value.length >= 3;
        fieldValidationErrors[name] = validity[name]
          ? ""
          : `${name} phonenumber is required`;
      }
      if (isUsername) {
        validity[name] = value.length >= 3;
        fieldValidationErrors[name] = validity[name]
          ? ""
          : `${name} username is required`;
      }
      if (isCpwd) {

        validity[name] = value.length >= 3;

        if (this.state.formValues.password != value) {

          fieldValidationErrors[name] = validity[name]
            ? `${name} Password and ConfirmPassword must be same`
            : '';
        }



      }
    }







    this.setState({
      formErrors: fieldValidationErrors,
      formValidity: validity
    });
  };

  dosubmit() {
    let user_captcha = document.getElementById('user_captcha_input').value;
    if (validateCaptcha(user_captcha) === true) {
      alert('Captcha Matched');
      loadCaptchaEnginge(6);
      document.getElementById('user_captcha_input').value = "";
    }

    else {

      document.getElementById('user_captcha_input').value = "";
    }
  }




  handleSubmit = () => {
    // e.preventDefault();
    this.setState({ isSubmitting: true });
    const { formValues, formValidity } = this.state;
    if (Object.values(formValidity).every(Boolean)) {
      alert("Form is validated! Submitting the form...");
      window.location.href = "./login";
      this.setState({ isSubmitting: false });
    } else {
      for (let key in formValues) {
        let target = {
          name: key,
          value: formValues[key]
        };
        this.handleValidation(target);
      }
      this.setState({ isSubmitting: false });
    }
  };


  handleChange = ({ target }) => {
    const { formValues } = this.state;
    formValues[target.name] = target.value;
    this.setState({ formValues });
    this.handleValidation(target);
    // 
  };


  submit() {
    let url = "https://testapi.paropakari.com/api/Authenticate/register";
    let data = this.state;
    fetch(url, {
      method: 'POST',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      body: JSON.stringify(data)
    }).then((result) => {
      result.json().then((resp) => {

        
        // this.props.history.push('/login');
        

      })
    })

  }



  render() {
    const { formValues, formErrors, isSubmitting } = this.state;
    return (


      <div>
        <title>Login V5</title>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/*===============================================================================================*/}
        <link rel="icon" type="image/png" href="./assets/images/icons/favicon.ico" />

        {/*===============================================================================================*/}

        {/*===============================================================================================*/}
        <link rel="stylesheet" type="text/css" href="./assets/css/util.css" />
        <link rel="stylesheet" type="text/css" href="./assets/css/main1.css" />
        <link href="css/bootstrap.min.css" rel="stylesheet" />
        <link href="css/font-awesome.min.css" rel="stylesheet" />
        <link href="css/lightbox.css" rel="stylesheet" />
        <link href="css/animate.min.css" rel="stylesheet" />
        <link href="css/main.css" rel="stylesheet" />
        <link href="css/responsive.css" rel="stylesheet" />
        {/*[if lt IE 9]>
              
              
          <![endif]*/}
        <link rel="shortcut icon" href="images/ico/favicon.ico" />
        <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
        <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
        <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
        <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />
        {/*===============================================================================================*/}
        <header id="header" style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}>
          <div className="container">
            <div className="row">

            </div>
          </div>
          <div className="navbar navbar-inverse" role="banner">
            <div className="container">
              <div className="navbar-header">
                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                </button>
                <a className="navbar-brand" href="index.html">
                  <h1 style={{ color: 'blue', fontFamily: 'Biysk-Regular' }}><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '19%', fontsize: '52px' }} />AROPAKARI</h1>
                </a>

              </div>

              <div className="collapse navbar-collapse">
                <ul className="nav navbar-nav navbar-right">

                  <li className="active"><a className="example" href="#/Login1" style={{ backgroundColor: '#ffd9a2', color: 'white', fontWeight: 'bold', marginRight: '82px', marginLeft: '-251px', fontSize: 18, textDecoration: 'none', whiteSpace: 'nowrap', fontFamily: 'cuyabra' }}>

                    Be a Paropakari
                  </a></li>
                  <li className="active"><a href="#/Index1.js" style={{ fontFamily: 'cuyabra', fontWeight: 'bold' }}>Home</a></li>
                  <li><a href="#/Ourstory.js" style={{ fontFamily: 'cuyabra', fontWeight: 'bold' }}>Our Story</a></li>
                  <li><a href="#/Howitworks.js" style={{ fontFamily: 'cuyabra', fontWeight: 'bold' }}>How It Works</a></li>
                  <li><a href="#/Howitworks.js" style={{ fontFamily: 'cuyabra', fontWeight: 'bold' }}>Blog</a></li>

                  <li><a href="#/Login1" style={{ fontFamily: 'cuyabra', fontWeight: 'bold' }}>Login</a></li>
                </ul>
              </div>
              <div className="search">
                <form role="form">
                  <i className="fa fa-search" />
                  <div className="field-toggle">
                    <input type="text" className="search-form" autoComplete="off" placeholder="Search" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </header>
        <div className="limiter">
          <div className="container-login100" style={{ backgroundImage: 'url("./assets/images/10.jpg")' }}>
            <div className="wrap-login100 p-l-110 p-r-110 p-t-62 p-b-33">
              <form className="login100-form validate-form flex-sb flex-w" role="form" onSubmit={this.handleSubmit} >

                <div className="p-t-31 p-b-9">
                  <span className="txt1">
                    Username
                  </span>
                </div>
                <div className="wrap-input100 validate-input" data-validate="Username is required">
                  <input className="input100" id="username" name="username" onChange={this.handleChange}
                    value={formValues.username}
                    onBlur={(data) => { this.setState({ username: data.target.value }) }}
                    placeholder="Username" required="required" type="text" />
                  <span className="focus-input100" />
                </div>

                <div className="p-t-31 p-b-9">
                  <span className="txt1">
                    Mobile Number
                  </span>
                </div>
                <div className="wrap-input100 validate-input" data-validate="Username is required">
                  <input className="input100" value={formValues.phonenumber}
                    onBlur={(data) => { this.setState({ phonenumber: data.target.value }) }} onChange={this.handleChange}
                    id="phonenumber" maxLength={10} name="phonenumber" placeholder="Mobile Number" type="text" />
                  <span className="focus-input100" />
                </div>

                <div className="p-t-31 p-b-9">
                  <span className="txt1">
                    Email
                  </span>
                </div>
                <div className="wrap-input100 validate-input" data-validate="Username is required">
                  <input className="input100" onChange={this.handleChange}
                    value={formValues.email}
                    onBlur={(data) => { this.setState({ email: data.target.value }) }}
                    id="email" name="email" placeholder="Email" type="text" />
                  <span className="focus-input100" />
                </div>


                <div className="p-t-13 p-b-9">
                  <span className="txt1">
                    Password
                  </span>

                </div>
                <div className="wrap-input100 validate-input" data-validate="Password is required">
                  <input className="input100" onChange={this.handleChange}
                    value={formValues.password}
                    onBlur={(data) => { this.setState({ password: data.target.value }) }}
                    id="Password" name="password" placeholder="Password" type="password" />

                </div>
                <div className="p-t-13 p-b-9">
                  <span className="txt1">
                    Confirm Password
                  </span>

                </div>
                <div className="wrap-input100 validate-input" data-validate="Password is required">
                  <input className="input100" id="cpwd" placeholder="Reenter Your Password" type="password" onChange={this.handleChange}
                    value={formValues.cpwd} name="cpwd" />

                </div>

                <div className="p-t-13 p-b-9">
                  <span className="txt1">

                    <LoadCanvasTemplate />
                  </span>

                </div>
                <div className="wrap-input100 validate-input" data-validate="Password is required">
                  <input className="input100" autoComplete="off" placeholder="Enter Captcha Value" id="user_captcha_input" name="user_captcha_input" style={{ width: '50%' }} type="text"></input><span className="focus-input100" />

                </div>
                <div className="container-login100-form-btn m-t-17">
                  <button className="login100-form-btn" onClick={
                    () => {
                      this.submit();

                      this.handleSubmit()
                    }
                  }
                    style={{ marginTop: '10px', marginLeft: '2px', marginBottom: "17px" }} id="Reg" disabled={isSubmitting} >Continue to Register {isSubmitting ? "Please wait..." : ""}</button>



                </div>
                <div className="w-full text-center p-t-55">
                  <span className="txt2">
                    Already User?
                  </span>
                  <a href="./Login1.js" className="txt2 bo1">
                    Sign In now
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div style={{ backgroundImage: "url(" + "./assets/images/23.jpg" + ")" }}>
          <footer>
            <br />

            <div className="container" style={{ marginRight: '455px' }}>
              <div className="row">
                <div className="col-sm-12 text-center bottom-separator">

                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="testimonial bottom">
                    <h2></h2>
                    <div className="media">
                      <div className="pull-left">

                      </div>
                      <div className="media-body">

                      </div>
                    </div>
                    <div className="media">
                      <div className="pull-left">

                      </div>
                      <div className="media-body">

                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="contact-info bottom">
                    <h2 style={{ color: 'white' }}>Contacts</h2>
                    <address style={{ color: 'white' }}>
                      E-mail: <a href="mailto:someone@example.com" style={{ color: 'white' }}>paropakaribeone@gmail.com</a> <br />
                      Phone:  <br />
                      Fax: <br />
                    </address>
                    <h2 style={{ color: 'white' }}>Address</h2>
                    <address style={{ color: 'white' }}>
                      <br />
                      <br />
                      <br />
                      Banglore <br />
                    </address>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="contact-form bottom">
                    <h2 style={{ color: 'white' }}>Send a message</h2>
                    <form id="main-contact-form" name="contact-form" method="post" action="sendemail.php">
                      <div className="form-group">
                        <input type="text" name="name" className="form-control" required="required" placeholder="Name" />
                      </div>
                      <div className="form-group">
                        <input type="email" name="email" className="form-control" required="required" placeholder="Email Id" />
                      </div>
                      <div className="form-group">
                        <textarea name="message" id="message" required="required" className="form-control" rows={8} placeholder="Your text here" defaultValue={""} />
                      </div>
                      <div className="form-group">
                        <input type="submit" name="submit" className="btn btn-submit" defaultValue="Submit" />
                      </div>
                    </form>
                  </div>
                </div>

                <div className="col-sm-12 overflow">

                  <div className="social-icons" style={{ marginLeft: '655px', color: 'white' }}>
                    <ul className="nav nav-pills">
                      <li><a href><i className="fa fa-facebook" /></a></li>
                      <li><a href><i className="fa fa-twitter" /></a></li>
                      <li><a href><i className="fa fa-google-plus" /></a></li>
                      <li><a href><i className="fa fa-dribbble" /></a></li>
                      <li><a href><i className="fa fa-linkedin" /></a></li>
                    </ul>
                  </div>

                </div>
                <div className="col-sm-12">

                  <div className="copyright-text text-center">
                    <p style={{ marginLeft: '355px', color: 'white' }}>Copyright © 2021 Paropakari</p>
                    <p><a target="_blank" href="https://paropakari.com/"></a></p>
                  </div>
                </div>


              </div>
            </div>

          </footer>
        </div>
        <div id="dropDownSelect1" />

      </div>


    );
  }
}
