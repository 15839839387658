import React, { useState, useEffect } from "react";

import Axios from "axios";
import Modal from "react-modal";
//import CommentComponent from './CommentComponent';

import { ReactTransliterate } from "react-transliterate";
import "react-transliterate/dist/index.css";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "480px",
    width: "405px",
    borderRadius: "20px",
    backgroundColor: "rgb(174, 230, 219)",
    border: "none",
    position: "fixed",
  },
};

export default function Comment({
  Id,
  Translated,
  Submit,
  CommentHere,
  dropYourComment,
  refreshcomment,
  Url,
  Data
}) {
  let formData = new FormData();
  const [isOpen, setIsOpen] = useState(false);

  const [modal, setModal] = useState(false);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [text, setText] = useState("");
  // const [lang, setLang] = useState<Language>("hi");
  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [myLocalStorageData, setMyLocalStorageData] = useState({});
  const [title, setTitle] = useState({
    comment_description: "",
  });

  // const onTitleChange = e => setTitle(e.target.value);
  function onTitleChange(e) {
    const newdata = { ...title };
    newdata[e.target.id] = e.target.value;
    setTitle(newdata);

  }
  // const [shown, setShown] = useState([])
  // const getUserShown = async () =>{
  //   const data = await Axios.get(`https://testapi.paropakari.com/api/GetProfile/Get_Comment?id="${Id}`)
  //   
  //   setShown(data.data)
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();


    
    let inputbox = document.getElementById('comment_description');
    if (inputbox.value !== '') {
      setTitle('');
    }

    //Sdr added
    if (!isButtonDisabled) {
      setIsButtonDisabled(true);

      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 5000);
    }
    //Sdr added
    let commentdesc = document.getElementById("comment_description").value;
    if(commentdesc != null && commentdesc.trim() !== ''){
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    // const url = "https://testapi.paropakari.com/api/GetProfile/Add_Comment";
    const headers = {
      Authorization: "Bearer " + accessToken,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
      "Access-Control-Allow-Headers": "Content-Type",
    };
    formData.append("comment_description", title.comment_description);
    formData.append("upload_id", Id);

    try {
      Axios({
        method: "post",
        url: "https://testapi.paropakari.com/api/GetProfile/Add_Comment",
        headers: { Authorization: `Bearer ${accessToken}` },
        data: formData,
      })
        // Axios.post(url,{"headers" : headers},formData)
        .then((res) => {
          // if(res.data == "update success")\           
          

          if (res.data.status == "Success") {
            
            // let url = `https://testapi.paropakari.com/api/GetProfile/Get_HomeCategoryFilter?id=${this.state.sortval}&CategoryID=${this.state.catval}`;
            let user = JSON.parse(localStorage.getItem("user"));
            const accessToken = user;
            fetch(Url, {
              method: "GET",
              headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "Bearer " + accessToken,
                "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
              },
            })
              .then((response) => response.json())

              .then((data) => {

                let final = data.map(e => {
                  if (e) {
                    let img = null
                    if (e.imageData !== null) {
                      img = e.imageData.split(',').map(img => {
                        return {
                          file_name: img.split("$$$")[0],
                          file_type: img.split("$$$")[1]
                        }
                      })
                    } return {
                      ...e,
                      data: e.imageData === null ? [] : img
                    }
                  }

                })
                

                Data(final)
                loadData();

                setTimeout(() => {
                  setModal(false);
                }, 1000);
              });


            // alert('Your Comment Added successfully....')
            // window.location.reload();
          } else {
            alert("Unable to update");
          }

        });
    } catch (err) {

    }
  }
  };
  const toggleModal = () => {
    setIsOpen(!isOpen);

    setModal(false);
  };

  useEffect(() => {
    loadData();
  }, [Id]);

  const loadData = async () => {
    try {
      let user1 = JSON.parse(localStorage.getItem("Languagetrasnliterate"));

      let Transtate = JSON.parse(localStorage.getItem("Languagetrasnliterate"));

      let user = JSON.parse(localStorage.getItem("user"));
      
      
      const accessToken = user;

    

      await fetch(
        "https://testapi.paropakari.com/api/GetProfile/Get_Comment?id=" + Id,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
        .then((response) => response.json())
        .then((receivedData) => setData(receivedData));
      localStorage.setItem("RowData", data);
    

    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const update = () => {
    // setIsOpen(true);
    setModal(true);
    //document.getElementById("modalRegisterForm").style.display = "block";
  };

  return (

    <div className="modalllllyyy">
     
      <button className="toggle" onClick={update}>
        {Translated ? Translated : "Comment"}
      </button>

      {modal && (
        <div
          id="modalRegisterForm"
          tabindex="-1"
          role="dialog"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1000,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
            outline: 0,
          }}
        >
          <div
            style={{
              position: "fixed",
              inset: "50% auto auto 50%",
              border: "none",
              background: "rgb(174, 230, 219)",
              overflow: "auto",
              borderRadius: "20px",
              outline: "none",
              padding: "20px",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              height: "480px",
              width: "405px",
            }}
          >
            <div className="modal-header">
              <h3 className="modal-title">{dropYourComment ? dropYourComment : "Drop Your Comment"}</h3>
              <button type="button" className="close" onClick={toggleModal}>
                ×
              </button>
            </div>
            <br />

            <div role="form">
              <form ng-submit="submitComment()" ng-controller="responseCtrl">
                <div role="form">
                  <center>
                    <input
                      type="text"
                      placeholder={CommentHere ? CommentHere : "Comment Here"}
                      required
                      autoComplete="off" maxLength={50}
                      className="form-control notranslate"
                      style={{ width: "85%" }}
                      onChange={(e) => onTitleChange(e)}
                      id="comment_description"
                      name="comment_description"
                      value={title.comment_description}
                    />
                  </center>
                  <br />

                  <center>
                    <input
                      type="submit"
                      value={Submit ? Submit : "Submit"}
                      className="btn-primary notranslate"
                      style={{
                        backgroundColor: "rgb(102, 71, 18)",
                        color: "white",
                        marginTop: 9,
                        width: "30%",
                        fontSize: "100%",
                        borderRadius: "5px",
                      }}
                      onClick={(e) => handleSubmit(e)}
                      disabled={isButtonDisabled}
                      id="submitComment"
                    />
                    <br />
                    <br />
                    <br />
                    <hr />
                  </center>
                </div>
              </form>
            </div>

            {data &&
              data.map((comment) => (
                <div style={{ fontSize: "64%", marginLeft: "4%" }}>
                  <h5>
                    <a
                      style={{ textDecoration: "none", color: "black" }}
                      key={comment}
                    >
                      {comment.profile_name} &nbsp;-&nbsp;{" "}<br />
 <br />
 
                    
 {/* {comment.comment_description} */}
 <textarea style={{width:'85%', backgroundColor:'unset', border:'unset'}} disabled>{comment.comment_description}</textarea>  
                      <a
                        style={{
                          fontSize: "9px",
                          textDecoration: "none",
                          color: "black",
                          float: "right",
                        }}
                      >
                        <br />
                        &nbsp;{comment.created_date}
                      </a>
                    </a>
                  </h5>

                  {/* <a style={{fontSize:'7px',textDecoration:'none',color:'black',float:'right'}}>&nbsp;{comment.created_date}</a> */}
                  <p style={{ color: "black" }}>
                    <hr />
                  </p>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
