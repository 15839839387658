import React, { Component } from 'react'
import "../assets/css/imagePopup.css"
import deleteicon from "../assets/images/deleteImg.png"
import Axios from 'axios'

export default class DisplayingPopup extends Component {

    constructor(props) {
        super(props);
        this.prevCountRef = React.createRef();

        this.state = {
            displayUsers: [],
            visible: false,
            NewdisplayUsers: [],
            File_Type: [],
        }
    }

    displayAlert() {
        window.location.reload()
    }
    deleteimg() {
        let url = "https://testapi.paropakari.com/api/Create/deleteimageedit?file_id=" + this.props.passid;
        let user = JSON.parse(localStorage.getItem('user'));
        const accessToken = user;
        fetch(url, {
            method: 'POST',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                Authorization: "Bearer " + accessToken,
                "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
            },
        })
            .then(data => {
                if (data.status == 200) {
                    window.location.reload()
                }
            })
    }
    getFileType = () => {
        const parts = this.props.passVal.split('.');
        if (parts.length > 1) {
            return parts[parts.length - 1];
        } else {
            return "Unknown";
        }
    }
    componentDidMount() {
        const fileType = this.getFileType();
        this.setState({
            File_Type: fileType
        }, () => {
            
        })
    }
    render() {
        const { File_Type } = this.state;
        return (
            <div className="modal__backdrop">
                <div className="modal__container">
                    <div class="pic-ctn">
                        <div className="head-sec">
                            <p align="right"><img src="./assets/images/delete.png" height="15px" onClick={() => this.displayAlert()} width="15px" /></p>
                            <>
                                {
                                    File_Type == "docx" ||
                                        File_Type == "doc" ||
                                        File_Type === "xlsx" ||
                                        File_Type === "xls" ||
                                        File_Type == "pdf" ||
                                        File_Type == "mp4" ||
                                        File_Type == "webm" ?
                                        <div style={{ paddingLeft: "10%", paddingTop: "10%" }}>
                                            <p>You want to delete this <strong>{File_Type}</strong> File?</p>
                                        </div>
                                        :
                                        <img src={this.props.passVal} className="img-algn" alt={this.props.passVal} height="150" width="200px" />

                                }
                                {
                                    File_Type == "docx" ||
                                        File_Type == "doc" ||
                                        File_Type === "xlsx" ||
                                        File_Type === "xls" ||
                                        File_Type == "pdf" ||
                                        File_Type == "mp4" ||
                                        File_Type == "webm" ?
                                        <div style={{ textAlign: "center" }}>
                                            <img src={deleteicon} height="15px" width="20px" onClick={() => this.deleteimg()}></img>
                                        </div>
                                        :
                                        <img src={deleteicon} height="15px" width="20px" onClick={() => this.deleteimg()}></img>
                                }

                            </>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}