import { color } from '@material-ui/system'
import React, { Component } from 'react'
import Script from 'react-load-script'
import Cookies from 'js-cookie';
import LogoutLink from "./LogoutLink";
//import SideBars from './SideBars.js';


export default class PrivacyPolicyLogin extends Component {


  constructor(props) {
    super(props)

    this.state = {

      // id : this.props.match.params.id, 
      foo: [],
      Menu: [],
      Details: [],
      newArray: [],
      ourstory: [],
      howitworks: [],
      beaparopakari: [],
      whatwedo: [],
      blog: [],
      Friends:[],
      login: [],
      indiaexpensless: [],
      oppertunitybringsmile: [],
      contactus: [],
      termsandcondition: [],
      causes: [],
      beneficiaries: [],
      paropakaris: [],
      amountraised: [],
      copyright: [],
      whatwedodesc: [],
      crowdfunding: [],
      humanelement: [],
      nofeecommision: [],
      nomiddlemen: [],
      privacypolicy: [],
      encourageaultruism: [], comingsoon: [],
      notify: [],
      invite: [], profile: [], changepassword: [], group: [], logout: [], myjourney: [],
      createacause: [],
      langID: [],
      movedown: true,
    }
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
  }
  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown
    })
  }
  Footer = () => {

    


    let url = "https://testapi.paropakari.com/api/Authenticate/Get_FooterDetails";
    let data = this.state;

    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          foo: data
        });
        
        // 
      });
  }

  notify = () => {


    let url = "https://testapi.paropakari.com/api/GetProfile/Notification_Count";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          notify: data
        });
        
        localStorage.setItem('noticount', JSON.stringify(data[0].noticount));
        // 
      });
  }

  DefaultMenu() {
    //  
    let RefreshID = 0;
    window.sessionStorage.setItem('reload', (RefreshID));
    let reloadsmyactivity = 0;
    window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
    window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
    let langval = "English";
    
    localStorage.setItem('menuid', langval);
    let MID = "English";
    if (sessionStorage.getItem('translate') !== null) {
      MID = sessionStorage.getItem('translate');
    }
    else {
      MID = "English";
    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~WhatWeDo~We are Indias only expenseless direct crowdfunding and support platform that connects the needy with the more fortunate directly without third party involvement or fees~Opportunity to Bring a Smile~Contact Us~Terms and Conditions~Copyright © 2024 Paropakari&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Ourstory = typeof items[0] !== "undefined" ? items[0] : null;
          var Howitwork = typeof items[1] !== "undefined" ? items[1] : null;
          var Blog = typeof items[2] !== "undefined" ? items[2] : null;
          var Login = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[8] !== "undefined" ? items[8] : null;
          var Termsandcondition = typeof items[9] !== "undefined" ? items[9] : null;
          var CopyRight = typeof items[10] !== "undefined" ? items[10] : null;

          var Beaparopakari = typeof items[4] !== "undefined" ? items[4] : null;
          var OppertunitytoBSmile = typeof items[7] !== "undefined" ? items[7] : null;
          var Whatwedo = typeof items[5] !== "undefined" ? items[5] : null;

          var Whatwedodesc = typeof items[6] !== "undefined" ? items[6] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          this.setState({
            ourstory: Ourstory,
            howitworks: Howitwork,
            blog: Blog,
            login: Login,
            beaparopakari: Beaparopakari,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            whatwedo: Whatwedo,
            oppertunitybringsmile: OppertunitytoBSmile,
            whatwedodesc: Whatwedodesc,
            langID: LangID,


          });

        });

    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Causes~Beneficiaries~Paropakaris~Amount Raised~EncourageAltruism~Crowd funding with:~A Human Element~No Fees/Commission~No Middlemen~Coming Soon~Privacy Policy&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Causes = typeof items[0] !== "undefined" ? items[0] : null;
          var Beneficiaries = typeof items[1] !== "undefined" ? items[1] : null;
          var Paropakaris = typeof items[2] !== "undefined" ? items[2] : null;
          var Amountraised = typeof items[3] !== "undefined" ? items[3] : null;

          var Crowdfunding = typeof items[5] !== "undefined" ? items[5] : null;
          var HumanElement = typeof items[6] !== "undefined" ? items[6] : null;
          var NoFeeCommision = typeof items[7] !== "undefined" ? items[7] : null;
          var NoMiddlemen = typeof items[8] !== "undefined" ? items[8] : null;
          var CominSoon = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy = typeof items[10] !== "undefined" ? items[10] : null;
          var EncourageAltruism = typeof items[4] !== "undefined" ? items[4] : null;


          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          this.setState({

            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            crowdfunding: Crowdfunding,
            humanelement: HumanElement,
            nofeecommision: NoFeeCommision,
            nomiddlemen: NoMiddlemen,
            privacypolicy: PrivacyPolicy,
            langID: LangID,
            comingsoon: CominSoon,

          });


        });

    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Invite~Profile~Change Password~Group~Logout~My Journey~Create a Cause~Friends&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Invite = typeof items[0] !== "undefined" ? items[0] : null;
          var Profile = typeof items[1] !== "undefined" ? items[1] : null;
          var ChangePasssword = typeof items[2] !== "undefined" ? items[2] : null;
          var Group = typeof items[3] !== "undefined" ? items[3] : null;
          var Logout = typeof items[4] !== "undefined" ? items[4] : null;
          var Myjourney = typeof items[5] !== "undefined" ? items[5] : null;
          var Createacause = typeof items[6] !== "undefined" ? items[6] : null;
var Friends = typeof items[7] !== "undefined" ? items[7] : null;
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          this.setState({


            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            myjourney: Myjourney,
            createacause: Createacause,
            Friends:Friends,
            langID: LangID

          });

        });
    }
  }



  handleChanges(event) {
    this.setState({ value: event.target.value });
    
    localStorage.setItem('menuid', event.target.value);
    // let l_id = JSON.parse(localStorage.getItem('menuid'));
    
    let values = ['OurStory', 'HowItWorks', 'Blog', 'Login', 'BeaParopakari', 'What We Do'];
    
    let MID = event.target.value;
    if (MID != null) {

      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~WhatWeDo~We are Indias only expenseless direct crowdfunding and support platform that connects the needy with the more fortunate directly without third party involvement or fees~Opportunity to Bring a Smile~Contact Us~Terms and Conditions~Copyright © 2024 Paropakari&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Ourstory = typeof items[0] !== "undefined" ? items[0] : null;
          var Howitwork = typeof items[1] !== "undefined" ? items[1] : null;
          var Blog = typeof items[2] !== "undefined" ? items[2] : null;
          var Login = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[8] !== "undefined" ? items[8] : null;
          var Termsandcondition = typeof items[9] !== "undefined" ? items[9] : null;
          var CopyRight = typeof items[10] !== "undefined" ? items[10] : null;

          var Beaparopakari = typeof items[4] !== "undefined" ? items[4] : null;
          var OppertunitytoBSmile = typeof items[7] !== "undefined" ? items[7] : null;
          var Whatwedo = typeof items[5] !== "undefined" ? items[5] : null;

          var Whatwedodesc = typeof items[6] !== "undefined" ? items[6] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          this.setState({
            ourstory: Ourstory,
            howitworks: Howitwork,
            blog: Blog,
            login: Login,
            beaparopakari: Beaparopakari,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            whatwedo: Whatwedo,
            oppertunitybringsmile: OppertunitytoBSmile,
            whatwedodesc: Whatwedodesc,
            langID: LangID,


          });

          // alert(firstItem);
          const trans = "Vijayaasri";
          let l_name = this.state.newArray[0];
          let l_names = l_name;

          
          
          const n = 2;
          const firstNElements = longArray.slice(0, n).map(({ result }) => {
            return (<p >{result}</p>)
          });
          // 
        });

    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Causes~Beneficiaries~Paropakaris~Amount Raised~EncourageAltruism~Crowd funding with:~A Human Element~No Fees/Commission~No Middlemen~Coming Soon~Privacy Policy&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Causes = typeof items[0] !== "undefined" ? items[0] : null;
          var Beneficiaries = typeof items[1] !== "undefined" ? items[1] : null;
          var Paropakaris = typeof items[2] !== "undefined" ? items[2] : null;
          var Amountraised = typeof items[3] !== "undefined" ? items[3] : null;

          var Crowdfunding = typeof items[5] !== "undefined" ? items[5] : null;
          var HumanElement = typeof items[6] !== "undefined" ? items[6] : null;
          var NoFeeCommision = typeof items[7] !== "undefined" ? items[7] : null;
          var NoMiddlemen = typeof items[8] !== "undefined" ? items[8] : null;
          var CominSoon = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy = typeof items[10] !== "undefined" ? items[10] : null;
          var EncourageAltruism = typeof items[4] !== "undefined" ? items[4] : null;


          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          this.setState({

            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            crowdfunding: Crowdfunding,
            humanelement: HumanElement,
            nofeecommision: NoFeeCommision,
            nomiddlemen: NoMiddlemen,
            privacypolicy: PrivacyPolicy,
            langID: LangID,
            comingsoon: CominSoon,

          });

        });

    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Invite~Profile~Change Password~Group~Logout~My Journey~Create a Cause~Friends&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Invite = typeof items[0] !== "undefined" ? items[0] : null;
          var Profile = typeof items[1] !== "undefined" ? items[1] : null;
          var ChangePasssword = typeof items[2] !== "undefined" ? items[2] : null;
          var Group = typeof items[3] !== "undefined" ? items[3] : null;
          var Logout = typeof items[4] !== "undefined" ? items[4] : null;
          var Myjourney = typeof items[5] !== "undefined" ? items[5] : null;
          var Createacause = typeof items[6] !== "undefined" ? items[6] : null;
          var Friends = typeof items[7] !== "undefined" ? items[7] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          this.setState({


            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            myjourney: Myjourney,
            createacause: Createacause,
            Friends:Friends,
            langID: LangID

          });

        });
    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Cancel&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;


          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;


          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;


          



          //localStorage.setItem("GoogleTranslate123",InviteFriendsDropdown)

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";

          }
          else

            if (datatranslirate == "Kannada") {
              datatransIDs = "/en/kn";
            }

            else
              if (datatranslirate == "Tamil") {
                datatransIDs = "/en/ta";
              }
              else
                if (datatranslirate == "Telugu") {
                  datatransIDs = "/en/te";
                }
                else
                  if (datatranslirate == "Hindi") {
                    datatransIDs = "/en/hi";
                  }
                  else
                  if (datatranslirate == "Spanish") {
                    datatransIDs = "/en/es";
                  }
                  else
                    if (datatranslirate == MID) {
                      datatransIDs = MID;
                    }
          let RefreshID = 0;
          window.sessionStorage.setItem('reload', (RefreshID));
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
          window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
          window.sessionStorage.setItem('GoogleTranslateValues', (datatransIDs));

          
          let googletrasvalues = localStorage.setItem("GoogleTranslateValues", datatransIDs);

          


          

          Cookies.remove('googtrans', { domain: '.paropakari.com' });
          Cookies.remove('googtrans', { domain: 'https://paropakari.com/' });

          Cookies.remove('googtrans', { domain: 'https://www.paropakari.com/' });
          let usernames = localStorage.getItem("GoogleTranslateValues", datatransIDs);
          let values = datatransIDs;
          
          

          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
            const expires = "expires=" + date.toUTCString();
            document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
            // window.location.reload();
          }

          Cookies.set('googtrans', datatransIDs, { domain: 'https://test.paropakari.com/#/' });
          Cookies.set('googtrans', datatransIDs, { domain: '.paropakari.com' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://www.paropakari.com/' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://paropakari.com/' });
          setCookie('googtrans', datatransIDs, 30);
          setCookie('googtrans', datatransIDs, 'https://test.paropakari.com/#/');
          setCookie('googtrans', datatransIDs, '.paropakari.com');
          setCookie('googtrans', datatransIDs, 'https://www.paropakari.com/');
          setCookie('googtrans', datatransIDs, 'https://paropakari.com/');
          let cookie = Cookies.get('googtrans', 'https://test.paropakari.com/#/');
          
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,


          });


        });
    }
  }
  renderIcon() {
    const longArray = [1, 2, 3];
    const size = 1;

    const newArray = new Array(Math.ceil(longArray.length / size)).fill("")
      .map(function () { return this.splice(0, size) }, longArray.slice());

    
    return (
      <div>{newArray}</div>
    )
  }

  componentDidMount() {

    window.addEventListener('load', this.Footer);
    this.Footer();
    window.addEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
    window.addEventListener('load', this.notify);
    this.notify();
    const element = document.querySelector('.VIpgJd-ZVi9od-ORHb-OEVmcd');
    if (element && window.getComputedStyle(element).getPropertyValue('position') === 'fixed') {
      element.style.position = 'absolute';
    }
    document.title = "India's only expenseless, direct crowdfunding platform"
  }

  componentWillUnmount() {

    window.removeEventListener('load', this.Footer);
    this.Footer();
    window.removeEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
    window.removeEventListener('load', this.notify);
    this.notify();
  }
  handleScriptCreate() {
    this.setState({ scriptLoaded: false })
  }

  handleScriptError() {
    this.setState({ scriptError: true })
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true })
  }
  render() {
    //   const {emps} = this.state;
    let { foo, Menu } = this.state;
    const { notify } = this.state;
    var noticount = JSON.parse(localStorage.getItem('noticount'));
    const { newArray, howitworks, beaparopakari, whatwedo, blog, login, indiaexpensless, ourstory, privacypolicy,
      invite, profile, changepassword, group, logout, myjourney, createacause,Friends,
      oppertunitybringsmile, contactus, termsandcondition, causes, beneficiaries, paropakaris, amountraised, copyright, whatwedodesc, encourageaultruism, crowdfunding, humanelement, nofeecommision, nomiddlemen, langID, weareindias, comingsoon } = this.state;
    if (localStorage.username != null) {
      var username = JSON.parse(localStorage.username);
    }
    return (
      <div className="VIpgJd-ZVi9od-ORHb-OEVmcd" style={{ position: "absolute" }}>
        <div style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }} className="notranslate">
          {/* <meta http-equiv="refresh" content="10"/>   */}
          <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
          <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
          <link href="./assets/css/lightbox.css" rel="stylesheet" />
          <link href="./assets/css/animate.min.css" rel="stylesheet" />
          <link href="./assets/css/main.css" rel="stylesheet" />

          {/*[if lt IE 9]>
              
              
          <![endif]*/}
          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />


          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />

          <select className="form-control" style={{ display: 'inline', width: '10%', marginTop: '0', marginLeft: '0', float: 'right', width: 'fit-content' }} value={this.state.value} onChange={this.handleChanges}>
            <option value="English">Languages</option>
            <option value="Kannada">Kannada</option>
            <option value="Tamil">Tamil</option>
            <option value="Hindi">Hindi</option>
            <option value="Telugu">Telugu</option>
             {/* <option value="Spanish">Spanish</option> */}
            <option value="English">English</option>
          </select>
          &nbsp;&nbsp; &nbsp;&nbsp;


          <div>
            {(() => {
              if (username != null) {
                return (
                  <div className="mag notranslate">
                    <div class="pro-home-nav">
                      <input type="checkbox" id="pro-home-nav-check" onClick={this.checkfun} />
                      <div class="pro-home-nav-header">
                        <div class="pro-home-nav-title notranslate">
                          <a className="navbar-brand" href="#/HomePage">
                            <h1 className="navbar-new-text"><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '300px' }} />AROPAKARI
                            </h1>
                          </a>
                        </div>
                      </div>

                      <div class="pro-home-nav-btn">
                        <label for="pro-home-nav-check">
                          <div className="pro-naav">
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        </label>
                      </div>

                      {langID == "English" ? <>
                        <div class="pro-home-nav-links pro-side pro-top">
                          <p class='pro-center'>
                            <a className="new-design" href="#/CauseFor">{beaparopakari.result}</a>
                          </p>
                          <ul className="parop-home-boxx parop-boxx-top">
                            <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                            <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                            <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                            <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                              <span class="-count">


                                {notifyy.noticount}

                              </span>))}</div></a></li>
                            <li className="parop-menu"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                              <ul class="dropdown">
                                <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                                <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                                <li className="hower-text"><a href="#/Group"><b>{group.result}</b></a></li>
                                <li className="hower-text"><LogoutLink logoutResult={logout.result} /></li> 
                              </ul>
                            </li>

                          </ul>
                        </div>
                      </> : langID == "Hindi" ? <>
                        <div class="pro-home-nav-links pro-side pro-top">
                          <p class='pro-center'>
                            <a className="new-design" href="#/CauseFor">{beaparopakari.result}</a>
                          </p>
                          <ul className="parop-home-boxx parop-boxx-top">
                            <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                            <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                            <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                            <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                              <span class="-count">


                                {notifyy.noticount}

                              </span>))}</div></a></li>
                            <li className="parop-menu"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                              <ul class="dropdown">
                                <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                                <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                                <li className="hower-text"><a href="#/Group"><b>{group.result}</b></a></li>
                                <li className="hower-text"><LogoutLink logoutResult={logout.result} /></li> 
                              </ul>
                            </li>

                          </ul>
                        </div>
                      </> : langID == "Tamil" ? <>
                        <div class="pro-home-nav-links pro-side pro-top">
                          <p class='pro-center'>
                            <a className="new-tamil-design" href="#/CauseFor">{beaparopakari.result}</a>
                          </p>
                          <ul className="parop-home-tamil-boxx parop-boxx-top">
                            <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                            <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                            <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                            <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                              <span class="-count">


                                {notifyy.noticount}

                              </span>))}</div></a></li>
                            <li className="parop-menu"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                              <ul class="dropdown">
                                <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                                <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                                <li className="hower-text"><a href="#/Group"><b>{group.result}</b></a></li>
                                <li className="hower-text"><LogoutLink logoutResult={logout.result} /></li> 
                              </ul>
                            </li>

                          </ul>
                        </div>
                        </> : langID == "Spanish" ? <>
                        <div class="pro-home-nav-links pro-side pro-top">
                          <p class='pro-center'>
                            <a className="new-tamil-design" href="#/CauseFor">{beaparopakari.result}</a>
                          </p>
                          <ul className="parop-home-tamil-boxx parop-boxx-top">
                            <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                            <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                            <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                            <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                              <span class="-count">


                                {notifyy.noticount}

                              </span>))}</div></a></li>
                            <li className="parop-menu"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                              <ul class="dropdown">
                                <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                                <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                                <li className="hower-text"><a href="#/Group"><b>{group.result}</b></a></li>
                                <li className="hower-text"><LogoutLink logoutResult={logout.result} /></li> 
                              </ul>
                            </li>

                          </ul>
                        </div>
                      </> : langID == "Kannada" ? <>
                        <div class="pro-home-nav-links pro-side pro-top">
                          <p class='pro-center'>
                            <a className="new-telugu-design" href="#/CauseFor">{beaparopakari.result}</a>
                          </p>
                          <ul className="parop-home-tamil-boxx parop-boxx-top">
                            <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                            <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                            <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                            <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                              <span class="-count">


                                {notifyy.noticount}

                              </span>))}</div></a></li>
                            <li className="parop-menu"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                              <ul class="dropdown">
                                <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                                <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                                <li className="hower-text"><a href="#/Group"><b>{group.result}</b></a></li>
                                <li className="hower-text"><LogoutLink logoutResult={logout.result} /></li> 
                              </ul>
                            </li>

                          </ul>
                        </div>
                      </> : langID == "Telugu" ? <>
                        <div class="pro-home-nav-links pro-side pro-top">
                          <p class='pro-center'>
                            <a className="new-telugu-design" href="#/CauseFor">{beaparopakari.result}</a>
                          </p>
                          <ul className="parop-home-tamil-boxx parop-boxx-top">
                            <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                            <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                            <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                            <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                              <span class="-count">


                                {notifyy.noticount}

                              </span>))}</div></a></li>
                            <li className="parop-menu"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                              <ul class="dropdown">
                                <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                                <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                                <li className="hower-text"><a href="#/Group"><b>{group.result}</b></a></li>
                                <li className="hower-text"><LogoutLink logoutResult={logout.result} /></li> 
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </> : null}
                    </div>
                  </div>

                );
              }
              else {
                return (

                  <div className="mag notranslate">
                    <div class="pro-nav">
                      <input type="checkbox" id="pro-nav-check" onClick={this.checkfun} />
                      <div class="pro-nav-header">
                        <div class="pro-nav-title notranslate">
                          <a className="navbar-brand" href="#/Index1">
                            <h1 className="navbar-new-text"><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '300px' }} />AROPAKARI
                            </h1>
                          </a>
                        </div>
                      </div>

                      <div class="pro-nav-btn">
                        <label for="pro-nav-check">
                          <div className="pro-naav">
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        </label>
                      </div>

                      {langID == "English" ? <>
                  <div class="pro-home-nav-links pro-side pro-top">
                    <p class='pro-center'>
                      <a className="new-design" href="#/CauseFor">{beaparopakari.result}</a>
                    </p>
                    <ul className="parop-home-boxx1 parop-boxx-top">
                      <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                      <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                      <li className="parop-menu"><a className="text-parop-menu" href="#/InviteFriend">{invite.result}</a></li>
                      <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                        <span class="-count">


                          {notifyy.noticount}

                        </span>))}</div></a></li>
                      <li className="parop-menu"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                        <ul class="dropdown" id="dropdownbox">
                          <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                          <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                          <li className="hower-text"><a href="#/Group"><b>{group.result}</b></a></li>
                          <li className="hower-text"><a href="#/Friends"><b>{Friends.result}</b></a></li>
                          <li className="hower-text"><LogoutLink logoutResult={logout.result} /></li> 
                        </ul>
                      </li>

                    </ul>
                  </div>
                </> : langID == "Hindi" ? <>
                  <div class="pro-home-nav-links pro-side pro-top">
                    <p class='pro-center'>
                      <a className="new-design" href="#/CauseFor">{beaparopakari.result}</a>
                    </p>
                    <ul className="parop-home-boxx1 parop-boxx-top">
                      <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                      <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                      <li className="parop-menu"><a className="text-parop-menu" href="#/InviteFriend">{invite.result}</a></li>
                      <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                        <span class="-count">


                          {notifyy.noticount}

                        </span>))}</div></a></li>
                      <li className="parop-menu"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                        <ul class="dropdown"   >
                          <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                          <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                          <li className="hower-text"><a href="#/Group"><b>{group.result}</b></a></li>
                          <li className="hower-text"><a href="#/Friends"><b>{Friends.result}</b></a></li>
                          <li className="hower-text"><LogoutLink logoutResult={logout.result} /></li> 
                        </ul>
                      </li>

                    </ul>
                  </div>
                </> : langID == "Tamil" ? <>
                  <div class="pro-home-nav-links pro-side pro-top">
                    <p class='pro-center'>
                      <a className="new-tamil-design" href="#/CauseFor">{beaparopakari.result}</a>
                    </p>
                    <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                      <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                      <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                      <li className="parop-menu"><a className="text-parop-menu" href="#/InviteFriend">{invite.result}</a></li>
                      <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                        <span class="-count">


                          {notifyy.noticount}

                        </span>))}</div></a></li>
                      <li className="parop-menu"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                        <ul class="dropdown" >
                          <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                          <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                          <li className="hower-text"><a href="#/Group"><b>{group.result}</b></a></li>
                          <li className="hower-text"><a href="#/Friends"><b>{Friends.result}</b></a></li>
                          <li className="hower-text"><LogoutLink logoutResult={logout.result} /></li> 
                        </ul>
                      </li>

                    </ul>
                  </div>
                </> : langID == "Kannada" ? <>
                  <div class="pro-home-nav-links pro-side pro-top">
                    <p class='pro-center'>
                      <a className="new-telugu-design" href="#/CauseFor">{beaparopakari.result}</a>
                    </p>
                    <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                      <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                      <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                      <li className="parop-menu"><a className="text-parop-menu" href="#/InviteFriend">{invite.result}</a></li>
                      <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                        <span class="-count">


                          {notifyy.noticount}

                        </span>))}</div></a></li>
                      <li className="parop-menu"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                        <ul class="dropdown">
                          <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                          <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                          <li className="hower-text"><a href="#/Group"><b>{group.result}</b></a></li>
                          <li className="hower-text"><a href="#/Friends"><b>{Friends.result}</b></a></li>
                          <li className="hower-text"><LogoutLink logoutResult={logout.result} /></li> 
                        </ul>
                      </li>

                    </ul>
                  </div>
                </> : langID == "Telugu" ? <>
                  <div class="pro-home-nav-links pro-side pro-top">
                    <p class='pro-center'>
                      <a className="new-telugu-design" href="#/CauseFor">{beaparopakari.result}</a>
                    </p>
                    <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                      <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                      <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                      <li className="parop-menu"><a className="text-parop-menu" href="#/InviteFriend">{invite.result}</a></li>
                      <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                        <span class="-count">


                          {notifyy.noticount}

                        </span>))}</div></a></li>
                      <li className="parop-menu"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                        <ul class="dropdown">
                          <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                          <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                          <li className="hower-text"><a href="#/Group"><b>{group.result}</b></a></li>
                          <li className="hower-text"><a href="#/Friends"><b>{Friends.result}</b></a></li>
                          <li className="hower-text"><LogoutLink logoutResult={logout.result} /></li> 
                        </ul>
                      </li>
                    </ul>
                  </div>
                </> : null}
                    </div>
                  </div>




                );
              }
            })()}

            {/* <header id="header" style={{backgroundImage: "url(" + "./assets/images/10.jpg" + ")"}}>    

         <div class="navbar navbar-inverse" role="banner">
            <div class="container" id="containerid">
                <div class="navbar-header">
                    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                        <span class="sr-only">Toggle navigassstion</span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>

                    <a class="navbar-brand" href="#/Index1">
                    	<h1 style={{color:'blue',fontFamily:'Biysk-Regular'}}><img src="./assets/images/plogo.png" alt="logo"style={{marginBottom:'17px',width:'26%',fontsize:'52px'}}  />AROPAKARI
                            
                        </h1>
                    </a>
                    
                </div>
                <div class="collapse navbar-collapse">
                      
       <ul className="nav navbar-nav navbar-right">

       <li id="paro" className="active ">
                   
 {(() => {if (langID == 'Tamil' ) {return (
       <li id="paro" className="active tamilbtn "><a className="btn1 third" id="tamilbtn1"  href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>

       {beaparopakari.result}
       </a></li>
);
}
else if (langID == 'English' ) {return (
  <li id="paro" className="active englishbtn"><a className="btn1 third" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>

  {beaparopakari.result}
  </a></li>
);
}
else if (langID == 'Hindi' ) {return (
  <li id="paro" className="active hindibtn"><a className="btn1 third" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra',marginTop:'2px',marginBottom:'-7px'}}>

  {beaparopakari.result}
  </a></li>
);
}
                 else {return(

                  <li id="paro" className="active olangbtn"><a className="btn1 third" id="homepagebutton" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>

           {beaparopakari.result}
           </a></li>

);}

})()}

</li>              





      <li>
      {(() => {if (langID == 'Tamil' ) {return (
<a href="#/Ourstory" style={{fontFamily:'cuyabra',fontsize:10,fontWeight: 'bold'}}>{ourstory.result}</a>

);
}
else {return(

<a href="#/Ourstory" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{ourstory.result}</a>

);}

})()} 
      
        
        </li> 
  <li>
  {(() => {if (langID == 'Tamil' ) {return (
<a href="#/Howitworks" style={{fontFamily:'cuyabra',fontsize:10,fontWeight: 'bold'}}>{howitworks.result}
    
</a>

);
}
else {return(

<a href="#/Howitworks" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{howitworks.result}
    
    </a>

);}

})()} 
    </li> 
  <li>
  {(() => {if (langID == 'Tamil' ) {return (
<a href="#/Blog" style={{fontFamily:'cuyabra',fontWeight: 'bold',fontsize:10}}>{blog.result}</a>

);
}
else {return(

<a href="#/Blog" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{blog.result}</a>

);}

})()} 
  
    
    </li>         
                     
  <li>
  {(() => {if (langID == 'Tamil' ) {return (
<a href="#/Login" style={{fontFamily:'cuyabra',fontWeight: 'bold',fontsize:10}}>{login.result}</a>

);
}
else {return(

<a href="#/Login" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{login.result}</a>

);}

})()} 

    
    
    </li>                    
</ul>
                </div>
            </div>
        </div>
</header> */}
            {/*/#header*/}

            {/*/#page-breadcrumb*/}
            <section id="about-company" className={this.state.movedown == true ? "wow fadeInUp" : "wow fadeInUp Tiptop"} data-wow-duration="400ms" data-wow-delay="400ms" style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}>
              <div className="container" id="containerid">
                <div class="row">
                  <div class="col-lg-12" style={{ color: 'black' }}>

                    <center><h2><b>{privacypolicy.result}</b></h2></center>
                    <br />
                    <p>
                      KDP Foundation (paropakari.com) recognises the importance of maintaining your privacy. We value your privacy and appreciate your trust in us. This Policy describes how we treat user information we collect on https://www.paropakari.com and other offline sources. This Privacy Policy applies to current and former visitors to our website and to our online customers. By visiting and/or using our website, you agree to this Privacy Policy.<br />
                      paropakari.com is a property of KDP Foundation, an Indian Charitable Organization registered in 2013 having its registered office at 80, Neelanjana, 6th Main, 2nd Cross, J P Nagar 3rd Phase, Bengaluru - 560078.<br />
                      <br />
                      <b>Information we collect</b><br />
                      <b>Contact information.</b>  We might collect your name, email, mobile number, phone number, street, city, state, pincode, country and ip address.<br />
                      <b>Information you post.</b> We collect information you post in a public space on our website or on a third-party social media site belonging to paropakari.com<br />
                      <b>Other information.</b>  If you use our website, we may collect information about your IP address and the browser you're using. We might look at what site you came from, duration of time spent on our website, pages accessed or what site you visit when you leave us. We might also collect the type of mobile device you are using, or the version of the operating system your computer or device is running.<br />
                      <b>We collect information in different ways.</b><br />
                      <b>We collect information directly from you.</b> We collect information directly from you when you register on our website. We also collect information if you post a comment on our websites or ask us a question through phone or email.<br />
                      <b>We collect information from you passively.</b>We use tracking tools like Google Analytics, Google Webmaster, browser cookies and web beacons for collecting information about your usage of our website.<br />
                      <b>We get information about you from third parties.</b>For example, if you use an integrated social media feature on our websites. The third-party social media site will give us certain information about you. This could include your name and email address.<br />
                      <br />

                      <b>Use of your personal information</b><br />
                      <b>We use information to contact you:</b> We might use the information you provide to contact you for confirmation for promotional purposes.<br />
                      <b>We use information to respond to your requests or questions.</b> We might use your information to confirm your registration on our website.<br />
                      <b>We use information to improve our products and services.</b> We might use your information to customize your experience with us. This could include displaying content based upon your preferences.<br />
                      <b>We use information to look at site trends and customer interests.</b> We may use your information to make our website and products better. We may combine information we get from you with information about you we get from third parties.<br />
                      <b>We use information for security purposes.</b> We may use information to protect our company, our customers, or our websites.<br />
                      <b>We use information for marketing purposes.</b> We might send you information about special promotions or offers. We might also tell you about new features or products. These might be our own offers or products, or third-party offers or products we think you might find interesting.<br />
                      <b>We use the information to send you transactional communications.</b> We might contact you via emails, phone or SMS(text messages) to deliver certain services or information about your account.<br />
                      We use information as otherwise permitted by law.<br />
                      <br />
                      <b>Sharing of information with third-parties</b><br />
                      <b>We will share information with third parties who perform services on our behalf.</b> We share information with vendors who help us manage our online registration process or payment processors or transactional message processors. Some vendors may be located outside of India.<br />
                      <b>We will share information with our business partners.</b> This includes a third party who provide or sponsor an event. Our partners use the information we give them as described in their privacy policies.<br />
                      <b>We may share information if we think we have to in order to comply with the law or to protect ourselves.</b> We will share information to respond to a court order or subpoena. We may also share it if a government agency or investigatory body requests. Or, we might also share information when we are investigating potential fraud.<br />
                      <b>We may share information with any successor to all or part of our business.</b> For example, if part of our business is sold we may give our customer list as part of that transaction.<br />
                      <b>We may share your information for reasons not described in this policy.</b> We will tell you before we do this.<br />
                      <b>Email Opt-Out</b><br />
                      You can opt out of receiving our marketing emails. To stop receiving our promotional emails, please email unsubscriber@paropakari.com. It may take about thirty days to process your request. Even if you opt out of getting marketing messages, we will still be sending you transactional messages through email and SMS about your content.<br />
                      <br />
                      <b>Third party sites</b><br />
                      If you click on one of the links to third party websites, you may be taken to websites we do not control. This policy does not apply to the privacy practices of those websites. Read the privacy policy of other websites carefully. We are not responsible for these third party sites.<br />
                      <br />
                      <b>Grievance Officer</b><br />

                      <b style={{ color: 'blue', textdecoration: 'underline' }}>If you have any questions about this Policy or other privacy concerns, you can also email us at contact@paropakari.com</b><br />
                      <br />
                      <b>Updates to this policy</b><br />
                      This Privacy Policy was last updated on 01.01.2022. From time to time we may change our privacy practices. We will notify you of any material changes to this policy as required by law. We will also post an updated copy on our website. Please check our site periodically for updates.<br />
                      <br />
                      <b>Jurisdiction</b><br />
                      If you choose to visit the website, your visit and any dispute over privacy is subject to this Policy and the website's terms of use. In addition to the foregoing, any disputes arising under this Policy shall be governed by the laws of India.<br />
                    </p>
                    <hr />

                  </div>
                </div>
              </div>
            </section>

            {/*/#team*/}
            <footer id="footer" style={{ color: 'white' }}>
              <br />
              <div className="container">
                <div className="copyright">

                  <div className="row col-lg-12">

                    <div className="col-lg-9 mb-6">
                      {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}


                      <p  >{copyright.result}&nbsp; |&nbsp;<a href="#/Contactuslogin" style={{ textDecoration: 'none', color: 'white' }}>{contactus.result}&nbsp;</a>| <a href="#/PrivacyPolicyLogin" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{privacypolicy.result}&nbsp;</a>|<a href="#/TermsandConditionLogin" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{termsandcondition.result}</a></p>

                    </div>
                    <div className="col-lg-3 mb-3">

                      <div className="social-icons" style={{ color: 'white' }}>
                        <ul className="nav nav-pills">
                          <li><a target="_blank" href="https://www.facebook.com/beoneparopakari"><i className="fa fa-facebook" /></a></li>
                          {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                          <li><a target="_blank" href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw" ><i className="fa fa-youtube-play" /></a></li>
                          <li><a target="_blank" href="https://www.instagram.com/paropakari_beone/" ><i className="fa fa-instagram" /></a></li>
                          <li><a target="_blank" href="https://www.linkedin.com/company/paropakari/"><i className="fa fa-linkedin" /></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="containerss">

              </div>
            </footer>

            {/*/#footer*/}
          </div>

        </div>
      </div>
    )
  }
}