import React, { Component } from "react";
import { Link } from "react-router-dom";
import Script from "react-load-script";
import ImagePreview from "./ImagePreview";
import Multipledd1 from "../Component/Multipledd1";
//import SideBars from './SideBars.js';
// import Demo from '../Component/Demo';
// import Sorting from  '../Component/Sorting';
// import {
//   PushSpinner, TraceSpinner, RainbowSpinner,
//   RingSpinner, SwishSpinner, PongSpinner,
//   MetroSpinner, JellyfishSpinner
// }
//   from "react-spinners-kit";
//import SideBars from './SideBars.js';
import GoogleTranslate from "./GoogleTranslate";
import Popup from "./Popup";
import Likepopup from "./Likepopup";
import Comment from "./Comment";
import Cookies from "js-cookie";
import TaggedFriends from "./TaggedFriends";
import LogoutLink from "./LogoutLink";
import { DocumentViewer } from "react-documents";
import { CardTravel, ContactSupportOutlined } from "@material-ui/icons";
import { PhotoProvider, PhotoView } from "react-photo-view";

const refreshPage = (e) => {
  setTimeout(() => window.location.reload(true), 2000);

  e.preventDefault();
};

export default class HomePage extends Component {
  constructor(props) {
    super(props);
    this.callchild = React.createRef();
    this.state = {
      // id : this.props.match.params.id,
      options: [
        {
          name: "Select…",
          value: null,
        },
        {
          name: "2017",
          value: "2017",
        },
        {
          name: "2018",
          value: "2018",
        },
        {
          name: "2019",
          value: "2019",
        },
        {
          name: "2020",
          value: "2020",
        },
        {
          name: "2021",
          value: "2021",
        },
      ],
      value: "?",
      benef: "",
      Gang: [],
      notify: [],
      Search: [],
      foo: [],
      Menu: [],
      beaparopakari: [],
      myjourney: [],
      createacause: [],
      invite: [],
      profile: [],
      changepassword: [],
      group: [],
      logout: [],
      search: [],
      categories: [],
      foodandnutrition: [],
      health: [],
      professional: [],
      sports: [],
      socialwefare: [],
      others: [],
      sort: [],
      mostrecent: [],
      mostpopular: [],
      available80g: [],
      amountdesc: [],
      amountasc: [],
      complete: [],
      loading: true,
      incomplete: [],
      education: [],
      enddate: [],
      causes: [],
      viewdetail: [],
      beneficiaries: [],
      paropakaris: [],
      amountraised: [],
      copyright: [],
      contactus: [],
      CommentHere: [],
      dropYourComment: [],
      Submit: [],
      friends: [],
      termsandcondition: [],
      privacypolicy: [],
      howitworks: [],
      blog: [],
      tag: [],
      like: [],
      comment: [],
      donateasmile: [],
      langID: [],
      clearall: [],
      value2: "?",
      googtrans: Cookies.get("googtrans", { domain: ".paropakari.com" }),
      movedown: true,
      // data:[],
      catval: "0",
      sortval: "0",

      imgSrc: "https://testapi.paropakari.com/Images/like.png",
      copyright_new: [],
      paropakari_new: [],
      imgSrc: "",
      fallback: false,
      PostLimit: 2,
      PostLoading: false,
    };
    this.submit = this.submit.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.DefaultMenu = this.DefaultMenu.bind(this);
    // this.refreshPage = this.refreshPage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.handleChangess = this.handleChangess.bind(this);
  }

  onKeyUp = (e) => {
    // filter post list by title using onKeyUp function

    // const Search = this.state.Gang.filter(
    //   (item) =>
    //     item.upload_title
    //       .toLowerCase()
    //       .includes(e.target.value.toLowerCase()) ||
    //     item.profile_name
    //       .toLowerCase()
    //       .includes(e.target.value.toLowerCase()) ||
    //     item.category_name
    //       .toLowerCase()
    //       .includes(e.target.value.toLowerCase()) ||
    //     item.upload_place
    //       .toLowerCase()
    //       .includes(e.target.value.toLowerCase()) ||
    //     item.profile_mobileNumber
    //       .toLowerCase()
    //       .includes(e.target.value.toLowerCase()) ||
    //     item.upload_amount.toLowerCase().includes(e.target.value.toLowerCase())
    // );

    let Search = this.state.Gang.filter(
      (item) =>
        item.upload_title.toLowerCase() === e.target.value.toLowerCase() ||
        item.profile_name.toLowerCase() === e.target.value.toLowerCase() ||
        item.category_name.toLowerCase() === e.target.value.toLowerCase() ||
        item.upload_place.toLowerCase() === e.target.value.toLowerCase() ||
        item.profile_mobileNumber.toLowerCase() ===
          e.target.value.toLowerCase() ||
        item.upload_amount.toLowerCase() === e.target.value.toLowerCase()
    ).concat(
      this.state.Gang.filter(
        (item) =>
          (item.upload_title
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) &&
            item.upload_title.toLowerCase() !== e.target.value.toLowerCase()) ||
          (item.profile_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) &&
            item.profile_name.toLowerCase() !== e.target.value.toLowerCase()) ||
          (item.category_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) &&
            item.category_name.toLowerCase() !==
              e.target.value.toLowerCase()) ||
          (item.upload_place
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) &&
            item.upload_place.toLowerCase() !== e.target.value.toLowerCase()) ||
          (item.profile_mobileNumber
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) &&
            item.profile_mobileNumber.toLowerCase() !==
              e.target.value.toLowerCase()) ||
          (item.upload_amount
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) &&
            item.upload_amount.toLowerCase() !== e.target.value.toLowerCase())
      )
    );
    function sortByCreatedDateDescending(data) {
      return data.sort((a, b) => {
        const dateA = new Date(a.created_date);
        const dateB = new Date(b.created_date);
        return dateB - dateA;
      });
    }

    const sortedData = sortByCreatedDateDescending(Search);
    if (this.state.PostLimit != 5) {
      this.setState({ PostLimit: 5 });
    }
    this.setState({ Search: sortedData });
  };

  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown,
    });
  };

  redirect = (e) => {
    e.preventDefault();
    var page = {
      pathname: "./Login.js",
    };
    this.props.history.push(page);
  };

  handlebenfChange = (evt) => {
    this.setState({ benef: evt.target.value });
  };

  handleyearChange = (event) => {
    this.setState({ value: event.target.value });
  };
  textLoading() {
    this.timer = setTimeout(() => {
      this.setState({ loading: false });
    }, 3000);
  }
  notify = () => {
    let url = "https://testapi.paropakari.com/api/GetProfile/Notification_Count";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())

      .then((data) => {
        this.setState({
          notify: data,
        });

        //

        localStorage.setItem("noticount", JSON.stringify(data[0].noticount));
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  Footer = () => {
    let url = "https://testapi.paropakari.com/api/Authenticate/Get_FooterDetails";
    let data = this.state;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          foo: data,
        });

        //
      });
  };

  ReloadPage = () => {
    let reloads = 0;
    if (sessionStorage.getItem("reload") !== null) {
      reloads = sessionStorage.getItem("reload");
      reloads++;
      window.sessionStorage.setItem("reload", reloads);
    } else {
      reloads = 1;
      window.sessionStorage.setItem("reload", reloads);
    }

    if (reloads == 1) {
      window.location.reload();
    }
  };

  DefaultMenu() {
    //
    let reloadsmyactivity = 0;
    window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
    window.sessionStorage.setItem("responsepagereload", reloadsmyactivity);
    window.sessionStorage.setItem("notificationpagereload", reloadsmyactivity);
    window.sessionStorage.setItem("confirmpagereload", reloadsmyactivity);
    window.sessionStorage.setItem("Indexreload", reloadsmyactivity);

    // let l_id = JSON.parse(localStorage.getItem('menuid'));
    let MID = "English";
    if (sessionStorage.getItem("translate") !== null) {
      MID = sessionStorage.getItem("translate");
    } else {
      MID = "English";
    }

    let GGID = this.state.googtrans;

    if (GGID != null || GGID == "undefined") {
      GGID = this.state.googtrans.slice(4, 6);
    } else {
      GGID = MID;
    }

    let datatrans = GGID;

    localStorage.setItem("GoogleTranslate123", this.state.googtrans);

    let GID = sessionStorage.getItem("this.state.googtrans");

    let GIDS = JSON.parse(localStorage.getItem("this.state.googtrans"));

    let datatransID = MID;
    if (datatrans == "en") {
      datatransID = "English";
    } else if (datatrans == "kn") {
      datatransID = "Kannada";
    } else if (datatrans == "ta") {
      datatransID = "Tamil";
    } else if (datatrans == "te") {
      datatransID = "Telugu";
    } else if (datatrans == "hi") {
      datatransID = "Hindi";
    } else if (datatrans == "es") {
      datatransID = "Spanish";
    } else if (datatrans == MID) {
      datatransID = MID;
    }

    window.sessionStorage.setItem("translate", datatransID);

    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Search&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          const longArray = data;
          const size = 1;
          var ary = data;

          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;
          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;
          var Search = typeof items[8] !== "undefined" ? items[8] : null;

          var LangID = datatransID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            myjourney: Myjourney,

            beaparopakari: Beaparopakari,

            createacause: Createacause,
            invite: Invite,

            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            search: Search,

            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Food and Nutrition~Health~Professional~Sports~Social Welfare~Others~Sort~Most Recent~Most popular~80G Available&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;

          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var FoodandNutrtion =
            typeof items[0] !== "undefined" ? items[0] : null;
          var Health = typeof items[1] !== "undefined" ? items[1] : null;
          var Professional = typeof items[2] !== "undefined" ? items[2] : null;
          var Sports = typeof items[3] !== "undefined" ? items[3] : null;
          var SocialWelfare = typeof items[4] !== "undefined" ? items[4] : null;
          var Others = typeof items[5] !== "undefined" ? items[5] : null;
          var Sort = typeof items[6] !== "undefined" ? items[6] : null;
          var MostRecent = typeof items[7] !== "undefined" ? items[7] : null;
          var MostPopular = typeof items[8] !== "undefined" ? items[8] : null;
          var Availabl80G = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = datatransID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            foodandnutrition: FoodandNutrtion,
            health: Health,
            langID: LangID,
            professional: Professional,
            sports: Sports,
            socialwefare: SocialWelfare,
            others: Others,
            sort: Sort,
            mostrecent: MostRecent,
            mostpopular: MostPopular,
            available80g: Availabl80G,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Amount Ascending~Amount Descending~Complete~Incomplete~HowItWorks~Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;

          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var AmountDesc = typeof items[0] !== "undefined" ? items[0] : null;
          var AmountAsc = typeof items[1] !== "undefined" ? items[1] : null;
          var Incomplete = typeof items[3] !== "undefined" ? items[3] : null;
          var Complete = typeof items[2] !== "undefined" ? items[2] : null;
          var Howitwork = typeof items[4] !== "undefined" ? items[4] : null;
          var Blog = typeof items[5] !== "undefined" ? items[5] : null;

          var Contactus = typeof items[6] !== "undefined" ? items[6] : null;
          var Termsandcondition =
            typeof items[7] !== "undefined" ? items[7] : null;
          var CopyRight = typeof items[8] !== "undefined" ? items[8] : null;
          var Causes = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = datatransID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            howitworks: Howitwork,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            blog: Blog,
            causes: Causes,

            amountasc: AmountAsc,
            amountdesc: AmountDesc,
            langID: LangID,

            complete: Complete,
            incomplete: Incomplete,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Beneficiaries~Paropakaris~Amount Raised~Education~End Date~Tag~Like~Comments~Donate a Smile~Clear all&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');

          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Beneficiaries = typeof items[0] !== "undefined" ? items[0] : null;
          var Paropakaris = typeof items[1] !== "undefined" ? items[1] : null;
          var Amountraised = typeof items[2] !== "undefined" ? items[2] : null;
          var Education = typeof items[3] !== "undefined" ? items[3] : null;
          var Enddate = typeof items[4] !== "undefined" ? items[4] : null;
          var Tag = typeof items[5] !== "undefined" ? items[5] : null;
          var Like = typeof items[6] !== "undefined" ? items[6] : null;
          var Comment = typeof items[7] !== "undefined" ? items[7] : null;
          var Donateasmile = typeof items[8] !== "undefined" ? items[8] : null;
          var clearall = typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = datatransID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            education: Education,
            enddate: Enddate,
            tag: Tag,
            like: Like,
            comment: Comment,
            donateasmile: Donateasmile,
            clearall: clearall,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=View Details~Comment Here~Submit~Friends~Drop Your Comment&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');

          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var ViewDetail = typeof items[0] !== "undefined" ? items[0] : null;
          var CommentHere = typeof items[1] !== "undefined" ? items[1] : null;
          var Submit = typeof items[2] !== "undefined" ? items[2] : null;
          var Friends = typeof items[3] !== "undefined" ? items[3] : null;
          var DropYourComment =
            typeof items[4] !== "undefined" ? items[4] : null;
          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            viewdetail: ViewDetail,
            CommentHere: CommentHere,
            friends: Friends,
            Submit: Submit,
            dropYourComment: DropYourComment,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari~Categories~Privacy Policy&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');

          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Categories = typeof items[2] !== "undefined" ? items[2] : null;
          var PrivacyPolicy = typeof items[3] !== "undefined" ? items[3] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
            categories: Categories,
            privacypolicy: PrivacyPolicy,
          });
        });
    }
  }

  handleChanges(event) {
    this.setState({ value: event.target.value });

    // localStorage.setItem('menuid', event.target.value);
    let values = [
      "OurStory",
      "HowItWorks",
      "Blog",
      "Login",
      "BeaParopakari",
      "What We Do",
    ];

    let l_id = JSON.parse(localStorage.getItem("MenuID"));

    let MID = event.target.value;
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Search&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;
          var Search = typeof items[8] !== "undefined" ? items[8] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            myjourney: Myjourney,

            beaparopakari: Beaparopakari,

            createacause: Createacause,
            invite: Invite,

            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            search: Search,

            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Food and Nutrition~Health~Professional~Sports~Social Welfare~Others~Sort~Most Recent~Most popular~80G Available&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var FoodandNutrtion =
            typeof items[0] !== "undefined" ? items[0] : null;
          var Health = typeof items[1] !== "undefined" ? items[1] : null;
          var Professional = typeof items[2] !== "undefined" ? items[2] : null;
          var Sports = typeof items[3] !== "undefined" ? items[3] : null;
          var SocialWelfare = typeof items[4] !== "undefined" ? items[4] : null;
          var Others = typeof items[5] !== "undefined" ? items[5] : null;
          var Sort = typeof items[6] !== "undefined" ? items[6] : null;
          var MostRecent = typeof items[7] !== "undefined" ? items[7] : null;
          var MostPopular = typeof items[8] !== "undefined" ? items[8] : null;
          var Availabl80G = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            foodandnutrition: FoodandNutrtion,

            health: Health,
            langID: LangID,
            professional: Professional,
            sports: Sports,
            socialwefare: SocialWelfare,
            others: Others,
            sort: Sort,
            mostrecent: MostRecent,
            mostpopular: MostPopular,
            available80g: Availabl80G,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Amount Ascending~Amount Descending~Complete~Incomplete~HowItWorks~Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var AmountDesc = typeof items[0] !== "undefined" ? items[0] : null;
          var AmountAsc = typeof items[1] !== "undefined" ? items[1] : null;
          var Incomplete = typeof items[3] !== "undefined" ? items[3] : null;
          var Complete = typeof items[2] !== "undefined" ? items[2] : null;
          var Howitwork = typeof items[4] !== "undefined" ? items[4] : null;
          var Blog = typeof items[5] !== "undefined" ? items[5] : null;

          var Contactus = typeof items[6] !== "undefined" ? items[6] : null;
          var Termsandcondition =
            typeof items[7] !== "undefined" ? items[7] : null;
          var CopyRight = typeof items[8] !== "undefined" ? items[8] : null;
          var Causes = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            howitworks: Howitwork,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            blog: Blog,
            causes: Causes,

            amountasc: AmountAsc,
            amountdesc: AmountDesc,
            langID: LangID,

            complete: Complete,
            incomplete: Incomplete,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Beneficiaries~Paropakaris~Amount Raised~Education~End Date~Tag~Like~Comments~Donate a Smile~Clear all&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Beneficiaries = typeof items[0] !== "undefined" ? items[0] : null;
          var Paropakaris = typeof items[1] !== "undefined" ? items[1] : null;
          var Amountraised = typeof items[2] !== "undefined" ? items[2] : null;
          var Education = typeof items[3] !== "undefined" ? items[3] : null;
          var Enddate = typeof items[4] !== "undefined" ? items[4] : null;
          var Tag = typeof items[5] !== "undefined" ? items[5] : null;
          var Like = typeof items[6] !== "undefined" ? items[6] : null;
          var Comment = typeof items[7] !== "undefined" ? items[7] : null;
          var Donateasmile = typeof items[8] !== "undefined" ? items[8] : null;
          var clearall = typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            education: Education,
            enddate: Enddate,
            tag: Tag,
            like: Like,
            comment: Comment,
            donateasmile: Donateasmile,
            clearall: clearall,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=View Details~Comment Here~Submit~Friends~Drop Your Comment&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var ViewDetail = typeof items[0] !== "undefined" ? items[0] : null;
          var CommentHere = typeof items[1] !== "undefined" ? items[1] : null;
          var Submit = typeof items[2] !== "undefined" ? items[2] : null;
          var Friends = typeof items[3] !== "undefined" ? items[3] : null;
          var DropYourComment =
            typeof items[4] !== "undefined" ? items[4] : null;
          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            viewdetail: ViewDetail,
            CommentHere: CommentHere,
            friends: Friends,
            Submit: Submit,
            dropYourComment: DropYourComment,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari~Categories~Privacy Policy&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Categories = typeof items[2] !== "undefined" ? items[2] : null;
          var PrivacyPolicy = typeof items[3] !== "undefined" ? items[3] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
            categories: Categories,
            privacypolicy: PrivacyPolicy,
          });
        });
    }
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });

    if (event.target.value == 0) {
      window.location.reload();
    }
    this.DefaultMenu();

    this.setState({
      catval: event.target.value,
    });
    let url = `https://testapi.paropakari.com/api/GetProfile/Get_HomeCategoryFilter?id=${this.state.sortval}&CategoryID=${event.target.value}`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let final = data.map((e) => {
          if (e) {
            let img = null;
            if (e.imageData !== null) {
              img = e.imageData.split(",").map((img) => {
                return {
                  file_name: img.split("$$$")[0],
                  file_type: img.split("$$$")[1],
                };
              });
            }
            return {
              ...e,
              data: e.imageData === null ? [] : img,
            };
          }
        });

        this.setState({
          Search: final,
        });
        if (this.state.PostLimit != 5) {
          this.setState({ PostLimit: 5 });
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  handleChangess(event) {
    this.setState({ value2: event.target.value });

    if (event.target.value == 0) {
    }
    this.setState({
      sortval: event.target.value,
    });
    let url = `https://testapi.paropakari.com/api/GetProfile/Get_HomeCategoryFilter?id=${event.target.value}&CategoryID=${this.state.catval}`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        let final = data.map((e) => {
          if (e) {
            let img = null;
            if (e.imageData !== null) {
              img = e.imageData.split(",").map((img) => {
                return {
                  file_name: img.split("$$$")[0],
                  file_type: img.split("$$$")[1],
                };
              });
            }
            return {
              ...e,
              data: e.imageData === null ? [] : img,
            };
          }
        });

        this.setState({
          Search: final,
        });
        if (this.state.PostLimit != 5) {
          this.setState({ PostLimit: 5 });
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }

  handlesubmit = async (event) => {
    try {
      event.preventDefault();

      const data = new FormData(event.target);
      let user = JSON.parse(localStorage.getItem("user"));

      const accessToken = user;
      const url = "https://testapi.paropakari.com/api/GetProfile/Get_PostDetailsFilter";
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
        },
        body: data,
      };
      this.setState(this.initialState);
      const response = await fetch(url, options);
      const result = await response.json();
      if (response.ok) {
      }
      this.setState(this.initialState);
    } catch (error) {
      console.error("An error occurred:", error);
      // You can also display an error message to the user.
    }
  };

  // handlesubmit1 = async event => {

  //   window.location.reload();

  // }

  submit = () => {
    try {
      // event.preventDefault();
      // let url = "https://testapi.paropakari.com/api/GetProfile/GetUserPost";
      let url = "https://testapi.paropakari.com/api/GetProfile/GetUserPost";
      let data = this.state;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
        //body:JSON.stringify(data)
      })
        .then((response) => response.json())
        .then((data) => {
          function sortByCreatedDateDescending(data) {
            return data.sort((a, b) => {
              const dateA = new Date(a.created_date);
              const dateB = new Date(b.created_date);
              return dateB - dateA;
            });
          }

          // Example usage:
          const sortedData = sortByCreatedDateDescending(data);

          let final = sortedData.map((e) => {
            if (e) {
              let img = null;
              if (e.imageData !== null) {
                img = e.imageData.split(",").map((img) => {
                  return {
                    file_name: img.split("$$$")[0],
                    file_type: img.split("$$$")[1],
                  };
                });
              }
              return {
                ...e,
                data: e.imageData === null ? [] : img,
              };
            }
          });

          this.setState({
            Gang: final,
            notify: final,
            Search: final,
          });
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  AddLiking = async (event) => {
    try {
      event.preventDefault();

      const data = new FormData(event.target);
      let user = JSON.parse(localStorage.getItem("user"));

      const accessToken = user;
      const url = "https://testapi.paropakari.com/api/GetProfile/Add_LikeDetails";
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
        },
        body: data,
      };
      this.setState(this.initialState);
      const response = await fetch(url, options);
      const result = await response.json();
      if (response.ok) {
        let url = `https://testapi.paropakari.com/api/GetProfile/Get_HomeCategoryFilter?id=${this.state.sortval}&CategoryID=${this.state.catval}`;
        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;
        fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            let final = data.map((e) => {
              if (e) {
                let img = null;
                if (e.imageData !== null) {
                  img = e.imageData.split(",").map((img) => {
                    return {
                      file_name: img.split("$$$")[0],
                      file_type: img.split("$$$")[1],
                    };
                  });
                }
                return {
                  ...e,
                  data: e.imageData === null ? [] : img,
                };
              }
            });

            this.setState({
              Search: final,
            });
          });
      }

      this.setState(this.initialState);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //   const data = new FormData(event.target);

  //   let user = JSON.parse(localStorage.getItem('user'));

  //   const accessToken = user;
  //   fetch('https://testapi.paropakari.com/api/GetProfile/Add_LikeDetails ', {
  //     method: 'POST',
  //     headers: {

  //       Accept: 'application/json',
  //       Authorization: "Bearer " + accessToken

  //     },
  //     body: data,
  //     Search: data,
  //   });

  // }

  handleMouseOver() {
    this.setState({
      imgSrc: "https://testapi.paropakari.com/Images/like.png",
    });
  }

  handleMouseOut() {
    this.setState({
      imgSrc: "https://paropakari.com/Images/liked.png",
    });
  }

  componentDidMount() {
    this.textLoading();
    this.submit();
    this.notify();
    this.Footer();
    this.ReloadPage();
    this.DefaultMenu();
    const element = document.getElementById('goog-gt-vt');
    if (element) {
      element.style.display = 'none'; // Hide the element if it exists
    }

    document.title = "India's only expenseless, direct crowdfunding platform";
    window.addEventListener("scroll", () => {
      const scrollTop = window.scrollY;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = window.innerHeight;
      // console.log(
      //   Math.round(scrollTop + clientHeight),
      //   Math.round(scrollHeight)
      // );
      if (
        Math.round(scrollTop + clientHeight) >= Math.round(scrollHeight - 100)
      ) {
        this.setState((prevstate) => ({
          PostLimit: prevstate.PostLimit + 2,
        }));
      }
    });
  }

  // componentWillUnmount() {
  //   window.removeEventListener('load', this.submit);
  //   this.submit();
  //   window.removeEventListener('load', this.notify);
  //   this.notify();
  //   window.removeEventListener('load', this.Footer);
  //   this.Footer();
  //   window.addEventListener('load', this.ReloadPage);
  //   this.ReloadPage();
  //   // window.removeEventListener('load', this.DefaultMenu);
  //   // this.DefaultMenu();
  // }
  componentWillUnmount() {
    clearTimeout(this.timer);
    const element = document.getElementById('goog-gt-vt');
    if (element) {
      element.style.display = 'none'; // Hide the element if it exists
    }
  }
  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  reload = () => {
    window.location.reload();
  };

  // refreshPage=()=>{

  //   window.location.reload();
  // }
  andleButtonClicked() {
    //window.location.reload();
  }
  searchTxt(e) {
    this.setState({ filter: e.target.value });
  }

  refreshcomment = () => {
    let url = `https://testapi.paropakari.com/api/GetProfile/Get_HomeCategoryFilter?id=${this.state.sortval}&CategoryID=${this.state.catval}`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())

      .then((data) => {
        this.setState({
          Search: data,
        });
      });
  };

  loadData = async (Id) => {
    let data = {};
    try {
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      let first_promise = new Promise((resolve, reject) => {
        if (user != null) {
          fetch(
            "https://testapi.paropakari.com/api/GetProfile/GetTop1ImagePreview?Id=" +
              Id,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          )
            .then((response) => response.json())
            .then((receivedData) => {
              data["data1"] = receivedData;

              resolve(receivedData);
            });
        }
      });

      let second_promise = new Promise((resolve, reject) => {
        fetch(
          "https://testapi.paropakari.com/api/GetProfile/GetImagePreview?Id=" + Id,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
          .then((response) => response.json())
          .then((receivedData1) => {
            data["data"] = receivedData1;

            resolve(receivedData1);
            //  setData(receivedData)
          });
      });
      try {
        let result = Promise.all([first_promise, second_promise]);
        result.then((data1) => {
          return data;
        });
      } catch (error) {}
    } catch (error) {}
  };
  showLoader = () => {
    document.getElementById("semiTransparenDiv").style.display = "block";
  };

  render() {
    //   const {emps} = this.state;

    // const { options, value } = this.state;

    // let {filter , emps} = this.state;

    // let Datasearch = emps.filter(emp =>{
    //      return Object.keys(emp).some(key=>
    //      typeof emp[key] === "string" &&  emp[key].toLowerCase().includes(filter.toLowerCase()))
    // }) ;

    // const{notify}=this.state;
    var noticount = JSON.parse(localStorage.getItem("noticount"));
    const { Search } = this.state;
    let { foo, Menu } = this.state;
    const { notify, loading } = this.state;
    const {
      howitworks,
      blog,
      contactus,
      termsandcondition,
      causes,
      beneficiaries,
      paropakaris,
      amountraised,
      copyright,
      beaparopakari,
      myjourney,
      createacause,
      invite,
      profile,
      changepassword,
      group,
      logout,
      search,
      categories,
      education,
      foodandnutrition,
      health,
      professional,
      sports,
      socialwefare,
      others,
      friends,
      sort,
      mostrecent,
      mostpopular,
      available80g,
      amountdesc,
      viewdetail,
      amountasc,
      complete,
      incomplete,
      CommentHere,
      dropYourComment,
      Submit,
      clearall,
      privacypolicy,
      enddate,
      tag,
      like,
      comment,
      donateasmile,
      langID,
      copyright_new,
      paropakari_new,
      PostLimit,
      PostLoading,
    } = this.state;
    // console.log("PostLimit", PostLimit);
    var username = JSON.parse(localStorage.username);
    const currentYear = new Date().getFullYear();
    const randomParam = Math.random();
    return (
      <div
        className="VIpgJd-ZVi9od-ORHb-OEVmcd"
        style={{ position: "absolute" }}
      >
        <div
          style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}
          // className="notranslate"
        >
          {/* <meta http-equiv="refresh" content="10"/>   */}
          <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
          <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
          <link href="./assets/css/lightbox.css" rel="stylesheet" />
          <link href="./assets/css/animate.min.css" rel="stylesheet" />
          <link href="./assets/css/main.css" rel="stylesheet" />
          {/*/head*/}
          <div onClick={refreshPage}>
            <select
              className="form-control notranslate"
              style={{
                display: "none",
                width: "10%",
                marginTop: "0",
                marginLeft: "0",
                float: "right",
                width: "fit-content",
              }}
              value={this.state.value}
              onChange={this.handleChanges}
            >
              <option value="English">Languages</option>
              <option value="Kannada">Kannada</option>
              <option value="Tamil">Tamil</option>
              <option value="Hindi">Hindi</option>
              <option value="Telugu">Telugu</option>
              <option value="English">English</option>
            </select>
          </div>
          {/* <GoogleTranslate></GoogleTranslate>    */}
          {/* <form onSubmit={this.refreshPage} onChange={this.handleChanges}>  */}
          <div
            id="google_translate_element"
            onClick={refreshPage}
            className="form-control"
            style={{
              display: "inline",
              width: "10%",
              marginTop: "0",
              marginLeft: "0",
              float: "right",
              width: "fit-content",
            }}
          >
            {/* <button onClick={refreshPage}>Click to reload!</button> */}
          </div>
          {/* </form>   */}
          &nbsp;&nbsp; &nbsp;&nbsp;
          <div>
            <div className="mag notranslate">
              <div class="pro-home-nav">
                <input
                  type="checkbox"
                  id="pro-home-nav-check"
                  onClick={this.checkfun}
                />
                <div class="pro-home-nav-header">
                  <div class="pro-home-nav-title notranslate">
                    <a className="navbar-brand" href="#/HomePage">
                      <h1 className="navbar-new-text">
                        <img
                          src="./assets/images/plogo.png"
                          alt="logo"
                          style={{
                            marginBottom: "17px",
                            width: "26%",
                            fontSize: "300px",
                          }}
                        />
                        AROPAKARI
                      </h1>
                    </a>
                  </div>
                </div>

                <div class="pro-home-nav-btn">
                  <label for="pro-home-nav-check">
                    <div className="pro-naav">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </label>
                </div>

                {langID == "English" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Hindi" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design notranslate" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu notranslate">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Tamil" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center" style={{ marginRight: "10px" }}>
                        <a className="new-tamil-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Kannada" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-telugu-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Spanish" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Telugu" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-telugu-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            {/* <header id="header" style={{backgroundImage: "url(" + "./assets/images/10.jpg" + ")"}} className="notranslate">
<div className="navbar navbar-inverse notranslate" role="banner">
   <div className="container" id="containerid">
       <div className="navbar-header">
           <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
               <span className="sr-only">Toggle navigassstion</span>
               <span className="icon-bar"></span>
               <span className="icon-bar"></span>
               <span className="icon-bar"></span>
           </button>

           <a className="navbar-brand" href="#/Homepage">
             <h1 style={{color:'blue',fontFamily:'Biysk-Regular'}}><img src="./assets/images/plogo.png" alt="logo" style={{marginBottom:'17px',width:'26%',fontSize:'52px'}}  />AROPAKARI

               </h1>
           </a>

       </div>
       <div className="collapse navbar-collapse">
           <ul className="nav navbar-nav navbar-right">

           <li id="paro" className="active ">
                   
                   {(() => {if (langID == 'Tamil' ) {return (
                         <li id="paro" className="active tamilbtn "><a className="btn1 third" id="tamilbtn1"  href="#/CauseFor" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>
                  
                         {beaparopakari.result}
                         </a></li>
                  );
                  }
                  else if (langID == 'English' ) {return (
                    <li id="paro" className="active englishbtn"><a className="btn1 third" href="#/CauseFor" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>
                  
                    {beaparopakari.result}
                    </a></li>
                  );
                  }
                  else if (langID == 'Hindi' ) {return (
                    <li id="paro" className="active hindibtn"><a className="btn1 third" href="#/CauseFor" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra',marginTop:'2px',marginBottom:'-7px'}}>
                  
                    {beaparopakari.result}
                    </a></li>
                  );
                  }
                                   else {return(
                  
                                    <li id="paro" className="active olangbtn"><a className="btn1 third" id="homepagebutton" href="#/CauseFor" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>
                  
                             {beaparopakari.result}
                             </a></li>
                  
                  );}
                  
                  })()}
                  
                  </li> 


               <li><a href="#/Myactivity"><b> {myjourney.result}</b></a></li>
               <li><a href="#/CauseFor"><b>{createacause.result}</b></a></li>
               <li><a href="#/InviteFriend"><b>{invite.result}</b></a></li>
               <li className=""><a href="#/Notification" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>

                            <div class="fa fa-bell" style={{fontSize:'24px'}}>{notify.map((notifyy) => (
                                                 <span class="-count">


                                  {noticount}

                                    </span> ))}</div>
                             </a></li>
              
               <li className="dropdown"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                   <ul role="menu" className="sub-menu">
                       <li><a href="#/Profile"><b>{profile.result}</b></a></li>
                       <li><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                       <li><a href="#/Group"><b>{group.result}</b></a></li>
                       <li><a href="#/Login"><b>{logout.result}</b></a></li>
                   </ul>
               </li>
                          </ul>
       </div>
       
   </div>
</div>
</header> */}
            {/*/#header*/}

            {/*/#page-breadcrumb*/}

            <div>
              {/*/#page-breadcrumb*/}
              <section
                id="blog"
                className={this.state.movedown == true ? null : "Tiptop"}
                style={{ backgroundColor: "#aee6db" }}
              >
                <div className="dropdown-container">
                  {/* use this section */}
                  <>
                    <div class="topcontainer notranslate">
                      <select
                        class="categories"
                        value={this.state.value}
                        onChange={this.handleChange}
                      >
                        <option value="0">{categories.result}</option>
                        <option value="8">{education.result}</option>
                        <option value="7">{foodandnutrition.result}</option>
                        <option value="6">{health.result}</option>
                        <option value="5">{professional.result}</option>
                        <option value="3">{sports.result}</option>
                        <option value="4">{socialwefare.result}</option>
                        <option value="2">{others.result}</option>
                      </select>

                      <select
                        class="sort"
                        value={this.state.value2}
                        onChange={this.handleChangess}
                      >
                        <option value="0">{sort.result}</option>
                        <option value="11">{mostrecent.result}</option>
                        <option value="12">{mostpopular.result}</option>
                        <option value="13">{available80g.result}</option>
                        <option value="15">{amountasc.result}</option>
                        <option value="14">{amountdesc.result}</option>
                        <option value="18">{complete.result}</option>
                        <option value="19">{incomplete.result}</option>
                      </select>

                      <input
                        type="text"
                        className="searchs "
                        onChange={this.onKeyUp}
                        placeholder={search.result}
                      />
                    </div>
                  </>

                  <div class="grid" style={{ width: "102%" }}>
                    {/* <div class="box drodown-box">
                      <select
                        className="form-control notranslate"
                        style={{ display: "inline", float: "right" }}
                        value={this.state.value}
                        onChange={this.handleChange}
                      >
                        <option value="0">{categories.result}</option>
                        <option value="8">{education.result}</option>
                        <option value="7">{foodandnutrition.result}</option>
                        <option value="6">{health.result}</option>
                        <option value="5">{professional.result}</option>
                        <option value="3">{sports.result}</option>
                        <option value="4">{socialwefare.result}</option>
                        <option value="2">{others.result}</option>
                      </select>
                    </div> */}
                    {/* <div class="box drodown-box">
                      <select
                        className="form-control notranslate"
                        style={{ display: "inline", float: "left" }}
                        value={this.state.value2}
                        onChange={this.handleChangess}
                      >
                        <option value="0">{sort.result}</option>
                        <option value="11">{mostrecent.result}</option>
                        <option value="12">{mostpopular.result}</option>
                        <option value="13">{available80g.result}</option>
                        <option value="15">{amountasc.result}</option>
                        <option value="14">{amountdesc.result}</option>
                        <option value="18">{complete.result}</option>
                        <option value="19">{incomplete.result}</option>
                      </select>
                    </div> */}
                    <div class="box">
                      {/* <input type="text" className="form-control notranslate" onChange={this.onKeyUp} placeholder={search.result} style={{width:"30%",float:"right"}}/> */}
                    </div>
                    {/* <div class="box" id="box3">
                      <input
                        type="text"
                        className="form-control notranslate "
                        onChange={this.onKeyUp}
                        placeholder={search.result}
                        style={{ float: "right", width: "47%" }}
                      />
                    </div> */}
                    <form
                      autoComplete="off"
                      method="post"
                      style={{ width: "", marginTop: "5px" }}
                      onSubmit={this.handlesubmit.bind(this)}
                    >
                      <div className="col-lg-4"></div>
                      <div className="col-lg-2"></div>
                    </form>
                    <div className="row">
                      <div
                        className="col-lg-12"
                        style={{ marginTop: "5px" }}
                      ></div>
                    </div>
                  </div>
                  <div className="inv-button" id="notifysidebar">
                    {(() => {
                      if (langID == "Tamil") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/InviteFriend/" + langID}
                          >
                            <div className="inv-button1" id="button-pulse">
                              <p className="inv-text">
                                &nbsp;&nbsp;&nbsp;{invite.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}
                    {(() => {
                      if (langID == "English") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/InviteFriend/" + langID}
                          >
                            <div className="inv-button1" id="button-pulse">
                              <p className="inv-text">
                                &nbsp;&nbsp;&nbsp;{invite.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}
                    {(() => {
                      if (langID == "Hindi") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/InviteFriend/" + langID}
                          >
                            <div className="inv-button1" id="button-pulse">
                              <p className="inv-text notranslate">
                                &nbsp;&nbsp;&nbsp;{invite.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}
                    {(() => {
                      if (langID == "Spanish") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/InviteFriend/" + langID}
                          >
                            <div className="inv-button1" id="button-pulse">
                              <p className="inv-text">
                                &nbsp;&nbsp;&nbsp;{invite.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}
                    {(() => {
                      if (langID == "Telugu") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/InviteFriend/" + langID}
                          >
                            <div className="inv-button1" id="button-pulse">
                              <p className="inv-text">
                                &nbsp;&nbsp;&nbsp;{invite.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}
                    {(() => {
                      if (langID == "Kannada") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/InviteFriend/" + langID}
                          >
                            <div className="inv-button1" id="button-pulse">
                              <p className="inv-text">
                                &nbsp;&nbsp;&nbsp;{invite.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}

                    {(() => {
                      if (langID == "Tamil") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/CauseFor/" + langID}
                          >
                            <div className="inv-button1">
                              <p className="inv-text">
                                &nbsp;&nbsp;&nbsp;{beaparopakari.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}

                    {(() => {
                      if (langID == "English") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/CauseFor/" + langID}
                          >
                            <div className="inv-button1">
                              <p className="inv-text">
                                &nbsp;&nbsp;&nbsp;{beaparopakari.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}

                    {(() => {
                      if (langID == "Hindi") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/CauseFor/" + langID}
                          >
                            <div className="inv-button1">
                              <p className="inv-text notranslate">
                                &nbsp;&nbsp;&nbsp;{beaparopakari.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}

                    {(() => {
                      if (langID == "Telugu") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/CauseFor/" + langID}
                          >
                            <div className="inv-button1">
                              <p className="inv-text">
                                &nbsp;&nbsp;&nbsp;{beaparopakari.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}
                    {(() => {
                      if (langID == "Kannada") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/CauseFor/" + langID}
                          >
                            <div className="inv-button1">
                              <p className="inv-text">
                                &nbsp;&nbsp;&nbsp;{beaparopakari.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}
                    {(() => {
                      if (langID == "Spanish") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/CauseFor/" + langID}
                          >
                            <div className="inv-button1">
                              <p className="inv-text">
                                &nbsp;&nbsp;&nbsp;{beaparopakari.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}
                    <div className="">
                      <a
                        onClick={this.topFunction}
                        id="myBtn"
                        title="Go to top"
                        style={{ display: "block", marginLeft: "75%" }}
                      >
                        <p
                          className="arrow-btn"
                          style={{ width: "100px", color: "rgb(102, 71, 18)" }}
                        >
                          <i
                            style={{
                              width: "100px",
                              color: "rgb(102, 71, 18)",
                            }}
                            class="fa1 fa-arrow-circle-up"
                          ></i>
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="container" id="containerid">
                  {/* <div>

       <Demo></Demo>

         <Sorting></Sorting>

       </div> */}

                  {/* <form autoComplete="off" method="post" width='25%' onSubmit={this.handlesubmit.bind(this)} >



<Multipledd1></Multipledd1>

<button type="submit" defaultValue="Create" className="btn btn-primary col-2">Filter</button>

</form>
*/}

                  {/*
  <td><Multipledd1></Multipledd1>  </td>
  <td> Beneficiaries  </td> */}

                  {/* <div className="row" style={{marginLeft: '0%', marginRight: '-6%'}}> */}
                  {/*
<span className=" ">
<div className="btn-group" style={{marginTop: "5px",width: "100%"}}>

</div>

<div className="row" style={{marginLeft: '0%', marginRight: '-6%' , width:'100%'}}>

         &nbsp;&nbsp;
</div>

</span>
&nbsp;&nbsp;&nbsp;  */}

                  {/* <form autoComplete="off" method="post" width='100%' onSubmit={this.handlesubmit.bind(this)} >
<br></br>

<Multipledd1></Multipledd1>

<button type="submit" defaultValue="Create" className="btn btn-primary col-2">Filter</button>

</form>  */}

                  {/* </div> */}

                  {/* <div className="container">   */}
                  <div className="row">
                    <div className="col-md-9 col-sm-7">
                      <div className="row">
                        {loading ? (
                          <p
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              fontSize: "109%",
                              paddingBottom: "3vh",
                              marginBottom: "0px",
                            }}
                          >
                            {" "}
                            Loading...{" "}
                          </p>
                        ) : //     <div className="spinner">
                        //     <MetroSpinner size={40} color="white"
                        //         loading={loading} />
                        // </div>
                        // big circle <div className="loader" />

                        //  first one  <div className="spinner">
                        //    <span>Loading...</span>
                        //    <div className="half-spinner"></div>
                        //  </div>

                        //    <div className="spinner" >
                        //     <div style={{marginTop:'-88%'}}>
                        //     <MetroSpinner size={40} color="white"
                        //         loading={loading} />

                        //         </div>
                        // </div>

                        Search.length === 0 ? (
                          <p
                            style={{ textAlign: "center", fontWeight: "bold" }}
                          >
                            {" "}
                            No Records Found{" "}
                          </p>
                        ) : (
                          Search.slice(0, PostLimit).map((emp) => (
                            <div className="col-lg-12 col-md-12">
                              {(() => {
                                if (emp.profile_name == username) {
                                  return (
                                    <div>
                                      <h3 style={{ fontSize: "15px" }}>
                                        <a
                                          href={"#/Confirm/" + emp.upload_id}
                                          style={{
                                            fontSize: "20px",
                                            textDecoration: "none",
                                            color: "black",
                                          }}
                                        >
                                          <b>{emp.upload_title}</b>
                                        </a>
                                        {(() => {
                                          if (emp.status_80G == "1") {
                                            return (
                                              <a
                                                style={{
                                                  marginLeft: "10px",
                                                  fontSize: "15px",
                                                  color: "gray",
                                                }}
                                              >
                                                <blink>
                                                  <b className="notranslate">
                                                    {available80g.result}
                                                  </b>
                                                </blink>
                                              </a>
                                            );
                                          } else {
                                            return <a></a>;
                                          }
                                        })()}
                                      </h3>
                                      {(() => {
                                        if (emp.causefor_Id == "0") {
                                          return (
                                            <p style={{ float: "" }}>
                                              Required Amount{" "}
                                              {emp.upload_amount}
                                            </p>
                                          );
                                        } else if (emp.causefor_Id == "1") {
                                          return (
                                            <p style={{ float: "" }}>
                                              Number of Beneficiaries{" "}
                                              {emp.upload_num_of_beneficiaries}
                                            </p>
                                          );
                                        }
                                      })()}
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div>
                                      <h3 style={{ fontSize: "15px" }}>
                                        <a
                                          href={
                                            "#/ResponsePage/" + emp.upload_id
                                          }
                                          style={{
                                            fontSize: "20px",
                                            textDecoration: "none",
                                            color: "black",
                                          }}
                                        >
                                          <b>{emp.upload_title}</b>
                                        </a>
                                        {(() => {
                                          if (emp.status_80G == "1") {
                                            return (
                                              <a
                                                style={{
                                                  marginLeft: "10px",
                                                  fontSize: "15px",
                                                  color: "gray",
                                                }}
                                              >
                                                <blink>
                                                  <b className="notranslate">
                                                    {available80g.result}
                                                  </b>
                                                </blink>
                                              </a>
                                            );
                                          } else {
                                            return <a></a>;
                                          }
                                        })()}
                                      </h3>
                                      {(() => {
                                        if (emp.causefor_Id == "0") {
                                          return (
                                            <p style={{ float: "" }}>
                                              Required Amount{" "}
                                              {emp.upload_amount}{" "}
                                              <input
                                                type="hidden"
                                                id="profilemobilenumber"
                                                name="profilemobilenumber"
                                                value={emp.profile_mobileNumber}
                                              />
                                            </p>
                                          );
                                        } else if (emp.causefor_Id == "1") {
                                          return (
                                            <p style={{ float: "" }}>
                                              Number of Beneficiaries{" "}
                                              {emp.upload_num_of_beneficiaries}
                                            </p>
                                          );
                                        }
                                      })()}
                                    </div>
                                  );
                                }
                              })()}

                              {/* <div className="row">
                                  <div className="col-lg-3 col-sm-3">
                                    {emp.category_name}  
                                  </div>
                                  <div className="col-lg-4 col-sm-5">
                                    <h4
                                      style={{
                                        fontSize: "15px",
                                        float: "right",
                                        color: "black",
                                        whiteSpace: "nowrap",
                                      }}>
                                      <b>{emp.upload_place} </b>
                                    </h4>
                                  </div>
                                  <div className="col-lg-4 col-sm-4">
                                    <p></p>
                                  </div>
                                </div> */}

                              <div className="row">
                                <div className="col-lg-4 col-sm-4">
                                  <p>{emp.category_name}</p>
                                </div>
                                <div className="col-lg-3 col-sm-3">
                                  <h4
                                    style={{
                                      fontSize: "15px",
                                      float: "right",
                                      color: "black",
                                    }}
                                  >
                                    <b>{emp.upload_place}</b>
                                  </h4>
                                </div>
                              </div>
                              <div className="row" id="imagesss">
                                {/* {(() => {
                                  if (emp.profile_name == username) {
                                    return (
                                      <div className="col-lg-7 col-sm-7">
                                        {(() => {
                                          if (
                                            emp.file_type == ".png" ||
                                            emp.file_type == ".jpg" ||
                                            emp.file_type == ".jfi" ||
                                            emp.file_type == ".jfif" ||
                                            emp.file_type == ".JPG" ||
                                            emp.file_type == ".jpeg"
                                          ) {
                                            return (

                                              <ImagePreview Id={emp.upload_id}>
                                                <a className="alttag" href="#" onClick={(e) => e.preventDefault()}>
                                                  {" "}
                                                  <img
                                                    src={emp.file_name}
                                                    className="img-responsive"
                                                    alt="Cause"
                                                  />
                                                </a>
                                              </ImagePreview>

                                            );
                                          } else if (emp.file_type == ".pdf" ||
                                            emp.file_type == ".docx" ||
                                            emp.file_type == ".doc" ||
                                            emp.file_type == ".xlsx") {
                                            return (
                                              <ImagePreview Id={emp.upload_id}>
                                                <a>
                                                  <iframe
                                                    src={"https://docs.google.com/gview?url=" + emp.file_name + "&embedded=true"}
                                                    autoplay="0"
                                                    style={{
                                                      width: "100%",
                                                      height: "300px",
                                                      background: "white",
                                                    }}
                                                    sandbox="allow-scripts allow-same-origin"
                                                  />
                                                </a>
                                              </ImagePreview>
                                            );
                                          } else if (emp.file_type == ".mp4") {
                                            return (
                                              <ImagePreview Id={emp.upload_id}>
                                                <a>
                                                  <iframe src={emp.file_name + "#toolbar=0"} autoplay="0" style={{width:'100%',height:'300px'}}/>
                                                  <video
                                                    width="100%"
                                                    height="300px"
                                                    controls
                                                    controlsList="nodownload"
                                                  >
                                                    <source
                                                      src={emp.file_name}
                                                      type="video/mp4"
                                                    />
                                                  </video>
                                                </a>
                                              </ImagePreview>
                                            );
                                          } else {
                                            return (
                                              <ImagePreview Id={emp.upload_id}>
                                                <a>
                                                  <iframe
                                                    src={"https://docs.google.com/gview?url=" + emp.file_name + "&embedded=true"}
                                                    autoplay="0"
                                                    style={{
                                                      width: "100%",
                                                      height: "300px",
                                                      background: "white",
                                                    }}
                                                    sandbox="allow-scripts allow-same-origin"
                                                  />
                                                </a>
                                              </ImagePreview>
                                            );
                                          }
                                        })()}
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div className="col-lg-7 col-sm-7">
                                        {(() => {
                                          if (
                                            emp.file_type == ".png" ||
                                            emp.file_type == ".jpg" ||
                                            emp.file_type == ".jfi" ||
                                            emp.file_type == ".jfif" ||
                                            emp.file_type == ".JPG" ||
                                            emp.file_type == ".jpeg"
                                          ) {
                                            return (
                                              <ImagePreview Id={emp.upload_id}>
                                                <a className="alttag" href="#" onClick={(e) => e.preventDefault()}>
                                                  {" "}
                                                  <img
                                                    src={emp.file_name}
                                                    className="img-responsive"
                                                    alt="Cause"
                                                  />
                                                </a>
                                              </ImagePreview>
                                            );
                                          } else if (emp.file_type == ".pdf" ||
                                            emp.file_type == ".docx" ||
                                            emp.file_type == ".doc" ||
                                            emp.file_type == ".xlsx") {
                                            return (
                                              <ImagePreview Id={emp.upload_id}>
                                                <a>
                                                  <iframe
                                                    src={"https://docs.google.com/gview?url=" + emp.file_name + "&embedded=true"}
                                                    autoplay="0"
                                                    style={{
                                                      width: "100%",
                                                      height: "300px",
                                                      background: "white",
                                                    }}
                                                    sandbox="allow-scripts allow-same-origin"
                                                  />
                                                </a>
                                              </ImagePreview>
                                            );
                                          } else if (
                                            emp.file_type == ".mp4" ||
                                            emp.file_type == ".webm"
                                          ) {
                                            return (
                                              <ImagePreview Id={emp.upload_id}>
                                                <a>
                                                  <iframe src={emp.file_name + "#toolbar=0"} autoplay="0" style={{width:'100%',height:'300px'}}/>

                                                  <video
                                                    width="100%"
                                                    height="300px"
                                                    controls
                                                    controlsList="nodownload"
                                                  >
                                                    <source
                                                      src={emp.file_name}
                                                      type="video/mp4"
                                                    />
                                                  </video>
                                                </a>
                                              </ImagePreview>
                                            );
                                          } else {
                                            return (
                                              <ImagePreview Id={emp.upload_id}>
                                                <a>
                                                  <iframe
                                                    src={"https://docs.google.com/gview?url=" + emp.file_name + "&embedded=true"}
                                                    autoplay="0"
                                                    style={{
                                                      width: "100%",
                                                      height: "300px",
                                                      background: "white",
                                                    }}
                                                    sandbox="allow-scripts allow-same-origin"
                                                  />
                                                </a>
                                              </ImagePreview>
                                            );
                                          }
                                        })()}
                                      </div>
                                    );
                                  }
                                })()} */}

                                {/* //////////////////////////////////// Using code////////////////////////////////  */}

                                {emp.profile_name == username ? (
                                  <div className="col-lg-7 col-sm-7">
                                    {emp.file_type == ".png" ||
                                    emp.file_type == ".jpg" ||
                                    emp.file_type == ".jfi" ||
                                    emp.file_type == ".jfif" ||
                                    emp.file_type == ".JPG" ||
                                    emp.file_type == ".jpeg" ? (
                                      <ImagePreview
                                        Id={emp.upload_id}
                                        datavalue={emp.data}
                                      >
                                        <a
                                          className="alttag"
                                          href="#"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          {" "}
                                          <embed
                                            src={emp.file_name + "#toolbar=0"}
                                            value={emp.file_name + "#toolbar=0"}
                                            title=""
                                            scrolling="no"
                                            className="img-responsive"
                                          ></embed>
                                        </a>
                                      </ImagePreview>
                                    ) : emp.file_type == ".docx" ||
                                      emp.file_type == ".doc" ||
                                      // emp.file_type == ".pdf" ||
                                      emp.file_type === ".xlsx" ? (
                                      <a>
                                        <DocumentViewer
                                          viewerUrl={
                                            "https://docs.google.com/gview?url=" +
                                            emp.file_name +
                                            "&embedded=true"
                                          }
                                          url={
                                            "https://docs.google.com/gview?url=" +
                                            emp.file_name +
                                            "&embedded=true"
                                          }
                                          viewer="url"
                                        ></DocumentViewer>
                                      </a>
                                    ) : emp.file_type == ".mp4" ||
                                      emp.file_type == ".webm" ? (
                                      <ImagePreview
                                        Id={emp.upload_id}
                                        datavalue={emp.data}
                                      >
                                        <a>
                                          <video
                                            width="100%"
                                            height="300px"
                                            controls
                                            controlsList="nodownload"
                                          >
                                            <source
                                              src={emp.file_name}
                                              type="video/mp4"
                                            />
                                          </video>
                                        </a>
                                      </ImagePreview>
                                    ) : emp.file_type == ".pdf" ? (
                                      // <a>
                                      //   <object data={emp.file_name} type="application/pdf" width="100%" height="100%">
                                      //   </object>
                                      // </a>
                                      <ImagePreview
                                        Id={emp.upload_id}
                                        datavalue={emp.data}
                                      >
                                        <a
                                          className="alttag"
                                          href="#"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          {" "}
                                          <embed
                                            src={emp.file_name + "#toolbar=0"}
                                            value={emp.file_name + "#toolbar=0"}
                                            title=""
                                            scrolling="no"
                                            className="img-responsive"
                                          ></embed>
                                        </a>
                                      </ImagePreview>
                                    ) : (
                                      <ImagePreview
                                        Id={emp.upload_id}
                                        datavalue={emp.data}
                                      >
                                        <a>
                                          <embed
                                            src={emp.file_name + "#toolbar=0"}
                                            value={emp.file_name + "#toolbar=0"}
                                            title=""
                                            scrolling="no"
                                            style={{
                                              width: "100%",
                                              height: "300px",
                                              background: "white",
                                            }}
                                          ></embed>
                                        </a>
                                      </ImagePreview>
                                    )}
                                  </div>
                                ) : (
                                  <div className="col-lg-7 col-sm-7">
                                    {emp.file_type == ".png" ||
                                    emp.file_type == ".jpg" ||
                                    emp.file_type == ".jfi" ||
                                    emp.file_type == ".jfif" ||
                                    emp.file_type == ".JPG" ||
                                    emp.file_type == ".jpeg" ? (
                                      <ImagePreview
                                        Id={emp.upload_id}
                                        datavalue={emp.data}
                                      >
                                        <a
                                          className="alttag"
                                          href="#"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          {" "}
                                          <embed
                                            src={emp.file_name + "#toolbar=0"}
                                            value={emp.file_name + "#toolbar=0"}
                                            title=""
                                            scrolling="no"
                                            className="img-responsive"
                                          ></embed>
                                        </a>
                                      </ImagePreview>
                                    ) : emp.file_type == ".docx" ||
                                      emp.file_type == ".doc" ||
                                      // emp.file_type == ".pdf" ||
                                      emp.file_type === ".xlsx" ? (
                                      <a>
                                        <DocumentViewer
                                          viewerUrl={
                                            "https://docs.google.com/gview?url=" +
                                            emp.file_name +
                                            "&embedded=true"
                                          }
                                          url={
                                            "https://docs.google.com/gview?url=" +
                                            emp.file_name +
                                            "&embedded=true"
                                          }
                                          viewer="url"
                                        ></DocumentViewer>
                                      </a>
                                    ) : emp.file_type == ".mp4" ||
                                      emp.file_type == ".webm" ? (
                                      <ImagePreview
                                        Id={emp.upload_id}
                                        datavalue={emp.data}
                                      >
                                        <a>
                                          <video
                                            width="100%"
                                            height="300px"
                                            controls
                                            controlsList="nodownload"
                                          >
                                            <source
                                              src={emp.file_name}
                                              type="video/mp4"
                                            />
                                          </video>
                                        </a>
                                      </ImagePreview>
                                    ) : emp.file_type == ".pdf" ? (
                                      // <a>
                                      //   <object data={emp.file_name} type="application/pdf" width="100%" height="100%">
                                      //   </object>
                                      // </a>
                                      <ImagePreview
                                        Id={emp.upload_id}
                                        datavalue={emp.data}
                                      >
                                        <a
                                          className="alttag"
                                          href="#"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          {" "}
                                          <embed
                                            src={emp.file_name + "#toolbar=0"}
                                            value={emp.file_name + "#toolbar=0"}
                                            title=""
                                            scrolling="no"
                                            className="img-responsive"
                                          ></embed>
                                        </a>
                                      </ImagePreview>
                                    ) : (
                                      <ImagePreview
                                        Id={emp.upload_id}
                                        datavalue={emp.data}
                                      >
                                        <a>
                                          <embed
                                            src={emp.file_name + "#toolbar=0"}
                                            value={emp.file_name + "#toolbar=0"}
                                            title=""
                                            scrolling="no"
                                            style={{
                                              width: "100%",
                                              height: "300px",
                                              background: "white",
                                            }}
                                          ></embed>
                                        </a>
                                      </ImagePreview>
                                    )}
                                  </div>
                                )}

                                <div className="col-lg-5 col-sm-5">
                                  <p></p>
                                </div>
                              </div>

                              <br />
                              <div className="row">
                                <div className="col-lg-4 col-sm-4">
                                  <p>
                                    <b>{emp.profile_name}'s Initiative</b>
                                  </p>
                                </div>
                                <div className="col-lg-3 col-sm-3">
                                  <p style={{ float: "right" }}>
                                    Posted on &nbsp;{emp.created_date}
                                  </p>
                                </div>
                                <div className="col-lg-5 col-sm-5">
                                  <p></p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-4 col-sm-4">
                                  <p>
                                    Required for {emp.relationship}
                                    {(() => {
                                      if (emp.relationship == "Self") {
                                        return (
                                          <span>
                                            ({emp.profile_name})
                                            {/* ({username}
                                          ) */}
                                          </span>
                                        );
                                      } else {
                                        return (
                                          <span>({emp.required_for})</span>
                                        );
                                      }
                                    })()}
                                  </p>
                                </div>
                                <div className="col-lg-3 col-sm-3">
                                  <p style={{ float: "right" }}>
                                    {enddate.result} &nbsp;{emp.required_by}
                                  </p>
                                </div>
                                <div className="col-lg-5 col-sm-5">
                                  <p></p>
                                </div>
                              </div>

                              {/* <div className="row">
           <div className="col-lg-4"><p>Required for {emp.relationship}({emp.required_for})</p></div>

           <div className="col-lg-3">
           {(() => {if (emp.causefor_Id == '0') {return (
                   <p style={{float:'right'}}>Required Amount {emp.upload_amount}</p>
                  );
                }
                else if (emp.causefor_Id == '1'){return(

                  <p style={{float:'right'}}>Number of Beneficiaries {emp.upload_num_of_beneficiaries}</p>
                )}
              })()}
           </div>
           <div className="col-lg-5"><p></p></div>

           </div> */}
                              {/*
            <div className="row">

            <div className="col-sm-6 col-md-6">
            <p style={{width:'700px'}}><a style={{width:'50%',textDecoration:'none',color:'black'}}>{emp.category_name}</a>
           </p>
              </div>
              <div className="col-sm-6 col-md-6">
            <p style={{width:'700px'}}><a style={{width:'50%',textDecoration:'none',color:'black', marginLeft:'2%'}}>{emp.upload_place}</a>

           </p>
              </div>
              </div> */}
                              <div className="single-blog single-column">
                                <div className="post-content overflow">
                                  {/* <p style={{width:'700px'}}>{emp.upload_description}</p>
                 <p><b className="notranslate">{enddate.result}</b> &nbsp;{emp.required_by}</p> */}

                                  {/* <a onClick={this.topFunction} id="myBtn" title="Go to top" style={{display: 'block',borderColor: 'transparent',textAlign:'right',fontSize:'60px'}}>

<i class="fa fa-arrow-circle-up" style={{fontSize:'18px',color:'rgb(102, 71, 18)'}}></i>
</a> */}

                                  <div className="post-bottom overflow col-lg-7 col-sm-7">
                                    <div className="col-lg-12">
                                      <div className="col-lg-4">
                                        {(() => {
                                          if (emp.profile_name == username) {
                                            return (
                                              <a style={{ color: "black" }}>
                                                <a
                                                  href={
                                                    "#/TagEdit/" + emp.upload_id
                                                  }
                                                  style={{
                                                    textDecoration: "none",
                                                    color: "black",
                                                  }}
                                                >
                                                  <i className="fa fa-tag" />
                                                </a>
                                                <b className="">
                                                  &nbsp;{emp.tagcount}&nbsp;
                                                  <TaggedFriends
                                                    trans={
                                                      tag.result
                                                        ? tag.result
                                                        : "Tags"
                                                    }
                                                    Id={emp.upload_id}
                                                  >
                                                    {" "}
                                                  </TaggedFriends>
                                                </b>
                                              </a>
                                            );
                                          } else {
                                            return (
                                              <a style={{ color: "black" }}>
                                                <i className="fa fa-tag" />
                                                <b className="">
                                                  &nbsp;{emp.tagcount} &nbsp;
                                                  <TaggedFriends
                                                    trans={
                                                      tag.result
                                                        ? tag.result
                                                        : "Tags"
                                                    }
                                                    Id={emp.upload_id}
                                                  >
                                                    {" "}
                                                  </TaggedFriends>
                                                </b>
                                              </a>
                                            );
                                          }
                                        })()}
                                      </div>

                                      <div className="col-lg-4">
                                        <a>
                                          <form
                                            onSubmit={this.AddLiking.bind(this)}
                                          >
                                            <input
                                              type="hidden"
                                              name="id"
                                              onChange={this.handlebenfChange}
                                              id="id"
                                              defaultValue={emp.upload_id}
                                            />

                                            <button
                                              type="submit"
                                              className="fa notranslate"
                                              style={{
                                                display: "contents",
                                                backgroundColor:
                                                  "rgb(174, 230, 219)",
                                                fontSize: "inherit",
                                                textRendering: "auto",
                                                WebkitFontSmoothing:
                                                  "antialiased",
                                                transform: "translate(0, 0)",
                                                fontFamily: "cuyabra",
                                              }}
                                            >
                                              {(() => {
                                                if (emp.uid == "true") {
                                                  return (
                                                    <img
                                                      onMouseOver={
                                                        this.handleMouseOver
                                                      }
                                                      onMouseOut={
                                                        this.handleMouseOut
                                                      }
                                                      src="https://testapi.paropakari.com/Images/like.png"
                                                      alt="like"
                                                      className="fa"
                                                      style={{
                                                        display: "inline-block",
                                                        fontSize: "inherit",
                                                        textRendering: "auto",
                                                        WebkitFontSmoothing:
                                                          "antialiased",
                                                        transform:
                                                          "translate(0, 0)",
                                                        height: "16px",
                                                        width: "16px",
                                                        border: "none",
                                                      }}
                                                    />
                                                  );
                                                } else {
                                                  return (
                                                    <img
                                                      onMouseOver={
                                                        this.handleMouseOver
                                                      }
                                                      onMouseOut={
                                                        this.handleMouseOut
                                                      }
                                                      src="https://paropakari.com/Images/liked.png"
                                                      alt="Unlike"
                                                      className="fa"
                                                      style={{
                                                        display: "inline-block",
                                                        fontSize: "inherit",
                                                        textRendering: "auto",
                                                        WebkitFontSmoothing:
                                                          "antialiased",
                                                        transform:
                                                          "translate(0, 0)",
                                                        height: "16px",
                                                        width: "16px",
                                                        border: "none",
                                                        outline: "none",
                                                      }}
                                                    />
                                                  );
                                                }
                                              })()}
                                              <b
                                                className="notranslate"
                                                style={{ color: "black" }}
                                              >
                                                &nbsp;{emp.likes} &nbsp;{" "}
                                              </b>
                                            </button>
                                          </form>
                                          <b style={{ color: "black" }}>
                                            <Likepopup
                                              Translated={
                                                emp.likes + like.result
                                                  ? like.result
                                                  : emp.likes + "Like"
                                              }
                                              Id={emp.upload_id}
                                              Url={`https://testapi.paropakari.com/api/GetProfile/Get_LikedUsers?id=${emp.upload_id}`}
                                              data={(data) =>
                                                this.setState({
                                                  data: data,
                                                })
                                              }
                                            >
                                              {" "}
                                            </Likepopup>
                                          </b>
                                        </a>
                                      </div>

                                      {(() => {
                                        if (emp.profile_name == username) {
                                          return (
                                            <div className="col-lg-4">
                                              <a style={{ color: "black" }}>
                                                <i className="fa fa-comments" />
                                                <b className="notranslate">
                                                  &nbsp;{emp.comments}
                                                  <Comment
                                                    Translated={
                                                      comment.result
                                                        ? comment.result
                                                        : "Comments"
                                                    }
                                                    Submit={
                                                      Submit.result
                                                        ? Submit.result
                                                        : "Submit"
                                                    }
                                                    CommentHere={
                                                      CommentHere.result
                                                        ? CommentHere.result
                                                        : "Comment Here"
                                                    }
                                                    dropYourComment={
                                                      dropYourComment.result
                                                        ? dropYourComment.result
                                                        : "Drop Your Comment"
                                                    }
                                                    Id={emp.upload_id}
                                                    refreshcomment={() =>
                                                      this.refreshcomment()
                                                    }
                                                    Url={`https://testapi.paropakari.com/api/GetProfile/Get_HomeCategoryFilter?id=${this.state.sortval}&CategoryID=${this.state.catval}`}
                                                    Data={(data) =>
                                                      this.setState({
                                                        Search: data,
                                                      })
                                                    }
                                                  />
                                                </b>
                                              </a>
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div className="col-lg-4">
                                              <a style={{ color: "black" }}>
                                                <i className="fa fa-comments" />
                                                <b className="notranslate">
                                                  &nbsp;{emp.comments}
                                                  <Comment
                                                    Translated={
                                                      comment.result
                                                        ? comment.result
                                                        : "Comments"
                                                    }
                                                    Submit={
                                                      Submit.result
                                                        ? Submit.result
                                                        : "Submit"
                                                    }
                                                    CommentHere={
                                                      CommentHere.result
                                                        ? CommentHere.result
                                                        : "Comment Here"
                                                    }
                                                    dropYourComment={
                                                      dropYourComment.result
                                                        ? dropYourComment.result
                                                        : "Drop Your Comment"
                                                    }
                                                    Id={emp.upload_id}
                                                    refreshcomment={() =>
                                                      this.refreshcomment()
                                                    }
                                                    Url={`https://testapi.paropakari.com/api/GetProfile/Get_HomeCategoryFilter?id=${this.state.sortval}&CategoryID=${this.state.catval}`}
                                                    Data={(data) =>
                                                      this.setState({
                                                        Search: data,
                                                      })
                                                    }
                                                  />
                                                </b>
                                              </a>
                                              {/* href={"#/ResponsePage/"+emp.upload_id}  */}
                                            </div>
                                          );
                                        }
                                      })()}
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="col-lg-1"></div>
                                      <div className="col-lg-10 col-md-10">
                                        <ul className="nav navbar-nav post-nav">
                                          {(() => {
                                            if (
                                              emp.confirmed >= "100" &&
                                              emp.causefor_Id == 1
                                            ) {
                                              return (
                                                <li
                                                  class="btn1 third"
                                                  style={{
                                                    backgroundColor:
                                                      "rgb(102, 71, 18)",
                                                    color: "white",
                                                    textDecoration: "none",
                                                    marginLeft: "90px",
                                                  }}
                                                >
                                                  <b
                                                    style={{
                                                      color: "white",
                                                      lineHeight: "20px",
                                                    }}
                                                  >
                                                    {" "}
                                                    100% Achieved
                                                  </b>{" "}
                                                </li>
                                              );
                                            } else if (
                                              emp.confirmed1 >= "100" &&
                                              emp.causefor_Id == 0
                                            ) {
                                              return (
                                                <li
                                                  class="btn1 third"
                                                  style={{
                                                    backgroundColor:
                                                      "rgb(102, 71, 18)",
                                                    color: "white",
                                                    textDecoration: "none",
                                                    marginLeft: "90px",
                                                  }}
                                                >
                                                  <b
                                                    style={{
                                                      color: "white",
                                                      lineHeight: "20px",
                                                    }}
                                                  >
                                                    {" "}
                                                    100% Achieved
                                                  </b>
                                                </li>
                                              );
                                            } else if (langID == "Tamil") {
                                              return (
                                                <li
                                                  class="btn1 third"
                                                  id="Donatesmilebutton"
                                                  style={{
                                                    backgroundColor:
                                                      "rgb(102, 71, 18)",
                                                    color: "white",
                                                    textDecoration: "none",
                                                  }}
                                                >
                                                  {(() => {
                                                    if (
                                                      emp.profile_name ==
                                                      username
                                                    ) {
                                                      return (
                                                        <Link
                                                          to={
                                                            "/Confirm/" +
                                                            emp.upload_id
                                                          }
                                                          style={{
                                                            backgroundColor:
                                                              "rgb(102, 71, 18)",
                                                            alignContent:
                                                              "space-around",
                                                            marginTop: "",
                                                            color: "white",
                                                            fontSize: 10,
                                                            textDecoration:
                                                              "none",
                                                          }}
                                                        >
                                                          {/* <input
                                                              type="image"
                                                              alt=""
                                                              src="assets/images/p3.png"
                                                            /> */}
                                                          &nbsp;
                                                          <b
                                                            style={{
                                                              color: "white",
                                                            }}
                                                          >
                                                            {viewdetail.result}
                                                          </b>
                                                        </Link>
                                                      );
                                                    } else {
                                                      return (
                                                        <Link
                                                          to={
                                                            "/ResponsePage/" +
                                                            emp.upload_id
                                                          }
                                                          style={{
                                                            backgroundColor:
                                                              "rgb(102, 71, 18)",
                                                            alignContent:
                                                              "space-around",
                                                            marginTop: "",
                                                            color: "white",
                                                            fontSize: 10,
                                                            textDecoration:
                                                              "none",
                                                          }}
                                                        >
                                                          {/* <input
                                                              type="image"
                                                              alt=""
                                                              src="~/Images/assets/p3.png"
                                                            /> */}
                                                          &nbsp;
                                                          <b
                                                            style={{
                                                              color: "white",
                                                            }}
                                                          >
                                                            {
                                                              donateasmile.result
                                                            }
                                                          </b>
                                                        </Link>
                                                      );
                                                    }
                                                  })()}
                                                </li>
                                              );
                                            } else {
                                              return (
                                                <li
                                                  class="btn1 third"
                                                  id="donatesmileb"
                                                  style={{
                                                    backgroundColor:
                                                      "rgb(102, 71, 18)",
                                                    color: "white",
                                                    textDecoration: "none",
                                                  }}
                                                >
                                                  {(() => {
                                                    if (
                                                      emp.profile_name ==
                                                      username
                                                    ) {
                                                      return (
                                                        <Link
                                                          to={
                                                            "/Confirm/" +
                                                            emp.upload_id
                                                          }
                                                          style={{
                                                            backgroundColor:
                                                              "rgb(102, 71, 18)",
                                                            color: "white",
                                                            fontSize: 10,
                                                            alignContent:
                                                              "space-around",
                                                            marginTop: "",
                                                            textDecoration:
                                                              "none",
                                                          }}
                                                        >
                                                          {/* <input
                                                              type="image"
                                                              alt=""
                                                              src="assets/images/p3.png"
                                                            /> */}
                                                          &nbsp;
                                                          <b
                                                            style={{
                                                              color: "white",
                                                            }}
                                                          >
                                                            {viewdetail.result}
                                                          </b>
                                                        </Link>
                                                      );
                                                    } else {
                                                      return (
                                                        <Link
                                                          to={
                                                            "/ResponsePage/" +
                                                            emp.upload_id
                                                          }
                                                          style={{
                                                            backgroundColor:
                                                              "rgb(102, 71, 18)",
                                                            color: "white",
                                                            alignContent:
                                                              "space-around",
                                                            marginTop: "",
                                                            fontSize: 10,
                                                            textDecoration:
                                                              "none",
                                                          }}
                                                        >
                                                          {/* <input
                                                              type="image"
                                                              alt=""
                                                              src="assets/images/p3.png"
                                                            /> */}
                                                          &nbsp;
                                                          <b
                                                            style={{
                                                              color: "white",
                                                            }}
                                                          >
                                                            {
                                                              donateasmile.result
                                                            }
                                                          </b>
                                                        </Link>
                                                      );
                                                    }
                                                  })()}
                                                </li>
                                              );
                                            }
                                          })()}
                                          {(() => {
                                            if (
                                              emp.confirmed <= "99" &&
                                              emp.causefor_Id == 1
                                            ) {
                                              return (
                                                <li
                                                  class="btn1 third"
                                                  style={{
                                                    backgroundColor:
                                                      "rgb(102, 71, 18)",
                                                    color: "white",
                                                    textDecoration: "none",
                                                  }}
                                                >
                                                  <b
                                                    style={{
                                                      color: "white",
                                                      lineHeight: "20px",
                                                    }}
                                                  >
                                                    {" "}
                                                    {emp.confirmed}% Achieved
                                                  </b>
                                                </li>
                                              );
                                            } else if (
                                              emp.confirmed1 <= "99" &&
                                              emp.causefor_Id == 0
                                            ) {
                                              return (
                                                <li
                                                  class="btn1 third"
                                                  style={{
                                                    backgroundColor:
                                                      "rgb(102, 71, 18)",
                                                    color: "white",
                                                    textDecoration: "none",
                                                  }}
                                                >
                                                  <b
                                                    style={{
                                                      color: "white",
                                                      lineHeight: "20px",
                                                    }}
                                                  >
                                                    {" "}
                                                    {emp.confirmed1}% Achieved
                                                  </b>
                                                </li>
                                              );
                                            }
                                          })()}
                                        </ul>
                                      </div>
                                      <div className="col-lg-1"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>

                    <div
                      className="col-md-1 col-sm-1"
                      style={{ fontSize: "25px", marginTop: "30%" }}
                    >
                      <div
                        style={{ position: "fixed", fontSize: "25px" }}
                      ></div>
                    </div>

                    {/* <div className="col-md-2 col-sm-2 notranslate" style={{marginTop:'15%'}}>
  <div className="sidebar blog-sidebar" id="notifysidebar" style={{position:'fixed'}}>
  {(() => {if (langID == 'Tamil' ) {return (
         <div className="sidebar-item  recent" id="button-pulse" style={{marginLeft:'2%',marginRight:'5%',color:'white',backgroundColor:'rgb(102, 71, 18)',borderColor:'transparent',border:'2px solid #63501e94',borderRadius:'15px'}}>

         <div className="media" >


           <div className="pull-left">
             <a href={"#/InviteFriend/"+langID} style={{textDecoration:'none',color:'white',marginLeft:'43%'}}>&nbsp;&nbsp;&nbsp;{invite.result}</a>
           </div>
           <div className="media-body">

           </div>
         </div>


       </div>
);
}

})()}

{(() => {if (langID == 'English' ) {return (
         <div className="sidebar-item  recent" id="button-pulse" style={{marginLeft:'36%',marginRight:'5%',color:'white',backgroundColor:'rgb(102, 71, 18)',borderColor:'transparent',border:'2px solid #63501e94',borderRadius:'15px'}}>

               <div className="media" >


                  <div className="pull-left">
                   <a href={"#/InviteFriend/"+langID} style={{textDecoration:'none',color:'white',marginLeft:'80%'}}>&nbsp;&nbsp;&nbsp;{invite.result}</a>
                  </div>
                  <div className="media-body">

                  </div>
                </div>


              </div>
);
}

})()}
{(() => {if ( langID == 'Hindi' ) {return (
         <div className="sidebar-item  recent" id="button-pulse" style={{marginLeft:'38%',marginRight:'5%',color:'white',backgroundColor:'rgb(102, 71, 18)',borderColor:'transparent',border:'2px solid #63501e94',borderRadius:'15px'}}>

               <div className="media" >


                  <div className="pull-left">
                   <a href={"#/InviteFriend/"+langID} style={{textDecoration:'none',color:'white',marginLeft:'60%'}}>&nbsp;&nbsp;&nbsp;{invite.result}</a>
                  </div>
                  <div className="media-body">

                  </div>
                </div>


              </div>
);
}

})()}

  {(() => {if (langID == 'Telugu' ) {return (
         <div className="sidebar-item  recent" id="button-pulse" style={{marginLeft:'22%',marginRight:'5%',color:'white',backgroundColor:'rgb(102, 71, 18)',borderColor:'transparent',border:'2px solid #63501e94',borderRadius:'15px'}}>

         <div className="media" >


           <div className="pull-left">
             <a href={"#/InviteFriend/"+langID} style={{textDecoration:'none',color:'white',marginLeft:'25%'}}>&nbsp;&nbsp;&nbsp;{invite.result}</a>
           </div>
           <div className="media-body">

           </div>
         </div>


       </div>
);
}

})()}
 {(() => {if (langID == 'Kannada' ) {return (
         <div className="sidebar-item  recent" id="button-pulse"  style={{marginLeft:'35%',marginRight:'5%',color:'white',backgroundColor:'rgb(102, 71, 18)',borderColor:'transparent',border:'2px solid #63501e94',borderRadius:'15px'}}>

         <div className="media" >


           <div className="pull-left">
             <a href={"#/InviteFriend/"+langID} style={{textDecoration:'none',color:'white',marginLeft:'37%'}}>&nbsp;&nbsp;&nbsp;{invite.result}</a>
           </div>
           <div className="media-body">

           </div>
         </div>


       </div>
);
}

})()}

{(() => {if (langID == 'Tamil' ) {return (
            <div className="sidebar-item  recent" style={{marginLeft:'2%',marginRight:'5%',marginBottom:'5px',color:'white',backgroundColor:'rgb(102, 71, 18)',borderColor:'transparent',border:'2px solid #63501e94',borderRadius:'15px'}}>


            <div className="media">
              <div className="pull-left">
              <a href={"#/CauseFor/"+langID} style={{textDecoration:'none',color:'white'}}>&nbsp;&nbsp;&nbsp;{beaparopakari.result}</a>
              </div>
              <div className="media-body">

              </div>
            </div>

          </div>
);
}

})()}
{(() => {if (langID == 'English' ) {return (
            <div className="sidebar-item  recent" style={{marginLeft:'36%',marginRight:'5%',marginBottom:'5px',color:'white',backgroundColor:'rgb(102, 71, 18)',borderColor:'transparent',border:'2px solid #63501e94',borderRadius:'15px'}}>


                               <div className="media">
                                 <div className="pull-left">
                                 <a href={"#/CauseFor/"+langID} style={{textDecoration:'none',color:'white'}}>&nbsp;&nbsp;&nbsp;{beaparopakari.result}</a>
                               </div>
                               <div className="media-body">

                                </div>
                               </div>

                             </div>
);
}


})()}
{(() => {if ( langID == 'Hindi' ) {return (
            <div className="sidebar-item  recent" style={{marginLeft:'38%',marginRight:'5%',marginBottom:'5px',color:'white',backgroundColor:'rgb(102, 71, 18)',borderColor:'transparent',border:'2px solid #63501e94',borderRadius:'15px'}}>


                               <div className="media">
                                 <div className="pull-left">
                                 <a href={"#/CauseFor/"+langID} style={{textDecoration:'none',color:'white'}}>&nbsp;&nbsp;&nbsp;{beaparopakari.result}</a>
                               </div>
                               <div className="media-body">

                                </div>
                               </div>

                             </div>
);
}


})()}
{(() => {if (langID == 'Telugu' ) {return (
            <div className="sidebar-item  recent" style={{marginLeft:'22%',marginRight:'5%',marginBottom:'5px',color:'white',backgroundColor:'rgb(102, 71, 18)',borderColor:'transparent',border:'2px solid #63501e94',borderRadius:'15px'}}>


            <div className="media">
              <div className="pull-left">
              <a href={"#/CauseFor/"+langID} style={{textDecoration:'none',color:'white'}}>&nbsp;&nbsp;&nbsp;{beaparopakari.result}</a>
              </div>
              <div className="media-body">

              </div>
            </div>

          </div>
);
}


})()}
{(() => {if (langID == 'Kannada' ) {return (
            <div className="sidebar-item  recent" style={{marginLeft:'35%',marginRight:'5%',marginBottom:'5px',color:'white',backgroundColor:'rgb(102, 71, 18)',borderColor:'transparent',border:'2px solid #63501e94',borderRadius:'15px'}}>


            <div className="media">
              <div className="pull-left">
              <a href={"#/CauseFor/"+langID} style={{textDecoration:'none',color:'white'}}>&nbsp;&nbsp;&nbsp;{beaparopakari.result}</a>
              </div>
              <div className="media-body">

              </div>
            </div>

          </div>
);
}


})()}


    <a onClick={this.topFunction} id="myBtn" title="Go to top" style={{display: 'block',borderColor: 'transparent',textAlign:'left',marginLeft:'80%',fontSize:'60px'}}>
<p style={{fontSize:'25px',sizes:'25',width:'100px',color:'rgb(102, 71, 18)'}}><i style={{fontSize:'25px',sizes:'25',width:'100px',color:'rgb(102, 71, 18)'}} class="fa1 fa-arrow-circle-up" ></i></p>

</a>


  </div>
</div> */}
                  </div>
                  {/* </div> */}
                </div>
                {PostLoading ? (
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "109%",
                      paddingBottom: "3vh",
                      marginBottom: "0px",
                    }}
                  >
                    {" "}
                    Loading...{" "}
                  </p>
                ) : null}
              </section>
            </div>
            {/*/#blog*/}

            <footer id="footer" style={{ color: "white" }}>
              <br />
              <div className="container notranslate">
                <div className="copyright">
                  <div className="row col-lg-12">
                    <div className="col-lg-9 mb-6">
                      {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}

                      <p>
                        {copyright_new.result} &nbsp;{currentYear} ©{" "}
                        {paropakari_new.result} |&nbsp;
                        <a
                          href="#/Contactuslogin"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          {contactus.result}&nbsp;
                        </a>
                        |{" "}
                        <a
                          href="#/PrivacyPolicyLogin"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          &nbsp;{privacypolicy.result}&nbsp;
                        </a>
                        |
                        <a
                          href="#/TermsandConditionLogin"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          &nbsp;{termsandcondition.result}
                        </a>
                      </p>
                    </div>
                    <div className="col-lg-3 mb-3">
                      <div className="social-icons" style={{ color: "white" }}>
                        <ul className="nav nav-pills">
                          <li>
                            <a
                              target="_blank"
                              href="https://www.facebook.com/beoneparopakari"
                            >
                              <i className="fa fa-facebook" />
                            </a>
                          </li>
                          {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                          <li>
                            <a
                              target="_blank"
                              href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw"
                            >
                              <i className="fa fa-youtube-play" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.instagram.com/paropakari_beone/"
                            >
                              <i className="fa fa-instagram" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.linkedin.com/company/paropakari/"
                            >
                              <i className="fa fa-linkedin" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="containerss"></div>
            </footer>
          </div>
          {/*/#footer*/}
        </div>
      </div>
    );
  }
}
