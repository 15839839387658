import React from "react";
import Select from "react-select";
import { Link } from "react-router-dom";


export default class Sampletest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {


      Years: {
        options: [
          { value: '2021', label: '2021' },
          { value: '2020', label: '2020' },
          { value: '2019', label: '2019' },
          { value: '2018', label: '2018' },
          { value: '2017', label: '2017' },
          { value: '2016', label: '2016' },
        ],
        value: '2021'
      },

      Categories: {
        options: [
          // { value: '0', label: 'Incomplete' },
          // { value: '1', label: '80G' },
          { value: '8', label: 'Education' },
          { value: '7', label: 'Food and Nutrition' },
          { value: '6', label: 'Health' },
          { value: '5', label: 'Professional' },
          { value: '4', label: 'Social welfare' },
          { value: '3', label: 'Sports' },
          { value: '2', label: 'Others' }


        ],
        value: '8'
      },


      Activity: {
        options: [
          { value: '0', label: 'My Causes' },
          { value: '2', label: 'Liked Causes' },
          { value: '3', label: 'Commented Causes' },
          { value: '4', label: 'Pledged Causes' }
        ],
        value: '0'
      },

      //  Details:[],
      //  dets: [] ,


    };

    this.submit = this.submit.bind(this);

  }

  submit() {
    let url = "https://testapi.paropakari.com/api/GetProfile/Get_MyPostDetail";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    
    




    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({

          // dets: data

        });
        

      });
  }


  componentDidMount() {
    window.addEventListener('load', this.submit);
    this.submit();

  }


  onSelectChange(name, value) {



    this.setState(
      (prev) => {
        return {
          ...prev,
          [name]: {
            ...prev[name],
            value: value.value
          }
        };
      },
      () => {

        let url =
          "https://testapi.paropakari.com/api/GetProfile/Get_MyPostDetails?id=" +
          this.state.Activity.value + "&Year=" +
          this.state.Years.value +
          "&CategoryID=" +
          this.state.Categories.value

          ;


        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;
        

        //

        fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
          }

          //body:JSON.stringify(data)

        })
          .then((response) => response.json())
          .then((data) => {

            this.setState({



              Details: data



            });

            
            // 
          });


      }
    );
  }



  AddLiking = async event => {
    event.preventDefault();


    const data = new FormData(event.target);
    let user = JSON.parse(localStorage.getItem('user'));

    const accessToken = user;
    const url = "https://testapi.paropakari.com/api/GetProfile/Add_LikeDetails";
    const options = {
      method: "POST",
      headers: {

        Accept: 'application/json',
        Authorization: "Bearer " + accessToken
      },
      body: data,
    };
    this.setState(this.initialState)
    const response = await fetch(url, options);
    const result = await response.json();
    if (response.ok) {

      // alert(' success')


      window.location.reload();

    }
    this.setState(this.initialState)
    

    

  }





  // send() {
  //   
  //   let url =
  //     "https://testapi.paropakari.com/api/GetProfile/Get_MyPostDetails?id=3&Year=" +
  //     this.state.years.value +
  //     "&CategoryID=" +
  //     this.state.categories.value;

  //   let user = JSON.parse(localStorage.getItem("user"));
  //   const accessToken = user;
  //   
  //   //
  //   fetch(url, {
  //     method: "GET",
  //     headers: {
  //       "Content-type": "application/json",
  //       Accept: "application/json",
  //       Authorization: "Bearer " + accessToken,
  //       "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
  //     }
  //     //body:JSON.stringify(data)
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       
  //       // 
  //     });
  // }



  render() {

    let { Details } = this.state;
    var username = JSON.parse(localStorage.username);
    //const{dets}=this.state;


    const customStyles = {
      control: (base, state) => ({
        ...base,
        backgroundColor: "white",
        align: "center",
        width: "25%",

        marginleft: "25%",


        //align:"center",
        //display:"none", 
      })
    };
    return (

      <div >



        {Object.keys(this.state).map((name, i) => {

          return (

            <div class="col-lg-2">
              <br />
              {/* <div style={{width: '60%',marginLeft: '1%', align:'center'  }}  > */}

              <Select className="flex-container"

                //key={i}
                //styles={customStyles}
                placeholder={name}
                //multi={true}
                //   multiple={true}

                options={this.state[name].options}


                onChange={this.onSelectChange.bind(this, name)}

              />



            </div>


          );
        })}


        {this.state.Details && this.state.Details.length > 0 && this.state.Details.map(det => (


          <div>

            {(() => {
              if (Details.user_id != 0) {
                return (




                  <div>

                    <h1> {Details.user_id}</h1>

                    <div>




                      {/* <tr key={det.user_id}>  */}

                      <div className="col-md-9 col-sm-7">
                        <div className="row">

                          <div className="col-sm-12 col-md-12">
                            <h3 style={{ fontsize: '15px' }}><a href={"#/ResponsePage/" + det.upload_id} style={{ fontsize: '20px', textDecoration: 'none', color: 'black' }}><b>{det.upload_title}</b></a></h3>
                            <p style={{}}><a style={{ width: '30%', textDecoration: 'none', color: 'black' }}>{det.category_name}</a>
                              {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                              <a style={{ width: '70%', textDecoration: 'none', color: 'black', marginLeft: '25%' }}>{det.upload_place}</a></p>
                            <div className="single-blog single-column">
                              <div className="post-thumb">

                                {(() => {
                                  if (det.file_type == '.png' || det.file_type == '.jpg' || det.file_type == '.JPG' || det.file_type == '.jpeg') {
                                    return (
                                      <a href={"#/ResponsePage/" + det.upload_id}><img src={det.file_name} className="img-responsive" alt /></a>



                                    );
                                  }
                                  else {
                                    return (
                                      <a href={"#/ResponsePage/" + det.upload_id}>
                                        <iframe src={det.file_name} style={{ width: '66%' }} />
                                      </a>
                                    );
                                  }

                                })()}
                                <div className="post-overlay">

                                </div>
                              </div>

                              <div className="post-content overflow">
                                <h3 className="post-author"><a href={"#/ResponsePage/" + det.upload_id} style={{ textDecoration: 'none', color: 'black' }}> <b>{det.profile_name}</b>

                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{det.created_date}
                                </a></h3>

                                <p style={{ width: '700px' }}>{det.upload_description}</p>
                                <p>{det.required_by}</p>

                                <div className="post-bottom overflow">
                                  <ul className="nav navbar-nav post-nav">

                                    <li><a href={"#/ResponsePage/" + det.upload_id}><i className="fa fa-tag" />{det.tagcount}&nbsp;Tag</a></li>
                                    <li><a href="#">




                                      <form onSubmit={this.AddLiking.bind(this)}>



                                        <input type="hidden" name="id" onChange={this.handlebenfChange} id="id" defaultValue={det.upload_id} />

                                        <button type="submit" className="fa" style={{ display: 'contents', backgroundColor: 'rgb(174, 230, 219)', fontsize: 'inherit', textRendering: 'auto', WebkitFontSmoothing: 'antialiased', transform: 'translate(0, 0)', fontFamily: 'cuyabra' }}>


                                          {(() => {
                                            if (det.uid == 'true') {
                                              return (

                                                <img onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut} src="https://paropakari.com/Images/like.png" className="fa" style={{ display: 'inline-block', fontsize: 'inherit', textRendering: 'auto', WebkitFontSmoothing: 'antialiased', transform: 'translate(0, 0)', height: "20px", width: "25px", border: 'none' }} />
                                              );
                                            }
                                            else {
                                              return (

                                                <img onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut} src="https://paropakari.com/Images/liked.png" className="fa" style={{ display: 'inline-block', fontsize: 'inherit', textRendering: 'auto', WebkitFontSmoothing: 'antialiased', transform: 'translate(0, 0)', height: "20px", width: "25px", border: 'none', outline: 'none' }} />);
                                            }



                                          })()}

                                          &nbsp;{det.likes} &nbsp;Like
                                        </button>


                                      </form>
                                    </a></li>
                                    <li><a href={"#/ResponsePage/" + det.upload_id}><i className="fa fa-comments" />{det.comments} Comments</a></li>

                                    {(() => {
                                      if (det.profile_name == username && det.likes == 0 && det.comments == 0 && det.confirmed == 0 && det.pending == 0) {
                                        return (

                                          <li><a href={"#/EditPage/" + det.upload_id}><i className="fa fa-edit" />Edit</a></li>
                                        );
                                      }
                                      else {
                                        return (

                                          <li><a href="#"></a></li>);
                                      }



                                    })()}



                                    {(() => {
                                      if (det.profile_name == username && det.likes == 0 && det.comments == 0 && det.confirmed == 0 && det.pending == 0) {
                                        return (

                                          <li>

                                            <i className="fa fa-trash-o" style={{ color: '#0099AE' }} /><button onClick={() => { this.DeleteGroup(det.upload_id) }}
                                              style={{
                                                backgroundColor: 'transparent',
                                                border: 'none', color: '#01464f'
                                              }}>Delete</button>


                                          </li>


                                        );
                                      }
                                      else {
                                        return (

                                          <li><a href="#"></a></li>);
                                      }



                                    })()}


                                    {(() => {
                                      if (det.profile_name == username) {
                                        return (

                                          <li class="btn1 third" style={{ backgroundColor: 'rgb(102, 71, 18)', color: 'white', textDecoration: 'none', margin: '-6px -11px -11px 0px' }}><Link to={"./Confirm/" + det.upload_id} style={{ backgroundColor: 'rgb(102, 71, 18)', color: 'white', fontsize: '14px', textDecoration: 'none' }}
                                            style={{ alignContent: 'space-around', marginTop: "", fontSize: 12 }} ><input type="image" alt="" src="assets/images/p3.png" />&nbsp;<b style={{ color: 'white' }}>View Detail</b>

                                          </Link> </li>

                                        );
                                      }
                                      else {
                                        return (

                                          <li class="btn1 third" style={{ backgroundColor: 'rgb(102, 71, 18)', color: 'white', textDecoration: 'none', margin: '-6px -11px -11px 0px' }}><Link to={"./ResponsePage/" + det.upload_id} style={{ backgroundColor: 'rgb(102, 71, 18)', color: 'white', fontsize: '14px', textDecoration: 'none' }}
                                            style={{ alignContent: 'space-around', marginTop: "", fontSize: 12 }} ><input type="image" alt="" src="assets/images/p3.png" />&nbsp;<b style={{ color: 'white' }}>Donate a Smile</b>

                                          </Link></li>

                                        );
                                      }



                                    })()}



                                  </ul>
                                </div>
                              </div>
                            </div>

                          </div>
                          {/* </tr> */}

                        </div>
                      </div>
                      <div className="col-md-1 col-sm-1" style={{ fontsize: '25px', marginTop: '30%' }}>
                        <div style={{ position: 'fixed', fontsize: '25px' }}>




                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2" style={{ marginTop: '15%' }}>
                        <div className="sidebar blog-sidebar" style={{ position: 'fixed' }}>

                          <div className="sidebar-item  recent" style={{ marginLeft: '36%', marginRight: '5%', color: 'white', backgroundColor: 'rgb(102, 71, 18)', borderColor: 'transparent', border: '2px solid #63501e94', borderRadius: '15px' }}>

                            <div className="media" >

                              <div className="pull-left">
                                <a href="#/InviteFriend" style={{ textDecoration: 'none', color: 'white', marginLeft: '70%' }}>&nbsp;&nbsp;&nbsp;Invite</a>
                              </div>
                              <div className="media-body">

                              </div>
                            </div>


                          </div>


                          <div className="sidebar-item  recent" style={{ marginLeft: '36%', marginRight: '5%', marginBottom: '5px', color: 'white', backgroundColor: 'rgb(102, 71, 18)', borderColor: 'transparent', border: '2px solid #63501e94', borderRadius: '15px' }}>


                            <div className="media">
                              <div className="pull-left">
                                <a href="#/Create" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;&nbsp;&nbsp;Be a Paropakari</a>
                              </div>
                              <div className="media-body">

                              </div>
                            </div>

                          </div>
                          <a onClick={this.topFunction} id="myBtn" title="Go to top" style={{ display: 'block', borderColor: 'transparent', textAlign: 'left', marginLeft: '80%', fontsize: '60px' }}>
                            <p style={{ fontsize: '25px', sizes: '25', width: '100px', color: 'rgb(102, 71, 18)' }}><i style={{ fontsize: '25px', sizes: '25', width: '100px', color: 'rgb(102, 71, 18)' }} class="fa1 fa-arrow-circle-up" ></i></p>

                          </a>


                        </div>
                      </div>


                    </div>







                  </div>




                );
              }



            })()}







          </div>
        ))}











        <div>



        </div>



        {/* <div>

      {Details.map((item) =>   (

        <li key={item.user_id}>
          
        <p> {item.profile_name}</p>
       
       </li>

      ))}

</div>   */}



      </div>



    );
  }
}
