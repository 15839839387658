
import React, { Component } from 'react';
import { Link } from "react-router-dom";
// import '../assets/css/bootstrap.min.css';


export default class MyActivitySort extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      filter: "",
      Details: [],
      foo: [],
      Menu: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangesCategory = this.handleChangesCategory.bind(this);
    this.handleChangess = this.handleChangess.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.handleMenuChanges = this.handleMenuChanges.bind(this);
  }

  handleChange(event) {


    this.setState({ value: event.target.value });

    let url =
      "https://testapi.paropakari.com/api/GetProfile/Get_MyPostDetails?id=6" +
      //event.target.value+
      // this.state.Sort.value + 
      "&Year=" + event.target.value +
      //  this.state.Years.value +
      "&CategoryID=0"

      ;


    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    

    //

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      }

      //body:JSON.stringify(data)

    })
      .then((response) => response.json())
      .then((data) => {

        this.setState({



          Details: data

        });

        
        // 
      });


  }

  handleChangesCategory(event) {


    this.setState({ value: event.target.value });

    let url =
      "https://testapi.paropakari.com/api/GetProfile/Get_MyPostDetails?id=5" +
      //event.target.value+
      // this.state.Sort.value + 
      "&Year=0" +
      //  this.state.Years.value +
      "&CategoryID=" + event.target.value


      ;


    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    

    //

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      }

      //body:JSON.stringify(data)

    })
      .then((response) => response.json())
      .then((data) => {

        this.setState({



          Details: data

        });

        
        // 
      });


  }

  handleChangess(event) {


    this.setState({ value: event.target.value });

    let url =
      "https://testapi.paropakari.com/api/GetProfile/Get_MyPostDetails?id=" +
      event.target.value +
      // this.state.Sort.value + 
      "&Year=0" +
      //  this.state.Years.value +
      "&CategoryID=0"


      ;


    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    

    //

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      }

      //body:JSON.stringify(data)

    })
      .then((response) => response.json())
      .then((data) => {

        this.setState({



          Details: data

        });

        
        // 
      });


  }

  //  handleMenuChanges(event) { 


  //   this.setState({value: event.target.value}); 

  //   let url =
  //   "https://testapi.paropakari.com/api/Authenticate/Translate?id="+
  //   event.target.value

  //   ;


  //   let user = JSON.parse(localStorage.getItem("user"));
  //   const accessToken = user;
  //   



  //   fetch(url, {
  //     method: "GET",
  //     headers: {
  //       "Content-type": "application/json",
  //       Accept: "application/json",
  //       Authorization: "Bearer " + accessToken,
  //       "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
  //     }



  //   })
  //   .then((response) => response.json())
  //   .then((data) => {

  //      this.setState({



  //        Menu: data

  //      });

  //     

  //   });


  //      }


  handleSubmit(event) {
    alert('Your favorite flavor is: ' + this.state.value);
    event.preventDefault();
  }

  AddLiking = async event => {
    event.preventDefault();


    const data = new FormData(event.target);
    let user = JSON.parse(localStorage.getItem('user'));

    const accessToken = user;
    const url = "https://testapi.paropakari.com/api/GetProfile/Add_LikeDetails";
    const optionss = {
      method: "POST",
      headers: {

        Accept: 'application/json',
        Authorization: "Bearer " + accessToken
      },
      body: data,
    };
    this.setState(this.initialState)
    const response = await fetch(url, optionss);
    const result = await response.json();
    if (response.ok) {

      // alert(' success')


      window.location.reload();

    }
    this.setState(this.initialState)
    

    

  }

  DefaultMenu() {

    
    let menuid = JSON.parse(localStorage.getItem('menuid'));
    // localStorage.setItem('menuid', JSON.stringify(this.props.match.params.id));

    let url = "https://testapi.paropakari.com/api/Authenticate/Translate?id=" + menuid;
    let data = this.state;

    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          Menu: data
        });
        
        // 
      });
  }


  handleChanges(event) {


    this.setState({ value: event.target.value });

    let url =
      "https://testapi.paropakari.com/api/Authenticate/Translate?id=" +
      event.target.value

      ;


    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    

    //

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      }

      //body:JSON.stringify(data)

    })
      .then((response) => response.json())
      .then((data) => {

        this.setState({



          Menu: data

        });

        
        // 
      });


  }



  componentDidMount() {

    window.addEventListener('load', this.DefaultMenu);
    this.DefaultMenu();

  }

  componentWillUnmount() {

    window.removeEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
  }


  render() {
    let { filter, Details, Menu } = this.state;
    var username = JSON.parse(localStorage.username);
    return (


      <div className="col-md-9 col-sm-7">
        {Menu.map((menu) => (
          <div className="row">


            <select className="form-control" style={{ display: 'inline', width: '25%', marginTop: '1%', marginLeft: '1%' }} value={this.state.value} onChange={this.handleChange}>
              <option value="0">{menu.yearr}</option>
              <option value="2021">2021</option>
              <option value="2020">2020</option>
              <option value="2019">2019</option>
              <option value="2018">2018</option>
              <option value="2017">2017</option>
              <option value="2016">2016</option>
            </select>
            &nbsp;&nbsp; &nbsp;&nbsp;

            <select className="form-control" style={{ display: 'inline', width: '25%' }} value={this.state.value} onChange={this.handleChangesCategory}>
              <option value="0">{menu.category}</option>
              <option value="8">{menu.education}</option>
              <option value="7">{menu.foodandNutrition}</option>
              <option value="6">{menu.health}</option>
              <option value="5">{menu.professional}</option>
              <option value="4">{menu.socialwelfare}</option>
              <option value="3">{menu.sports}</option>
              <option value="2">{menu.others}</option>
            </select>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <select className="form-control" style={{ display: 'inline', width: '25%' }} value={this.state.value} onChange={this.handleChangess}>
              <option value="10">{menu.activity}</option>
              <option value="11">{menu.myCauses}</option>
              <option value="12">{menu.likedCauses}</option>
              <option value="13">{menu.commentedCauses}</option>
              <option value="14">{menu.pledgedCauses}</option>

            </select>
            {Details.map((emp) => (
              <div className="col-lg-12 col-md-12">



                <h3 style={{ fontsize: '15px' }}><a href={"#/ResponsePage/" + emp.upload_id} style={{ fontsize: '20px', textDecoration: 'none', color: 'black' }}><b>{emp.upload_title}</b></a></h3>

                <div className="row">
                  <div className="col-lg-4"><p>{emp.category_name}</p></div>
                  <div className="col-lg-4"><p style={{ float: 'right' }}>{emp.upload_place}</p></div>
                  <div className="col-lg-4"><p></p></div>

                </div>
                <div className="row">
                  <div className="col-lg-8">
                    {(() => {
                      if (emp.file_type == '.png' || emp.file_type == '.jpg' || emp.file_type == '.JPG' || emp.file_type == '.jpeg') {
                        return (
                          <a href={"#/ResponsePage/" + emp.upload_id}><img src={emp.file_name} className="img-responsive" alt /></a>



                        );
                      }
                      else {
                        return (
                          <a href={"#/ResponsePage/" + emp.upload_id}>
                            <iframe src={emp.file_name} style={{ width: '100%', height: '300px' }} />
                          </a>
                        );
                      }

                    })()}

                  </div>

                  <div className="col-lg-4"><p></p></div>

                </div>

                <div className="row">
                  <div className="col-lg-4"><p>{emp.profile_name}</p></div>
                  <div className="col-lg-4"><p style={{ float: 'right' }}>{emp.created_date}</p></div>
                  <div className="col-lg-4"><p></p></div>

                </div>

                {/* 
          <div className="row">

         
       
          <div className="col-sm-6 col-md-6">
          <p style={{width:'700px'}}><a style={{width:'50%',textDecoration:'none',color:'black'}}>{emp.category_name}</a>
         </p>
            </div>
            <div className="col-sm-6 col-md-6">
          <p style={{width:'700px'}}><a style={{width:'50%',textDecoration:'none',color:'black', marginLeft:'2%'}}>{emp.upload_place}</a>

         </p>
            </div>
            </div> */}
                <div className="single-blog single-column">


                  <div className="post-content overflow">


                    <p style={{ width: '700px' }}>{emp.upload_description}</p>
                    <p><b>End Date</b> &nbsp;{emp.required_by}</p>

                    {/* <a onClick={this.topFunction} id="myBtn" title="Go to top" style={{display: 'block',borderColor: 'transparent',textAlign:'right',fontsize:'60px'}}>

<i class="fa fa-arrow-circle-up" style={{fontsize:'18px',color:'rgb(102, 71, 18)'}}></i>
</a> */}
                    <div className="post-bottom overflow">
                      <ul className="nav navbar-nav post-nav">
                        {/* {(() => {if (emp.user_id = userid) {return (
     
                    <li><a href={"#/TagEdit/"+emp.upload_id}><i className="fa fa-tag" />Tag</a></li>
                    );
  }
                   else {return(

                     <li><a href="#"></a></li>   );}


                   
                   })()} */}
                        <li><a href={"#/ResponsePage/" + emp.upload_id}><i className="fa fa-tag" />{emp.tagcount}&nbsp;Tag</a></li>
                        <li><a href="#">




                          <form onSubmit={this.AddLiking.bind(this)}>



                            <input type="hidden" name="id" onChange={this.handlebenfChange} id="id" defaultValue={emp.upload_id} />

                            <button type="submit" className="fa" style={{ display: 'contents', backgroundColor: 'rgb(174, 230, 219)', fontsize: 'inherit', textRendering: 'auto', WebkitFontSmoothing: 'antialiased', transform: 'translate(0, 0)', fontFamily: 'cuyabra' }}>

                              {/* <img src='https://paropakari.com/Images/like.png' style={{height:"40px",width:"50px"}}   /> <br></br>
    <img src='https://paropakari.com/Images/liked.png' style={{height:"40px",width:"50px"}} /> */}


                              {(() => {
                                if (emp.uid == 'true') {
                                  return (

                                    <img onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut} src="https://paropakari.com/Images/like.png" className="fa" style={{ display: 'inline-block', fontsize: 'inherit', textRendering: 'auto', WebkitFontSmoothing: 'antialiased', transform: 'translate(0, 0)', height: "20px", width: "25px", border: 'none' }} />
                                  );
                                }
                                else {
                                  return (

                                    <img onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut} src="https://paropakari.com/Images/liked.png" className="fa" style={{ display: 'inline-block', fontsize: 'inherit', textRendering: 'auto', WebkitFontSmoothing: 'antialiased', transform: 'translate(0, 0)', height: "20px", width: "25px", border: 'none', outline: 'none' }} />);
                                }



                              })()}

                              &nbsp;{emp.likes} &nbsp;Like
                            </button>


                          </form>
                        </a></li>
                        <li><a href={"#/ResponsePage/" + emp.upload_id}><i className="fa fa-comments" />{emp.comments} Comments</a></li>

                        {(() => {
                          if (emp.profile_name == username && emp.likes == 0 && emp.comments == 0 && emp.confirmed == 0 && emp.pending == 0) {
                            return (

                              <li><a href={"#/EditPage/" + emp.upload_id}><i className="fa fa-edit" />Edit</a></li>
                            );
                          }
                          else {
                            return (

                              <li><a href="#"></a></li>);
                          }



                        })()}



                        {(() => {
                          if (emp.profile_name == username && emp.likes == 0 && emp.comments == 0 && emp.confirmed == 0 && emp.pending == 0) {
                            return (

                              <li>

                                <i className="fa fa-trash-o" style={{ color: '#0099AE' }} /><button onClick={() => { this.DeleteGroup(emp.upload_id) }}
                                  style={{
                                    backgroundColor: 'transparent',
                                    border: 'none', color: '#01464f'
                                  }}>Delete</button>


                              </li>


                            );
                          }
                          else {
                            return (

                              <li><a href="#"></a></li>);
                          }



                        })()}


                        {(() => {
                          if (emp.profile_name == username) {
                            return (

                              <li class="btn1 third" style={{ backgroundColor: 'rgb(102, 71, 18)', color: 'white', textDecoration: 'none', margin: '-6px -11px -11px 0px' }}><Link to={"./Confirm/" + emp.upload_id} style={{ backgroundColor: 'rgb(102, 71, 18)', color: 'white', fontsize: '14px', textDecoration: 'none', alignContent: 'space-around', marginTop: "", fontSize: 12 }}
                                >
                                  {/* <input type="image" alt="" src="assets/images/p3.png" /> */}
                                  &nbsp;<b style={{ color: 'white' }}>View Detail</b>

                              </Link> </li>

                            );
                          }
                          else {
                            return (

                              <li class="btn1 third" style={{ backgroundColor: 'rgb(102, 71, 18)', color: 'white', textDecoration: 'none', margin: '-6px -11px -11px 0px' }}><Link to={"./ResponsePage/" + emp.upload_id} style={{ backgroundColor: 'rgb(102, 71, 18)', color: 'white', fontsize: '14px', textDecoration: 'none',alignContent: 'space-around', marginTop: "", fontSize: 12 }}
                              >
                                {/* <input type="image" alt="" src="assets/images/p3.png" /> */}
                                &nbsp;<b style={{ color: 'white' }}>Donate a Smile</b>

                              </Link></li>

                            );
                          }



                        })()}



                      </ul>
                    </div>
                  </div>
                </div>

              </div>

            ))
            }
          </div>

        ))}
      </div>

    );
  }
}