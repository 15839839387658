import React, { Component } from 'react';
// import '../assets/css/bootstrap.min.css';
import ScriptTag from 'react-script-tag';
import Script from 'react-load-script'

export default class Manual extends Component {
  constructor(props) {

    super(props);

    this.state = {

      foo: [],
    }


  }

  Footer() {

    


    let url = "https://testapi.paropakari.com/api/Authenticate/Get_FooterDetails";
    let data = this.state;

    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          foo: data
        });
        
        // 
      });
  }


  componentDidMount() {

    window.addEventListener('load', this.Footer);
    this.Footer();
    document.title = "Paropakari's User manual"
  }


  componentWillUnmount() {

    window.removeEventListener('load', this.Footer);
    this.Footer();
  }
  handleScriptCreate() {
    this.setState({ scriptLoaded: false })
  }

  handleScriptError() {
    this.setState({ scriptError: true })
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true })
  }
  render() {
    let { foo } = this.state;
    return (

      <div>
        {/* <link href="css/bootstrap.min.css" rel="stylesheet">
    <link href="css/font-awesome.min.css" rel="stylesheet">
    <link href="css/animate.min.css" rel="stylesheet"> 
    <link href="css/lightbox.css" rel="stylesheet"> 
	<link href="css/main.css" rel="stylesheet">
	<link href="css/responsive.css" rel="stylesheet"></link> */}
        {/* 
 <ScriptTag isHydrating={true} type="text/javascript" src="../assets/js/jquery.js" /> 
    <ScriptTag isHydrating={true} type="text/javascript" src="../assets/js/bootstrap.min.js" /> 
    <ScriptTag isHydrating={true} type="text/javascript" src="../assets/js/lightbox.min.js" /> 
    <ScriptTag isHydrating={true} type="text/javascript" src="../assets/js/wow.min.js" /> 
    <ScriptTag isHydrating={true} type="text/javascript" src="../assets/js/main.js" />   */}

        {/* <Script
      url="../assets/js/jquery.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />
     <Script
      url="../assets/js/bootstrap.min.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />
     <Script
      url="../assets/js/lightbox.min.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />
     <Script
      url="../assets/js/wow.min.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />
     <Script
      url="../assets/js/main.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    /> */}

        <header id="header" style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}>

          <div class="navbar navbar-inverse" role="banner">
            <div class="container">
              <div class="navbar-header">
                <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                  <span class="sr-only">Toggle navigassstion</span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                </button>

                <a class="navbar-brand" href="#/Index1">
                  <h1 style={{ color: 'blue', fontFamily: 'Biysk-Regular' }}><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '52px' }} />AROPAKARI

                  </h1>
                </a>

              </div>
              <div class="collapse navbar-collapse">
                <ul class="nav navbar-nav navbar-right">
                  <li id="paro" className="active"><a class="btn1 third" href="#/Login" style={{ backgroundColor: 'rgb(102, 71, 18)', color: 'white', fontWeight: 'bold', marginRight: '160px', marginLeft: '-274px', fontSize: 16, marginTop: '2px', marginBottom: '-7px', textDecoration: 'none', whiteSpace: 'nowrap', fontFamily: 'cuyabra' }}>

                    Be a Paropakari
                  </a></li>
                  <li><a href="#/Ourstory"><b>Our Story</b></a></li>
                  <li class="active"><a href="#/Howitworks"><b>How It Works</b></a></li>
                  <li><a href="#/Blog"><b>Blog</b></a></li>
                  {/* <li><a href="shortcodes.html ">Login</a></li>  */}
                  <li><a href="#/Login"><b>Login</b></a></li>
                  {/* <li><a href="shortcodes.html ">Shortcodes</a></li>                     */}
                </ul>
              </div>
              {/* <div className="search">
                    <form role="form">
                        <i className="fa fa-search"></i>
                        <div className="field-toggle">
                            <input type="text" className="search-form" autocomplete="off" placeholder="Search"/>
                        </div>
                    </form>
                </div> */}
            </div>
          </div>
        </header>



        <section id="blog" className="padding-bottom" style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}>
          <div className="containerss">
            <div className="row">
              <div className="timeline-blog overflow">
                <div className="timeline-date text-center">
                  <a className="btn btn-common uppercase">User Manual</a>
                </div>
                <div className="timeline-divider overflow padding-bottom">
                  <div className="col-sm-6 padding-right arrow-right wow fadeInLeft" data-wow-duration="1000ms" data-wow-delay="300ms">
                    <div className="single-blog timeline">
                      <div className="single-blog-wrapper">
                        <div className="post-thumb">
                          <iframe src="https://testapi.paropakari.com/images/How To Login.pdf" />
                        </div>
                      </div>
                      <div className="post-content overflow">
                        <h2 className="post-title bold"><a href="blogdetails.html">Introduction</a></h2>
                        <p >#Register <a >(with your Mobile Phone or email)</a></p>
                        <p >#Login <a>(with your credentials)</a></p>
                        <p >#Update Profile <a >(Your Image/Date of Birth/<br />Date of Anniversary/Place)</a></p>
                        <a href="#" className="read-more"></a>
                        <div className="post-bottom overflow">
                          <span className="post-date pull-left"></span>
                          <span className="comments-number pull-right"><a href="#"></a></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 padding-left padding-top arrow-left wow fadeInRight" data-wow-duration="1000ms" data-wow-delay="300ms">
                    <div className="single-blog timeline">
                      <div className="single-blog-wrapper">
                        <div className="post-thumb">
                          <iframe src="https://testapi.paropakari.com/images/Complete Profile.pdf" />
                        </div>
                      </div>
                      <div className="post-content overflow">
                        <h2 className="post-title bold"><a href="blogdetails.html#">Complete Profile</a></h2>
                        <p >#Register <a >(with your Mobile Phone or email)</a></p>
                        <p >#Login <a >(with your credentials)</a></p>
                        <p >#Update Profile <a >(Your Image/Date of Birth/<br />Date of Anniversary/Place)</a></p>
                        <a href="#" className="read-more"></a>
                        <div className="post-bottom overflow">
                          <span className="post-date pull-left"></span>
                          <span className="comments-number pull-right"><a href="#"></a></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 padding-right arrow-right wow fadeInLeft" data-wow-duration="1000ms" data-wow-delay="300ms">
                    <div className="single-blog timeline">
                      <div className="single-blog-wrapper">
                        <div className="post-thumb">
                          <iframe src="https://testapi.paropakari.com/images/Invite Your Friends.pdf" />
                        </div>
                      </div>
                      <div className="post-content overflow">
                        <h2 className="post-title bold"><a href="blogdetails.html">Invite Your Friends</a></h2>
                        <p>#Invite </p>
                        <p>#Add Your Friend <a>(Name/Mobile/Email)</a></p>
                        <p>#Add Other Friends <a>(Name/Mobile/Email)</a></p>
                        <p>#Select Your Added Friends</p>
                        <p>#Select Your Causes</p>
                        <p>#Send Invitations</p>
                        <a href="#" className="read-more"></a>
                        <div className="post-bottom overflow">
                          <span className="post-date pull-left"></span>
                          <span className="comments-number pull-right"><a href="#"></a></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="timeline-blog overflow">

                <div className="timeline-divider overflow padding-bottom">
                  <div className="col-sm-6 padding-right arrow-right wow fadeInLeft" data-wow-duration="1000ms" data-wow-delay="300ms">
                    <div className="single-blog timeline">
                      <div className="single-blog-wrapper">
                        <div className="post-thumb">
                          <iframe src="https://testapi.paropakari.com/images/Create Your Groups.pdf" />
                        </div>
                      </div>
                      <div className="post-content overflow">
                        <h2 className="post-title bold"><a href="blogdetails.html">Create Your Groups</a></h2>

                        <p>#Profile/Groups/Add My Groups</p>
                        <p>#Create Your Group <a>(Name/Icon)</a></p>
                        <p>#Add Members to Your Group <a>(Search by Name)</a></p>
                        <p>#Create Group</p>
                        <a href="#" className="read-more"></a>
                        <div className="post-bottom overflow">
                          <span className="post-date pull-left"></span>
                          <span className="comments-number pull-right"><a href="#"></a></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 padding-left padding-top arrow-left wow fadeInRight" data-wow-duration="1000ms" data-wow-delay="300ms">
                    <div className="single-blog timeline">
                      <div className="single-blog-wrapper">
                        <div className="post-thumb">
                          <iframe src="https://testapi.paropakari.com/images/Create Your Cause.pdf" />
                        </div>
                      </div>
                      <div className="post-content overflow">
                        <h2 className="post-title bold"><a href="blogdetails.html#">Create Your Cause</a></h2>
                        <p>#Create Your Cause or Fundraising Need <a >(for eg: <br />Education (School, High School or College),<br /> Note Books, Text Books, Medical, etc)</a></p>
                        <p >#Describe <a >(Title/Description/Purpose/Beneficiaries/<br />Amount/Place/Upload Supporting Documents)</a></p>
                        <p>#Date Required By/Who Is It Required For</p>
                        <p>#Tag Friends <a >(By Name)</a></p>
                        <p >#Tag Your Groups <a >(By Name)</a></p>
                        <p >#Tax Benefits <a >(Mention if your cause can <br />provide Income tax benefits (80G) to the supporters)</a></p>
                        <a href="#" className="read-more"></a>
                        <div className="post-bottom overflow">
                          <span className="post-date pull-left"></span>
                          <span className="comments-number pull-right"><a href="#"></a></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 padding-right arrow-right wow fadeInLeft" data-wow-duration="1000ms" data-wow-delay="300ms">
                    <div className="single-blog timeline">
                      <div className="single-blog-wrapper">
                        <div className="post-thumb">
                          <iframe src="https://testapi.paropakari.com/images/Share Your Cause.pdf" />
                        </div>
                      </div>
                      <div className="post-content overflow">
                        <h2 className="post-title bold"><a href="blogdetails.html">Share Your Cause</a></h2>
                        <p >#From Home Page <a >(FB/Twitter/LinkedIn/<br />Whatsapp/Email,etc)</a></p>
                        <p >#From My Journey Page <a >(FB/Twitter/LinkedIn/<br />Whatsapp/Email,etc)</a></p>
                        <br />
                        <a href="#" className="read-more"></a>
                        <div className="post-bottom overflow">
                          <span className="post-date pull-left"></span>
                          <span className="comments-number pull-right"><a href="#"></a></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="timeline-blog overflow">

                <div className="timeline-divider overflow padding-bottom">
                  <div className="col-sm-6 padding-right arrow-right wow fadeInLeft" data-wow-duration="1000ms" data-wow-delay="300ms">
                    <div className="single-blog timeline">
                      <div className="single-blog-wrapper">
                        <div className="post-thumb">
                          <iframe src="https://testapi.paropakari.com/images/Donate to a Cause.pdf" />
                        </div>
                      </div>
                      <div className="post-content overflow">
                        <div className="post-content overflow">
                          <h2 className="post-title bold"><a href="blogdetails.html">Donate to a Cause</a></h2>
                          <p >#Home Page </p>
                          <p>#Select a Cause to Support </p>
                          <p>#Select Donate a Smile </p>
                          <p>#Enter the % of Your Support to the Cause</p>
                          <p>#Write Messege to the Creator of the Cause</p>
                          <p>#Submit Your Support <a><br />(You and the Creator of the cause will receive a<br /> text/email notification)</a></p><br />
                          <a href="#" className="read-more"></a>
                          <div className="post-bottom overflow">
                            <span className="post-date pull-left"></span>
                            <span className="comments-number pull-right"><a href="#"></a></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 padding-left padding-top arrow-left wow fadeInRight" data-wow-duration="1000ms" data-wow-delay="300ms">
                    <div className="single-blog timeline">
                      <div className="single-blog-wrapper">
                        <div className="post-thumb">
                          <iframe src="https://testapi.paropakari.com/images/Response to your Cause.pdf" />
                        </div>
                      </div>
                      <div className="post-content overflow">
                        <h2 className="post-title bold"><a href="blogdetails.html">Response to Your Cause</a></h2>

                        <p>#My Journey/Your Cause Post/View Details</p>
                        <p>#Review the Level of Support <a ><br />(from single or multiple supporters)</a></p>
                        <p>#Confirm Income Tax Documents <a >(if applicable)</a></p>
                        <p>#Confirm Support <a>(after receipt of support.<br /> You and the Supporter of the cause will receive a text/email notification)</a></p>
                        <br />
                        <a href="#" className="read-more"></a>
                        <div className="post-bottom overflow">
                          <span className="post-date pull-left"></span>
                          <span className="comments-number pull-right"><a href="#"></a></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="timeline-blog overflow">

                <div className="timeline-divider overflow padding-bottom">
                  <div className="col-sm-6 padding-right arrow-right wow fadeInLeft" data-wow-duration="1000ms" data-wow-delay="300ms">
                    <div className="single-blog timeline">
                      <div className="single-blog-wrapper">
                        <div className="post-thumb">
                          <iframe src="https://testapi.paropakari.com/images/Manage Your Cause.pdf" />
                        </div>
                      </div>
                      <div className="post-content overflow">
                        <h2 className="post-title bold"><a href="blogdetails.html">Manage Your Cause</a></h2>

                        <p>#My Journey/Your Cause Post/View Details</p>
                        <p>#Review the Progress of Your Cause <a><br />(Happiness Meter - % of Support Pledged or Received and Confirmed)</a></p>
                        <p>#View Paropakaris <a>(to your cause)</a></p>
                        <p>#View Likes/Comments/Tagged Persons</p>
                        <p>#Share Your Cause <a>(FB/Twitter/LinkedIn/Whatsapp,etc)</a></p>
                        <br />
                        <a href="#" className="read-more"></a>
                        <div className="post-bottom overflow">
                          <span className="post-date pull-left"></span>
                          <span className="comments-number pull-right"><a href="#"></a></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 padding-left padding-top arrow-left wow fadeInRight" data-wow-duration="1000ms" data-wow-delay="300ms">
                    <div className="single-blog timeline">
                      <div className="single-blog-wrapper">
                        <div className="post-thumb">
                          <iframe src="https://testapi.paropakari.com/images/Review Your Notifications.pdf" />
                        </div>
                      </div>
                      <div className="post-content overflow">
                        <h2 className="post-title bold"><a href="blogdetails.html">Review Your Notifications</a></h2>

                        <p>#Notifications Page </p>
                        <p>#Select the Notification</p>
                        <p>#You will be taken to My Journey/Your Cause Page</p>
                        <p>#Review Your Cause <a style={{ fontSize: 15 }} /></p>
                        <br />
                        <a href="#" className="read-more"></a>
                        <div className="post-bottom overflow">
                          <span className="post-date pull-left"></span>
                          <span className="comments-number pull-right"><a href="#"></a></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div className="timeline-blog overflow">

                <div className="timeline-divider overflow padding-bottom">
                  <div className="col-sm-6 padding-right arrow-right wow fadeInLeft" data-wow-duration="1000ms" data-wow-delay="300ms">
                    <div className="single-blog timeline">
                      <div className="single-blog-wrapper">
                        <div className="post-thumb">
                          <iframe src="https://testapi.paropakari.com/images/Edit or Delete Your Cause.pdf" />
                        </div>
                      </div>
                      <div className="post-content overflow">
                        <h2 className="post-title bold"><a href="blogdetails.html">Edit/Delete Your Cause</a></h2>

                        <p>#You can only edit/delete causes that do not have any pledged support, likes or comments</p>
                        <p>#My Journey Page</p>
                        <p>#Select Edit in Your Cause</p>
                        <p>#Edit <a style={{ fontSize: 15 }}>(Title/Description/Purpose/Beneficiaries/<br />Amount/Place/Upload Supporting Documents/Date Required By/Who Is It Required For)</a></p>
                        <p>#Edit Tax Benefits </p>
                        <p>#Delete Your Cause <a>(Select Delete)</a></p>
                        <br />
                        <a href="#" className="read-more"></a>
                        <div className="post-bottom overflow">
                          <span className="post-date pull-left"></span>
                          <span className="comments-number pull-right"><a href="#"></a></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 padding-left padding-top arrow-left wow fadeInRight" data-wow-duration="1000ms" data-wow-delay="300ms">
                    <div className="single-blog timeline">
                      <div className="single-blog-wrapper">
                        <div className="post-thumb">
                          <iframe src="https://testapi.paropakari.com/images/Review Your Journey.pdf" />
                        </div>
                      </div>
                      <div className="post-content overflow">
                        <h2 className="post-title bold"><a href="blogdetails.html">Review Your Journey</a></h2>
                        <p>#My Journey Page</p>
                        <p>#By Year</p>
                        <p>#Filter By Purpose/Number of Beneficiaries </p>
                        <p>#By Purpose</p>
                        <p>#By Activity</p>
                        <br />
                        <a href="#" className="read-more"></a>
                        <div className="post-bottom overflow">
                          <span className="post-date pull-left"></span>
                          <span className="comments-number pull-right"><a href="#"></a></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="timeline-blog overflow">

                <div className="timeline-divider overflow padding-bottom">
                  <div className="col-sm-6 padding-right arrow-right wow fadeInLeft" data-wow-duration="1000ms" data-wow-delay="300ms">
                    <div className="single-blog timeline">
                      <div className="single-blog-wrapper">
                        <div className="post-thumb">
                          <iframe src="https://testapi.paropakari.com/images/Edit or Delete Your Cause.pdf" />
                        </div>
                      </div>
                      <div className="post-content overflow">
                        <h2 className="post-title bold"><a href="blogdetails.html">Edit/Delete Your Cause</a></h2>

                        <p>#You can only edit/delete causes that do not have any pledged support, likes or comments</p>
                        <p>#My Journey Page</p>
                        <p>#Select Edit in Your Cause</p>
                        <p>#Edit <a style={{ fontSize: 15 }}>(Title/Description/Purpose/Beneficiaries/<br />Amount/Place/Upload Supporting Documents/Date Required By/Who Is It Required For)</a></p>
                        <p>#Edit Tax Benefits </p>
                        <p>#Delete Your Cause <a>(Select Delete)</a></p>
                        <br />
                        <a href="#" className="read-more"></a>
                        <div className="post-bottom overflow">
                          <span className="post-date pull-left"></span>
                          <span className="comments-number pull-right"><a href="#"></a></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 padding-left padding-top arrow-left wow fadeInRight" data-wow-duration="1000ms" data-wow-delay="300ms">
                    <div className="single-blog timeline">
                      <div className="single-blog-wrapper">
                        <div className="post-thumb">
                          <iframe src="https://testapi.paropakari.com/images/Home Page Navigation.pdf" />
                        </div>
                      </div>
                      <div className="post-content overflow">
                        <h2 className="post-title bold"><a href="blogdetails.html">Home Page Navigation</a></h2>
                        <p>#Sort <a>(Recent/Purpose/Incomplete/80G, etc)</a></p>
                        <p>#Search <a>(by Typing)</a></p>
                        <p>#Filter By Purpose/Number of Beneficiaries </p>
                        <p>#Donate to a Cause <a>(Select Donate a Smile)</a></p>
                        <p>#Like, Comment, Tag and Share a Cause</p>
                        <br />
                        <a href="#" className="read-more"></a>
                        <div className="post-bottom overflow">
                          <span className="post-date pull-left"></span>
                          <span className="comments-number pull-right"><a href="#"></a></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </section>
        <footer id="footer" style={{ color: 'white' }}>
          <br />
          <div className="container">
            <div className="copyright">

              <div className="row col-lg-12">

                <div className="col-lg-3 mb-5" style={{ textDecoration: 'none', color: 'white' }}>

                  <a href="#/Contactus" style={{ textDecoration: 'none', color: 'white' }}>Contact Us</a>
                  <br />
                  <a href="#/Howitwork" style={{ textDecoration: 'none', color: 'white' }}>How It Works</a>
                  <br />
                  <a href="#/Blog" style={{ textDecoration: 'none', color: 'white' }}>Blog</a>
                  <br />
                  <a href="#/TermsandCondition" style={{ textDecoration: 'none', color: 'white' }}>Terms and Conditions</a>
                </div>
                <div className="col-lg-5 mb-3">
                  {foo.map((fo) => (
                    <p className="m-0 text-center text-black" style={{ paddingTop: '-50px', fontSize: 12, color: 'white' }}>
                      <a className="blink_me" style={{ color: '#ffd9a2', fontSize: 18 }}> &nbsp;{fo.posts}</a>&nbsp;Causes
                      <a className="blink_me" style={{ color: '#ffd9a2', fontSize: 18 }}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
                      <a className="blink_me" style={{ color: '#ffd9a2', fontSize: 18 }}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
                      <a className="blink_me" style={{ color: '#ffd9a2', fontSize: 18 }}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
                    </p>
                  ))}
                  <div className="copyright" >
                    <center> <p>Copyright © 2021 Paropakari</p></center>
                  </div>
                </div>
                <div className="col-lg-4 mb-3">
                  <br />
                  <div className="social-icons" style={{ color: 'white' }}>
                    <ul className="nav nav-pills">
                      <li><a href><i className="fa fa-facebook" /></a></li>
                      <li><a href><i className="fa fa-twitter" /></a></li>
                      <li><a href><i className="fa fa-google-plus" /></a></li>
                      <li><a href><i className="fa fa-dribbble" /></a></li>
                      <li><a href><i className="fa fa-linkedin" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="containerss">

          </div>
        </footer>

        {/* <script type="text/javascript" src="js/jquery.js"></script> */}

        {/* <script type='text/javascript' src='./assets/js/jquery.js'/>
    <script type="text/javascript" src='./assets/js/bootstrap.min.js'></script>
    <script type="text/javascript" src="./assets/js/lightbox.min.js"></script>
    <script type="text/javascript" src='./assets/js/wow.min.js'></script>
    <script type="text/javascript" src='./assets/js/main.js'></script>    */}

      </div>
    )
  }
}
