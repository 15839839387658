import React, { Component } from 'react'
import $ from "jquery";
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import Cookies from 'js-cookie';

const refreshPage = () => {

  setTimeout(() => window.location.reload(), 4000);


}




//import SideBars from './SideBars.js';

//setTimeout(function(){$('.errormsg').fadeOut();}, 9000);

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // id : this.props.match.params.id,
      username: "",
      password: "",
      l_name: "",
      formErrors: {},
      foo: [],
      Details: [],
      newArray: [],
      ourstory: [],
      howitworks: [],
      beaparopakari: [],
      whatwedo: [],
      blog: [],
      login: [],
      indiaexpensless: [],
      oppertunitybringsmile: [],
      contactus: [],
      termsandcondition: [],
      causes: [],
      beneficiaries: [],
      paropakaris: [],
      amountraised: [],
      copyright: [],
      whatwedodesc: [],
      welcome: [],
      usernames: [],
      passwords: [],
      forgotpassword: [],
      signin: [],
      donthaveacc: [],
      signup: [],
      entercaptchvalue: [],
      enter: [],
      langID: [],
      languageid: [],
      lID: [],
      valid_new: []
      , dlangID: [],
      EnterUsername: [],
      copyright_new: [],
      paropakari_new: [],
      EnterPassword: [],
      or: [], email: [], mobilenumber: [],
      UsernameandPasswordisincorrectTryAgain: [],
      privacypolicy: [], userdoesnotexist: [], pleaseverifyotp: [],
      Captchisnotmatch: [],
      movedown: true,
      isPasswordShown: false,

    };
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.performLogin = this.performLogin.bind(this);
    this.renderIcon = this.renderIcon.bind(this);
    this.Login = this.Login.bind(this);
    // this.CheckConfirm = this.CheckConfirm.bind(this);


  }


  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown
    })
  }

  togglePasswordVisiblity = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };


  ReloadPage = () => {
    // let reloads=sessionStorage.getItem('reloadlogin');
    //  // setTimeout(() => window.location.reload(), 2000);
    //   if(sessionStorage.getItem('reloadlogin') !== null ){
    //     
    //     reloads= sessionStorage.getItem('reloadlogin');
    //     reloads++;
    //     window.sessionStorage.setItem('reloadlogin', (reloads));
    //     
    //   }
    //   else{
    //     reloads=1;
    //     window.sessionStorage.setItem('reloadlogin', (reloads));
    //     
    //   }

    //   if(reloads ==1)
    //   {
    //     reloads= sessionStorage.getItem('reloadlogin');
    //     
    //     window.location.reload();
    //   }
    let reloads = 0;
    // setTimeout(() => window.location.reload(), 2000);
    if (sessionStorage.getItem('reloadlogin') !== null) {
      
      reloads = sessionStorage.getItem('reloadlogin');
      reloads++;
      window.sessionStorage.setItem('reloadlogin', (reloads));
      
    }
    else {
      reloads = 1;
      window.sessionStorage.setItem('reloadlogin', (reloads));
      
    }

    if (reloads == 1) {
      window.location.reload();
    }


  }



  componentDidMount() {
    // if(sessionStorage.getItem('dtranslate') == null){
    //   setTimeout(() => {

    //       
    //      window.alerthello2();
    //     }, 3000);

    // }
    // if(sessionStorage.getItem('translate') !== null){
    //   setTimeout(() => {

    //       
    //     // window.alerthello();
    //     }, 3000);

    // }


    loadCaptchaEnginge(6);
    window.addEventListener('load', this.Footer);
    this.Footer();
    window.addEventListener('load', this.ReloadPage);
    this.ReloadPage();
    window.addEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
    const element = document.querySelector('.VIpgJd-ZVi9od-ORHb-OEVmcd');
    if (element && window.getComputedStyle(element).getPropertyValue('position') === 'fixed') {
      element.style.position = 'absolute';
    }


    // window.addEventListener('load', this.CheckConfirm);
    // this.CheckConfirm();
  };

  componentWillUnmount() {

    loadCaptchaEnginge(6);
    window.removeEventListener('load', this.Footer);
    this.Footer();
    window.addEventListener('load', this.ReloadPage);
    this.ReloadPage();
    window.removeEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
    // window.removeEventListener('load', this.CheckConfirm);
    // this.CheckConfirm();

  }
  redirect = (e) => {
    e.preventDefault();
    var page = {
      pathname: './Login.js',

    }
    this.props.history.push(page)
  }

  handleFormValidation() {
    
    const { username, password } = this.state;
    let formErrors = {};
    let formIsValid = true;

    // let user_captcha = document.getElementById('user_captcha_input').value;
    // var username = this.state.username;
    var mailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
    //  
    // if (this.state.username == "") {
    //   alert("enter email or mobile number");
    // }
    // else if (validateCaptcha(user_captcha) === true) {
    // }


    if (!username) {
      formIsValid = false;
      formErrors["opErr"] = "Enter Username.";
    }
    if ((this.state.username != "") && (!mailFormat.test(this.state.username))) {

      formIsValid = false;
      formErrors["validErr"] = "Enter Valid Mail or Mobile number";
      // alert("Enter Valid Mail or Mobile number");
      // return false;
    }
    if (!password || password.trim() === "") {
      formIsValid = false;
      formErrors["npErr"] = "Enter Password.";
    }

    this.setState({ formErrors: formErrors });
    
    return formIsValid;
  }

  handleClick = (e) => {
    e.preventDefault();    
    localStorage.clear();
    window.location.href = '#/Forgot';
  }
  signupClick = (e) => {
    e.preventDefault();    
    localStorage.clear();
    window.location.href = '#/Register';
  }

  performLogin = async event => {
    event.preventDefault();
    
    //  debugger;   

    if (this.handleFormValidation()) {
      

      // debugger;   
      //  localStorage.setItem('document',JSON.stringify(this.state));
      const vg = this.state.howitworks;
      //  alert(vg.result);
      let user_captcha = document.getElementById('user_captcha_input').value;
      let Password = document.getElementById('Password').value;
      if (validateCaptcha(user_captcha) === true) {
        // alert('Captcha Matched');
        
        



        let url = "https://testapi.paropakari.com/api/Otp/ConfirmEmailorMobileLogin?EmailOrMobile=" + this.state.username + "&Password=" + this.state.password;

        let user = JSON.parse(localStorage.getItem('user'));
        const accessToken = user;
        
        await fetch(url, {
          method: 'GET',
          headers: {
            "Content-type": "application/json",
            "Accept": "application/json",
            Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
          },
          //body:JSON.stringify(data)
        }).then(response => response.json())
          .then(data => {
            this.setState({
              GroupList: data,
              allPosts: data
            });
            
            // 
            
            if(data.status == "Failure")
            {
              alert(this.state.UsernameandPasswordisincorrectTryAgain.result ? this.state.UsernameandPasswordisincorrectTryAgain.result : 'Username or Password is incorrect, Try again');
            }
            if (data.table == '') {

              alert(this.state.userdoesnotexist.result);
            }
         if (data.message == 'Logged in') {
          //alert("cslled");
            localStorage.setItem('Phonenumber', JSON.stringify(this.state.username));
            // localStorage.setItem('emailOrMobile', JSON.stringify(data.emailOrMobile));
            localStorage.setItem('otp', JSON.stringify(data.otp));
            
            
            

            localStorage.setItem('EmailConfirm', JSON.stringify(data.table[0].emailConfirmed));
            localStorage.setItem('PhoneConfirm', JSON.stringify(data.table[0].phoneNumberConfirmed));


            let EmailConfirm = JSON.parse(localStorage.getItem('EmailConfirm'));
            let PhoneConfirm = JSON.parse(localStorage.getItem('PhoneConfirm'));
            
           
            if (EmailConfirm == true || PhoneConfirm == true) {

              this.Login();

            }

           
          }
          if(data.message === 'OTP is not verified') {
           // alert("llll")
           localStorage.setItem('Phonenumber', JSON.stringify(this.state.username));
            localStorage.setItem('NAME', this.state.username)
            localStorage.setItem('psw', this.state.password)
            localStorage.setItem('otp', JSON.stringify(data.otp));
            
           // alert("this.state.pleaseverifyotp.result");
            alert(this.state.pleaseverifyotp.result);
            var page = {
              pathname: 'LoginOTP',
              pno: this.state.pno,
            }
            this.props.history.push(page)
          }
          });
      }
      else {
        document.getElementById('user_captcha_input').value = ""
        alert(this.state.Captchisnotmatch.result);
      }
    };
  }



  Login = async event => {


    var body = {
      username: this.state.username,
      password: this.state.password,

    };

    
    var url = "https://testapi.paropakari.com/api/Authenticate/login";

    var options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

        "Accept": "application/json"
      },
      body: JSON.stringify(body)
    };


    try {
      const response = await fetch(url, options);
      const result = await response.json();
      localStorage.setItem('user', JSON.stringify(result.token));
      localStorage.setItem('username', JSON.stringify(result.name));
      localStorage.setItem('userid', JSON.stringify(result.userid));
      ///localStorage.setItem('create', JSON.stringify(result.id));

      
      
      
      if (result.status == "Success") {

        var LangID = document.getElementById("lang_id").value
        var page = {
          pathname: '#/HomePage',
          state: {
            data: {
              username: this.state.username,
              password: this.state.password,
            }
          }
        }
        
        //window.alert("login succesful");
        //this.props.history.push(page)
        // alert("Login Successful...");

     window.location.href = "#/HomePage";

      } else if (result.status == "Failure") {
        alert(this.state.UsernameandPasswordisincorrectTryAgain.result ? this.state.UsernameandPasswordisincorrectTryAgain.result : 'Username or Password is incorrect, Try again');
      }
      else if (result.message == "Username or Password are Incorrect") {
        //  const status =this.state.UsernameandPasswordisincorrectTryAgain.result ? this.state.UsernameandPasswordisincorrectTryAgain : 'Username or Password is incorrect, Try again'
        alert(this.state.UsernameandPasswordisincorrectTryAgain.result ? this.state.UsernameandPasswordisincorrectTryAgain.result : 'Username or Password is incorrect, Try again');

        //  window.location.reload();
      }
    }


    catch (error) {
      console.error(error);
    }

  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  // handleChange = (e) => {
  //   const { name, value } = e.target;
  //   const trimmedValue = value.trim();
  //   this.setState({ [name]: trimmedValue });
  // }
  handleSubmit = () => {
    const { username, password } = this.state;
    alert(`Signed up with username: ${username} password: ${password}`);
  };

  Footer = () => {

    


    let url = "https://testapi.paropakari.com/api/Authenticate/Get_FooterDetails";
    let data = this.state;

    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          foo: data
        });
        
        // 
      });
  }


  DefaultMenu() {
    //   
    let RefreshID = 0;
    window.sessionStorage.setItem('reload', (RefreshID));
    let reloadsmyactivity = 0;
    window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
    window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
    
    let MID;
    //         if(sessionStorage.getItem('transliteration') !== null){
    //           MID= sessionStorage.getItem('transliteration');
    //         }
    // else
    if (sessionStorage.getItem('translate') !== null) {
      MID = sessionStorage.getItem('translate');
    }
    else {
      MID = "English";
    }
    



    let datatrans = MID;

    

    localStorage.setItem("GoogleTranslate123", this.state.googtrans);

    let GID = sessionStorage.getItem('translate');
    

    let GIDS = JSON.parse(localStorage.getItem('this.state.googtrans'));
    
    
    let datatransID = MID;
    if (datatrans == "kn") {

      datatransID = "Kannada";
    }
    else
      if (datatrans == "ta") {
        datatransID = "Tamil";
      }
      else
        if (datatrans == "te") {
          datatransID = "Telugu";
        }
        else
          if (datatrans == "hi") {
            datatransID = "Hindi";
          }
          else
          if (datatrans == "es") {
            datatransID = "Spanish";
          }
          else
            if (datatrans == MID) {
              datatransID = MID;
            }

    let LnID = MID;
    if (LnID == "Kannada") {

      LnID = "kn";
    }
    else
      if (LnID == "Tamil") {
        LnID = "ta";
      }
      else
        if (LnID == "Telugu") {
          LnID = "te";
        }
        else
          if (LnID == "Hindi") {
            LnID = "hi";
          }
          else
          if (LnID == "Spanish") {
            LnID = "es";
          }
          else
            if (LnID == MID) {
              LnID = MID;
            }

    if (MID != null) {
      

      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~WhatWeDo~Opportunity to Bring a Smile~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Privacy Policy&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Ourstory = typeof items[0] !== "undefined" ? items[0] : null;
          var Howitwork = typeof items[1] !== "undefined" ? items[1] : null;
          var Blog = typeof items[2] !== "undefined" ? items[2] : null;
          var Login = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[7] !== "undefined" ? items[7] : null;
          var Termsandcondition = typeof items[8] !== "undefined" ? items[8] : null;
          var CopyRight = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy = typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[4] !== "undefined" ? items[4] : null;
          var OppertunitytoBSmile = typeof items[6] !== "undefined" ? items[6] : null;
          var Whatwedo = typeof items[5] !== "undefined" ? items[5] : null;
          var LanguageID = MID;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            ourstory: Ourstory,
            howitworks: Howitwork,
            blog: Blog,
            login: Login,
            beaparopakari: Beaparopakari,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            whatwedo: Whatwedo,
            oppertunitybringsmile: OppertunitytoBSmile,
            privacypolicy: PrivacyPolicy,
            langID: LangID,
            languageid: LanguageID,
            lID: LID

          });
          ;
        });

    }
    if (MID != null) {
      

      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Causes~Beneficiaries~Paropakaris~Amount Raised~Welcome!~Username~Password~Forgot password~Login~Dont have an account?&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Causes = typeof items[0] !== "undefined" ? items[0] : null;
          var Beneficiaries = typeof items[1] !== "undefined" ? items[1] : null;
          var Paropakaris = typeof items[2] !== "undefined" ? items[2] : null;
          var Amountraised = typeof items[3] !== "undefined" ? items[3] : null;

          var Welcome = typeof items[4] !== "undefined" ? items[4] : null;
          var Usernames = typeof items[5] !== "undefined" ? items[5] : null;
          var Passwords = typeof items[6] !== "undefined" ? items[6] : null;
          var ForgotPassword = typeof items[7] !== "undefined" ? items[7] : null;
          var Signin = typeof items[8] !== "undefined" ? items[8] : null;
          var DonthavAcc = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised.result,
            welcome: Welcome,
            usernames: Usernames,
            passwords: Passwords,
            forgotpassword: ForgotPassword,
            signin: Signin,
            donthaveacc: DonthavAcc,

            langID: LangID,
            lID: LID

          });

        });

    }
    if (MID != null) {
      

      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Sign Up~Enter Captcha~Enter~Enter Username~Enter Your Password~Username or Password is incorrect, Try again~Or~Email~Mobile Number&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Signup = typeof items[0] !== "undefined" ? items[0] : null;
          var Entercaptchvalue = typeof items[1] !== "undefined" ? items[1] : null;
          var Enter = typeof items[2] !== "undefined" ? items[2] : null;


          var EnterPassword = typeof items[4] !== "undefined" ? items[4] : null;
          var EnterUsername = typeof items[3] !== "undefined" ? items[3] : null;
          var UsernameandPasswordisincorrectTryAgain = typeof items[5] !== "undefined" ? items[5] : null;


          var Or = typeof items[6] !== "undefined" ? items[6] : null;
          var Email = typeof items[7] !== "undefined" ? items[7] : null;
          var MobileNumber = typeof items[8] !== "undefined" ? items[8] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            signup: Signup,
            entercaptchvalue: Entercaptchvalue,
            enter: Enter,
            langID: LangID,
            lID: LID,
            EnterPassword: EnterPassword,
            EnterUsername: EnterUsername,
            UsernameandPasswordisincorrectTryAgain: UsernameandPasswordisincorrectTryAgain,
            or: Or,
            email: Email,
            mobilenumber: MobileNumber

          });

        });

    }
    if (MID != null) {
      

      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=User Does Not Exist!~Looks like the user name was not verified during signup. Please Verify OTP~Please enter valid captcha&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var UserDoesnotExist = typeof items[0] !== "undefined" ? items[0] : null;
          var PleaseverifyOTP = typeof items[1] !== "undefined" ? items[1] : null;
          var CaptchadoesnotMatch = typeof items[2] !== "undefined" ? items[2] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({


            langID: LangID,
            lID: LID,
            pleaseverifyotp: PleaseverifyOTP,
            Captchisnotmatch: CaptchadoesnotMatch,
            userdoesnotexist: UserDoesnotExist

          });


        });

    }

    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari~Enter Your Email or Mobile Number&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;
          var valid_new = typeof items[2] !== "undefined" ? items[2] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
            valid_new: valid_new
          });


          // 
        });
    }

  }


  handleChanges(event) {
    this.setState({ value: event.target.value });
    
    localStorage.setItem('menuid', event.target.value);
    let values = ['OurStory', 'HowItWorks', 'Blog', 'Login', 'BeaParopakari', 'What We Do'];
    
    let MID = event.target.value;
    let LnID = event.target.value;
    if (LnID == "Kannada") {

      LnID = "kn";
    }
    else
      if (LnID == "Tamil") {
        LnID = "ta";
      }
      else
        if (LnID == "Telugu") {
          LnID = "te";
        }
        else
          if (LnID == "Hindi") {
            LnID = "hi";
          }
          else
          if (LnID == "Spanish") {
            LnID = "es";
          }
          else
            if (LnID == event.target.value) {
              LnID = event.target.value;
            }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~WhatWeDo~Opportunity to Bring a Smile~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Privacy Policy&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Ourstory = typeof items[0] !== "undefined" ? items[0] : null;
          var Howitwork = typeof items[1] !== "undefined" ? items[1] : null;
          var Blog = typeof items[2] !== "undefined" ? items[2] : null;
          var Login = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[7] !== "undefined" ? items[7] : null;
          var Termsandcondition = typeof items[8] !== "undefined" ? items[8] : null;
          var CopyRight = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy = typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[4] !== "undefined" ? items[4] : null;
          var OppertunitytoBSmile = typeof items[6] !== "undefined" ? items[6] : null;
          var Whatwedo = typeof items[5] !== "undefined" ? items[5] : null;
          var LanguageID = MID;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            ourstory: Ourstory,
            howitworks: Howitwork,
            blog: Blog,
            login: Login,
            beaparopakari: Beaparopakari,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            whatwedo: Whatwedo,
            oppertunitybringsmile: OppertunitytoBSmile,
            privacypolicy: PrivacyPolicy,
            langID: LangID,
            languageid: LanguageID,
            lID: LID

          });


          // alert(firstItem);
          const trans = "Vijayaasri";
          let l_name = this.state.newArray[0];
          let l_names = l_name;

          
          
          const n = 2;
          const firstNElements = longArray.slice(0, n).map(({ result }) => {
            return (<p >{result}</p>)
          });
          // 
        });

    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=User Does Not Exist!~Looks like the user name was not verified during signup. Please Verify OTP~Please enter valid captcha&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var UserDoesnotExist = typeof items[0] !== "undefined" ? items[0] : null;
          var PleaseverifyOTP = typeof items[1] !== "undefined" ? items[1] : null;
          var CaptchadoesnotMatch = typeof items[2] !== "undefined" ? items[2] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({


            langID: LangID,
            lID: LID,
            pleaseverifyotp: PleaseverifyOTP,
            Captchisnotmatch: CaptchadoesnotMatch,
            userdoesnotexist: UserDoesnotExist

          });

        });

    }
    if (MID != null) {
      

      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Causes~Beneficiaries~Paropakaris~Amount Raised~Welcome!~Username~Password~Forgot password~Login~Dont have an account?&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Causes = typeof items[0] !== "undefined" ? items[0] : null;
          var Beneficiaries = typeof items[1] !== "undefined" ? items[1] : null;
          var Paropakaris = typeof items[2] !== "undefined" ? items[2] : null;
          var Amountraised = typeof items[3] !== "undefined" ? items[3] : null;

          var Welcome = typeof items[4] !== "undefined" ? items[4] : null;
          var Usernames = typeof items[5] !== "undefined" ? items[5] : null;
          var Passwords = typeof items[6] !== "undefined" ? items[6] : null;
          var ForgotPassword = typeof items[7] !== "undefined" ? items[7] : null;
          var Signin = typeof items[8] !== "undefined" ? items[8] : null;
          var DonthavAcc = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised.result,
            welcome: Welcome,
            usernames: Usernames,
            passwords: Passwords,
            forgotpassword: ForgotPassword,
            signin: Signin,
            donthaveacc: DonthavAcc,

            langID: LangID,
            lID: LID

          });


          // alert(firstItem);
          const trans = "Vijayaasri";
          let l_name = this.state.newArray[0];
          let l_names = l_name;

          
          
          const n = 2;
          const firstNElements = longArray.slice(0, n).map(({ result }) => {
            return (<p >{result}</p>)
          });
          // 
        });

    }
    if (MID != null) {

      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Sign Up~Enter Captcha~Enter~Enter Username~Enter Your Password~Username or Password is incorrect, Try again~Or~Email~Mobile Number&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Signup = typeof items[0] !== "undefined" ? items[0] : null;
          var Entercaptchvalue = typeof items[1] !== "undefined" ? items[1] : null;
          var Enter = typeof items[2] !== "undefined" ? items[2] : null;


          var EnterPassword = typeof items[4] !== "undefined" ? items[4] : null;
          var EnterUsername = typeof items[3] !== "undefined" ? items[3] : null;
          var UsernameandPasswordisincorrectTryAgain = typeof items[5] !== "undefined" ? items[5] : null;


          var Or = typeof items[6] !== "undefined" ? items[6] : null;
          var Email = typeof items[7] !== "undefined" ? items[7] : null;
          var MobileNumber = typeof items[8] !== "undefined" ? items[8] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            signup: Signup,
            entercaptchvalue: Entercaptchvalue,
            enter: Enter,
            langID: LangID,
            lID: LID,
            EnterPassword: EnterPassword,
            EnterUsername: EnterUsername,
            UsernameandPasswordisincorrectTryAgain: UsernameandPasswordisincorrectTryAgain,
            or: Or,
            email: Email,
            mobilenumber: MobileNumber

          });

        });

    }

    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari~Enter Your Email or Mobile Number&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;
          var valid_new = typeof items[2] !== "undefined" ? items[2] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
            valid_new: valid_new
          });


          // 
        });
    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Cancel&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;


          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;


          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;


          



          //localStorage.setItem("GoogleTranslate123",InviteFriendsDropdown)

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";

          }
          else

            if (datatranslirate == "Kannada") {
              datatransIDs = "/en/kn";
            }

            else
              if (datatranslirate == "Tamil") {
                datatransIDs = "/en/ta";
              }
              else
                if (datatranslirate == "Telugu") {
                  datatransIDs = "/en/te";
                }
                else
                  if (datatranslirate == "Hindi") {
                    datatransIDs = "/en/hi";
                  }
                  else
                  if (datatranslirate == "Spanish") {
                    datatransIDs = "/en/es";
                  }
                  else
                    if (datatranslirate == MID) {
                      datatransIDs = MID;
                    }
          let RefreshID = 0;
          window.sessionStorage.setItem('reload', (RefreshID));
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
          window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
          window.sessionStorage.setItem('GoogleTranslateValues', (datatransIDs));

          
          let googletrasvalues = localStorage.setItem("GoogleTranslateValues", datatransIDs);

          


          
          Cookies.remove('googtrans', { domain: '.paropakari.com' });
          Cookies.remove('googtrans', { domain: 'https://paropakari.com/' });

          Cookies.remove('googtrans', { domain: 'https://www.paropakari.com/' });
          let usernames = localStorage.getItem("GoogleTranslateValues", datatransIDs);
          let values = datatransIDs;
          
          

          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
            const expires = "expires=" + date.toUTCString();
            document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
            // window.location.reload();
          }

          Cookies.set('googtrans', datatransIDs, { domain: 'https://test.paropakari.com/#/' });
          Cookies.set('googtrans', datatransIDs, { domain: '.paropakari.com' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://www.paropakari.com/' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://paropakari.com/' });
          setCookie('googtrans', datatransIDs, 30);
          setCookie('googtrans', datatransIDs, 'https://test.paropakari.com/#/');
          setCookie('googtrans', datatransIDs, '.paropakari.com');
          setCookie('googtrans', datatransIDs, 'https://www.paropakari.com/');
          setCookie('googtrans', datatransIDs, 'https://paropakari.com/');
          let cookie = Cookies.get('googtrans', 'https://test.paropakari.com/#/');
          
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,


          });


        });
    }

  }
  renderIcon() {
    const longArray = [1, 2, 3];
    const size = 1;

    const newArray = new Array(Math.ceil(longArray.length / size)).fill("")
      .map(function () { return this.splice(0, size) }, longArray.slice());

    
    return (
      <div>{newArray}</div>
    )
  }

  render() {
    const currentYear = new Date().getFullYear();
    const { isPasswordShown, copyright_new,
      paropakari_new } = this.state;
    const { username, password, l_name } = this.state;
    const { opErr, npErr, validErr } = this.state.formErrors;
    const isEnabled = username.length > 0 && password.length > 8;
    const error = localStorage.getItem('error');
    const { foo } = this.state;

    const { Details, firstNElements } = this.state;
    const { newArray, howitworks, beaparopakari, whatwedo, blog, login, indiaexpensless, ourstory, oppertunitybringsmile,
      contactus, termsandcondition, causes, beneficiaries, paropakaris, amountraised, copyright, whatwedodesc,
      langID, welcome, usernames, passwords, forgotpassword, valid_new, signin, donthaveacc, signup, entercaptchvalue,
      enter, EnterPassword, dlangID, lID, privacypolicy, languageid, or, email, mobilenumber, userdoesnotexist, pleaseverifyotp,
      EnterUsername, UsernameandPasswordisincorrectTryAgain, Captchisnotmatch } = this.state;
    window.onload = window.localStorage.clear();

    const handleRoute = () => {

      this.props.history.push("#/HomePage");
    }
    return (
      <div id="myElement">
        <div className="VIpgJd-ZVi9od-ORHb-OEVmcd" style={{ position: "absolute" }}>

          <div style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed' }} className="notranslate">
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="description" content />
            <meta name="author" content />
            <title>Blog Masonry | Triangle</title>
            <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
            <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
            <link href="./assets/css/lightbox.css" rel="stylesheet" />
            <link href="./assets/css/animate.min.css" rel="stylesheet" />
            <link href="./assets/css/main.css" rel="stylesheet" />
            <link href="./assets/css/Register.css" rel="stylesheet" />
            {/*[if lt IE 9]>
              
              
          <![endif]*/}
            <link rel="shortcut icon" href="images/ico/favicon.ico" />
            <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
            <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
            <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
            <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />
            {/*/head*/}

            {/* 
            <div onClick={refreshPage}> */}

            <select className="form-control" style={{ display: 'inline', width: '10%', marginTop: '0', marginLeft: '0', float: 'right', width: 'fit-content' }} value={this.state.value} onChange={this.handleChanges}>
              <option value="English">Languages</option>
              <option value="Kannada">Kannada</option>
              <option value="Tamil">Tamil</option>
              <option value="Hindi">Hindi</option>
              <option value="Telugu">Telugu</option>
               {/* <option value="Spanish">Spanish</option> */}
              <option value="English">English</option>

            </select>

            {/* </div> */}

            &nbsp;&nbsp; &nbsp;&nbsp;

            <div>
              {/* <div className="mag notranslate">
                <div class="pro-nav">
                  <input type="checkbox" id="pro-nav-check" onClick={this.checkfun} />
                  <div class="pro-nav-header">
                    <div class="pro-nav-title notranslate">
                      <a className="navbar-brand" href="#/Index1">
                        <h1 className="text-nowrap navbar-new-text"><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '300px' }} />AROPAKARI
                        </h1>
                      </a>
                    </div>
                  </div>

                  <div class="pro-nav-btn">
                    <label for="pro-nav-check">
                      <div className="pro-naav">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </label>
                  </div>

                  {langID == "English" ? <>
                    <div class="pro-nav-links pro-side">
                      <p class='pro-center'>
                        <a className="new-design" href="#/Login">{beaparopakari.result}</a>
                      </p>
                      <div className="parop-boxx parop-boxx-top">
                        <a href="#/Ourstory" className="parop-menu">{ourstory.result}</a>
                        <a href="#/Howitworks" className="parop-menu">{howitworks.result}</a>
                        <a href="#/Blog" className="parop-menu">{blog.result}</a>
                        <a href="#/Login" className="parop-menu text-parop-menu">{login.result}</a>
                      </div>
                    </div>
                  </> : langID == "Hindi" ? <>
                    <div class="pro-nav-links pro-side">
                      <p class='pro-center'>
                        <a className="new-hindi-design" href="#/Login">{beaparopakari.result}</a>
                      </p>
                      <div className="parop-boxx parop-boxx-top">
                        <a href="#/Ourstory" className="parop-hindi-menu">{ourstory.result}</a>
                        <a href="#/Howitworks" className="parop-hindi-menu">{howitworks.result}</a>
                        <a href="#/Blog" className="parop-hindi-menu">{blog.result}</a>
                        <a href="#/Login" className="parop-hindi-menu text-parop-menu">{login.result}</a>
                      </div>
                    </div>
                  </> : langID == "Kannada" ? <>
                    <div class="pro-nav-links pro-side">
                      <p class='pro-center'>
                        <a className="new-kannada-design" href="#/Login">{beaparopakari.result}</a>
                      </p>
                      <div className="parop-kannada-boxx parop-boxx-top">
                        <a href="#/Ourstory" className="parop-kannada-menu">{ourstory.result}</a>
                        <a href="#/Howitworks" className="parop-kannada-menu">{howitworks.result}</a>
                        <a href="#/Blog" className="parop-kannada-menu">{blog.result}</a>
                        <a href="#/Login" className="parop-kannada-menu text-parop-menu">{login.result}</a>
                      </div>
                    </div>
                  </> : langID == "Tamil" ? <>
                    <div class="pro-nav-links pro-side">
                      <p class='pro-center'>
                        <a className="new-tamil-design" href="#/Login">{beaparopakari.result}</a>
                      </p>
                      <div className="parop-tamil-boxx parop-boxx-top">
                        <a href="#/Ourstory" className="parop-tamil-menu">{ourstory.result}</a>
                        <a href="#/Howitworks" className="parop-tamil-menu">{howitworks.result}</a>
                        <a href="#/Blog" className="parop-tamil-menu">{blog.result}</a>
                        <a href="#/Login" className="parop-tamil-menu text-parop-menu">{login.result}</a>
                      </div>
                    </div>
                  </> : <>
                    <div class="pro-nav-links pro-side">
                      <p class='pro-center'>
                        <a className="new-telugu-design" href="#/Login">{beaparopakari.result}</a>
                      </p>
                      <div className="parop-boxx parop-boxx-top">
                        <a href="#/Ourstory" className="parop-telugu-menu">{ourstory.result}</a>
                        <a href="#/Howitworks" className="parop-telugu-menu">{howitworks.result}</a>
                        <a href="#/Blog" className="parop-telugu-menu">{blog.result}</a>
                        <a href="#/Login" className="parop-telugu-menu text-parop-menu">{login.result}</a>
                      </div>
                    </div>
                  </>}
                </div>
              </div> */}

              <div className="HeaderMenualignment">
                <div className="mag notranslate">
                  <div className="pro-nav">
                    <input type="checkbox" id="pro-nav-check" onClick={this.checkfun} />
                    <div className="pro-nav-header">
                      <div className="pro-nav-title notranslate">
                        <a className="navbar-brand" href="#/Index1">
                          <h1 className="text-nowrap navbar-new-text">
                            <img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '1.7vh', width: '26%', fontSize: '3vh' }} />
                            AROPAKARI
                          </h1>
                        </a>
                      </div>
                    </div>

                    <div className="pro-nav-btn">
                      <label htmlFor="pro-nav-check">
                        <div className="pro-naav">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </label>
                    </div>

                    {langID === "English" ? <>
                      <div className="pro-nav-links pro-side">
                        <p className="pro-center">
                          <a className="new-design" href="#/Login">{beaparopakari.result}</a>
                        </p>
                        <div className="parop-boxx parop-boxx-top">
                          <a href="#/Ourstory" className="parop-menu">{ourstory.result}</a>
                          <a href="#/Howitworks" className="parop-menu">{howitworks.result}</a>
                          <a href="#/Blog" className="parop-menu">{blog.result}</a>
                          <a href="#/Login" className="parop-menu text-parop-menu">{login.result}</a>
                        </div>
                      </div>
                    </> : langID === "Hindi" ? <>
                      <div className="pro-nav-links pro-side">
                        <p className="pro-center">
                          <a className="new-hindi-design" href="#/Login">{beaparopakari.result}</a>
                        </p>
                        <div className="parop-boxx parop-boxx-top">
                          <a href="#/Ourstory" className="parop-hindi-menu">{ourstory.result}</a>
                          <a href="#/Howitworks" className="parop-hindi-menu">{howitworks.result}</a>
                          <a href="#/Blog" className="parop-hindi-menu">{blog.result}</a>
                          <a href="#/Login" className="parop-hindi-menu text-parop-menu">{login.result}</a>
                        </div>
                      </div>
                      </> : langID === "Spanish" ? <>
                      <div className="pro-nav-links pro-side">
                        <p className="pro-center">
                          <a className="new-kannada-design" href="#/Login">{beaparopakari.result}</a>
                        </p>
                        <div className="parop-kannada-boxx parop-boxx-top">
                          <a href="#/Ourstory" className="parop-kannada-menu">{ourstory.result}</a>
                          <a href="#/Howitworks" className="parop-kannada-menu">{howitworks.result}</a>
                          <a href="#/Blog" className="parop-kannada-menu">{blog.result}</a>
                          <a href="#/Login" className="parop-kannada-menu text-parop-menu">{login.result}</a>
                        </div>
                      </div>
                    </> : langID === "Kannada" ? <>
                      <div className="pro-nav-links pro-side">
                        <p className="pro-center">
                          <a className="new-kannada-design" href="#/Login">{beaparopakari.result}</a>
                        </p>
                        <div className="parop-kannada-boxx parop-boxx-top">
                          <a href="#/Ourstory" className="parop-kannada-menu">{ourstory.result}</a>
                          <a href="#/Howitworks" className="parop-kannada-menu">{howitworks.result}</a>
                          <a href="#/Blog" className="parop-kannada-menu">{blog.result}</a>
                          <a href="#/Login" className="parop-kannada-menu text-parop-menu">{login.result}</a>
                        </div>
                      </div>
                    </> : langID === "Tamil" ? <>
                      <div className="pro-nav-links pro-side">
                        <p className="pro-center">
                          <a className="new-tamil-design" href="#/Login">{beaparopakari.result}</a>
                        </p>
                        <div className="parop-tamil-boxx parop-boxx-top">
                          <a href="#/Ourstory" className="parop-tamil-menu">{ourstory.result}</a>
                          <a href="#/Howitworks" className="parop-tamil-menu">{howitworks.result}</a>
                          <a href="#/Blog" className="parop-tamil-menu">{blog.result}</a>
                          <a href="#/Login" className="parop-tamil-menu text-parop-menu">{login.result}</a>
                        </div>
                      </div>
                    </> : <>
                      <div className="pro-nav-links pro-side">
                        <p className="pro-center">
                          <a className="new-telugu-design" href="#/Login">{beaparopakari.result}</a>
                        </p>
                        <div className="parop-boxx parop-boxx-top">
                          <a href="#/Ourstory" className="parop-telugu-menu">{ourstory.result}</a>
                          <a href="#/Howitworks" className="parop-telugu-menu">{howitworks.result}</a>
                          <a href="#/Blog" className="parop-telugu-menu">{blog.result}</a>
                          <a href="#/Login" className="parop-telugu-menu text-parop-menu">{login.result}</a>
                        </div>
                      </div>
                    </>}
                  </div>
                </div>
              </div>

              <section id="blog" className={this.state.movedown == true ? "padding-top padding-bottom new-padding" : "padding-top padding-bottom new-padding home-Tiptop"} style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed' }}>

                <div className="container " id="containerid">

                  <div className="row">

                    <div id="rcont" class="rcont">

                      <div id="login" class="form sign-in" style={{ backgroundColor: '#a5d9cf' }}>

                        <form className="" onSubmit={this.performLogin} role="form" style={{ margin: '5px' }} >

                          <h2 style={{ color: 'black' }}>{welcome.result}</h2>
                          {/* 
   <div onClick={refreshPage}> 
      
    <select id="languageDropDown1" className="form-c
    ontrol" 

       onChange={window['languageChangeHandler']}   
      >   <option value={lID}>{langID}</option>
          <option value="hi">HINDI</option>
          <option value="kn">KANNADA</option>
          <option value="ta">TAMIL</option>
          <option value="te">TELUGU</option>
          <option value="en">English</option>
          
     </select>

    </div>  */}

                          <label>
                            <span> {mobilenumber.result} &nbsp;{or.result} &nbsp; {email.result}</span>

                            <input type="hidden" value={langID} id="lang_id"
                              onChange={this.handleChange} name="langid" />
                            <input type="hidden" value={lID} id="l_id"
                              onChange={this.handleChange} name="langid" />

                            {/* <input type="text"  value={dlangID} id="dlang_ID"   
                                 onChange={this.handleChange}  name="dlangID" /> */}

                            <input type="text" value={this.state.username}  placeholder={enter.result}
                              onChange={this.handleChange} autoComplete="off" 
                              id="handleEmailChange" maxLength={30} name="username" />

                          </label>
                          <div style={{ marginLeft: "0%" }} >
                            {(() => {
                              if (languageid == 'Kannada' || languageid == 'Tamil') {
                                return (
                                <center> <span className="errormsg" style={{ color: "red", marginLeft: '-17px' }}> {` ${opErr ? EnterUsername.result ? EnterUsername.result : opErr : ''}`}</span></center> 

                                );
                              }

                            })()}

                            {(() => {
                              if (languageid == 'Hindi') {
                                return (
                                  <center> <span className="errormsg" style={{ color: "red", marginLeft: '-17px' }}> {` ${opErr ? EnterUsername.result ? EnterUsername.result : opErr : ''}`}</span></center> 
                                );
                              }


                            })()}
                            {(() => {
                              if (languageid == 'Telugu') {
                                return (
                                  <center> <span className="errormsg" style={{ color: "red", marginLeft: '-17px' }}> {` ${opErr ? EnterUsername.result ? EnterUsername.result : opErr : ''}`}</span></center> 
                                );
                              }


                            })()}
                              {(() => {
                              if (languageid == 'Spanish') {
                                return (
                                  <center> <span className="errormsg" style={{ color: "red", marginLeft: '-17px' }}> {` ${opErr ? EnterUsername.result ? EnterUsername.result : opErr : ''}`}</span></center> 
                                );
                              }


                            })()}
                            {(() => {
                              if (languageid == 'English') {
                                return (
                                  <center> <span className="errormsg" style={{ color: "red", marginLeft: '-17px' }}> {` ${opErr ? EnterUsername.result ? EnterUsername.result : opErr : ''}`}</span></center> 

                                );
                              }

                            })()}

                          </div>

                          <div style={{ marginLeft: "0%" }} >
                            {(() => {
                              if (languageid == 'Kannada' || languageid == 'Tamil') {
                                return (

                                <center> <span className="errormsg" style={{ color: "red", marginLeft: '-17px' }}> {` ${validErr ? valid_new.result ? valid_new.result : validErr : ''}`}</span></center> 

                                );
                              }

                            })()}

                            {(() => {
                              if (languageid == 'Hindi') {
                                return (
                                  <center> <span className="errormsg" style={{ color: "red", marginLeft: '-17px' }}> {` ${validErr ? valid_new.result ? valid_new.result : validErr : ''}`}</span></center> 

                                );
                              }

                            })()}
                            {(() => {
                              if (languageid == 'Telugu') {
                                return (
                                  <center> <span className="errormsg" style={{ color: "red", marginLeft: '-17px' }}> {` ${validErr ? valid_new.result ? valid_new.result : validErr : ''}`}</span></center> 
                                );
                              }


                            })()}
                             {(() => {
                              if (languageid == 'Spanish') {
                                return (
                                  <center> <span className="errormsg" style={{ color: "red", marginLeft: '-17px' }}> {` ${validErr ? valid_new.result ? valid_new.result : validErr : ''}`}</span></center> 
                                );
                              }


                            })()}
                            {(() => {
                              if (languageid == 'English') {
                                return (
                                  <center> <span className="errormsg" style={{ color: "red", marginLeft: '-17px' }}> {` ${validErr ? valid_new.result ? valid_new.result : validErr : ''}`}</span></center> 

                                );
                              }

                            })()}

                          </div>

                          <label>
                            <span>{passwords.result}</span>
                            {/* <span>{passwords.result}</span>
                                <input type="password" value={this.state.password} maxLength={20} autoComplete="off" onChange={this.handleChange} placeholder=""
                                 data-val-length-max={100} data-val-length-min={4} data-val-required="The Password field is required." id="Password"  name="password" /> */}
                            <div className="passwowrd_foeld" style={{ display: "flex" }}>
                              <input value={this.state.password} autoComplete="off" onChange={this.handleChange}  placeholder={enter.result}
                                onBlur={(data) => { this.setState({ password: data.target.value }) }} id="Password" name="password" type={isPasswordShown ? "text" : "password"} />

                              <i style={{ marginLeft: "-16px", marginTop: "9px" }}
                                className={isPasswordShown ? "fa fa-eye-slash" : "fa fa-eye password-icon"}
                                onClick={this.togglePasswordVisiblity}
                              /> </div>
                          </label>

                          <div style={{ marginLeft: "0%" }} >
                            {(() => {
                              if (languageid == 'Kannada') {
                                return (
                                <center>  <span className="errormsg" style={{ color: "red", marginLeft: '-17px' }}> {` ${npErr ? EnterPassword.result ? EnterPassword.result : npErr : ''}`}</span></center>

                                );
                              }


                            })()}

                            {(() => {
                              if (languageid == 'Tamil') {
                                return (
                                  <center>  <span className="errormsg" style={{ color: "red", marginLeft: '-17px' }}> {` ${npErr ? EnterPassword.result ? EnterPassword.result : npErr : ''}`}</span></center>

                                );
                              }


                            })()}
                            {(() => {
                              if (languageid == 'Hindi' || languageid == 'English' ) {
                                return (
                                  <center>  <span className="errormsg" style={{ color: "red", marginLeft: '-17px' }}> {` ${npErr ? EnterPassword.result ? EnterPassword.result : npErr : ''}`}</span></center>

                                );
                              }


                            })()}
                            {(() => {
                              if (languageid == 'Spanish') {
                                return (
                                  <center>  <span className="errormsg" style={{ color: "red", marginLeft: '-17px' }}> {` ${npErr ? EnterPassword.result ? EnterPassword.result : npErr : ''}`}</span></center>

                                );
                              }


                            })()}
                            {(() => {
                              if (languageid == 'Telugu') {
                                return (
                                  <center>  <span className="errormsg" style={{ color: "red", marginLeft: '-17px' }}> {` ${npErr ? EnterPassword.result ? EnterPassword.result : npErr : ''}`}</span></center>

                                );
                              }


                            })()}

                          </div>
                          <br />
                          <center>
                            <div className="Captcha" style={{ cursor: "none" }}>
                              <LoadCanvasTemplate />
                            </div>

                            <div className="k-row" >
                              <input id="user_captcha_input" name="user_captcha_input" autoComplete="off" style={{ width: '50%', color: 'black',fontSize:'16px' }} type="text" placeholder={entercaptchvalue.result}></input>
                            </div>
                          </center>

                          <br></br>
                          {(() => {
                            if (languageid == 'Tamil') {
                              return (
                                <center>
                                  {/* <a href={"#/Forgot"} class="forgot-pass" id="linkid"><b className="forgotpass">{forgotpassword.result}?</b></a> */}
                                  <a className="forgot-pass" id="linkid" onClick={this.handleClick}> <b className="forgotpass">{forgotpassword.result}?</b></a>
                                </center>
                              );
                            }
                            else {
                              return (

                                <center>  <a className="forgot-pass" id="linkid" onClick={this.handleClick}> <b className="forgotpass">{forgotpassword.result}?</b></a></center>

                              );
                            }

                          })()}

                          {(() => {
                            if (languageid == 'English') {
                              return (
                                <button type="submit" id="Reg" class="submit" >{login.result}</button>

                              );
                            }
                            else if (languageid == 'Telugu') {
                              return (

                                <button type="submit" id="Reg" class="submit" >{login.result}</button>
                              );
                            }
                            else if (languageid == 'Hindi') {
                              return (

                                <button type="submit" id="Reg" class="submit"  >{login.result}</button>
                              );
                            }
                            else if (languageid == 'Spanish') {
                              return (

                                <button type="submit" id="Reg" class="submit"  >{login.result}</button>
                              );
                            }

                            else {
                              return (

                                <button type="submit" id="Reg" class="submit"  >{login.result}</button>
                              );
                            }

                          })()

                          }

                          <div class="tnr1" id="tnr1">
                            {(() => {
                              if (languageid == 'Tamil' || languageid == 'Kannada') {
                                return (
                                  <center> <a href={"#/Register"} class="new__btn img__btn m--up" id="imagebutton" style={{ textDecoration: 'none', color: 'background: rgb(102, 71, 18);', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px', paddingRight: '10px' }}>{signup.result}</a></center>

                                );
                              }
                              else {
                                return (

                                  <center>   <a href={"#/Register"} class="new__btn img__btn m--up" id="imagebutton" style={{ textDecoration: 'none', color: 'background: rgb(102, 71, 18);', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px', paddingRight: '10px' }}>{signup.result}</a></center>

                                );
                              }

                            })()}


                          </div>


                        </form>

                      </div>

                      <div id="rsub-cont" class="sub-rcont">

                        <div id="rimgsub-cont" class="img">

                          <div class="img__text m--up">

                            <h3>{donthaveacc.result} <a href={"#/Register/" + langID} style={{ textDecoration: 'none', color: 'black' }} ></a></h3>
                          </div>
                          <div class="img__text m--in">

                            <h3>If you already has an account, just sign in.</h3>
                          </div>
                          <div class="tnr" id="tnr">
                            {(() => {
                              if (languageid == 'Tamil' || languageid == 'Kannada') {
                                return (
                                  <a href="#" onClick={this.signupClick} class="img__btn m--up" style={{ textDecoration: 'none', color: 'background: rgb(102, 71, 18);', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px', paddingRight: '10px', marginLeft: '50px' }}>  {signup.result}</a>

                                );
                              }
                              else {
                                return (

                                  <a href="#" onClick={this.signupClick} class="img__btn m--up" style={{ textDecoration: 'none', color: 'background: rgb(102, 71, 18);', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px', paddingRight: '10px', marginLeft: '90px' }}>  {signup.result}</a>
                                );
                              }

                            })()}

                            <span class="m--in" >Sign In</span>
                          </div>
                        </div>


                        <div class="form sign-up">
                          <h2>Create your Account</h2>
                          <label>
                            <span>Name</span>
                            <input type="text" />
                          </label>
                          <label>
                            <span>Email</span>
                            <input type="email" />
                          </label>
                          <label>
                            <span>Password</span>
                            <input type="password" />
                          </label>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/*/#blog*/}
              <div style={{ backgroundColor: '#f2dba8' }}>
                <footer id="footer" style={{ color: 'white' }}>
                  <br />
                  <div className="container">
                    <div className="copyright">

                      <div className="row col-lg-12">

                        <div className="col-lg-9 mb-6">
                          {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}

                          <p >{copyright_new.result} &nbsp;{currentYear} © {paropakari_new.result}&nbsp; |&nbsp;<a href="#/Contactus" style={{ textDecoration: 'none', color: 'white' }}>{contactus.result}&nbsp;</a>| <a href="#/PrivacyPolicy" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{privacypolicy.result}&nbsp;</a>|<a href="#/TermsandCondition" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{termsandcondition.result}</a></p>

                        </div>
                        <div className="col-lg-3 mb-3">

                          <div className="social-icons" style={{ color: 'white' }}>
                            <ul className="nav nav-pills">
                              <li><a target="_blank" href="https://www.facebook.com/beoneparopakari"><i className="fa fa-facebook" /></a></li>
                              {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                              <li><a target="_blank" href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw" ><i className="fa fa-youtube-play" /></a></li>
                              <li><a target="_blank" href="https://www.instagram.com/paropakari_beone/" ><i className="fa fa-instagram" /></a></li>
                              <li><a target="_blank" href="https://www.linkedin.com/company/paropakari/"><i className="fa fa-linkedin" /></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="containerss">

                  </div>
                </footer>
              </div>
            </div>
            {/*/#footer*/}
          </div>
        </div>
      </div>
    )
  }
}