import React, { Component } from "react";
import { BrowserRouter as Navlink } from "react-router-dom";
import AddMemberGroup from "./AddMemberGroup";
import AddMember from "./AddMember";
import Select from "react-select";
import CancelIcon from "@material-ui/icons/Cancel";
import Resizer from "react-image-file-resizer";
import Script from "react-load-script";
import Cookies from "js-cookie";

export default class Updategroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      selectedOption: [],
      minimem: [],
      updategrp: [],
      deletegrp: [],
      selectgrp: [],
      ok: [],
      Dropdownlist: [],
      setSelectedValue: [],
      notify: [],
      Details: [],
      foo: [],
      wanttodelete: [],
      updatesuccess: [],
      updateprofile: [],
      deleteprofilepic: [],
      selectprofilepic: [],
      edit: [],
      Menu: [],
      beaparopakari: [],
      myjourney: [],
      createacause: [],
      invite: [],
      AreyousureyouwanttodeletethisGroup: [],
      profile: [],
      changepassword: [],
      group: [],
      logout: [],
      privacypolicy: [],
      requiredforr: [],
      self: [],
      familyfriends: [],
      others: [],
      beneficiaryname: [],
      submit: [],
      causes: [],
      friends: [],
      beneficiaries: [],
      paropakaris: [],
      amountraised: [],
      copyright: [],
      contactus: [],
      termsandcondition: [],
      howitworks: [],
      updatesuccess: [],
      blog: [],
      donateasmile: [],
      groupinfo: [],
      back: [],
      members: [],
      name: [],
      change: [],
      update: [],
      groupicon: [],

      langID: [],
      loading: false,
      ImageDisplay: "",
      movedown: true,
      savedImagePath: null,
    };
    this.Logo = this.Logo.bind(this);
    this.ImagePreview = this.ImagePreview.bind(this);
    this.resetFile = this.resetFile.bind(this);
    this.submit = this.submit.bind(this);
    this.dropdown = this.dropdown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.reader = new FileReader();
  }
  toggleLoader = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
    } else {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000);
    }
  };

  getValues(apiValues) {
   // console.log("values", apiValues.length);
    if (apiValues.length > 0) {
      let previousValue = [];
      let names = apiValues[0].profile_name.split(",");
      let imgs = apiValues[0].profile_imagePath.split(",");
      // let ids = apiValues[0].user_id.split(",").map(id => id.trim());
      let ids = apiValues[0].user_id.split(",");
      for (let i = 0; i < names.length; i++) {
        let data = {
          value: ids[i].toString(),
          label: (
            <div>
              <img className="group-image" width="30" src={imgs[i]} />{" "}
              <p style={{ marginTop: "9px" }}>&nbsp;&nbsp;{names[i]}</p>
            </div>
          ),
        };
        previousValue.push(data);
      }

      this.setState({ selectedOption: previousValue });
    } else {
      let previousValue = [];
      let names = apiValues[0].profile_name;
      let imgs = apiValues[0].profile_imagePath;
      // let ids = apiValues[0].user_id.split(",").map(id => id.trim());
      let ids = apiValues[0].user_id;
      for (let i = 0; i < names.length; i++) {
        let data = {
          value: ids[i].toString(),
          label: (
            <div>
              <img className="group-image" width="30" src={imgs[i]} />{" "}
              <p style={{ marginTop: "9px" }}>&nbsp;&nbsp;{names[i]}</p>
            </div>
          ),
        };
        previousValue.push(data);
      }

      this.setState({ selectedOption: previousValue });
    }
  }
  onImageChange = (event) => {
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
    });
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          profile_imagePath: e.target.result,
        });
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };
  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown,
    });
  };

  submit = () => {
    //let url = 'https://testapi.paropakari.com/api/Group/GetGroupEdit?GroupId='+this.props.match.params.id;
    let url =
      "https://testapi.paropakari.com/api/Group/GetGroupEdit?GroupId=" +
      this.props.match.params.id;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.getValues(data);
        this.setState({
          Details: data,
        });
      });
  };
  ImagePreview = (event) => {
    var fileInput = false;
    var maxSize = "1024";
    const imageFile = event.target.files[0];
    if (event.target.files[0]) {
      fileInput = true;
    }

    var reader = new FileReader();
    var url = reader.readAsDataURL(imageFile);

    reader.onloadend = function (e) {
      this.setState({
        ImageDisplay: [reader.result],
      });
    }.bind(this);

    if (fileInput) {
      Resizer.imageFileResizer(
        imageFile,
        30,
        30,
        "PNG",
        10,
        0,
        (uri) => {},
        "base64"
      );
    }

    var fsize = event.target.files[0].size / 15;
    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      this.setState({ invalidImage: "Please select valid image." });
      return false;
    } else if (fsize < maxSize) {
      this.setState({ invalidImage: "Please select Quality image." });
      return false;
    }
    this.reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        this.setState({ selectedFile: imageFile, invalidImage: null });
      };
      img.onerror = () => {
        this.setState({ invalidImage: "Invalid image content." });
        return false;
      };

      img.src = e.target.result;
    };
    this.reader.readAsDataURL(imageFile);
  };
  resetFile(event) {
    event.preventDefault();

    this.setState({ ImageDisplay: null });
  }
  dropdown() {
    // let url = 'https://testapi.paropakari.com/api/Group/EditprofileloggedUser?GroupId='+this.props.match.params.id;
    let user = JSON.parse(localStorage.getItem("user"));

    const accessToken = user;

    let url =
      "https://testapi.paropakari.com/api/Group/EditprofileloggedUser?GroupId=" +
      this.props.match.params.id;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Dropdownlist: data,
        });
        //
      });
  }
  customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "white",
    }),
  };

  dropdownhandleChange = (e) => {
    this.setState({
      setSelectedValue: Array.isArray(e) ? e.map((x) => x.value) : null,
    });
  };
  handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.target);
    let user = JSON.parse(localStorage.getItem("user"));

    const accessToken = user;
    //this.toggleLoader();
    const url = "https://testapi.paropakari.com/api/Group/PostGroup";
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
      },
      body: data,
    };
    this.setState(this.initialState);
    const response = await fetch(url, options);
    const result = await response;

    if (response.ok) {
      let group_Name = document.getElementById("group_Name").value;
      alert(group_Name + " " + this.state.updatesuccess.result);

      window.location.href = "#/Group";

      // alert(this.state.updatesuccess.result)

      // window.location.href = "#/Group";
    } else {
      window.location.reload();
    }
    this.setState(this.initialState);
  };

  handleChange(value, actionDetails) {
    let items = [...this.state.selectedOption];
    if (
      actionDetails.action === "remove-value" ||
      actionDetails.action === "pop-value"
    ) {
      if (items) {
        var index = -1;
        items.forEach((item, i) => {
          if (
            item.label === actionDetails.removedValue.label &&
            item.value === actionDetails.removedValue.value
          ) {
            index = i;
            return;
          }
        });
        if (index > -1) {
          items.splice(index, 1);
        }
      }
    } else {
      items.push(actionDetails.option);
    }

    this.setState({ selectedOption: items });
  }

  notify = () => {
    let url = "https://testapi.paropakari.com/api/GetProfile/Notification_Count";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          notify: data,
        });

        //
      });
  };

  Footer = () => {
    let url = "https://testapi.paropakari.com/api/Authenticate/Get_FooterDetails";
    let data = this.state;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          foo: data,
        });

        //
      });
  };

  //deletepicture//
  handleDeletePic = async (event) => {
    event.preventDefault();

    // var x = window.confirm('Want to Delete This Group Icon? Are You Sure?');
    var x = window.confirm(
      this.state.AreyousureyouwanttodeletethisGroup.result
        ? this.state.AreyousureyouwanttodeletethisGroup.result
        : "Want to Delete This Group Icon? Are You Sure?"
    );
    if (x) {
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      // this.toggleLoader();
      let group_Name = document.getElementById("group_Name").value;
      const data = new FormData(event.target);

      var url = "https://testapi.paropakari.com/api/Group/Deletegroupimage";

      var options = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + accessToken,
          // 'Content-Type': 'application/json',
          // 'Accept': 'application/json'
        },
        body: data,
      };

      try {
        const response = await fetch(url, options);

        if (response.ok) {
          alert(group_Name + " Updated successfully!");
          window.location.reload();
        } else {
          window.location.reload();
        }
      } catch (error) {
        console.error("error", error);
      }
    } else {
      return false;
    }
  };
  //deletepicture end //

  //selectpic//
  //  handleImageevents = async event => {
  //   event.preventDefault();

  //   let user = JSON.parse(localStorage.getItem('user'));
  //   const accessToken = user;
  //
  //   if(this.state.ImageDisplay == undefined)
  //   {
  //     //alert("Please select some Image");
  //     window.location.reload();

  //   }

  //   const data = new FormData(event.target);

  //         //var url = "https://testapi.paropakari.com/api/UploadFile/Post";
  //         var url = "https://testapi.paropakari.com/api/Group/Editgroup";

  //         var options = {
  //           method: "POST",
  //           headers: {

  //             Authorization: "Bearer " +accessToken ,
  //            // 'Content-Type': 'application/json',
  //            // 'Accept': 'application/json'
  //           },
  //           body:data
  //         };

  //
  //         try {
  //           const response = await fetch(url, options);

  //           if (response.ok) {

  //             alert(this.state.updatesuccess.result);
  //             window.location.reload();

  //           }
  //           else{

  //             window.location.reload();
  //           }

  //         }
  //       catch (error) {
  //           console.error("error",error);
  //         }

  // };
  handleImageevents = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    //
    const data = new FormData(event.target);
    data.append("ImagePath", this.state.ImageDisplay);
    let group_Name = document.getElementById("group_Name").value;
    // Dhinesh
    let user = JSON.parse(localStorage.getItem("user"));

    const accessToken = user;
    //this.toggleLoader();
    const url = "https://testapi.paropakari.com/api/Group/Editgroupimage";
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
      },
      body: data,
    };
    this.setState(this.initialState);
    const response = await fetch(url, options);
    const result = await response;

    if (response.ok) {
      alert(this.state.updatesuccess.result);
      window.location.reload();
      // window.location.href = "#/Group";
    } else {
      window.location.reload();
    }
    this.setState(this.initialState);
  };

  //selectpic//
  Alert() {
    if (this.state.selectedOption.length == 0) {
      alert(this.state.minimem.result);
      // window.location.href = "/Updategroup/"+item.group_Id;
      window.location.reload();
    }
  }
  DefaultMenu() {
    let reloads = 0;
    window.sessionStorage.setItem("reload", reloads);

    let reloadsmyactivity = 0;
    window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
    window.sessionStorage.setItem("responsepagereload", reloadsmyactivity);
    window.sessionStorage.setItem("notificationpagereload", reloadsmyactivity);
    window.sessionStorage.setItem("Indexreload", reloadsmyactivity);
    let MID = "English";
    if (sessionStorage.getItem("translate") !== null) {
      MID = sessionStorage.getItem("translate");
    } else {
      MID = "English";
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others~HowItWorks&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Detailss: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Howitwork = typeof items[9] !== "undefined" ? items[9] : null;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;

          var Others = typeof items[8] !== "undefined" ? items[8] : null;
          var Edit = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            others: Others,
            howitworks: Howitwork,
            langID: LangID,
            edit: Edit,
          });
        });
    }
    // if(MID != null)
    //     {
    //       let url =
    //       "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Provide Email Address~Provide Location~Provide Bank Details~Enter Valid IFSC Code~Enter Valid Bank Account Number~Want to Delete Your Profile Picture? Are You Sure?~Enter Valid UPI ID&lang="+MID;
    //       let user = JSON.parse(localStorage.getItem("user"));
    //       const accessToken = user;
    //
    //       fetch(url, {
    //         method: "GET",
    //         headers: {
    //           "Content-type": "application/json",
    //           Accept: "application/json",
    //           Authorization: "Bearer " + accessToken,
    //           "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
    //         }
    //       })
    //       .then((response) => response.json())
    //       .then((data) => {
    //          this.setState({
    //            Details: data
    //          });
    //
    //         const longArray = data;
    //         const size = 1;
    //         var ary = data;
    //         // localStorage.setItem('menuid', 'English');
    //         const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
    //             .map(function() { return this.splice(0, size) }, longArray.slice());
    //             var items = data;

    //             var WanttoDelete = typeof items[5] !== "undefined" ? items[5] : null;

    //             var LangID = MID;
    //             localStorage.setItem('MenuID', JSON.stringify(LangID));
    //             window.sessionStorage.setItem('translate', (LangID));

    //             this.setState({

    //               langID:LangID,

    //              wanttodelete:WanttoDelete,

    //             });

    //         });
    //       }
    //     if(MID != null)
    //     {
    //       let url =
    //       "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Privacy Policy~Updated Successfully~Profile Update~Delete Profile Picture~Select Profile Picture&lang="+MID;
    //       let user = JSON.parse(localStorage.getItem("user"));
    //       const accessToken = user;
    //
    //       fetch(url, {
    //         method: "GET",
    //         headers: {
    //           "Content-type": "application/json",
    //           Accept: "application/json",
    //           Authorization: "Bearer " + accessToken,
    //           "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
    //         }
    //       })
    //       .then((response) => response.json())
    //       .then((data) => {
    //          this.setState({
    //            Details: data
    //          });
    //
    //         const longArray = data;
    //         const size = 1;
    //         var ary = data;
    //         // localStorage.setItem('menuid', 'English');
    //         const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
    //             .map(function() { return this.splice(0, size) }, longArray.slice());
    //             var items = data;

    //             var UpdateSuccess = typeof items[1] !== "undefined" ? items[1] : null;
    //             var UpdateProfile = typeof items[2] !== "undefined" ? items[2] : null;
    //             var DeleteProfilePic = typeof items[3] !== "undefined" ? items[3] : null;
    //             var SelectProfilePic = typeof items[4] !== "undefined" ? items[4] : null;

    //             var LangID = MID;
    //             localStorage.setItem('MenuID', JSON.stringify(LangID));
    //             window.sessionStorage.setItem('translate', (LangID));

    //             this.setState({

    //               langID:LangID,

    //              updatesuccess:UpdateSuccess,
    //              updateprofile:UpdateProfile,
    //              deleteprofilepic:DeleteProfilePic,
    //              selectprofilepic:SelectProfilePic

    //             });

    //         });
    //       }

    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Blog~Contact Us~Terms and Conditions~Copyright © 2024 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self~Privacy Policy&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Detailss: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Blog = typeof items[0] !== "undefined" ? items[0] : null;
          var Contactus = typeof items[1] !== "undefined" ? items[1] : null;
          var Termsandcondition =
            typeof items[2] !== "undefined" ? items[2] : null;
          var CopyRight = typeof items[3] !== "undefined" ? items[3] : null;
          var Causes = typeof items[4] !== "undefined" ? items[4] : null;
          var Beneficiaries = typeof items[5] !== "undefined" ? items[5] : null;
          var Paropakaris = typeof items[6] !== "undefined" ? items[6] : null;
          var Amountraised = typeof items[7] !== "undefined" ? items[7] : null;
          var RequiredFor = typeof items[8] !== "undefined" ? items[8] : null;
          var Self = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy =
            typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            requiredforr: RequiredFor,
            self: Self,
            privacypolicy: PrivacyPolicy,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Family/Friends~Beneficiary Name~Submit~Group Info~Back~Members~Name~Change~Update~Group Icon&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Detailss: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var FamilyandFriends =
            typeof items[0] !== "undefined" ? items[0] : null;
          var BeneficiaryName =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Submit = typeof items[2] !== "undefined" ? items[2] : null;
          var GroupInfo = typeof items[3] !== "undefined" ? items[3] : null;
          var Back = typeof items[4] !== "undefined" ? items[4] : null;
          var Members = typeof items[5] !== "undefined" ? items[5] : null;
          var Name = typeof items[6] !== "undefined" ? items[6] : null;
          var Change = typeof items[7] !== "undefined" ? items[7] : null;
          var Update = typeof items[8] !== "undefined" ? items[8] : null;
          var GroupIcon = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            familyfriends: FamilyandFriends,
            beneficiaryname: BeneficiaryName,
            submit: Submit,
            groupinfo: GroupInfo,
            back: Back,
            members: Members,
            name: Name,
            change: Change,
            update: Update,
            groupicon: GroupIcon,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Updated Successfully!~Want to Delete This Group Icon? Are You Sure?~Friends~Add minumum one member to create a group~Update Group Icon~Delete Group Icon~Select Group Icon~Ok&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Detailss: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;

          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var UpdateSucess = typeof items[0] !== "undefined" ? items[0] : null;
          var AreyousureyouwanttodeletethisGroup =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Friends = typeof items[2] !== "undefined" ? items[2] : null;
          var minimem = typeof items[3] !== "undefined" ? items[3] : null;
          var updategrp = typeof items[4] !== "undefined" ? items[4] : null;
          var deletegrp = typeof items[5] !== "undefined" ? items[5] : null;
          var selectgrp = typeof items[6] !== "undefined" ? items[6] : null;
          var ok = typeof items[7] !== "undefined" ? items[7] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            updatesuccess: UpdateSucess,
            AreyousureyouwanttodeletethisGroup:
              AreyousureyouwanttodeletethisGroup,
            friends: Friends,
            minimem: minimem,
            updategrp: updategrp,
            deletegrp: deletegrp,
            selectgrp: selectgrp,
            ok: ok,
            langID: LangID,
          });
        });
    }
  }

  handleChanges(event) {
    this.setState({ value: event.target.value });

    // localStorage.setItem('menuid', event.target.value);
    let values = [
      "OurStory",
      "HowItWorks",
      "Blog",
      "Login",
      "BeaParopakari",
      "What We Do",
    ];

    let l_id = JSON.parse(localStorage.getItem("MenuID"));

    let MID = event.target.value;
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others~HowItWorks&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Detailss: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Howitwork = typeof items[9] !== "undefined" ? items[9] : null;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;

          var Others = typeof items[8] !== "undefined" ? items[8] : null;
          var Edit = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            others: Others,
            howitworks: Howitwork,
            langID: LangID,
            edit: Edit,
          });
        });
    }
    // if(MID != null)
    //   {
    //     let url =
    //     "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Privacy Policy~Updated Successfully~Profile Update~Delete Profile Picture~Select Profile Picture&lang="+MID;
    //     let user = JSON.parse(localStorage.getItem("user"));
    //     const accessToken = user;
    //
    //     fetch(url, {
    //       method: "GET",
    //       headers: {
    //         "Content-type": "application/json",
    //         Accept: "application/json",
    //         Authorization: "Bearer " + accessToken,
    //         "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
    //       }
    //     })
    //     .then((response) => response.json())
    //     .then((data) => {
    //        this.setState({
    //          Details: data
    //        });
    //
    //       const longArray = data;
    //       const size = 1;
    //       var ary = data;
    //       // localStorage.setItem('menuid', 'English');
    //       const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
    //           .map(function() { return this.splice(0, size) }, longArray.slice());
    //           var items = data;

    //           var UpdateSuccess = typeof items[1] !== "undefined" ? items[1] : null;
    //           var UpdateProfile = typeof items[2] !== "undefined" ? items[2] : null;
    //           var DeleteProfilePic = typeof items[3] !== "undefined" ? items[3] : null;
    //           var SelectProfilePic = typeof items[4] !== "undefined" ? items[4] : null;

    //           var LangID = MID;
    //           localStorage.setItem('MenuID', JSON.stringify(LangID));
    //           window.sessionStorage.setItem('translate', (LangID));

    //           this.setState({

    //             langID:LangID,

    //            updatesuccess:UpdateSuccess,
    //            updateprofile:UpdateProfile,
    //            deleteprofilepic:DeleteProfilePic,
    //            selectprofilepic:SelectProfilePic

    //           });

    //       });
    //     }

    //     if(MID != null)
    //     {
    //       let url =
    //       "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Provide Email Address~Provide Location~Provide Bank Details~Enter Valid IFSC Code~Enter Valid Bank Account Number~Want to Delete Your Profile Picture? Are You Sure?~Enter Valid UPI ID&lang="+MID;
    //       let user = JSON.parse(localStorage.getItem("user"));
    //       const accessToken = user;
    //
    //       fetch(url, {
    //         method: "GET",
    //         headers: {
    //           "Content-type": "application/json",
    //           Accept: "application/json",
    //           Authorization: "Bearer " + accessToken,
    //           "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
    //         }
    //       })
    //       .then((response) => response.json())
    //       .then((data) => {
    //          this.setState({
    //            Details: data
    //          });
    //
    //         const longArray = data;
    //         const size = 1;
    //         var ary = data;
    //         // localStorage.setItem('menuid', 'English');
    //         const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
    //             .map(function() { return this.splice(0, size) }, longArray.slice());
    //             var items = data;

    //             var WanttoDelete = typeof items[5] !== "undefined" ? items[5] : null;

    //             var LangID = MID;
    //             localStorage.setItem('MenuID', JSON.stringify(LangID));
    //             window.sessionStorage.setItem('translate', (LangID));

    //             this.setState({

    //               langID:LangID,

    //              wanttodelete:WanttoDelete,

    //             });

    //         });
    //       }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Blog~Contact Us~Terms and Conditions~Copyright © 2024 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self~Privacy Policy&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Detailss: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Blog = typeof items[0] !== "undefined" ? items[0] : null;
          var Contactus = typeof items[1] !== "undefined" ? items[1] : null;
          var Termsandcondition =
            typeof items[2] !== "undefined" ? items[2] : null;
          var CopyRight = typeof items[3] !== "undefined" ? items[3] : null;
          var Causes = typeof items[4] !== "undefined" ? items[4] : null;
          var Beneficiaries = typeof items[5] !== "undefined" ? items[5] : null;
          var Paropakaris = typeof items[6] !== "undefined" ? items[6] : null;
          var Amountraised = typeof items[7] !== "undefined" ? items[7] : null;
          var RequiredFor = typeof items[8] !== "undefined" ? items[8] : null;
          var Self = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy =
            typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            requiredforr: RequiredFor,
            self: Self,
            privacypolicy: PrivacyPolicy,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Family/Friends~Beneficiary Name~Submit~Group Info~Back~Members~Name~Change~Update~Group Icon&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Detailss: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var FamilyandFriends =
            typeof items[0] !== "undefined" ? items[0] : null;
          var BeneficiaryName =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Submit = typeof items[2] !== "undefined" ? items[2] : null;
          var GroupInfo = typeof items[3] !== "undefined" ? items[3] : null;
          var Back = typeof items[4] !== "undefined" ? items[4] : null;
          var Members = typeof items[5] !== "undefined" ? items[5] : null;
          var Name = typeof items[6] !== "undefined" ? items[6] : null;
          var Change = typeof items[7] !== "undefined" ? items[7] : null;
          var Update = typeof items[8] !== "undefined" ? items[8] : null;
          var GroupIcon = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            familyfriends: FamilyandFriends,
            beneficiaryname: BeneficiaryName,
            submit: Submit,
            groupinfo: GroupInfo,
            back: Back,
            members: Members,
            name: Name,
            change: Change,
            update: Update,
            groupicon: GroupIcon,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Updated Successfully!~Want to Delete This Group Icon? Are You Sure?~Friends~Add minumum one member to create a group~Update Group Icon~Delete Group Icon~Select Group Icon~Ok&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Detailss: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var UpdateSucess = typeof items[0] !== "undefined" ? items[0] : null;
          var AreyousureyouwanttodeletethisGroup =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Friends = typeof items[2] !== "undefined" ? items[2] : null;
          var minimem = typeof items[3] !== "undefined" ? items[3] : null;
          var updategrp = typeof items[4] !== "undefined" ? items[4] : null;
          var deletegrp = typeof items[5] !== "undefined" ? items[5] : null;
          var selectgrp = typeof items[6] !== "undefined" ? items[6] : null;
          var ok = typeof items[7] !== "undefined" ? items[7] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            updatesuccess: UpdateSucess,
            AreyousureyouwanttodeletethisGroup:
              AreyousureyouwanttodeletethisGroup,
            friends: Friends,
            minimem: minimem,
            updategrp: updategrp,
            deletegrp: deletegrp,
            selectgrp: selectgrp,
            ok: ok,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Cancel&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;

          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;

          //localStorage.setItem("GoogleTranslate123",InviteFriendsDropdown)

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";
          } else if (datatranslirate == "Kannada") {
            datatransIDs = "/en/kn";
          } else if (datatranslirate == "Tamil") {
            datatransIDs = "/en/ta";
          } else if (datatranslirate == "Telugu") {
            datatransIDs = "/en/te";
          } else if (datatranslirate == "Hindi") {
            datatransIDs = "/en/hi";
          } else if (datatranslirate == "Spanish") {
            datatransIDs = "/en/es";
          } else if (datatranslirate == MID) {
            datatransIDs = MID;
          }
          let RefreshID = 0;
          window.sessionStorage.setItem("reload", RefreshID);
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
          window.sessionStorage.setItem(
            "responsepagereload",
            reloadsmyactivity
          );
          window.sessionStorage.setItem(
            "notificationpagereload",
            reloadsmyactivity
          );
          window.sessionStorage.setItem("Indexreload", reloadsmyactivity);
          window.sessionStorage.setItem("GoogleTranslateValues", datatransIDs);

          let googletrasvalues = localStorage.setItem(
            "GoogleTranslateValues",
            datatransIDs
          );

          Cookies.remove("googtrans", { domain: ".paropakari.com" });
          Cookies.remove("googtrans", { domain: "https://paropakari.com/" });

          Cookies.remove("googtrans", {
            domain: "https://www.paropakari.com/",
          });
          let usernames = localStorage.getItem(
            "GoogleTranslateValues",
            datatransIDs
          );
          let values = datatransIDs;

          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
            const expires = "expires=" + date.toUTCString();
            document.cookie =
              cName + "=" + cValue + "; " + expires + "; path=/";
            // window.location.reload();
          }

          Cookies.set("googtrans", datatransIDs, {
            domain: "https://test.paropakari.com/#/",
          });
          Cookies.set("googtrans", datatransIDs, { domain: ".paropakari.com" });
          Cookies.set("googtrans", datatransIDs, {
            domain: "https://www.paropakari.com/",
          });
          Cookies.set("googtrans", datatransIDs, {
            domain: "https://paropakari.com/",
          });
          setCookie("googtrans", datatransIDs, 30);
          setCookie(
            "googtrans",
            datatransIDs,
            "https://test.paropakari.com/#/"
          );
          setCookie("googtrans", datatransIDs, ".paropakari.com");
          setCookie("googtrans", datatransIDs, "https://www.paropakari.com/");
          setCookie("googtrans", datatransIDs, "https://paropakari.com/");
          let cookie = Cookies.get(
            "googtrans",
            "https://test.paropakari.com/#/"
          );

          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,
          });
        });
    }

    // this.submit();
  }

  componentDidMount() {
    window.addEventListener("load", this.submit);
    this.submit();
    window.addEventListener("load", this.dropdown);
    this.dropdown();
    window.addEventListener("load", this.notify);
    this.notify();
    window.addEventListener("load", this.Footer);
    this.Footer();
    window.addEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
    const element = document.querySelector(".VIpgJd-ZVi9od-ORHb-OEVmcd");
    if (
      element &&
      window.getComputedStyle(element).getPropertyValue("position") === "fixed"
    ) {
      element.style.position = "absolute";
    }
    document.title = "India's only expenseless, direct crowdfunding platform";
  }

  componentWillUnmount() {
    window.removeEventListener("load", this.submit);
    this.submit();
    window.addEventListener("load", this.dropdown);
    this.dropdown();
    window.removeEventListener("load", this.notify);
    this.notify();
    window.removeEventListener("load", this.Footer);
    this.Footer();
    window.removeEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
  }
  Logo() {
    var page = {
      pathname: "/Home",
    };

    this.props.history.push(page);
  }

  handleScriptCreate() {
    this.setState({ scriptLoaded: false });
  }

  handleScriptError() {
    this.setState({ scriptError: true });
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true });
  }

  render() {
    const { Details, Dropdownlist, setSelectedValue } = this.state;
    const {
      howitworks,
      blog,
      contactus,
      termsandcondition,
      causes,
      beneficiaries,
      paropakaris,
      amountraised,
      copyright,
      beaparopakari,
      myjourney,
      createacause,
      invite,
      profile,
      changepassword,
      group,
      logout,
      others,
      requiredforr,
      familyfriends,
      self,
      submit,
      beneficiaryname,
      name,
      groupinfo,
      members,
      back,
      groupicon,
      change,
      update,
      privacypolicy,
      edit,
      updatesuccess,
      AreyousureyouwanttodeletethisGroup,
      friends,
      langID,
      updategrp,
      deletegrp,
      selectgrp,
      ok,
      savedImagePath,
    } = this.state;

    let selectedValue = [];
    let selectedLabel = [];
    this.state.selectedOption &&
      this.state.selectedOption.forEach((item) => {
        selectedValue.push(item.value);
        selectedLabel.push(item.label);
      });
    let options = Dropdownlist.map(function (item) {
      return {
        value: item.id,
        label: item.name,
        image: item.profile_imagePath,
      };
    });

    let formatOptionLabel = ({ value, label, image }) => (
      <div className="grp-flex" style={{ display: "flex" }}>
        <img className="grp-edit-img2" src={image} />
        <span>{label}</span>
      </div>
    );

    let unique = [...new Set(selectedLabel)];

    let unique2 = [...new Set(selectedValue)];

    const { notify } = this.state;
    var username = JSON.parse(localStorage.username);
    let { foo, Menu } = this.state;
    if (this.state.loading) {
      return (
        <center className="notranslate">
          <img
            className="loading-img2 notranslate"
            src="https://testapi.paropakari.com/images/Paropakari.gif"
          />
        </center>
      );
    } else {
      return (
        <div
          className="VIpgJd-ZVi9od-ORHb-OEVmcd"
          style={{ position: "absolute" }}
        >
          <div
            style={{
              overflowX: "hidden",
              backgroundImage: "url(" + "./assets/images/10.jpg" + ")",
            }}
            className="notranslate"
          >
            <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
            <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
            <link href="./assets/css/lightbox.css" rel="stylesheet" />
            <link href="./assets/css/animate.min.css" rel="stylesheet" />
            <link href="./assets/css/main.css" rel="stylesheet" />
            <select
              className="form-control"
              style={{
                display: "inline",
                width: "10%",
                marginTop: "0",
                marginLeft: "0",
                float: "right",
                width: "fit-content",
              }}
              value={this.state.value}
              onChange={this.handleChanges}
            >
              <option value="English">Languages</option>
              <option value="Kannada">Kannada</option>
              <option value="Tamil">Tamil</option>
              <option value="Hindi">Hindi</option>
              <option value="Telugu">Telugu</option>
               {/* <option value="Spanish">Spanish</option> */}
              <option value="English">English</option>
            </select>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <div>
              <div className="mag notranslate">
                <div class="pro-home-nav">
                  <input
                    type="checkbox"
                    id="pro-home-nav-check"
                    onClick={this.checkfun}
                  />
                  <div class="pro-home-nav-header">
                    <div class="pro-home-nav-title notranslate">
                      <a className="navbar-brand" href="#/HomePage">
                        <h1 className="navbar-new-text">
                          <img
                            src="./assets/images/plogo.png"
                            alt="logo"
                            style={{
                              marginBottom: "17px",
                              width: "26%",
                              fontsize: "300px",
                            }}
                          />
                          AROPAKARI
                        </h1>
                      </a>
                    </div>
                  </div>

                  <div class="pro-home-nav-btn">
                    <label for="pro-home-nav-check">
                      <div className="pro-naav">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </label>
                  </div>

                  {langID == "English" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p class="pro-center">
                          <a className="new-design" href="#/CauseFor">
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-boxx1 parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/InviteFriend">{invite.result}</a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontsize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a className="text-parop-menu">
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown">
                              <li className="hower-text">
                                <a href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a className="text-parop-menu" href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Login">
                                  <b>{logout.result}</b>
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : langID == "Hindi" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p class="pro-center">
                          <a className="new-design" href="#/CauseFor">
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-boxx parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/InviteFriend">{invite.result}</a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontsize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a className="text-parop-menu">
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown">
                              <li className="hower-text">
                                <a href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a className="text-parop-menu" href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Login">
                                  <b>{logout.result}</b>
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : langID == "Spanish" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p class="pro-center">
                          <a className="new-design" href="#/CauseFor">
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-boxx1 parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/InviteFriend">{invite.result}</a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontsize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a className="text-parop-menu">
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown">
                              <li className="hower-text">
                                <a href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a className="text-parop-menu" href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Login">
                                  <b>{logout.result}</b>
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : langID == "Tamil" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p class="pro-center">
                          <a className="new-tamil-design" href="#/CauseFor">
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-tamil-boxx parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/InviteFriend">{invite.result}</a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontsize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a className="text-parop-menu">
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown">
                              <li className="hower-text">
                                <a href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a className="text-parop-menu" href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Login">
                                  <b>{logout.result}</b>
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : langID == "Kannada" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p class="pro-center">
                          <a className="new-telugu-design" href="#/CauseFor">
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-tamil-boxx parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/InviteFriend">{invite.result}</a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontsize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a className="text-parop-menu">
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown">
                              <li className="hower-text">
                                <a href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a className="text-parop-menu" href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Login">
                                  <b>{logout.result}</b>
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : langID == "Telugu" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p class="pro-center">
                          <a className="new-telugu-design" href="#/CauseFor">
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-tamil-boxx parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/InviteFriend">{invite.result}</a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontsize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a className="text-parop-menu">
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown">
                              <li className="hower-text">
                                <a href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a className="text-parop-menu" href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Login">
                                  <b>{logout.result}</b>
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>

              {/* <header id="header" style={{backgroundImage: "url(" + "./assets/images/10.jpg" + ")"}}>    

<div class="navbar navbar-inverse" role="banner">
   <div class="container">
       <div class="navbar-header">
           <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
               <span class="sr-only">Toggle navigassstion</span>
               <span class="icon-bar"></span>
               <span class="icon-bar"></span>
               <span class="icon-bar"></span>
           </button>

           <a class="navbar-brand" href="#/Homepage">
             <h1 style={{color:'blue',fontFamily:'Biysk-Regular'}}><img src="./assets/images/plogo.png" alt="logo"style={{marginBottom:'17px',width:'26%',fontsize:'52px'}}  />AROPAKARI
                   
               </h1>
           </a>
           
       </div>
       <div class="collapse navbar-collapse">
           <ul class="nav navbar-nav navbar-right">
           
               
               <li><a href="#/Myactivity"><b>{myjourney.result}</b></a></li> 
               <li><a href="#/CauseFor"><b>{createacause.result}</b></a></li> 
               <li><a href="#/InviteFriend"><b>{invite.result}</b></a></li> 
               <li className=""><a href="#/Notification" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>
                            
                          <div class="fa fa-bell" style={{fontsize:'24px'}}>{notify.map((notifyy) => (
                                               <span class="-count"> 
                                
                              
                                {notifyy.noticount}
                               
                                  </span> ))}</div>
                           </a></li> 
               <li class="dropdown"><a><b>{username}</b> <i class="fa fa-angle-down"></i></a>
                   <ul role="menu" class="sub-menu">
                       <li><a href="#/Profile"><b>{profile.result}</b></a></li>
                       <li><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                       <li className="active"><a href="#/Group"><b>{group.result}</b></a></li>
                       <li><a href="#/Login"><b>{logout.result}</b></a></li>
                   </ul>
               </li>                         
           </ul>
       </div>
   </div>
</div>
</header> */}

              {/*/#header*/}

              {/*/#page-breadcrumb*/}
              <section
                id="about-company"
                className={
                  this.state.movedown == true
                    ? "wow fadeInUp"
                    : "wow fadeInUp Tiptop"
                }
                data-wow-duration="400ms"
                data-wow-delay="400ms"
                style={{ backgroundColor: "#aee6db" }}
              >
                <div className="container">
                  {/* Page Heading/Breadcrumbs */}
                  {/* Content Row */}

                  <br />

                  <center>
                    <h2 style={{ color: "black", marginLeft: "-7%" }}>
                      <b>{groupinfo.result}</b>
                    </h2>
                  </center>
                  <br />

                  <form action="" onSubmit={this.handleSubmit.bind(this)}>
                    {/* <h3><b>{groupinfo.result}</b></h3> */}

                    <div className="row">
                      <div class="form-group col-sm-4 col-lg-4"></div>
                      <div class="form-group col-sm-4 col-lg-4">
                        <label style={{ float: "left" }}>{name.result}</label>

                        {Details.map((Det) => (
                          <input
                            type="text"
                            className="form-control"
                            style={{ width: "100%", float: "inline-start" }}
                            readOnly="true"
                            defaultValue={Det.group_Name}
                            name="group_Name"
                            id="group_Name"
                          ></input>
                        ))}
                        <div style={{ color: "red" }}>
                          <span
                            className="field-validation-valid"
                            data-valmsg-for="group_Name"
                            data-valmsg-replace="true"
                          />
                        </div>
                      </div>
                      <div class="form-group col-sm-4 col-lg-4"></div>
                    </div>
                    <div className="row">
                      <div class="form-group col-sm-4 col-lg-4"></div>
                      <div class="form-group col-sm-4 col-lg-4">
                        {(() => {
                          if (langID == "Tamil") {
                            return (
                              <label style={{ float: "left" }}>
                                {members.result}
                              </label>
                            );
                          }
                        })()}
                        {(() => {
                          if (langID == "Hindi") {
                            return (
                              <label style={{ float: "left" }}>
                                {members.result}
                              </label>
                            );
                          }
                        })()}
                        {(() => {
                          if (langID == "Kannada") {
                            return (
                              <label style={{ float: "left" }}>
                                {members.result}
                              </label>
                            );
                          }
                        })()}
                        {(() => {
                          if (
                            langID == "English" ||
                            langID == "Telugu" ||
                            langID == "Spanish"
                          ) {
                            return (
                              <label style={{ float: "left" }}>
                                {members.result}
                              </label>
                            );
                          }
                        })()}

                        <br />
                        {/* <AddMemberGroup> </AddMemberGroup> */}
                        <div
                          style={{
                            width: "100%",
                            float: "inline-start",
                            backgroundColor: "white",
                          }}
                        >
                          <Select
                            isClearable={false}
                            placeholder={"Select Mates"}
                            styles={this.customStyles}
                            // menuPortalTarget={document.querySelector('body')}
                            value={this.state.selectedOption}
                            onChange={this.handleChange}
                            selectedValue={true}
                            hideSelectedOptions={true}
                            options={options}
                            isMulti
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                neutral50: "white",
                              },
                            })}
                            formatOptionLabel={formatOptionLabel}
                          />
                        </div>
                      </div>
                      <div class="form-group col-sm-4 col-lg-4"></div>
                    </div>

                    <div className="row">
                      <div class="form-group col-sm-4 col-lg-4"></div>
                      <div class="form-group col-sm-4 col-lg-4">
                        {(() => {
                          if (langID == "Tamil") {
                            return (
                              <label
                                htmlFor="GroupName"
                                style={{ float: "left" }}
                              >
                                {groupicon.result}{" "}
                              </label>
                            );
                          }
                        })()}

                        {(() => {
                          if (langID == "Kannada") {
                            return (
                              <label
                                htmlFor="GroupName"
                                style={{ float: "left" }}
                              >
                                {groupicon.result}{" "}
                              </label>
                            );
                          }
                        })()}
                        {(() => {
                          if (langID == "Telugu") {
                            return (
                              <label
                                htmlFor="GroupName"
                                style={{ float: "left" }}
                              >
                                {groupicon.result}{" "}
                              </label>
                            );
                          }
                        })()}
                        {(() => {
                          if (langID == "Hindi") {
                            return (
                              <label
                                htmlFor="GroupName"
                                style={{ float: "left" }}
                              >
                                {groupicon.result}{" "}
                              </label>
                            );
                          }
                        })()}
                        {(() => {
                          if (langID == "English") {
                            return (
                              <label
                                htmlFor="GroupName"
                                style={{ float: "left" }}
                              >
                                {groupicon.result}{" "}
                              </label>
                            );
                          }
                        })()}
                        {(() => {
                          if (langID == "Spanish") {
                            return (
                              <label
                                htmlFor="GroupName"
                                style={{ float: "left" }}
                              >
                                {groupicon.result}{" "}
                              </label>
                            );
                          }
                        })()}
                        <input
                          type="hidden"
                          name="user_id"
                          defaultValue={unique2}
                        />

                        {Details.map((Det) => (
                          <input
                            type="hidden"
                            name="default_user_id"
                            defaultValue={Det.user_id}
                          />
                        ))}

                        <input
                          type="file"
                          className="image-upload notranslate"
                          id="ImagePath"
                          accept="image/*"
                          name="ImagePath"
                          style={{ width: "25%", display: "none" }}
                          onChange={this.ImagePreview}
                        />

                        {/* <br />  <label for="ImagePath" className="form-control" style={{ fontFamily: 'cuyabra', width: '91%', float: "inline-start" }}>{change.result}&nbsp;<i style={{ fontsize: '24px' }} class="fa"> &#xf067;</i></label> */}

                        {Details.map((Det) => (
                          <input
                            type="hidden"
                            defaultValue={Det.imagePath}
                            name="default_ImagePath"
                          ></input>
                        ))}
                        {/* {this.state.ImageDisplay && (
                                            <div style={{ textAlign: "center" }}>
                                              <button className="remove" onClick={this.resetFile}><CancelIcon className="icon-x"></CancelIcon></button>
                                            </div>
                                          )} */}
                        {/* <img id="groupimage" style={{width:'25%',height:'30%',marginLeft:'-1%'}} src={this.state.ImageDisplay} alt /> */}
                        <br />

                        {this.state.ImageDisplay && (
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "12%",
                              marginLeft: "35%",
                            }}
                          >
                            <button className="remove" onClick={this.resetFile}>
                              <CancelIcon className="icon-x"></CancelIcon>
                            </button>
                          </div>
                        )}

                        {/* <div
                          class="pic-container pic-medium pic-circle"
                          style={{ marginLeft: "31%" }}
                        >
                          {Details.map((Det) =>
                            this.state.ImageDisplay ? (
                              <img
                                className="pic"
                                style={{ borderRadius: "100%" }}
                                src={this.state.ImageDisplay}
                                name="imagePath"
                                alt
                              />
                            ) : (
                              <img
                                className="pic"
                                style={{ borderRadius: "99%" }}
                                alt
                                src={Det.imagePath}
                                name="imagePath"
                              />
                            )
                          )}

                          <div class="pic-overlay">
                             <a>
                              <i
                                class="fa fa-pencil-square-o"
                                aria-hidden="true"
                                data-toggle="modal"
                                data-target="#basicModal"
                              ></i>
                            </a>
                          </div>
                        </div> */}

                        <div
                          className="pic-container pic-medium pic-circle"
                          style={{ marginLeft: "31%" }}
                        >
                          {this.state.Details.map((Det, index) => {
                            const imagePath =
                              this.state.ImageDisplay || Det.imagePath;
                            // console.log(`Rendering image: ${imagePath}`);

                            if (!this.state.savedImagePath) {
                              this.setState({ savedImagePath: imagePath });
                            }

                            return (
                              <img
                                key={index}
                                className="pic"
                                style={{ borderRadius: "100%" }}
                                src={savedImagePath}
                                alt="Image Preview"
                                name="imagePath"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = "fallback-image-path.jpg";
                                }} // Replace with a valid fallback image path
                              />
                            );
                          })}
                          <div className="pic-overlay">
                            <a>
                              <i
                                className="fa fa-pencil-square-o"
                                aria-hidden="true"
                                data-toggle="modal"
                                data-target="#basicModal"
                              ></i>
                            </a>
                          </div>
                        </div>
                        <br />
                      </div>
                      <div class="form-group col-sm-4 col-lg-4"></div>
                    </div>
                    <div className="row">
                      <div class="form-group col-sm-4 col-lg-4"></div>
                      <div class="form-group col-sm-4 col-lg-4">
                        <center>
                          <button
                            type="submit"
                            onClick={() => this.Alert()}
                            defaultValue="Create"
                            className="btn btn-secondary "
                            style={{
                              backgroundColor: "rgb(102, 71, 18)",
                              color: "white",
                              width: "120px",
                            }}
                          >
                            {update.result}
                          </button>

                          <a
                            className="btn btn-secondary "
                            style={{
                              textDecoration: "none",
                              marginLeft: "0%",
                              fontSize: "14px",
                              backgroundColor: "rgb(102, 71, 18)",
                              color: "white",
                              width: "100px",
                            }}
                            href={
                              this.state.selectedOption.length != 0
                                ? "#/Group/" + langID
                                : "#/Group/"
                            }
                          >
                            {back.result}{" "}
                          </a>
                          <br />
                        </center>

                        <br />
                        <br />
                      </div>
                      <div class="form-group col-sm-4 col-lg-4"></div>
                    </div>
                  </form>
                </div>

                <br />
                <br />
                <br />
                <br />
              </section>
              <div
                class="modal fade"
                id="basicModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <div class="modal-header" id="Headerback">
                      <h5 class="modal-title" id="exampleModalLongTitle">
                        {updategrp.result}
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <form
                        autoComplete="off"
                        onSubmit={this.handleDeletePic.bind(this)}
                      >
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            marginLeft: "-7px",
                          }}
                        >
                          <input
                            type="hidden"
                            name="remove"
                            id="remove"
                            defaultValue="1"
                          />
                          {Details.map((Det) => (
                            <div style={{ display: "none" }}>
                              <input
                                type="text"
                                className="form-control"
                                style={{ width: "25%" }}
                                readOnly="true"
                                defaultValue={Det.group_Id}
                                name="Group_Id"
                              ></input>
                              <input
                                type="text"
                                className="form-control"
                                style={{ width: "25%" }}
                                readOnly="true"
                                defaultValue={Det.imagePath}
                                name="default_ImagePath"
                              ></input>
                              <input
                                type="text"
                                name="default_user_id"
                                defaultValue={Det.user_id}
                              />

                              <input
                                type="file"
                                className="image-upload"
                                id="ImagePath"
                                accept="image/*"
                                name="ImagePath"
                                style={{ width: "25%", display: "none" }}
                                onChange={this.ImagePreview}
                              />
                            </div>
                          ))}
                          <label style={{ fontSize: "11px" }}>
                            {" "}
                            <b>
                              {" "}
                              {deletegrp.result}{" "}
                              <a>
                                <i
                                  class="fa fa-window-close-o"
                                  aria-hidden="true"
                                ></i>
                              </a>{" "}
                            </b>{" "}
                          </label>
                        </button>
                      </form>

                      <br />

                      <form
                        autoComplete="off"
                        onSubmit={this.handleImageevents.bind(this)}
                      >
                        {/*             
                        Choose File
                        <input type="file" class="hide_file"> */}
                        <label
                          style={{ fontFamily: "cuyabra", fontSize: "11px" }}
                        >
                          <b>
                            {selectgrp.result}{" "}
                            <a>
                              <i class="fa fa-pencil-square-o"></i>
                            </a>
                          </b>{" "}
                        </label>
                        {Details.map((Det) => (
                          <div style={{ display: "none" }}>
                            <input
                              type="text"
                              className="form-control"
                              style={{ width: "25%" }}
                              readOnly="true"
                              defaultValue={Det.group_Id}
                              name="Group_Id"
                            ></input>
                            <input
                              type="text"
                              className="form-control"
                              style={{ width: "25%" }}
                              readOnly="true"
                              defaultValue={Det.imagePath}
                              name="default_ImagePath"
                            ></input>
                            <input
                              type="text"
                              name="default_user_id"
                              defaultValue={Det.user_id}
                            />
                            {/* <input type="text"  name="ImagePath" value="paropkarii" ></input> */}

                            <input
                              type="file"
                              className="image-upload"
                              id="ImagePath"
                              accept="image/*"
                              name="ImagePath"
                              style={{ width: "25%", display: "none" }}
                              onChange={this.ImagePreview}
                            />
                          </div>
                        ))}
                        <br />

                        <input
                          placeholder="Select"
                          type="file"
                          id="ImagePath"
                          accept="image/*"
                          name="ImagePath"
                          onChange={this.ImagePreview}
                          className="hide_file"
                          style={{
                            color: "rgb(165, 217, 207)",
                            position: "absolute",
                            top: "34%",
                            left: "0%",
                            width: "100%",
                            height: "60px",
                          }}
                        />

                        {(() => {
                          if (this.state.ImageDisplay == null) {
                            return null;
                          } else {
                            return (
                              <div className="small-pre">
                                <img
                                  id="groupimage"
                                  className="small-circle-image"
                                  src={this.state.ImageDisplay}
                                />
                              </div>
                            );
                          }
                        })()}

                        <button
                          type="submit"
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            marginTop: -"36px",
                          }}
                        >
                          <br />
                          <p>{ok.result}</p>
                          {/* <p>Ok</p> */}
                        </button>
                        <br />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/*/#team*/}
              <footer id="footer" style={{ color: "white" }}>
                <br />

                <div className="container">
                  <div className="copyright">
                    <div className="row col-lg-12">
                      <div className="col-lg-9 mb-6">
                        {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}

                        <p style={{ marginLeft: "45px" }}>
                          {copyright.result}&nbsp; |&nbsp;
                          <a
                            href="#/Contactuslogin"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            {contactus.result}&nbsp;
                          </a>
                          |{" "}
                          <a
                            href="#/PrivacyPolicyLogin"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            &nbsp;{privacypolicy.result}&nbsp;
                          </a>
                          |
                          <a
                            href="#/TermsandConditionLogin"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            &nbsp;{termsandcondition.result}
                          </a>
                        </p>
                      </div>
                      <div className="col-lg-3 mb-3">
                        <div
                          className="social-icons"
                          style={{ color: "white", marginLeft: "118px" }}
                        >
                          <ul className="nav nav-pills">
                            <li>
                              <a
                                target="_blank"
                                href="https://www.facebook.com/beoneparopakari"
                              >
                                <i className="fa fa-facebook" />
                              </a>
                            </li>
                            {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                            <li>
                              <a
                                target="_blank"
                                href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw"
                              >
                                <i className="fa fa-youtube-play" />
                              </a>
                            </li>
                            <li>
                              <a
                                target="_blank"
                                href="https://www.instagram.com/paropakari_beone/"
                              >
                                <i className="fa fa-instagram" />
                              </a>
                            </li>
                            <li>
                              <a
                                target="_blank"
                                href="https://www.linkedin.com/company/paropakari/"
                              >
                                <i className="fa fa-linkedin" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="containerss"></div>
              </footer>
              {/*/#footer*/}
            </div>
          </div>
        </div>
      );
    }
  }
}
