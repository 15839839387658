
import React, { useState, useEffect } from 'react';
import Select from 'react-select';


function Causes() {
    const [data, setData] = useState([]);

    const [selectedValue, setSelectedValue] = useState([]);

    useEffect(() => {
        loadData();

    }, []);

    const loadData = async () => {
        let user = JSON.parse(localStorage.getItem('user'));
        const accessToken = user;
        await fetch('https://testapi.paropakari.com/api/Invite/Title', { headers: { "Authorization": `Bearer ${accessToken}` } })
            .then(response => response.json())
            .then(receivedData => setData(receivedData));

    }
    const handleChange = (e) => {
        setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);

    }
    const customStyles = {
        control: (base, state) => ({
            ...base,
            backgroundColor: "transparent",
            color: "black",
            width: "488px"
        })
    };
    return (

        <div>

            <Select

                placeholder={"Select Your Causes"}
                styles={customStyles}
                menuPortalTarget={document.querySelector('body')}
                onChange={handleChange}
                options={data.map(e => ({ label: e.upload_title, value: e.upload_id }))}
                isMulti

                theme={theme => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        neutral50: 'black',
                    },
                })}

            />

            {selectedValue && <div style={{ marginTop: 20, lineHeight: '25px' }}>

                <input type="hidden" id="imageUpload" name="upload_id" defaultValue={selectedValue} required />

            </div>}
        </div>



    );
}
export default Causes;