import { Height } from '@material-ui/icons';
import React, { Component } from 'react'
import { BrowserRouter as Navlink } from "react-router-dom";
import Dropdowns from './Dropdowns';
import Taggroup from './Taggroup';
import CancelIcon from '@material-ui/icons/Cancel';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Cookies from 'js-cookie';
import 'react-datepicker/dist/react-datepicker.css';

//import SideBars from './SideBars.js';
import $ from "jquery";
import { getDefaultNormalizer } from '@testing-library/react';

const refreshPage = () => {

  setTimeout(() => window.location.reload(), 4000);


}
export default class Claim extends Component {

  constructor(props) {
    super(props)

    this.state = {
      id: this.props.match.params.id,
      upload_title: "",
      category_id: "",
      relation_id: "",
      upload_description: "",
      upload_amount: "",
      upload_num_of_beneficiaries: "",
      upload_place: "",
      required_by: "",
      status_80G: "",
      notify: [],
      formErrors: {},
      emps: [],
      foo: [],
      Menu: [],
      beaparopakari: [],
      myjourney: [],
      createacause: [],
      invite: [],
      Beneficiary_UPI: '',
      upload_BankAccount: '',
      emailId: '',
      Beneficiary_name: '',
      profile: [], changepassword: [], group: [], logout: [],
      requiredforr: [], self: [], familyfriends: [], others: [], beneficiaryname: [], submit: [],
      categories: [], foodandnutrition: [], health: [], professional: [], sports: [], socialwefare: [], education: [],
      causes: [], privacypolicy: [], includedocs: [], forex: [], close: [], iagreealldet: [], youwillrcvwithin: [],
      beneficiaries: [],
      paropakaris: [],
      amountraised: [],
      copyright: [],
      contactus: [],
      termsandcondition: [],
      howitworks: [],
      blog: [], donateasmile: [],
      createyourcause: [], title: [], description: [], bankaccount: [],
      amount: [], addsupportingdocuments: [], tagfriends: [], ifscode: [], confirmbankdetails: [],
      place: [], available80G: [], enddate: [], taggroup: [], upiid: [], create: [], enter: [], select: [],
      name: [], email: [], mobilenumber: [], confirmyourbank: [],
      langID: [],
      lID: [],
      file_name: null,
      disabled: false,
      disable: false,
      prof_BankAccount: "",
      Beneficiary_AccountNumber: "",
      ifsc: "",
      upi_ID: "",
      dob: moment(),
      TitleisRequired: [],
      DescriptionisRequired: [],
      PurposeisRequired: [],
      AmountisRequired: [],
      PleaseConfirmtheBankDetails: [],
      PlaceisRequired: [],
      DateisRequired: [],
      Causecreatedsuccessfully: [],
      studName: '',
      emailId: '',
      phoneNumber: '',
    };

    this.initialState = this.state;
    
    this.dateChange = this.dateChange.bind(this);
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmittrans = this.handleSubmittrans.bind(this);
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.submit = this.submit.bind(this);
    this.Payout = this.Payout.bind(this);

  }
  redirect = (e) => {
    e.preventDefault();
    var page = {
      pathname: './Login.js',

    }
    this.props.history.push(page)
  }


  ReloadPage = () => {
    let reloads = 0;
    // setTimeout(() => window.location.reload(), 2000);
    if (sessionStorage.getItem('reloadcreate') !== null) {
      
      reloads = sessionStorage.getItem('reloadcreate');
      reloads++;
      window.sessionStorage.setItem('reloadcreate', (reloads));
      
    }
    else {
      reloads = 1;
      window.sessionStorage.setItem('reloadcreate', (reloads));
      
    }

    if (reloads == 1) {
      window.location.reload();
    }


  }


  fileChangedHandler(event) {

    var fileInput = false

    if (event.target.files[0]) {
      fileInput = true
    }
    this.setState({
      file: URL.createObjectURL(event.target.files[0])
    });
  }
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  dateChange(date) {
    this.setState({
      dob: date
    });
  }
  handleFormValidation() {
    //  alert("Validation function called");
    // const{upload_title,upload_description,upload_amount,ifsc,
    //   upload_num_of_beneficiaries,upload_place,required_by,relation_id,Beneficiary_AccountNumber,
    //   category_id,status_80G,checkbox}=this.state;
    // let Beneficiary_name =document.getElementById("Beneficiary_name").defaultValue
    let Beneficiary_name = document.getElementById("Beneficiary_name").value;

    //  let upload_BankAccount =document.getElementById("upload_BankAccount").defaultValue;
    let upload_BankAccount = document.getElementById("upload_BankAccount").value;

    // let Beneficiary_IFSC =document.getElementById("Beneficiary_IFSC").defaultValue;
    let Beneficiary_IFSC = document.getElementById("Beneficiary_IFSC").value;

    //  let Beneficiary_UPI =document.getElementById("Beneficiary_UPI").defaultValue;
    // let Beneficiary_UPI =document.getElementById("Beneficiary_UPI").value;

    //  let Beneficiary_email =document.getElementById("Beneficiary_email").defaultValue;
    let Beneficiary_email = document.getElementById("Beneficiary_email").value;

    // let Beneficiary_mobilenumber =document.getElementById("Beneficiary_mobilenumber").defaultValue;
    let Beneficiary_mobilenumber = document.getElementById("Beneficiary_mobilenumber").value;

    let formErrors = {};
    let formIsValid = true;

    if (Beneficiary_name == '') {
      formIsValid = false;
      formErrors["opErr"] = "Name  is Required.";
    }

    if (Beneficiary_email == '') {
      formIsValid = false;
      formErrors["upaErr"] = "Email id is required.";
    } else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Beneficiary_email))) {

      formIsValid = false;
      formErrors["upaErr"] = "Invalid email id.";
    }

    if (Beneficiary_mobilenumber == '') {
      formIsValid = false;
      formErrors["uplErr"] = "Phone number is required.";
    }
    else {
      var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/;
      // if (!mobPattern.test(Beneficiary_mobilenumber)) {    
      //     formIsValid = false;    
      //     formErrors["uplErr"] = "Invalid phone number.";    
      // }    
    }


    if (upload_BankAccount == '') {
      formIsValid = false;
      formErrors["reqErr"] = "Bank Account Number is Required.";
    }
    //   else if (!(/^[0-9]{4}-[0-9]{4}-[0-9]{2}-[0-9]{9,18}$/.test(upload_BankAccount))) {    

    //     formIsValid = false;    
    //     formErrors["reqErr"] = "Invalid Bank Account Number.";    
    // }
    if (Beneficiary_IFSC == '') {
      formIsValid = false;
      formErrors["PurErr"] = "IFSC Number is Required.";
    } else if (!(/^[A-Z]{4}0[A-Z0-9]{6}$/.test(Beneficiary_IFSC))) {

      formIsValid = false;
      formErrors["PurErr"] = "Invalid IFSC Code .";
    }
    //   if(Beneficiary_UPI == ''){
    //     formIsValid=false;
    //     formErrors["befErr"]="UPI ID  is Required.";
    // } 
    //   else if (!(/^[a-zA-Z0-9_]{3,}@[a-zA-Z]{3,}$/.test(Beneficiary_UPI))) 
    //   {    

    //     formIsValid = false;    
    //     formErrors["befErr"] = "Invalid UPI ID .";    
    // }

    //  if(!relation_id){
    //     formIsValid=false;
    //     formErrors["RelaErr"]="Required_for is Required.";
    // }
    //   if(!status_80G){
    //     formIsValid=false;
    //     formErrors["StatusErr"]="status_80G is Required.";
    // }

    if (document.querySelectorAll('[type="checkbox"]:checked').length < 1) {
      formErrors["ChckErr"] = "Please, Confirm the Bank Details";
      formIsValid = false;
    }

    this.setState({ formErrors: formErrors });
    
    return formIsValid;

  }
  submit = () => {

    const urll = new URL(window.location.href);
    let upload_id = JSON.parse(localStorage.getItem('upload_id'));
    

    let url = "https://testapi.paropakari.com/api/GetProfile/Get_ResponseDetails?id=" + upload_id;
    let data = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          emps: data
        });
        
        // 
      });
  }
  handleSubmittrans = async prof_benID => {
    if (this.props.location.state.data.id == 3)
    //event.preventDefault();

    {

      var phone = 9994115433
      var address = document.getElementById("upload_place").value
      var email = 'vg@gmail.com'

      var benename = document.getElementById("benfname").value
      
      var bankAccount = document.getElementById("Beneficiary_AccountNumber").value
      var ifsc = document.getElementById("ifsc").value
      var benId = document.getElementById("benId").value

      let user = JSON.parse(localStorage.getItem('user'));
      var data = {
        phone: phone,
        benename: benename,
        address: address,
        email: email,
        bankAccount: bankAccount,
        ifsc: ifsc
      }
      

      const accessToken = user;
      var url = "https://testapi.paropakari.com/api/UploadFile/Authorize?benename=" + benename + '&phone=' + phone + '&email=' + email + '&address1=' + address +
        '&bankAccount=' + bankAccount +
        '&ifsc=' + ifsc +
        '&benId=' + benId;



      //  var url = "https://testapi.paropakari.com/api/Create/Authorize?benename=tyryrty&phone=89879879879898798&email=vg@ynyu.bhn&address1=5657ghggj&bankAccount=56756756756756&ifsc=SBTgtrh5664"  ;

      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken

        },
        body: data
      };


      try {
        const response = await fetch(url, options);
        const result = await response.json();
        localStorage.setItem('benefId', JSON.stringify(result.benefId));
        // localStorage.setItem('username', JSON.stringify(result.name));
        // localStorage.setItem('userid', JSON.stringify(result.userid));
        ///localStorage.setItem('create', JSON.stringify(result.id));

        

        if (result.ok) {

          // var page= {
          //   pathname: '#/HomePage',
          //   state: {
          //     data:{
          //      password: this.state.password,
          //      username: this.state.username,

          //     }
          //   }
          // }
          
          //window.alert("login succesful");
          //this.props.history.push(page)
          // alert("Login Successful...");
          //  window.location.href = "#/HomePage";

        } else {
          alert('Username and Password is incorrect!. Try Again');

          // window.location.reload();
        }

      }
      catch (error) {
        console.error(error);
      }
    }
    else {
      var phone = 9994115433
      var address = document.getElementById("upload_place").value
      var email = 'vg@gmail.com'

      var benename = document.getElementById("benfname").value
      
      var bankAccount = document.getElementById("Beneficiary_AccountNumber").value
      var ifsc = document.getElementById("ifsc").value
      var prof_benID = prof_benID;
      
      let user = JSON.parse(localStorage.getItem('user'));
      var data = {
        phone: phone,
        benename: benename,
        address: address,
        email: email,
        bankAccount: bankAccount,
        ifsc: ifsc
      }
      

      const accessToken = user;
      var url = "https://testapi.paropakari.com/api/Create/Authorize?benename=" + benename + '&phone=' + phone + '&email=' + email + '&address1=' + address +
        '&bankAccount=' + bankAccount +
        '&ifsc=' + ifsc +
        '&prof_benID=' + prof_benID;



      //  var url = "https://testapi.paropakari.com/api/Create/Authorize?benename=tyryrty&phone=89879879879898798&email=vg@ynyu.bhn&address1=5657ghggj&bankAccount=56756756756756&ifsc=SBTgtrh5664"  ;

      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken

        },
        body: data
      };


      try {
        const response = await fetch(url, options);
        const result = await response.json();
        localStorage.setItem('benefId', JSON.stringify(result.benefId));
        // localStorage.setItem('username', JSON.stringify(result.name));
        // localStorage.setItem('userid', JSON.stringify(result.userid));
        ///localStorage.setItem('create', JSON.stringify(result.id));

        

        if (response.ok) {

          // var page= {
          //   pathname: '#/HomePage',
          //   state: {
          //     data:{
          //      password: this.state.password,
          //      username: this.state.username,

          //     }
          //   }
          // }
          
          //window.alert("login succesful");
          //this.props.history.push(page)
          // alert("Login Successful...");
          //  window.location.href = "#/HomePage";

        } else {
        //  alert('Username and Password is incorrect!. Try Again');

          // window.location.reload();
        }

      }
      catch (error) {
        console.error(error);
      }
    }


  }

  notify = () => {


    let url = "https://testapi.paropakari.com/api/GetProfile/Notification_Count";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          notify: data
        });
        
        // 
        
        localStorage.setItem('noticount', JSON.stringify(data[0].noticount));
      });
  }


  handleSubmit = async event => {
    event.preventDefault();

    let Beneficiary_name = document.getElementById("Beneficiary_name").value;

    let upload_BankAccount = document.getElementById("upload_BankAccount").value;

    let Beneficiary_IFSC = document.getElementById("Beneficiary_IFSC").value;
    let Beneficiary_email = document.getElementById("Beneficiary_email").value;
    let Beneficiary_mobilenumber = document.getElementById("Beneficiary_mobilenumber").value;
    let Beneficiary_UPI = document.getElementById("Beneficiary_UPI").value;

    if (Beneficiary_name == '' || upload_BankAccount == '' || Beneficiary_IFSC == '' || Beneficiary_email == '' || Beneficiary_mobilenumber == '') {
      this.handleFormValidation();
    }
    else {
      var x = window.confirm(this.state.iagreealldet.result);
      if (x) {
        // var x = window.confirm('Congratulations!! You have claimed successfully. You claim money will be credited within 24 hrs');
        // if (x){
        //   window.location.href = "#/Claim";
        // }



        const data = new FormData(event.target);
        let user = JSON.parse(localStorage.getItem('user'));

        const accessToken = user;
        const url = "https://testapi.paropakari.com/api/GetProfile/UpdateBenfdetail";
        const options = {
          method: "POST",
          headers: {

            Accept: 'application/json',
            Authorization: "Bearer " + accessToken
          },
          body: data,
        };
        // this.setState(this.initialState)
        const response = await fetch(url, options);
        const result = await response.json();

        if (response.ok) {

          var x = window.confirm(this.state.youwillrcvwithin.result);
          window.location.href = "#/ClaimSuccess";
          //         let Contact_id =JSON.parse(localStorage.getItem('Contact_id'));
          //   let user = JSON.parse(localStorage.getItem('user'));
          //  const accessToken = user;

          //         const url ='https://testapi.paropakari.com/api/GetProfile/CreateFund?contact_id=' + Contact_id + '&name=' +Beneficiary_name +'&ifsc=' +Beneficiary_IFSC + '&accountnumber=' +upload_BankAccount ;
          //         const options = {
          //             method: "POST",
          //   headers:{

          //     Accept: 'application/json',             
          //     Authorization: "Bearer " +accessToken 
          //   },
          //   body: data,
          //   };
          //   const response = await fetch(url, options);
          //   const result = await response.json();
          //   
          //   localStorage.setItem('Fund_id', JSON.stringify(result.id));
          //   if(result.id != 'null')
          //   {
          //     this.Payout();


          //   }
          //   else{
          //     alert(result.error.description);
          //   }

          //    if (response.ok) {


          //   } else {
          //     alert('Failed!. Try Again');
          //    }
        }
      }
    }
  }

  // UpdateBeneficiary = async event => {
  //   event.preventDefault();

  // alert("hi");
  //   const data = new FormData(event.target);
  //   let user = JSON.parse(localStorage.getItem('user'));

  //   const accessToken=user;
  //   const url ="https://testapi.paropakari.com/api/GetProfile/UpdateBenfdetail";
  //   const options = {
  //   method: "POST",
  //   headers:{

  //   Accept: 'application/json',
  //   Authorization: "Bearer " +accessToken
  //   },
  //   body: data,
  //   };
  //  // this.setState(this.initialState)
  //   const response = await fetch(url, options);
  //   const result = await response.json();

  //   if (response.ok) {
  //     this.handleSubmit();


  //             }
  // }

  Payout = async event => {
    let Beneficiary_name = document.getElementById("Beneficiary_name").value;

    let upload_BankAccount = document.getElementById("upload_BankAccount").value;
    let Amount = document.getElementById("claimamount").value;
    let Beneficiary_IFSC = document.getElementById("Beneficiary_IFSC").value;
    let Beneficiary_email = document.getElementById("Beneficiary_email").value;
    let Beneficiary_mobilenumber = document.getElementById("Beneficiary_mobilenumber").value;
    let Beneficiary_UPI = document.getElementById("Beneficiary_UPI").value;
    let fund_account_id = JSON.parse(localStorage.getItem('Fund_id'));
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;

    const url = 'https://testapi.paropakari.com/api/GetProfile/CreatePayout?fund_account_id=' + fund_account_id + '&accountnumber=' + upload_BankAccount + '&amount=' + Amount;
    const options = {
      method: "POST",
      headers: {


        Accept: 'application/json',
        Authorization: "Bearer " + accessToken
      },
      body: "data",
    };
    const response = await fetch(url, options);
    const result = await response.json();
    
    localStorage.setItem('Payout_Id', JSON.stringify(result.id));
    localStorage.setItem('Payou_status', JSON.stringify(result.status));
    if (result.id != 'null') {
      this.AddPayoutdetails();
      if (result.status === 'processing') {
        var x = window.confirm(this.state.youwillrcvwithin.result);
        if (x) {
          window.location.href = "#/HomePage";
        }
      }
      else if (result.status === 'processed') {
        window.location.href = "#/ClaimSuccess";
      }



    }
    else {
      alert("Error Occured");
    }


    // localStorage.setItem('fund_id', JSON.stringify(result));
    if (response.ok) {




    } else {
      alert('Failed!. Try Again');
    }

  }

  AddPayoutdetails = async event => {


    let upload_id = JSON.parse(localStorage.getItem('upload_id'));
    let Fund_id = JSON.parse(localStorage.getItem('Fund_id'));
    let Payout_Id = JSON.parse(localStorage.getItem('Payout_Id'));
    let Payou_status = JSON.parse(localStorage.getItem('Payou_status'));
    let Amount = document.getElementById("claimamount").value;
    let Contactid = JSON.parse(localStorage.getItem('Contact_id'));

    var data = {
      upload_id: upload_id,
      Fund_id: Fund_id,
      Payout_Id: Payout_Id,
      Payou_status: Payou_status,
      Payout_amount: Amount,
      Contact_id: Contactid
    };
    
    let user = JSON.parse(localStorage.getItem('user'));

    const accessToken = user;
    const url = "https://testapi.paropakari.com/api/GetProfile/AddPayoutdetails";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

        "Accept": "application/json",

        Authorization: "Bearer " + accessToken
      },
      body: JSON.stringify(data),
    };
    // this.setState(this.initialState)
    const response = await fetch(url, options);
    const result = await response.json();
    if (response.ok) {
      // alert("Inserted Successfully!");

    }
  }


  Footer() {



    let url = "https://testapi.paropakari.com/api/Authenticate/Get_FooterDetails";
    let data = this.state;

    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          foo: data
        });
        
        // 
      });
  }

  DefaultMenu() {
    //   
    let reloads = 0;
    window.sessionStorage.setItem('reload', (reloads));

    let reloadsmyactivity = 0;
    window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
    window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
    
    
    let MID = sessionStorage.getItem('translate');
    //         if(sessionStorage.getItem('transliteration') !== null){
    //           MID= sessionStorage.getItem('transliteration');
    //         }
    // else
    if (sessionStorage.getItem('translate') !== null) {
      MID = sessionStorage.getItem('translate');
    }
    else {
      MID = "English";
    }
    



    let datatrans = MID;

    

    localStorage.setItem("GoogleTranslate123", this.state.googtrans);

    let GID = sessionStorage.getItem('translate');
    

    let GIDS = JSON.parse(localStorage.getItem('this.state.googtrans'));
    
    
    let datatransID = MID;
    if (datatrans == "kn") {

      datatransID = "Kannada";
    }
    else
      if (datatrans == "ta") {
        datatransID = "Tamil";
      }
      else
        if (datatrans == "te") {
          datatransID = "Telugu";
        }
        else
          if (datatrans == "hi") {
            datatransID = "Hindi";
          }
          else
          if (datatrans == "es") {
            datatransID = "Spanish";
          }
          else
            if (datatrans == MID) {
              datatransID = MID;
            }

    let LnID = MID;
    if (LnID == "Kannada") {

      LnID = "kn";
    }
    else
      if (LnID == "Tamil") {
        LnID = "ta";
      }
      else
        if (LnID == "Telugu") {
          LnID = "te";
        }
        else
          if (LnID == "Hindi") {
            LnID = "hi";
          }
          else
          if (LnID == "Spanish") {
            LnID = "es";
          }
          else
            if (LnID == MID) {
              LnID = MID;
            }


    
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others~HowItWorks~Blog&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Howitwork = typeof items[9] !== "undefined" ? items[9] : null;
          var Blog = typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword = typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;
          var Others = typeof items[8] !== "undefined" ? items[8] : null;


          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            myjourney: Myjourney,
            howitworks: Howitwork,
            blog: Blog,

            beaparopakari: Beaparopakari,

            createacause: Createacause,
            invite: Invite,

            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            langID: LangID,
            llangID: LangID,
            lID: LID,
            others: Others

          });



          // 
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self~Family/Friends~Beneficiary Name&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Contactus = typeof items[0] !== "undefined" ? items[0] : null;
          var Termsandcondition = typeof items[1] !== "undefined" ? items[1] : null;
          var CopyRight = typeof items[2] !== "undefined" ? items[2] : null;
          var Causes = typeof items[3] !== "undefined" ? items[3] : null;
          var Beneficiaries = typeof items[4] !== "undefined" ? items[4] : null;
          var Paropakaris = typeof items[5] !== "undefined" ? items[5] : null;
          var Amountraised = typeof items[6] !== "undefined" ? items[6] : null;
          var RequiredFor = typeof items[7] !== "undefined" ? items[7] : null;
          var Self = typeof items[8] !== "undefined" ? items[8] : null;
          var FamilyandFriends = typeof items[9] !== "undefined" ? items[9] : null;
          var BeneficiaryName = typeof items[10] !== "undefined" ? items[10] : null;


          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            requiredforr: RequiredFor,
            self: Self,
            familyfriends: FamilyandFriends,
            langID: LangID,
            llangID: LangID,
            lID: LID,
            beneficiaryname: BeneficiaryName
          });



          // 
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Submit~Food and Nutrition~Health~Professional~Sports~Social Welfare~Education~Create Your Cause~Title~Description~Categories&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Submit = typeof items[0] !== "undefined" ? items[0] : null;


          var FoodandNutrtion = typeof items[1] !== "undefined" ? items[1] : null;
          var Health = typeof items[2] !== "undefined" ? items[2] : null;
          var Professional = typeof items[3] !== "undefined" ? items[3] : null;
          var Sports = typeof items[4] !== "undefined" ? items[4] : null;
          var SocialWelfare = typeof items[5] !== "undefined" ? items[5] : null;
          var Educattion = typeof items[6] !== "undefined" ? items[6] : null;
          var CreateYourCause = typeof items[7] !== "undefined" ? items[7] : null;
          var Title = typeof items[8] !== "undefined" ? items[8] : null;
          var Description = typeof items[9] !== "undefined" ? items[9] : null;
          var Categories = typeof items[10] !== "undefined" ? items[10] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            createacause: CreateYourCause,
            categories: Categories,
            foodandnutrition: FoodandNutrtion,
            education: Educattion,
            health: Health,
            professional: Professional,
            sports: Sports,
            socialwefare: SocialWelfare,
            title: Title,
            description: Description,
            llangID: LangID,
            lID: LID,
            submit: Submit

          });



          // 
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Bank Account~Amount~Required For~Add Supporting Documents~IFSC Code~Confirm Bank Details~Place~80G Available~End Date~Tag Groups~Date is Required.&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var BankAccount = typeof items[0] !== "undefined" ? items[0] : null;
          var Amount = typeof items[1] !== "undefined" ? items[1] : null;
          var RequiredFor = typeof items[2] !== "undefined" ? items[2] : null;
          var AddSuppDocs = typeof items[3] !== "undefined" ? items[3] : null;
          var IFSCCode = typeof items[4] !== "undefined" ? items[4] : null;
          var ConfirmBankDetails = typeof items[5] !== "undefined" ? items[5] : null;
          var Place = typeof items[6] !== "undefined" ? items[6] : null;
          var Available80G = typeof items[7] !== "undefined" ? items[7] : null;
          var EndDate = typeof items[8] !== "undefined" ? items[8] : null;
          var TagGroup = typeof items[9] !== "undefined" ? items[9] : null;
          var DateisRequired = typeof items[10] !== "undefined" ? items[10] : null;


          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            bankaccount: BankAccount,
            amount: Amount,
            requiredforr: RequiredFor,
            addsupportingdocuments: AddSuppDocs,
            ifscode: IFSCCode,
            confirmbankdetails: ConfirmBankDetails,
            place: Place,
            available80G: Available80G,
            enddate: EndDate,
            taggroup: TagGroup,
            DateisRequired: DateisRequired,
            llangID: LangID,
            lID: LID

          });



          // 
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=UPI ID~Create~Tag Friends~Enter~Select~Title is Required.~Description is Required.~Purpose is Required.~Amount is Required.~Please Confirm the Bank Details~Place is Required.&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var UPIID = typeof items[0] !== "undefined" ? items[0] : null;
          var Create = typeof items[1] !== "undefined" ? items[1] : null;
          var TagFriends = typeof items[2] !== "undefined" ? items[2] : null;
          var Enter = typeof items[3] !== "undefined" ? items[3] : null;
          var Select = typeof items[4] !== "undefined" ? items[4] : null;
          var TitleisRequired = typeof items[5] !== "undefined" ? items[5] : null;
          var DescriptionisRequired = typeof items[6] !== "undefined" ? items[6] : null;
          var PurposeisRequired = typeof items[7] !== "undefined" ? items[7] : null;
          var AmountisRequired = typeof items[8] !== "undefined" ? items[8] : null;
          var PleaseConfirmtheBankDetails = typeof items[9] !== "undefined" ? items[9] : null;
          var PlaceisRequired = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            upiid: UPIID,
            create: Create,
            tagfriends: TagFriends,
            enter: Enter,
            select: Select,
            TitleisRequired: TitleisRequired,
            DescriptionisRequired: DescriptionisRequired,
            PurposeisRequired: PurposeisRequired,
            AmountisRequired: AmountisRequired,
            PleaseConfirmtheBankDetails: PleaseConfirmtheBankDetails,
            PlaceisRequired: PlaceisRequired,

            llangID: LangID,
            lID: LID
          });

        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Congratulations!! You have claimed successfully. You claim money will be credited within 24 hrs&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var YouwillRcvwithin = typeof items[0] !== "undefined" ? items[0] : null;

          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            youwillrcvwithin: YouwillRcvwithin,
            llangID: LangID,

            lID: LID
          });

        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Cause created successfully!~Privacy Policy~Include documents that support your cause~For Example: Medical Bills, Medical Certificates, School Register, Images etc~Close~Name~Email~Mobile Number~Confirm Your Bank Details~I Agree, All Details Are Correct&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Causecreatedsuccessfully = typeof items[0] !== "undefined" ? items[0] : null;
          var PrivacyPolicy = typeof items[1] !== "undefined" ? items[1] : null;
          var IncludeDocs = typeof items[2] !== "undefined" ? items[2] : null;
          var ForEx = typeof items[3] !== "undefined" ? items[3] : null;
          var Close = typeof items[4] !== "undefined" ? items[4] : null;
          var Name = typeof items[5] !== "undefined" ? items[5] : null;
          var Email = typeof items[6] !== "undefined" ? items[6] : null;
          var MobileNumber = typeof items[7] !== "undefined" ? items[7] : null;
          var ConfirmYourBank = typeof items[8] !== "undefined" ? items[8] : null;
          var Iagreealldet = typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            Causecreatedsuccessfully: Causecreatedsuccessfully,
            llangID: LangID,
            privacypolicy: PrivacyPolicy,
            includedocs: IncludeDocs,
            forex: ForEx,
            close: Close,
            name: Name,
            email: Email,
            mobilenumber: MobileNumber,
            confirmbankdetails: ConfirmYourBank,
            iagreealldet: Iagreealldet,
            lID: LID
          });

        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;
        
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,            
          });
        });
    }
  }


  handleChanges(event) {
    this.setState({ value: event.target.value });
    
    // localStorage.setItem('menuid', event.target.value);
    let values = ['OurStory', 'HowItWorks', 'Blog', 'Login', 'BeaParopakari', 'What We Do'];
    
    let l_id = JSON.parse(localStorage.getItem('MenuID'));
    
    let LnID = event.target.value;
    if (LnID == "Kannada") {

      LnID = "kn";
    }
    else
      if (LnID == "Tamil") {
        LnID = "ta";
      }
      else
        if (LnID == "Telugu") {
          LnID = "te";
        }
        else
          if (LnID == "Hindi") {
            LnID = "hi";
          }
          else
            if (LnID == event.target.value) {
              LnID = event.target.value;
            }
    let MID = event.target.value;
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others~HowItWorks~Blog&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Howitwork = typeof items[9] !== "undefined" ? items[9] : null;
          var Blog = typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword = typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;
          var Others = typeof items[8] !== "undefined" ? items[8] : null;


          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            myjourney: Myjourney,
            howitworks: Howitwork,
            blog: Blog,

            beaparopakari: Beaparopakari,

            createacause: Createacause,
            invite: Invite,

            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,

            llangID: LangID,
            lID: LID,
            others: Others

          });



          // 
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self~Family/Friends~Beneficiary Name&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Contactus = typeof items[0] !== "undefined" ? items[0] : null;
          var Termsandcondition = typeof items[1] !== "undefined" ? items[1] : null;
          var CopyRight = typeof items[2] !== "undefined" ? items[2] : null;
          var Causes = typeof items[3] !== "undefined" ? items[3] : null;
          var Beneficiaries = typeof items[4] !== "undefined" ? items[4] : null;
          var Paropakaris = typeof items[5] !== "undefined" ? items[5] : null;
          var Amountraised = typeof items[6] !== "undefined" ? items[6] : null;
          var RequiredFor = typeof items[7] !== "undefined" ? items[7] : null;
          var Self = typeof items[8] !== "undefined" ? items[8] : null;
          var FamilyandFriends = typeof items[9] !== "undefined" ? items[9] : null;
          var BeneficiaryName = typeof items[10] !== "undefined" ? items[10] : null;


          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            requiredforr: RequiredFor,
            self: Self,
            familyfriends: FamilyandFriends,
            llangID: LangID,
            lID: LID,
            beneficiaryname: BeneficiaryName
          });



          // 
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Submit~Food and Nutrition~Health~Professional~Sports~Social Welfare~Education~Create Your Cause~Title~Description~Categories&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Submit = typeof items[0] !== "undefined" ? items[0] : null;


          var FoodandNutrtion = typeof items[1] !== "undefined" ? items[1] : null;
          var Health = typeof items[2] !== "undefined" ? items[2] : null;
          var Professional = typeof items[3] !== "undefined" ? items[3] : null;
          var Sports = typeof items[4] !== "undefined" ? items[4] : null;
          var SocialWelfare = typeof items[5] !== "undefined" ? items[5] : null;
          var Educattion = typeof items[6] !== "undefined" ? items[6] : null;
          var CreateYourCause = typeof items[7] !== "undefined" ? items[7] : null;
          var Title = typeof items[8] !== "undefined" ? items[8] : null;
          var Description = typeof items[9] !== "undefined" ? items[9] : null;
          var Categories = typeof items[10] !== "undefined" ? items[10] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            createacause: CreateYourCause,
            categories: Categories,
            foodandnutrition: FoodandNutrtion,
            education: Educattion,
            health: Health,
            professional: Professional,
            sports: Sports,
            socialwefare: SocialWelfare,
            title: Title,
            description: Description,
            llangID: LangID,
            lID: LID,
            submit: Submit

          });



          // 
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Bank Account~Amount~Required For~Add Supporting Documents~IFSC Code~Confirm Bank Details~Place~80G Available~End Date~Tag Groups~Date is Required.&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var BankAccount = typeof items[0] !== "undefined" ? items[0] : null;
          var Amount = typeof items[1] !== "undefined" ? items[1] : null;
          var RequiredFor = typeof items[2] !== "undefined" ? items[2] : null;
          var AddSuppDocs = typeof items[3] !== "undefined" ? items[3] : null;
          var IFSCCode = typeof items[4] !== "undefined" ? items[4] : null;
          var ConfirmBankDetails = typeof items[5] !== "undefined" ? items[5] : null;
          var Place = typeof items[6] !== "undefined" ? items[6] : null;
          var Available80G = typeof items[7] !== "undefined" ? items[7] : null;
          var EndDate = typeof items[8] !== "undefined" ? items[8] : null;
          var TagGroup = typeof items[9] !== "undefined" ? items[9] : null;
          var DateisRequired = typeof items[10] !== "undefined" ? items[10] : null;


          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            bankaccount: BankAccount,
            amount: Amount,
            requiredforr: RequiredFor,
            addsupportingdocuments: AddSuppDocs,
            ifscode: IFSCCode,
            confirmbankdetails: ConfirmBankDetails,
            place: Place,
            available80G: Available80G,
            enddate: EndDate,
            taggroup: TagGroup,
            DateisRequired: DateisRequired,
            llangID: LangID,
            lID: LID

          });



          // 
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=UPI ID~Create~Tag Friends~Enter~Select~Title is Required.~Description is Required.~Purpose is Required.~Amount is Required.~Please Confirm the Bank Details~Place is Required.&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var UPIID = typeof items[0] !== "undefined" ? items[0] : null;
          var Create = typeof items[1] !== "undefined" ? items[1] : null;
          var TagFriends = typeof items[2] !== "undefined" ? items[2] : null;
          var Enter = typeof items[3] !== "undefined" ? items[3] : null;
          var Select = typeof items[4] !== "undefined" ? items[4] : null;
          var TitleisRequired = typeof items[5] !== "undefined" ? items[5] : null;
          var DescriptionisRequired = typeof items[6] !== "undefined" ? items[6] : null;
          var PurposeisRequired = typeof items[7] !== "undefined" ? items[7] : null;
          var AmountisRequired = typeof items[8] !== "undefined" ? items[8] : null;
          var PleaseConfirmtheBankDetails = typeof items[9] !== "undefined" ? items[9] : null;
          var PlaceisRequired = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            upiid: UPIID,
            create: Create,
            tagfriends: TagFriends,
            enter: Enter,
            select: Select,
            TitleisRequired: TitleisRequired,
            DescriptionisRequired: DescriptionisRequired,
            PurposeisRequired: PurposeisRequired,
            AmountisRequired: AmountisRequired,
            PleaseConfirmtheBankDetails: PleaseConfirmtheBankDetails,
            PlaceisRequired: PlaceisRequired,

            llangID: LangID,
            lID: LID
          });

        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Cause created successfully!~Privacy Policy~Include documents that support your cause~For Example: Medical Bills, Medical Certificates, School Register, Images etc~Close~Name~Email~Mobile Number~Confirm Your Bank Details~I Agree, All Details Are Correct&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Causecreatedsuccessfully = typeof items[0] !== "undefined" ? items[0] : null;
          var PrivacyPolicy = typeof items[1] !== "undefined" ? items[1] : null;
          var IncludeDocs = typeof items[2] !== "undefined" ? items[2] : null;
          var ForEx = typeof items[3] !== "undefined" ? items[3] : null;
          var Close = typeof items[4] !== "undefined" ? items[4] : null;
          var Name = typeof items[5] !== "undefined" ? items[5] : null;
          var Email = typeof items[6] !== "undefined" ? items[6] : null;
          var MobileNumber = typeof items[7] !== "undefined" ? items[7] : null;
          var ConfirmYourBank = typeof items[8] !== "undefined" ? items[8] : null;
          var Iagreealldet = typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            Causecreatedsuccessfully: Causecreatedsuccessfully,
            llangID: LangID,
            privacypolicy: PrivacyPolicy,
            includedocs: IncludeDocs,
            forex: ForEx,
            close: Close,
            name: Name,
            email: Email,
            mobilenumber: MobileNumber,
            confirmbankdetails: ConfirmYourBank,
            iagreealldet: Iagreealldet,
            lID: LID
          });

        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Congratulations!! You have claimed successfully. You claim money will be credited within 24 hrs&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var YouwillRcvwithin = typeof items[0] !== "undefined" ? items[0] : null;

          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            youwillrcvwithin: YouwillRcvwithin,
            llangID: LangID,

            lID: LID
          });

        });
    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Cancel&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;


          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;


          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;


          



          //localStorage.setItem("GoogleTranslate123",InviteFriendsDropdown)

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";

          }
          else

            if (datatranslirate == "Kannada") {
              datatransIDs = "/en/kn";
            }

            else
              if (datatranslirate == "Tamil") {
                datatransIDs = "/en/ta";
              }
              else
                if (datatranslirate == "Telugu") {
                  datatransIDs = "/en/te";
                }
                else
                  if (datatranslirate == "Hindi") {
                    datatransIDs = "/en/hi";
                  }
                  else
                  if (datatranslirate == "Spanish") {
                    datatransIDs = "/en/es";
                  }
                  else
                    if (datatranslirate == MID) {
                      datatransIDs = MID;
                    }
          let RefreshID = 0;
          window.sessionStorage.setItem('reload', (RefreshID));
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
          window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
          window.sessionStorage.setItem('GoogleTranslateValues', (datatransIDs));

          
          let googletrasvalues = localStorage.setItem("GoogleTranslateValues", datatransIDs);

          


          


          Cookies.remove('googtrans', { domain: '.paropakari.com' });
          Cookies.remove('googtrans', { domain: 'https://paropakari.com/' });

          Cookies.remove('googtrans', { domain: 'https://www.paropakari.com/' });
          let usernames = localStorage.getItem("GoogleTranslateValues", datatransIDs);
          let values = datatransIDs;
          
          

          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
            const expires = "expires=" + date.toUTCString();
            document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
            // window.location.reload();
          }

          Cookies.set('googtrans', datatransIDs, { domain: 'https://test.paropakari.com/#/' });
          Cookies.set('googtrans', datatransIDs, { domain: '.paropakari.com' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://www.paropakari.com/' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://paropakari.com/' });
          setCookie('googtrans', datatransIDs, 30);
          setCookie('googtrans', datatransIDs, 'https://test.paropakari.com/#/');
          setCookie('googtrans', datatransIDs, '.paropakari.com');
          setCookie('googtrans', datatransIDs, 'https://www.paropakari.com/');
          setCookie('googtrans', datatransIDs, 'https://paropakari.com/');
          let cookie = Cookies.get('googtrans', 'https://test.paropakari.com/#/');
          
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,


          });


        });
    }

  }







  handleothersClik() {
    this.setState({ disable: !this.state.disable })
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem('username')
    localStorage.removeItem('user')

    localStorage.clear();
    window.location.href = "#/Login";

  }
  handleGameClik() {
    this.setState({ disabled: !this.state.disabled })
  }
  // submit =() =>{
  //   let url = "https://testapi.paropakari.com/api/GetProfile/getaccdetails";
  //   let data = this.state;
  // let user = JSON.parse(localStorage.getItem('user'));
  // const accessToken = user;
  //   

  //   fetch(url, {
  //           method: 'GET',
  //           headers: {
  //               "Content-type": "application/json",
  //               "Accept": "application/json",
  //               Authorization: "Bearer " + accessToken,
  //               "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
  //           },
  //           //body:JSON.stringify(data)
  //       }).then(response => response.json())
  //       .then(data => {
  //           this.setState({
  //               emps: data
  //           });
  //           
  //          // 
  //       });
  // }    

  componentDidMount() {
    // if(sessionStorage.getItem('translate') !== null){
    //   setTimeout(() => {

    //       
    //     // window.alerthello();
    //     }, 3000);

    // }
    window.addEventListener('load', this.DefaultMenu);
    this.DefaultMenu();

    window.addEventListener('load', this.notify);
    this.notify();
    window.addEventListener('load', this.Footer);
    this.Footer();
    window.addEventListener('load', this.ReloadPage);
    this.ReloadPage();
    window.addEventListener('load', this.changetextbox);
    this.changetextbox();
    window.addEventListener('load', this.submit);
    this.submit();
    const element = document.querySelector('.VIpgJd-ZVi9od-ORHb-OEVmcd');
    if (element && window.getComputedStyle(element).getPropertyValue('position') === 'fixed') {
      element.style.position = 'absolute';
    }
  }
  disabledSubmissionDate = (submissionValue) => {
    if (!submissionValue) {
      return false;
    }
    return (submissionValue.valueOf() < Date.now()) || (submissionValue.valueOf() >= moment().add(1, 'month'));
  }
  componentWillUnmount() {

    window.removeEventListener('load', this.notify);
    this.notify();
    window.removeEventListener('load', this.Footer);
    this.Footer();
    window.removeEventListener('load', this.changetextbox);
    this.changetextbox();
    window.removeEventListener('load', this.ReloadPage);
    this.ReloadPage();
    window.removeEventListener('load', this.submit);
    this.submit();
    window.removeEventListener('load', this.DefaultMenu);
    this.DefaultMenu();

  }


  //   componentDidMount(props){
  //     if(sessionStorage.getItem('translate') !== null){
  //      let MID= sessionStorage.getItem('translate');
  //      
  //      if(MID == "Kannada" || MID == "Tamil" || MID == "Telugu" || MID == "Hindi")
  //      {
  //       setTimeout(() => {

  //           
  //         // window.alerthello();
  //         }, 3000);
  //      }


  //     }
  //     window.addEventListener('load', this.ReloadPage);
  //     this.ReloadPage();
  //     let user1 = JSON.parse(localStorage.getItem('id'));
  //     let benuser = JSON.parse(localStorage.getItem('benname'));
  //     
  //     if (user1 == 3)  

  // {   
  //      
  //   let url = "https://testapi.paropakari.com/api/GetProfile/getaccdetails";
  //   let data = this.state;
  // let user = JSON.parse(localStorage.getItem('user'));
  // const accessToken = user;
  //   

  //   fetch(url, {
  //           method: 'GET',
  //           headers: {
  //               "Content-type": "application/json",
  //               "Accept": "application/json",
  //               Authorization: "Bearer " + accessToken,
  //               "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
  //           },
  //           //body:JSON.stringify(data)
  //       }).then(response => response.json())
  //       .then(data => {
  //           this.setState({
  //               emps: data
  //           });
  //           
  //          // 
  //       });

  // }

  // window.addEventListener('load', this.DefaultMenu);
  // this.DefaultMenu();

  // // else {  

  // //       document.getElementById("upload_otherRelation").disabled = 'true'; 

  // //       document.getElementById("upload_otherRelation1").disabled = 'true'; 
  // //     }  
  // }
  changetextbox = () => {
    
    let user1 = JSON.parse(localStorage.getItem('id'));
    if (user1 == 3) {
      
      let url = "https://testapi.paropakari.com/api/GetProfile/getaccdetails";
      let data = this.state;
      let user = JSON.parse(localStorage.getItem('user'));
      const accessToken = user;
      

      fetch(url, {
        method: 'GET',
        headers: {
          "Content-type": "application/json",
          "Accept": "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        },
        //body:JSON.stringify(data)
      }).then(response => response.json())
        .then(data => {
          this.setState({
            emps: data
          });
          
          // 
        });
    }

    // else if (this.props.location.state.data.id == "2")  {

    //   document.getElementById("upload_otherRelation").disabled = '';  
    //   document.getElementById("upload_otherRelation1").disabled = '';  
    // }    




  }

  render() {
    const { opErr, npErr, cnpErr,
      upaErr,
      befErr,
      uplErr,
      reqErr,
      PurErr, ChckErr,
      RelaErr, UocErr, CheckErr,
      relErr } = this.state.formErrors;
    var upload_id = JSON.parse(localStorage.upload_id);
    // var order_id= JSON.parse(localStorage.order_id) ;
    var benfname = (localStorage.benname);
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
    var yyyy = today.getFullYear();
    var yyyy2 = today.getFullYear() + 1;
    var mm2 = today.getMonth() + 2; //January is 0 so need to add 1 to make it 1!


    if (dd < 10) {
      dd = '0' + dd
    }
    if (mm < 10) {
      mm = '0' + mm
    }

    today = yyyy + '-' + mm + '-' + dd;
    var nextmonth = yyyy + '-' + mm2 + '-' + dd;
    var nextYear = yyyy2 + '-' + mm + '-' + dd;
    
    
    

    const { emps, upload_amount, category_id, created_date, delete_status, device_type, ip_address, upload_num_of_beneficiaries
      , upload_place, relation_id, required_by, upload_title, upload_description, user_id,
      upload_otherCategory, upload_otherRelation, status_80G, prof_BankAccount } = this.state;
    const { howitworks, blog, contactus, termsandcondition, causes, beneficiaries, paropakaris, amountraised, copyright, beaparopakari, myjourney, createacause, invite,
      profile, changepassword, group, logout, others, requiredforr, familyfriends, self, submit, beneficiaryname,
      createyourcause, title, description, categories, bankaccount, enter, select, includedocs, forex, close, youwillrcvwithin,
      amount, addsupportingdocuments, tagfriends, ifscode, confirmbankdetails, iagreealldet,
      education, foodandnutrition, health, professional, sports, socialwefare,
      place, available80G, enddate, taggroup, upiid, create, privacypolicy, name, email, mobilenumber, confirmyourbank,
      langID, lID, TitleisRequired, DescriptionisRequired, PurposeisRequired, copyright_new, paropakari_new,
      AmountisRequired, PleaseConfirmtheBankDetails, PlaceisRequired, DateisRequired, Causecreatedsuccessfully, Beneficiary_name } = this.state;
    var noticount = JSON.parse(localStorage.getItem('noticount'));
    const currentYear = new Date().getFullYear();
    const { notify } = this.state;
    let { foo, Menu } = this.state;
    var username = JSON.parse(localStorage.username);
    var claimamount = sessionStorage.getItem('ClaimAmount');
    // var user1= JSON.parse(localStorage.getItem('id')) ;
    return (
      <div className="VIpgJd-ZVi9od-ORHb-OEVmcd" style={{ position: "absolute" }}>
        <div style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }} className="notranslate">
          {/* <meta http-equiv="refresh" content="10"/>   */}
          <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
          <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
          <link href="./assets/css/lightbox.css" rel="stylesheet" />
          <link href="./assets/css/animate.min.css" rel="stylesheet" />
          <link href="./assets/css/main.css" rel="stylesheet" />

          {/*[if lt IE 9]>
              
              
          <![endif]*/}
          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />
          {/*/head*/}



          <select className="form-control" style={{ display: 'inline', width: '10%', marginTop: '0', marginLeft: '0', float: 'right', width: 'fit-content' }} value={this.state.value} onChange={this.handleChanges}>
            <option value="English">Languages</option>
            <option value="Kannada">Kannada</option>
            <option value="Tamil">Tamil</option>
            <option value="Hindi">Hindi</option>
            <option value="Telugu">Telugu</option>
             {/* <option value="Spanish">Spanish</option> */}
            <option value="English" onClick={window['checkboxClickHandler']}>English</option>
          </select>



          &nbsp;&nbsp; &nbsp;&nbsp;


          <div>
            <div className="mag notranslate">
              <div class="pro-home-nav">
                <input type="checkbox" id="pro-home-nav-check" onClick={this.checkfun} />
                <div class="pro-home-nav-header">
                  <div class="pro-home-nav-title notranslate">
                    <a className="navbar-brand" href="#/HomePage">
                      <h1 className="navbar-new-text"><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '300px' }} />AROPAKARI
                      </h1>
                    </a>
                  </div>
                </div>

                <div class="pro-home-nav-btn">
                  <label for="pro-home-nav-check">
                    <div className="pro-naav">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </label>
                </div>

                {langID == "English" ? <>
                  <div class="pro-home-nav-links pro-side pro-top">
                    <p class='pro-center'>
                      <a className="new-design" href="#/CauseFor">{beaparopakari.result}</a>
                    </p>
                    <ul className="parop-home-boxx parop-boxx-top">
                      <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                      <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                      <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                      <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                        <span class="-count">
                          {noticount}

                        </span>))}</div></a></li>
                      <li className="parop-menu"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                        <ul class="dropdown">
                          <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                          <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                          <li className="hower-text"><a href="#/Group"><b>{group.result}</b></a></li>
                          <li className="hower-text"><a href="#/Login"><b>{logout.result}</b></a></li>
                        </ul>
                      </li>

                    </ul>
                  </div>
                </> : langID == "Hindi" ? <>
                  <div class="pro-home-nav-links pro-side pro-top">
                    <p class='pro-center'>
                      <a className="new-design" href="#/CauseFor">{beaparopakari.result}</a>
                    </p>
                    <ul className="parop-home-boxx parop-boxx-top">
                      <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                      <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                      <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                      <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                        <span class="-count">
                          {noticount}

                        </span>))}</div></a></li>
                      <li className="parop-menu"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                        <ul class="dropdown">
                          <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                          <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                          <li className="hower-text"><a href="#/Group"><b>{group.result}</b></a></li>
                          <li className="hower-text"><a href="#/Login"><b>{logout.result}</b></a></li>
                        </ul>
                      </li>

                    </ul>
                  </div>
                  </> : langID == "Spanish" ? <>
                  <div class="pro-home-nav-links pro-side pro-top">
                    <p class='pro-center'>
                      <a className="new-tamil-design" href="#/CauseFor">{beaparopakari.result}</a>
                    </p>
                    <ul className="parop-home-tamil-boxx parop-boxx-top">
                      <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                      <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                      <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                      <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                        <span class="-count">
                          {noticount}

                        </span>))}</div></a></li>
                      <li className="parop-menu"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                        <ul class="dropdown">
                          <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                          <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                          <li className="hower-text"><a href="#/Group"><b>{group.result}</b></a></li>
                          <li className="hower-text"><a href="#/Login"><b>{logout.result}</b></a></li>
                        </ul>
                      </li>

                    </ul>
                  </div>
                </> : langID == "Tamil" ? <>
                  <div class="pro-home-nav-links pro-side pro-top">
                    <p class='pro-center'>
                      <a className="new-tamil-design" href="#/CauseFor">{beaparopakari.result}</a>
                    </p>
                    <ul className="parop-home-tamil-boxx parop-boxx-top">
                      <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                      <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                      <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                      <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                        <span class="-count">
                          {noticount}

                        </span>))}</div></a></li>
                      <li className="parop-menu"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                        <ul class="dropdown">
                          <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                          <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                          <li className="hower-text"><a href="#/Group"><b>{group.result}</b></a></li>
                          <li className="hower-text"><a href="#/Login"><b>{logout.result}</b></a></li>
                        </ul>
                      </li>

                    </ul>
                  </div>
                </> : langID == "Kannada" ? <>
                  <div class="pro-home-nav-links pro-side pro-top">
                    <p class='pro-center'>
                      <a className="new-telugu-design" href="#/CauseFor">{beaparopakari.result}</a>
                    </p>
                    <ul className="parop-home-tamil-boxx parop-boxx-top">
                      <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                      <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                      <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                      <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                        <span class="-count">
                          {noticount}

                        </span>))}</div></a></li>
                      <li className="parop-menu"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                        <ul class="dropdown">
                          <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                          <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                          <li className="hower-text"><a href="#/Group"><b>{group.result}</b></a></li>
                          <li className="hower-text"><a href="#/Login"><b>{logout.result}</b></a></li>
                        </ul>
                      </li>

                    </ul>
                  </div>
                </> : langID == "Telugu" ? <>
                  <div class="pro-home-nav-links pro-side pro-top">
                    <p class='pro-center'>
                      <a className="new-telugu-design" href="#/CauseFor">{beaparopakari.result}</a>
                    </p>
                    <ul className="parop-home-tamil-boxx parop-boxx-top">
                      <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                      <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                      <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                      <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                        <span class="-count">
                          {noticount}

                        </span>))}</div></a></li>
                      <li className="parop-menu"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                        <ul class="dropdown">
                          <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                          <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                          <li className="hower-text"><a href="#/Group"><b>{group.result}</b></a></li>
                          <li className="hower-text"><a href="#/Login"><b>{logout.result}</b></a></li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </> : null}
              </div>
            </div>

            {/* <header id="header" style={{backgroundImage: "url(" + "./assets/images/10.jpg" + ")"}}>      
              <div className="container">
                <div className="row">
                
                </div>
              </div>
              <div className="navbar navbar-inverse" role="banner">
                <div className="container">
                  <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                   <a className="navbar-brand" href="#/HomePage">
                   <h1 style={{color:'blue',fontFamily:'Biysk-Regular'}}><img src="./assets/images/plogo.png" alt="logo" style={{marginBottom:'17px',width:'26%',fontsize:'52px'}}  />AROPAKARI</h1>
          </a>
          
                  </div>
                  
                  <div className="collapse navbar-collapse">
                  <ul className="nav navbar-nav navbar-right">
                    
                  {(() => {if (langID == 'Tamil' ) {return (
       <li id="paro" className="active"><a className="btn1 third" href="#/CauseFor" target="_blank" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',marginRight:'40px',marginLeft:'-274px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>
           
       {beaparopakari.result}
       </a></li>
);
}
                 else {return(

                  <li id="paro" className="active"><a className="btn1 third" href="#/CauseFor" target="_blank" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',marginRight:'100px',marginLeft:'-274px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>
           
           {beaparopakari.result}
           </a></li>

);}

})()}    
        
                    
                                                 
                            
                                   <li className=""><a href="#/MyActivity" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{myjourney.result}</a></li>     
                                   <li className="active"><a href="#/CauseFor" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{createacause.result}</a></li>     
                                   <li className=""><a href="#/InviteFriend" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{invite.result}</a></li>    
                                            
                                   <li className=""><a href="#/Notification" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>
                                     
                                   <div class="fa fa-bell" style={{fontsize:'24px'}}>{notify.map((notifyy) => (
                                                        <span class="-count"> 
                                         
                                       
                                         {notifyy.notificationCount}
                                        
                                           </span> ))}</div>
                                    </a></li>               
                                    <li class="dropdown"><a><b>{username}</b> <i class="fa fa-angle-down"></i></a>
                                     <ul role="menu" className="sub-menu">
                                       <li><a href="#/Profile">{profile.result}</a></li>
                                       <li><a href="#/ChangePassword">{changepassword.result}</a></li>
                                       <li><a href="#/Group">{group.result}</a></li>
                                       <li><a href="#/Login">{logout.result}</a></li>
                                      
                                     </ul>
                                   </li>    
           
                                         
                                 </ul>
                  </div>
                  
                </div>
              </div>
            </header> */}

            {/*/#header*/}



            {/* <div className="nav" >
  <input type="checkbox" id="nav-check" />
  <div className="nav-header">
    <div className="nav-title">
    <h1 style={{color:'blue',fontFamily:'Biysk-Regular',fontsize:'52px'}}><img src="./assets/images/plogo.png" alt="logo"
     style={{marginBottom:'17px',width:'23%',fontsize:'60px'}}  />AROPAKARI</h1>



    </div>
    
  </div>
 
  <div className="nav-btn">
    <label htmlFor="nav-check">
      <span />
      <span />
      <span />
    </label>
  </div>
  <div className="bea">
  <a class="btn1 third" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',
  marginRight:'552px ',marginLeft:'539px',fontSize:20,marginTop:'-82px',marginBottom:'-37px',textDecoration:'none',
  whiteSpace:'nowrap',fontFamily:'cuyabra',width:'281px'}}>
       
       Be a Paropakari
       </a>
</div>
  <div className="nav-links" >
    
  <a href="#/MyActivity" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>My Journey</a>
  <a href="#/Create" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>Create a Cause</a>
  <a href="#/InviteFriend" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>Invite</a>
                                         
  <a href="#/Notification" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>
                                     
                                     <div class="fa fa-bell" style={{fontsize:'24px'}}>{notify.map((notifyy) => (
                                                          <span class="-count"> 
                                           
                                         
                                           {notifyy.notificationCount}
                                          
                                             </span> ))}</div>
                                      </a>

                                             <li className="dropdown">  Heloo<i className="fa fa-angle-down" />
                                     <ul role="menu" className="sub-menu">
                                       <li><a href="#/Profile">My Profile</a></li>
                                       <li><a href="#/ChangePassword">Change Password</a></li>
                                       <li><a href="#/Group">Group</a></li>
                                       <li><a href="#/Login">Logout</a></li>
                                      
                                     </ul>
                                   </li>   
  </div>
</div>   */}
            {/*/#page-breadcrumb*/}
            <section id="about-company" className=" wow fadeInUp" data-wow-duration="400ms" data-wow-delay="400ms" style={{ backgroundColor: '#aee6db' }}>

              <div className="container">
                {/* Page Content */}


                <h3>Your claim amount is Rs {claimamount}</h3>


                <br />
                {emps.map((emp) => (
                  <form autoComplete="off" onSubmit={this.handleSubmit} >
                    <div className="row" style={{ marginLeft: 3 }}>
                      <input type="hidden" value={langID} id="lang_id"
                        onChange={this.handleChange} name="langid" />
                      <input type="hidden" value={lID} id="l_id"
                        onChange={this.handleChange} name="langid" />
                      <input type="hidden" value={claimamount} id="claimamount" />
                      <div className="col-lg-4" /* style={{maxWidth=""}} */>
                        <label className htmlFor="studName">{name.result}</label>
                        <input className="form-control"

                          maxLength={30} name="Beneficiary_name"
                          defaultValue={emp.profile_name} id="Beneficiary_name"
                          onBlur={(data) => { this.setState({ Beneficiary_name: data.target.value }) }}
                          placeholder="Enter Name" size={30} type="text"
                        />

                        {opErr &&
                          <div style={{ marginLeft: "7%" }}><span style={{ color: "red" }}>{opErr}</span></div>
                        }
                        <input type="hidden" name="upload_id" value={upload_id} />
                        <input type="hidden" name="Claim_amount" id=" Claim_amount" value={claimamount} />

                        {/* <input type="text" name="Order_id" value={order_id} /> */}

                        <label className htmlFor="category_id" style={{ marginTop: 19 }}>{bankaccount.result}</label>

                        <input type="text" name="Beneficiary_AccountNumber" defaultValue={emp.upload_BankAccount}
                          disabled={(this.state.disabled) ? "disabled" : ""} id="upload_BankAccount"
                          className="form-control" placeholder="Enter Account Number" onBlur={(data) => { this.setState({ upload_BankAccount: data.target.value }) }} />
                        {reqErr &&
                          <div style={{ marginLeft: "7%" }}><span style={{ color: "red" }}>{reqErr}</span></div>
                        }

                        <br />


                        {/* {emps.map(emp=> */}

                        {/* )} */}

                      </div>


                      <div className="col-lg-4">

                        <label className htmlFor="emailId">{email.result}</label>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <input className="form-control"

                          onBlur={(data) => { this.setState({ Beneficiary_email: data.target.value }) }} defaultValue={emp.profile_email}
                          id="Beneficiary_email" name="Beneficiary_email" placeholder="Enter Email" type="text" />
                        {upaErr &&
                          <div style={{ marginLeft: "7%" }}><span style={{ color: "red" }}>{upaErr}</span></div>
                        }


                        {/*                     
                      <div style={{ marginLeft: "7%"}}>
                {upaErr && <span style={{color:"red"}}>{ AmountisRequired.result ? AmountisRequired.result:upaErr}</span>} 
                </div>   */}
                        <br />


                        {/* <select className="form-control"  id="relation_id" 
                value={this.state.relation_id}     onChange={this.handleChange}
              
                name="relation_id" >
                <option value>Select</option>
                  <option onClick={this.submit} value={3}>Self</option>
                  <option value={2}>Family/Friends</option>
                  <option value={1}
                  
                  >Others</option>
                </select> */}

                        {/* <input type="hidden" name="relation_id" value={reqid}/> */}





                        <label className htmlFor="category_id" style={{ marginTop: 0 }}>{ifscode.result} </label>



                        <input type="text" name="Beneficiary_IFSC" id="Beneficiary_IFSC" defaultValue={emp.upload_IFSC}
                          disabled={(this.state.disabled) ? "disabled" : ""}
                          className="form-control" placeholder="IFSC" onBlur={(data) => { this.setState({ Beneficiary_IFSC: data.target.value }) }} />
                        {opErr &&
                          <div style={{ marginLeft: "7%" }}><span style={{ color: "red" }}>{PurErr}</span></div>
                        }
                        <br />

                        {/* <input type="checkbox" required name="checkbox"/> Confirm Bank Details
            <div style={{ marginLeft: "7%"}}>
                {CheckErr && <span style={{color:"red"}}>{CheckErr}</span>} 
                </div>  */}

                        {/* 
<label>
  <input type="checkbox" name="q1b" /> {confirmbankdetails.result}
</label>

<div style={{ marginLeft: "-1%"}}>
                     {ChckErr && <span style={{color:"red"}}>{PleaseConfirmtheBankDetails.result ? PleaseConfirmtheBankDetails.result : ChckErr}</span>}
                     
                     </div> */}

                      </div>






                      <div className="col-lg-4" >

                        <input id="user_id" name="user_id" type="hidden" Value />
                        <div className="form-group" style={{ marginTop: '-20px' }}>
                          <br />
                        </div>
                        <div className="form-group" style={{ marginTop: '-40px', paddingBottom: '5px' }}>
                          <br />
                          <label className htmlFor="phoneNumber">{mobilenumber.result}</label>
                          <input className="form-control" id="Beneficiary_mobilenumber"
                            defaultValue={emp.profile_mobileNumber}
                            onBlur={(data) => { this.setState({ Beneficiary_mobilenumber: data.target.value }) }}
                            name="Beneficiary_mobilenumber" placeholder="Enter Phone Number" type="number" />
                          {uplErr &&
                            <div style={{ marginLeft: "7%" }}><span style={{ color: "red" }}>{uplErr}</span></div>
                          }
                          {/* <div style={{ marginLeft: "7%"}}>
                {uplErr && <span style={{color:"red"}}>{PlaceisRequired.result ? PlaceisRequired.result : uplErr}</span>} 
                </div>   */}
                          <br />



                          <label className htmlFor="category_id" style={{ marginTop: 0 }}>{upiid.result}</label>

                          <input type="text" name="Beneficiary_UPI" id="Beneficiary_UPI" defaultValue={emp.upload_UPIID}

                            className="form-control" placeholder="UPI ID" onBlur={(data) => { this.setState({ Beneficiary_UPI: data.target.value }) }}
                          />

                        </div>
                        {/* {emps.map(emp=>
                    <input type="hidden" value={emp.prof_benID} name="benId" id="upi_ID" />
                    
                    )}
                    {uplErr &&    
                          <div style={{marginLeft: "7%" }}><span style={{color:"red"}}>{befErr}</span></div>    
                      }   */}













                      </div>

                    </div>
                    <center>
                      <input type="checkbox" required name="checkbox" /> {confirmbankdetails.result}

                      <div style={{ marginLeft: "7%" }}>
                        {CheckErr && <span style={{ color: "red" }}>{CheckErr}</span>}
                      </div>
                      <br />
                      {(() => {
                        if (langID == 'Tamil' || langID == 'Hindi' || langID == 'Telugu') {
                          return (
                            <button type="submit" style={{ width: "95px", Height: "9%", color: 'white', marginTop: '2px', backgroundColor: 'rgb(102, 71, 18)' }}
                              Value="" className="btn btn-primary"  >{submit.result}</button>
                          );
                        }
                        else {
                          return (

                            <button type="submit" style={{ width: "70px", Height: "9%", color: 'white', marginTop: '2px', backgroundColor: 'rgb(102, 71, 18)' }}
                              Value="" className="btn btn-primary"  >{submit.result}</button>

                          );
                        }

                      })()}

                    </center>
                  </form>
                ))}










                <br />


              </div>
              <br />

              <br />
            </section>
            <div className="modal fade" id="fileModal" role="dialog">
              <div className="modal-dialog">
                {/* Modal content*/}
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">{includedocs.result}</h5>
                    <button type="button" className="close" data-dismiss="modal">×</button>
                  </div>
                  <div className="modal-body">
                    {/* Go to www.addthis.com/dashboard to customize your tools */}
                    {forex.result}
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-default" data-dismiss="modal">{close.result}</button>
                  </div>
                </div>
              </div>
            </div>
            {/*/#team*/}
            <div style={{ backgroundColor: '#664712' }}>
              <footer id="footer" style={{ color: 'white' }}>
                <br />
                <div className="container">
                  <div className="copyright">

                    <div className="row col-lg-12">

                      <div className="col-lg-9 mb-6">
                        {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}


                        <p style={{ marginLeft: '45px' }}>{copyright_new.result} &nbsp;{currentYear} © {paropakari_new.result} |&nbsp;<a href="#/Contactuslogin" style={{ textDecoration: 'none', color: 'white' }}>{contactus.result}&nbsp;</a>| <a href="#/PrivacyPolicyLogin" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{privacypolicy.result}&nbsp;</a>|<a href="#/TermsandConditionLogin" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{termsandcondition.result}</a></p>

                      </div>
                      <div className="col-lg-3 mb-3">

                        <div className="social-icons" style={{ color: 'white', marginLeft: '118px' }}>
                          <ul className="nav nav-pills">
                            <li><a target="_blank" href="https://www.facebook.com/beoneparopakari"><i className="fa fa-facebook" /></a></li>
                            {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                            <li><a target="_blank" href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw" ><i className="fa fa-youtube-play" /></a></li>
                            <li><a target="_blank" href="https://www.instagram.com/paropakari_beone/" ><i className="fa fa-instagram" /></a></li>
                            <li><a target="_blank" href="https://www.linkedin.com/company/paropakari/"><i className="fa fa-linkedin" /></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="containerss">

                </div>
              </footer>

            </div>

          </div>

          {/*/#footer*/}
        </div>
      </div>
    )
  }
}