import React, { useState } from "react";

const Test2 = () => {
  const [file, setFile] = useState([]);
  const fileObj = useState([]);
  const fileArray = useState([]);
  function uploadSingleFile(e) {
    // let fileObj = [];
    // let fileArray = [];


    // this.fileObj.push(e.target.files)
    //     for (let i = 0; i < this.fileObj[0].length; i++) {
    //        // this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
    //          // setFile([...file, URL.createObjectURL(e.target.files[0])]);
    //          this.fileArray.push(this.fileObj[0][i].name)
    
            
    //     }
    //     // this.setState({ file: this.fileArray })
    // setFile([...file, e.target.files[0].name]);
    // 
    // 


    let ImagesArray = Object.entries(e.target.files).map((e) =>
    //   URL.createObjectURL(e[1])
    e[1].name
    // e.target.files
    );
    
     
    setFile([...file, ...ImagesArray]);
    

     // setFile([...file, URL.createObjectURL(e.target.files[0])]);
     



    // this.fileObj.push(e.target.files)
    //     for (let i = 0; i < this.fileObj[0].length; i++) {
    //        // this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
    //          // setFile([...file, URL.createObjectURL(e.target.files[0])]);
    //          this.fileArray.push(this.fileObj[0][i].name)
    
            
    //     }
    //     this.setState({ file: this.fileArray })
  }

  function upload(e) {
    e.preventDefault();
    
  }

  function deleteFile(e) {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
    
  }

  return (
    <form>
      <div className="form-group preview">
        {file.length > 0 &&
          file.map((item, index) => {
            return (
              <div key={item}>
                {/* <img src={item} alt="" /> */}
                <p>{item}</p>
                <button type="button" onClick={() => deleteFile(index)}>
                  delete
                </button>
              </div>
            );
          })}
      </div>

      <div className="form-group">
        <input
          type="file"
          disabled={file.length === 5}
          className="form-control"
          onChange={uploadSingleFile}
          multiple
        />
      </div>
      <button
        type="button"
        className="btn btn-primary btn-block"
        onClick={upload}
      >
        Upload
      </button>
    </form>
  );
};

export default Test2;
