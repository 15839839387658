import React, { Component } from 'react';
import Test2 from './Test2'
 
export default class ImageUploadPreviewComponent extends Component {
 
    fileObj = [];
    fileArray = [];
 
    constructor(props) {
        super(props)
        this.state = {
            file: [null]
        }
        this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this)
        this.uploadFiles = this.uploadFiles.bind(this)
    }
 
    uploadMultipleFiles(e) {
        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
           // this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
             // setFile([...file, URL.createObjectURL(e.target.files[0])]);
             this.fileArray.push(this.fileObj[0][i].name)
    
            
        }
        this.setState({ file: this.fileArray })
    }
 
    uploadFiles(e) {
        e.preventDefault()
        
    }
 
    render() {
        return (
            <div>
                
            
            <form>
                <div className="form-group multi-preview">
                    {(this.fileArray || []).map(url => (
                        // <img src={url} alt="..." />
                        <p>{url}</p>
                    ))}
                </div>
 
                <div className="form-group">
                    <input type="file" className="form-control" onChange={this.uploadMultipleFiles} multiple />
                </div>
                <button type="button" className="btn btn-danger btn-block" onClick={this.uploadFiles}>Upload</button>
            </form >

            <Test2></Test2>
            </div>
           
        )
    }
}