import { Height } from "@material-ui/icons";
import React, { Component, useState } from "react";
import { BrowserRouter as Navlink } from "react-router-dom";
import Dropdowns from "./Dropdowns";
import TaggedFriendss from "./TaggedFriendss";

import Taggroup from "./Taggroup";
import CancelIcon from "@material-ui/icons/Cancel";
import Resizer from "react-image-file-resizer";
import DatePicker from "react-datepicker";
import moment from "moment";
import Cookies from "js-cookie";
import "react-datepicker/dist/react-datepicker.css";
import MultiImage from "./MultiImage";

//import SideBars from './SideBars.js';
import $ from "jquery";
import { getDefaultNormalizer } from "@testing-library/react";
import Modal from "react-modal";
import LogoutLink from "./LogoutLink";

const refreshPage = () => {
  setTimeout(() => window.location.reload(), 4000);
};

// const[modal,setModal] = useState(false);

export default class Create extends Component {
  constructor(props) {
    super(props);
    this.state = { ImageDisplay: "" };
    this.resetFile = this.resetFile.bind(this);
    this.ImagePreview = this.ImagePreview.bind(this);

    this.state = {
      fields: {},
      errors: {},
    };
    this.state = {
      // id : this.props.match.params.id,
      files: [],
      //  bene_limit:[],
      imageName: "",
      upload_title: "",
      yes: [],
      no: [],
      category_id: "",
      relation_id: "",
      EnterTitle: [],
      EnterDesc: [],
      Benerequire: [],
      EnterAccount: [],
      EnterIfsc: [],
      EnterPlace: [],
      file: [],
      upload_description: "",
      upload_amount: "",
      upload_num_of_beneficiaries: "",
      upload_place: "",
      required_by: "",
      status_80G: "",
      notify: [],
      formErrors: {},
      emps: [],
      foo: [],
      Menu: [],
      beaparopakari: [],
      Minimumamt: [],
      copyright_new: [],
      paropakari_new: [],
      myjourney: [],
      createacause: [],
      invite: [],
      profile: [],
      changepassword: [],
      group: [],
      logout: [],
      requiredforr: [],
      self: [],
      familyfriends: [],
      others: [],
      beneficiaryname: [],
      submit: [],
      categories: [],
      foodandnutrition: [],
      health: [],
      professional: [],
      sports: [],
      socialwefare: [],
      education: [],
      causes: [],
      privacypolicy: [],
      includedocs: [],
      forex: [],
      close: [],
      beneficiaries: [],
      validifsc: [],
      validbankacc: [],
      validupi: [],
      paropakaris: [],
      Add: [],
      amountraised: [],
      copyright: [],
      contactus: [],
      termsandcondition: [],
      howitworks: [],
      required_for: [],
      blog: [],
      donateasmile: [],
      paropakarinotresponsible: [],
      paropakaribankpop: [],
      youareresponsible: [],
      confirm: [],
      youagreetoprovide: [],
      createyourcause: [],
      title: [],
      description: [],
      bankaccount: [],
      providebankdet: [],
      amount: [],
      addsupportingdocuments: [],
      tagfriends: [],
      ifscode: [],
      confirmbankdetails: [],
      place: [],
      available80G: [],
      enddate: [],
      taggroup: [],
      upiid: [],
      create: [],
      enter: [],
      select: [],
      noofbeneficiary: [],
      friends: [],
      langID: [],
      lID: [],
      file_name: null,
      disabled: false,
      disable: false,
      valid_amnt: [],
      valid_benef: [],
      prof_BankAccount: "",
      Bank_acc: "",
      ifsc: "",
      upi_ID: "",
      dob: moment(),
      TitleisRequired: [],
      DescriptionisRequired: [],
      PurposeisRequired: [],
      AmountisRequired: [],
      PleaseConfirmtheBankDetails: [],
      PlaceisRequired: [],
      GRequired: [],
      DateisRequired: [],
      Causecreatedsuccessfully: [],
      AmountLessthanLakh: [],
      loading: false,
      setIsOpen: false,
      modalconfirm: true,
      compressedFile: null,
      movedown: true,
      modal: false,
      confirmmodal: false,
      confirmmodal1: false,
    };

    this.initialState = this.state;

    this.dateChange = this.dateChange.bind(this);
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitNonMonitory = this.handleSubmitNonMonitory.bind(this);
    this.handleSubmittrans = this.handleSubmittrans.bind(this);
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.uploadSingleFile = this.uploadSingleFile.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.modal = this.modal.bind(this);
  }

  toggleModal() {
    // setModal(false)
    this.setState({
      setIsOpen: !this.state.setIsOpen,
      confirmmodal: false,
    });
  }

  customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      height: "200px",
      width: "350px",
      borderRadius: "20px",
      backgroundColor: "rgb(174, 230, 219)",
      border: "none",
      position: "fixed",
    },
  };

  toggleConfirm = async (event) => {
    event.preventDefault();

    var newda = new FormData(document.getElementById("causeform"));
    // this.toggleLoader();
    let user = JSON.parse(localStorage.getItem("user"));

    newda.append("file_name", this.state.imageName);

    for (let i = 0; i < this.state.imageName.length; i++) {
      newda.append("file_name", this.state.imageName[i]);
    }
    this.toggleLoader();

    const accessToken = user;
    //const url = "https://testapi.paropakari.com/api/Create/CreatePost";
    const url = "https://testapi.paropakari.com/api/Create/CreatePost";
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
      },
      body: newda,
    };
    // this.setState(this.initialState)
    const response = await fetch(url, options);
    const result = await response.json();

    if (result.message == "Cause created successfully!") {
      alert(
        this.state.Causecreatedsuccessfully.result
          ? this.state.Causecreatedsuccessfully.result
          : "Your Cause is on its way to bring happiness!"
      );

      const prof_benID = result.prof_benID;

      window.location.href = "#/HomePage";
      // var benename = document.getElementById("benfname3").value
      // this.handleSubmittrans(prof_benID);
    } else if (result.message == "Please, Enter Your UPI ID in your Profile!") {
      alert(result.message);
    } else {
      alert(
        this.state.all_fields.result
          ? this.state.all_fields.result
          : "Try to fill all the fields and Retry"
      );
      // alert("Try to fill all the fields and Retry");
      //  window.location.reload();
    }
    //  this.setState(this.initialState)
  };

  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown,
    });
  };

  redirect = (e) => {
    e.preventDefault();
    var page = {
      pathname: "./Login.js",
    };
    this.props.history.push(page);
  };
  resetFile(event) {
    event.preventDefault();

    this.setState({ ImageDisplay: null });
  }
  uploadSingleFile1(e) {
    // setFile([...file, URL.createObjectURL(e.target.files[0])]);

    // this.setState({
    //     file:e.target.files[0].name
    //   });

    //     for (let i = 0; i < e.target.files.length; i++) {
    //      // file.push(URL.createObjectURL(e.target.files[i]));

    //      this.setState({
    //           file: [...this.state.file,e.target.files[i].name]

    //          })

    //  // this.setState([...file, e.target.files[0].name]);
    //
    //
    // }

    this.setState({ files: [...this.state.files, ...e.target.files] });
    this.setState(
      {
        file: [...this.state.file, e.target.files[0].name],
      },
      () => {
        localStorage.setItem("Images", JSON.stringify(this.state.file));
      }
    );

    // let file = [];

    //   for (let i = 0; i < e.target.files.length; i++) {
    //     file.push([e.target.files[0].name]);

    //   this.setState({

    //     file: [ ...this.state.file, e.target.files[0].name]

    //   });
    //
    //  }

    // this.setState({
    //   file: [...this.state.file, e.target.files[0].name]
    // })
    //
  }

  uploadSingleFile(e) {
    let ImagesArray = Object.entries(e.target.files).map(
      (e) =>
        //   URL.createObjectURL(e[1])
        e[1].name

      // e.target.files
    );
    //
    // //
    // setFile([ ...this.state.file, ...ImagesArray]);

    this.setState(
      {
        file: [...this.state.file, ...ImagesArray],
      },
      () => {
        // localStorage.setItem('Images', JSON.stringify(this.state.file));
      }
    );
  }
  imageHandleChange(e) {
    if (e.target.files) {
      this.setState({
        imageName: [...e.target.files, ...this.prevCountRef],
      });
    }
  }

  deleteimg = (index) => {
    const updatedImages = this.state.imageName.filter((_, i) => i !== index);

    this.setState({
      imageName: updatedImages,
    });
  };

  componentDidUpdate(prevCountRef) {
    if (this.state.imageName !== this.prevCountRef) {
      this.prevCountRef = this.state.imageName;
    }
  }
  upload(e) {
    e.preventDefault();
  }

  deleteFile(e) {
    const s = this.state.file.filter((item, index) => index !== e);

    this.setState({
      file: s,
    });
    //  this.setState(file:s);
  }

  ImagePreview = (event) => {
    var fileInput = false;
    var maxSize = "200";

    const imageFile = event.target.files[0];

    if (event.target.files[0]) {
      fileInput = true;
    }
    const fi = Math.round(imageFile.size / 2048);

    //  if(fi > maxSize)
    //  {
    //    alert("Too Large");
    //    window.location.reload();
    //  }
    //  else if(fi < 50)
    //  {
    //    alert("Please select Quality Image");
    //    window.location.reload();
    //  }

    var reader = new FileReader();
    var url = reader.readAsDataURL(imageFile);

    reader.onloadend = function (e) {
      this.setState({
        ImageDisplay: [reader.result],
      });
    }.bind(this);
    if (fileInput) {
      Resizer.imageFileResizer(
        imageFile,
        30,
        30,
        "PNG",
        10,
        0,
        (uri) => {},
        "base64"
      );
    }

    var fsize = event.target.files[0].size / 150;

    if (!imageFile) {
      this.setState({ invalidImage: "Please select image." });
      return false;
    }

    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      this.setState({ invalidImage: "Please select valid image." });

      return false;
    } else if (fsize < maxSize) {
      this.setState({ invalidImage: "Please select Quality image." });

      return false;
    }
    this.reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        this.setState({ selectedFile: imageFile, invalidImage: null });
      };
      img.onerror = () => {
        this.setState({ invalidImage: "Invalid image content." });

        return false;
      };

      img.src = e.target.result;
    };
    this.reader.readAsDataURL(imageFile);

    /////////////////////////////////////

    // const fi = document.getElementById('profile_imagePath');
    // 	// Check if any file is selected.
    // 	if (fi.files.length > 0) {
    // 		for (let i = 0; i <= fi.files.length - 1; i++) {

    // 			const fsize = fi.files.item(i).size;

    // 			//const file = Math.round((fsize / 1024));
    //
    // 			// The size of the file.
    // 			if (fsize > 89400) {
    // 				alert(
    // 				"File too Big, please select a file less than 4mb");
    // 			} else if (fsize < 1024) {
    // 				alert(
    // 				"File too small, please select a file greater than 1mb");
    // 			} else {
    // 				document.getElementById('profile_imagePath').innerHTML = '<b>'
    // 				+ fi + '</b> KB';
    // 			}
    // 		}
    // 	}
  };

  ReloadPage = () => {
    let reloads = 0;
    // setTimeout(() => window.location.reload(), 2000);
    if (sessionStorage.getItem("reloadcreate") !== null) {
      reloads = sessionStorage.getItem("reloadcreate");
      reloads++;
      window.sessionStorage.setItem("reloadcreate", reloads);
    } else {
      reloads = 1;
      window.sessionStorage.setItem("reloadcreate", reloads);
    }

    if (reloads == 1) {
      window.location.reload();
    }
  };

  fileChangedHandler(event) {
    var fileInput = false;

    if (event.target.files[0]) {
      fileInput = true;
    }
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
    });
  }
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  dateChange(date) {
    this.setState({
      dob: date,
    });
  }
  handleFormValidation() {
    const {
      upload_title,
      upload_description,
      upload_amount,
      Bank_acc,
      ifsc,
      upload_num_of_beneficiaries,
      upload_place,
      required_by,
      relation_id,
      category_id,
      status_80G,
      checkbox,
    } = this.state;
    let formErrors = {};
    var causeID = JSON.parse(localStorage.id);
    let formIsValid = true;

    if (!upload_title || upload_title == "") {
      formIsValid = false;
      formErrors["opErr"] = "Title is Required.";
    }
    if (!upload_description || upload_description == "") {
      formIsValid = false;
      formErrors["npErr"] = "Description is Required.";
    }

    if (!upload_num_of_beneficiaries) {
      if (causeID == 3) {
        //alert("success")
        formIsValid = true;
        //formErrors["nbErr"] = "Number of beneficiaries is Required.";
      } else {
        formIsValid = false;
        formErrors["nbErr"] = "Number of beneficiaries is Required.";
      }
    }

    //   if(!Bank_acc){
    //     formIsValid=false;
    //     formErrors["bankErr"]="Bank account number is Required.";
    // }
    //        if(!ifsc){
    //           formIsValid=false;
    //           formErrors["ifscErr"]="IFSC is Required.";
    //       }
    if (!upload_amount || upload_amount.trim() == "") {
      formIsValid = false;
      formErrors["upaErr"] = "Amount is Required.";
    } else {
      const amountValue = parseFloat(upload_amount);

      if (amountValue > 100000) {
        formIsValid = false;
        formErrors["upalkhErr"] = "Amount should not exceed 1 lakh.";
      }
    }
    if (!upload_title || upload_title == "") {
      formIsValid = false;
      formErrors["opErr"] = "Title is Required.";
    }
    if (!upload_description || upload_description == "") {
      formIsValid = false;
      formErrors["npErr"] = "Description is Required.";
    }
    if (!upload_place || upload_place.trim() == "") {
      formIsValid = false;
      formErrors["uplErr"] = "Place is Required.";
    }
    if (!required_by) {
      formIsValid = false;
      formErrors["reqErr"] = "Date is Required.";
    }

    if (!category_id || category_id.trim() == "") {
      formIsValid = false;
      formErrors["PurErr"] = "Purpose is Required.";
    }
    //  if(!relation_id){
    //     formIsValid=false;
    //     formErrors["RelaErr"]="Required_for is Required.";
    // }
    if (!status_80G) {
      formIsValid = false;
      formErrors["gErr"] = "Status 80G is Required.";
    }

    if (document.querySelectorAll('[type="checkbox"]:checked').length < 1) {
      formErrors["ChckErr"] = "Please, Confirm the Bank Details";
      formIsValid = false;
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  handleValidation() {
    const {
      upload_title,
      upload_description,
      upload_amount,
      upload_num_of_beneficiaries,
      upload_place,
      required_by,
      relation_id,
      category_id,
      status_80G,
      checkbox,
    } = this.state;
    var causeID = JSON.parse(localStorage.id);
    let formErrors = {};
    let formIsValid = true;

    if (!upload_title) {
      formIsValid = false;
      formErrors["opErr"] = "Title is Required.";
    }
    if (!upload_description || upload_description.trim() == "") {
      formIsValid = false;
      formErrors["npErr"] = "Description is Required.";
    }

    if (!upload_num_of_beneficiaries) {
      if (causeID == 3) {
        //alert("success")
        formIsValid = true;
        //formErrors["nbErr"] = "Number of beneficiaries is Required.";
      } else {
        formIsValid = false;
        formErrors["nbErr"] = this.state.Benerequire.result;
      }
    }
    // if(!upload_amount){
    //     formIsValid=false;
    //     formErrors["upaErr"]="Amount is Required.";
    // }

    if (!upload_place || upload_place.trim() == "") {
      formIsValid = false;
      formErrors["uplErr"] = "Place is Required.";
    }
    if (!required_by) {
      formIsValid = false;
      formErrors["reqErr"] = "Date is Required.";
    }

    if (!category_id || category_id.trim() == "") {
      formIsValid = false;
      formErrors["PurErr"] = "Purpose is Required.";
    }
    //  if(!relation_id){
    //     formIsValid=false;
    //     formErrors["RelaErr"]="Required_for is Required.";
    // }
    //   if(!status_80G){
    //     formIsValid=false;
    //     formErrors["gErr"]="status 80G is Required.";
    // }

    // if (document.querySelectorAll('[type="checkbox"]:checked').length < 1) {
    //   formErrors["ChckErr"]="Please, Confirm the Bank Details";
    //   formIsValid = false;
    // }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  toggleLoader = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
    } else {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000);
    }
  };

  handleSubmittrans = async (prof_benID) => {
    if (this.props.location.state.data.id == 3) {
      //event.preventDefault();

      var phone = 9994115433;
      var address = document.getElementById("upload_place").value;
      var email = "vg@gmail.com";

      var benename = document.getElementById("benfname").value;

      var bankAccount = document.getElementById("Bank_acc").value;
      var ifsc = document.getElementById("ifsc").value;
      var benId = document.getElementById("benId").value;

      let user = JSON.parse(localStorage.getItem("user"));
      var data = {
        phone: phone,
        benename: benename,
        address: address,
        email: email,
        bankAccount: bankAccount,
        ifsc: ifsc,
      };

      const accessToken = user;
      var url =
        "https://testapi.paropakari.com/api/UploadFile/Authorize?benename=" +
        benename +
        "&phone=" +
        phone +
        "&email=" +
        email +
        "&address1=" +
        address +
        "&bankAccount=" +
        bankAccount +
        "&ifsc=" +
        ifsc +
        "&benId=" +
        benId;

      //  var url = "https://testapi.paropakari.com/api/Create/Authorize?benename=tyryrty&phone=89879879879898798&email=vg@ynyu.bhn&address1=5657ghggj&bankAccount=56756756756756&ifsc=SBTgtrh5664"  ;

      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        body: data,
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        localStorage.setItem("benefId", JSON.stringify(result.benefId));
        // localStorage.setItem('username', JSON.stringify(result.name));
        // localStorage.setItem('userid', JSON.stringify(result.userid));
        ///localStorage.setItem('create', JSON.stringify(result.id));

        if (result.ok) {
          // var page= {
          //   pathname: '#/HomePage',
          //   state: {
          //     data:{
          //      password: this.state.password,
          //      username: this.state.username,
          //     }
          //   }
          // }
          //window.alert("login succesful");
          //this.props.history.push(page)
          // alert("Login Successful...");
          //  window.location.href = "#/HomePage";
        } else {
          alert("Username and Password is incorrect!. Try Again");

          // window.location.reload();
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      var phone = 9994115433;
      var address = document.getElementById("upload_place").value;
      var email = "vg@gmail.com";

      var benename = document.getElementById("benfname").value;

      var bankAccount = document.getElementById("Bank_acc").value;
      var ifsc = document.getElementById("ifsc").value;
      var prof_benID = prof_benID;

      let user = JSON.parse(localStorage.getItem("user"));
      var data = {
        phone: phone,
        benename: benename,
        address: address,
        email: email,
        bankAccount: bankAccount,
        ifsc: ifsc,
      };

      const accessToken = user;
      var url =
        "https://testapi.paropakari.com/api/Create/Authorize?benename=" +
        benename +
        "&phone=" +
        phone +
        "&email=" +
        email +
        "&address1=" +
        address +
        "&bankAccount=" +
        bankAccount +
        "&ifsc=" +
        ifsc +
        "&prof_benID=" +
        prof_benID;

      //  var url = "https://testapi.paropakari.com/api/Create/Authorize?benename=tyryrty&phone=89879879879898798&email=vg@ynyu.bhn&address1=5657ghggj&bankAccount=56756756756756&ifsc=SBTgtrh5664"  ;

      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        body: data,
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        localStorage.setItem("benefId", JSON.stringify(result.benefId));
        // localStorage.setItem('username', JSON.stringify(result.name));
        // localStorage.setItem('userid', JSON.stringify(result.userid));
        ///localStorage.setItem('create', JSON.stringify(result.id));

        if (response.ok) {
          // var page= {
          //   pathname: '#/HomePage',
          //   state: {
          //     data:{
          //      password: this.state.password,
          //      username: this.state.username,
          //     }
          //   }
          // }
          //window.alert("login succesful");
          //this.props.history.push(page)
          // alert("Login Successful...");
          //  window.location.href = "#/HomePage";
        } else {
          alert("Username and Password is incorrect!. Try Again");
          // window.location.reload();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  modal = () => {
    if (!this.state.modal) {
      this.setState({ modal: true });
      //alert('aarthi')
    }
  };

  confirmtoggleModal = () => {
    this.setState({ confirmmodal: false });
  };

  confirmtoggleModal1 = () => {
    this.setState({ confirmmodal1: true });
  };
  // Dhinesh
  handleSubmit = async (event) => {
    // alert(this.state.youagreetoprovide.result);

    var s80G = document.getElementById("status_80G").value;

    var confirm80g = document.getElementById("80gconfirm");
    var no80g = document.getElementById("80gno");
    if (s80G == 0) {
      event.preventDefault();
      if (this.handleFormValidation()) {
        // alert("fghj")

        //  alert("0");
        var bankAccount = document.getElementById("Bank_acc").value;
        var ifsc = document.getElementById("ifsc").value;
        var upi_ID = document.getElementById("upi_ID").value;
        var u_amount = document.getElementById("upload_amount").value;
        // if(u_amount == 0)
        // {
        //   alert(
        //     this.state.valid_amnt.result
        //       ? this.state.valid_amnt.result
        //       : "Please, Enter Valid amount"
        //   );
        //  // alert("");
        // }
        // alert("*");
        //    const { ifsc,Bank_acc } = this.state;

        if (this.state.Bank_acc == "" && bankAccount == "") {
          document.getElementById("bankalert").innerHTML =
            this.state.providebankdet.result;
          // alert(this.state.providebankdet.result);
        }
        if (this.state.ifsc == "" && ifsc == "") {
          document.getElementById("ifscalert").innerHTML =
            this.state.validifsc.result;
          // alert(this.state.providebankdet.result);
        }
        // const { ifsc,Bank_acc } = this.state;
        if (
          (this.state.ifsc != "" && this.state.Bank_acc != "") ||
          bankAccount != "" ||
          ifsc != ""
        ) {
          var Bankaccountnumber;
          if (bankAccount != "") {
            Bankaccountnumber = bankAccount;
          }
          if (this.state.Bank_acc != "") {
            Bankaccountnumber = this.state.Bank_acc;
          }
          const ree = new RegExp("^[0-9]{9,18}$");
          const isOk = ree.test(Bankaccountnumber);

          if (!isOk) {
            document.getElementById("bankalert").innerHTML =
              this.state.validbankacc.result;
            // alert(this.state.validbankacc.result);
          } else {
            if (this.state.ifsc != "" || ifsc != "") {
              var ifsccode;
              if (ifsc != "") {
                ifsccode = ifsc;
              }
              if (this.state.ifsc != "") {
                ifsccode = this.state.ifsc;
              }
              const re = new RegExp("^[A-Za-z]{4}0[A-Z0-9a-z]{6}$");
              const isOkk = re.test(ifsccode);

              if (!isOkk) {
                document.getElementById("ifscalert").innerHTML =
                  this.state.validifsc.result;
                // alert(this.state.validifsc.result);
              }

              var u_amount = document.getElementById("upload_amount").value;
              if (u_amount == 0) {
                alert(
                  this.state.valid_amnt.result
                    ? this.state.valid_amnt.result
                    : "Please, Enter Valid amount"
                );
                // alert("");
              }
              // var u_amount = document.getElementById("upload_amount").value;
              else if (u_amount < 100) {
                alert(
                  this.state.Minimumamt.result
                    ? this.state.Minimumamt.result
                    : "Minimum amount should be 100"
                );

                // alert("");
              }
              var beneficiarycount = document.getElementById(
                "upload_num_of_beneficiaries"
              ).value;
              // alert("GET In")

              if (beneficiarycount == 0) {
                alert(
                  this.state.valid_benef.result
                    ? this.state.valid_benef.result
                    : "Please Enter Valid Number of Beneficiary"
                );
                // alert("Please enter valid no. of Beneficiary");
              }
              if (this.state.upi_ID != "" || upi_ID != "") {
                //alert("Bank acc:"+upi_ID)
                var UPIID;
                if (upi_ID != "") {
                  UPIID = upi_ID;
                }
                if (this.state.upi_ID != "") {
                  UPIID = this.state.upi_ID;
                }
                const reeUPI = new RegExp("[a-zA-Z0-9.-_]@[a-zA-Z]");
                const isOkkUPI = reeUPI.test(UPIID);

                if (!isOkkUPI) {
                  //document.getElementById('upiidalert').innerHTML = this.state.validupi.result
                  alert(this.state.validupi.result);
                }

                if (
                  isOk &&
                  isOkk &&
                  beneficiarycount >= 1 &&
                  u_amount >= 100 &&
                  isOkkUPI
                ) {
                  //if (isOk && isOkk) {

                  // alert("GET In")
                  const data = new FormData(event.target);
                  // this.toggleLoader();
                  let user = JSON.parse(localStorage.getItem("user"));

                  data.append("file_name", this.state.imageName);

                  for (let i = 0; i < this.state.imageName.length; i++) {
                    data.append("file_name", this.state.imageName[i]);
                  }

                  this.toggleLoader();
                  const accessToken = user;
                  const url = "https://testapi.paropakari.com/api/Create/CreatePost";
                  const options = {
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      Authorization: "Bearer " + accessToken,
                    },
                    body: data,
                  };
                  // this.setState(this.initialState)
                  const response = await fetch(url, options);
                  const result = await response.json();

                  if (result.message == "Cause created successfully!") {
                    alert(
                      this.state.Causecreatedsuccessfully.result
                        ? this.state.Causecreatedsuccessfully.result
                        : "Your Cause is on its way to bring happiness!"
                    );

                    //  this.toggleLoader();
                    const prof_benID = result.prof_benID;
                    //alert('4')
                    window.location.href = "#/HomePage";
                    // var benename = document.getElementById("benfname3").value
                    // this.handleSubmittrans(prof_benID);
                  }
                  // else if (result.message == "Please, Enter Your UPI ID in your Profile!") {
                  //   alert(result.message);
                  // }
                  else {
                    //alert("Please, Enter Your UPI ID");
                  }
                  // else {
                  //    alert("Please, Enter Your UPI ID");
                  //    window.location.reload();
                  // }
                  //  this.setState(this.initialState)
                }
              } else {
                if (isOk && isOkk && beneficiarycount >= 1 && u_amount >= 100) {
                  //if (isOk && isOkk) {

                  // alert("GET In")
                  const data = new FormData(event.target);
                  // this.toggleLoader();
                  let user = JSON.parse(localStorage.getItem("user"));

                  data.append("file_name", this.state.imageName);

                  for (let i = 0; i < this.state.imageName.length; i++) {
                    data.append("file_name", this.state.imageName[i]);
                  }

                  this.toggleLoader();
                  const accessToken = user;
                  const url = "https://testapi.paropakari.com/api/Create/CreatePost";
                  const options = {
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      Authorization: "Bearer " + accessToken,
                    },
                    body: data,
                  };
                  // this.setState(this.initialState)
                  const response = await fetch(url, options);
                  const result = await response.json();

                  if (result.message == "Cause created successfully!") {
                    alert(
                      this.state.Causecreatedsuccessfully.result
                        ? this.state.Causecreatedsuccessfully.result
                        : "Your Cause is on its way to bring happiness!"
                    );

                    //  this.toggleLoader();
                    const prof_benID = result.prof_benID;
                    //alert('4')
                    window.location.href = "#/HomePage";
                    // var benename = document.getElementById("benfname3").value
                    // this.handleSubmittrans(prof_benID);
                  }
                  // else if (result.message == "Please, Enter Your UPI ID in your Profile!") {
                  //   alert(result.message);
                  // }
                  else {
                    //alert("Please, Enter Your UPI ID");
                  }
                  // else {
                  //    alert("Please, Enter Your UPI ID");
                  //    window.location.reload();
                  // }
                  //  this.setState(this.initialState)
                }
              }
            } else {
              // alert("0")
              alert(
                this.state.all_fields.result
                  ? this.state.all_fields.result
                  : "Try to fill all the fields and Retry"
              );
            }
          }
        }
      }
    } else if (s80G == 1) {
      // alert("1");
      event.preventDefault();
      if (this.handleFormValidation()) {
        var bankAccount = document.getElementById("Bank_acc").value;
        var ifsc = document.getElementById("ifsc").value;
        var upi_ID = document.getElementById("upi_ID").value;
        //    const { ifsc,Bank_acc } = this.state;

        if (this.state.Bank_acc == "" && bankAccount == "") {
          document.getElementById("bankalert").innerHTML =
            this.state.providebankdet.result;
          // alert(this.state.providebankdet.result);
        }
        if (this.state.ifsc == "" && ifsc == "") {
          document.getElementById("ifscalert").innerHTML =
            this.state.validifsc.result;
          // alert(this.state.providebankdet.result);
        }
        //  const { ifsc,Bank_acc } = this.state;
        if (
          (this.state.ifsc != "" && this.state.Bank_acc != "") ||
          bankAccount != "" ||
          ifsc != ""
        ) {
          var ifsccode;
          if (ifsc != "") {
            ifsccode = ifsc;
          }
          if (this.state.ifsc != "") {
            ifsccode = this.state.ifsc;
          }
          const re = new RegExp("^[A-Za-z]{4}0[A-Z0-9a-z]{6}$");
          const isOk = re.test(ifsccode);

          if (!isOk) {
            return alert(this.state.validifsc.result);
          } else {
            if (this.state.Bank_acc != "" || bankAccount != "") {
              var Bankaccountnumber;
              if (bankAccount != "") {
                Bankaccountnumber = bankAccount;
              }
              if (this.state.Bank_acc != "") {
                Bankaccountnumber = this.state.Bank_acc;
              }
              const ree = new RegExp("^[0-9]{9,18}$");
              const isOkk = ree.test(Bankaccountnumber);

              if (!isOkk) {
                return alert(this.state.validbankacc.result);
              }

              let beneficiarycount = document.getElementById(
                "upload_num_of_beneficiaries"
              ).value;
              // alert("GET In")

              if (beneficiarycount == 0) {
                alert(
                  this.state.valid_benef.result
                    ? this.state.valid_benef.result
                    : "Please enter valid no. of Beneficiary"
                );
                // alert("Please enter valid no. of Beneficiary");
              }
              var u_amount = document.getElementById("upload_amount").value;
              if (u_amount == 0) {
                alert(
                  this.state.valid_amnt.result
                    ? this.state.valid_amnt.result
                    : "Please, Enter Valid amount"
                );
                // alert("");
              }
              // var u_amount = document.getElementById("upload_amount").value;
              else if (u_amount < 100) {
                alert(
                  this.state.Minimumamt.result
                    ? this.state.Minimumamt.result
                    : "Minimum amount should be 100"
                );
                // alert("");
              }
              if (this.state.upi_ID != "" || upi_ID != "") {
                //alert("Bank acc:"+upi_ID)
                var UPIID;
                if (upi_ID != "") {
                  UPIID = upi_ID;
                }
                if (this.state.upi_ID != "") {
                  UPIID = this.state.upi_ID;
                }
                const reeUPI = new RegExp("[a-zA-Z0-9.-_]@[a-zA-Z]");
                const isOkkUPI = reeUPI.test(UPIID);

                if (!isOkkUPI) {
                  return alert(this.state.validupi.result);
                }

                if (
                  isOk &&
                  isOkk &&
                  beneficiarycount >= 1 &&
                  u_amount >= 100 &&
                  isOkkUPI
                ) {
                  this.setState({ confirmmodal: true });

                  if (this.state.confirmmodal1 === true) {
                    //  this.toggleLoader();
                    // alert("ada ponga daa");
                    // this.modal();

                    // Dhinesh2
                    // this.setState({ modal: !this.state.modal });
                    // alert('aarthi')

                    const data = new FormData(event.target);

                    let user = JSON.parse(localStorage.getItem("user"));

                    data.append("file_name", this.state.imageName);

                    for (let i = 0; i < this.state.imageName.length; i++) {
                      data.append("file_name", this.state.imageName[i]);
                    }

                    const accessToken = user;
                    this.toggleLoader();
                    //const url = "https://testapi.paropakari.com/api/Create/CreatePost";
                    const url = "https://testapi.paropakari.com/api/Create/CreatePost";
                    const options = {
                      method: "POST",
                      headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + accessToken,
                      },
                      body: data,
                    };
                    //  this.setState(this.initialState)
                    const response = await fetch(url, options);
                    const result = await response.json();

                    //  this.toggleLoader();
                    if (result.message == "Cause created successfully!") {
                      alert(
                        this.state.Causecreatedsuccessfully.result
                          ? this.state.Causecreatedsuccessfully.result
                          : "Your Cause is on its way to bring happiness!"
                      );

                      const prof_benID = result.prof_benID;
                      // alert('5')
                      window.location.href = "#/HomePage";
                      // var benename = document.getElementById("benfname3").value
                      // this.handleSubmittrans(prof_benID);
                    } else if (
                      result.message ==
                      "Please, Enter Your UPI ID in your Profile!"
                    ) {
                      alert(result.message);
                    } else {
                      //  alert("1")
                      alert(
                        this.state.all_fields.result
                          ? this.state.all_fields.result
                          : "Try to fill all the fields and Retry"
                      );
                      // window.location.reload();
                    }
                    // this.setState(this.initialState)
                  }
                }
              } else {
                if (isOk && isOkk && beneficiarycount >= 1 && u_amount >= 100) {
                  this.setState({ confirmmodal: true });

                  if (this.state.confirmmodal1 === true) {
                    //  this.toggleLoader();
                    // alert("ada ponga daa");
                    // this.modal();

                    // Dhinesh2
                    // this.setState({ modal: !this.state.modal });
                    // alert('aarthi')

                    const data = new FormData(event.target);

                    let user = JSON.parse(localStorage.getItem("user"));

                    data.append("file_name", this.state.imageName);

                    for (let i = 0; i < this.state.imageName.length; i++) {
                      data.append("file_name", this.state.imageName[i]);
                    }

                    const accessToken = user;
                    this.toggleLoader();
                    //const url = "https://testapi.paropakari.com/api/Create/CreatePost";
                    const url = "https://testapi.paropakari.com/api/Create/CreatePost";
                    const options = {
                      method: "POST",
                      headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + accessToken,
                      },
                      body: data,
                    };
                    // this.setState(this.initialState)
                    const response = await fetch(url, options);
                    const result = await response.json();

                    //  this.toggleLoader();
                    if (result.message == "Cause created successfully!") {
                      alert(
                        this.state.Causecreatedsuccessfully.result
                          ? this.state.Causecreatedsuccessfully.result
                          : "Your Cause is on its way to bring happiness!"
                      );

                      const prof_benID = result.prof_benID;
                      // alert('5')
                      window.location.href = "#/HomePage";
                      // var benename = document.getElementById("benfname3").value
                      // this.handleSubmittrans(prof_benID);
                    } else if (
                      result.message ==
                      "Please, Enter Your UPI ID in your Profile!"
                    ) {
                      alert(result.message);
                    } else {
                      //  alert("1")
                      alert(
                        this.state.all_fields.result
                          ? this.state.all_fields.result
                          : "Try to fill all the fields and Retry"
                      );
                      // window.location.reload();
                    }
                    // this.setState(this.initialState)
                  }
                }
              }

              //this.toggleModal()
            } else {
              // alert("2")
              alert(
                this.state.all_fields.result
                  ? this.state.all_fields.result
                  : "Try to fill all the fields and Retry"
              );
            }
          }
          // alert("Hai")
        }
      }
    }
  };

  handleSubmitNonMonitory = async (event) => {
    // alert(this.state.youagreetoprovide.result);
    event.preventDefault();
    if (this.handleValidation()) {
      //  alert("Hit");
      // event.target.file_name=this.state.file;
      const data = new FormData(event.target);
      for (let [key, value] of data.entries()) {
      }
      //   for (var value of FormData.values()) {
      //
      //  }
      let beneficiarycount = document.getElementById(
        "upload_num_of_beneficiaries"
      ).value;
      // alert("GET In")

      if (beneficiarycount == 0) {
        alert(
          this.state.valid_benef.result
            ? this.state.valid_benef.result
            : "Please enter valid no. of Beneficiary"
        );
        // alert("Please enter valid no. of Beneficiary");
      } else {
        for (const key of Object.keys(this.state.file)) {
          data.append("file", this.state.file[key]);
        }
        // data.append('file',this.state.file)

        // const form = document.getElementById('file_name');
        //
        //
        //

        data.append("file_name", this.state.imageName);

        for (let i = 0; i < this.state.imageName.length; i++) {
          data.append("file_name", this.state.imageName[i]);
        }

        let user = JSON.parse(localStorage.getItem("user"));

        const accessToken = user;
        this.toggleLoader();
        const url = "https://testapi.paropakari.com/api/Create/CreatePost";
        const options = {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
          },
          body: data,
        };
        // this.setState(this.initialState)
        const response = await fetch(url, options);
        const result = await response.json();

        if (result.message == "Cause created successfully!") {
          alert(
            this.state.Causecreatedsuccessfully.result
              ? this.state.Causecreatedsuccessfully.result
              : "Your Cause is on its way to bring happiness!"
          );

          const prof_benID = result.prof_benID;
          // window.location.reload();
          // alert('6')
          window.location.href = "#/HomePage";
          // var benename = document.getElementById("benfname3").value
          // this.handleSubmittrans(prof_benID);
        } else if (
          result.message == "Please, Enter Your UPI ID in your Profile!"
        ) {
          alert(result.message);
        } else {
          alert(
            this.state.all_fields.result
              ? this.state.all_fields.result
              : "Try to fill all the fields and Retry"
          );
          //  window.location.reload();
        }
        //  this.setState(this.initialState)
      }
    }
  };
  Footer() {
    let url = "https://testapi.paropakari.com/api/Authenticate/Get_FooterDetails";
    let data = this.state;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          foo: data,
        });

        //
      });
  }

  DefaultMenu() {
    //
    let reloads = 0;
    window.sessionStorage.setItem("reload", reloads);

    let reloadsmyactivity = 0;
    window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
    window.sessionStorage.setItem("responsepagereload", reloadsmyactivity);
    window.sessionStorage.setItem("notificationpagereload", reloadsmyactivity);
    window.sessionStorage.setItem("Indexreload", reloadsmyactivity);

    let MID = sessionStorage.getItem("translate");
    //         if(sessionStorage.getItem('transliteration') !== null){
    //           MID= sessionStorage.getItem('transliteration');
    //         }
    // else
    if (sessionStorage.getItem("translate") !== null) {
      MID = sessionStorage.getItem("translate");
    } else {
      MID = "English";
    }

    if (MID === "English") {
      //
      // alert("English");
      document.getElementById("EnglishButton").click();
    }

    let datatrans = MID;

    localStorage.setItem("GoogleTranslate123", this.state.googtrans);

    let GID = sessionStorage.getItem("translate");

    let GIDS = JSON.parse(localStorage.getItem("this.state.googtrans"));

    let datatransID = MID;
    if (datatrans == "kn") {
      datatransID = "Kannada";
    } else if (datatrans == "ta") {
      datatransID = "Tamil";
    } else if (datatrans == "te") {
      datatransID = "Telugu";
    } else if (datatrans == "hi") {
      datatransID = "Hindi";
    } else if (datatrans == MID) {
      datatransID = MID;
    }

    let LnID = MID;
    if (LnID == "Kannada") {
      LnID = "kn";
    } else if (LnID == "Tamil") {
      LnID = "ta";
    } else if (LnID == "Telugu") {
      LnID = "te";
    } else if (LnID == "Hindi") {
      LnID = "hi";
    } else if (LnID == MID) {
      LnID = MID;
    }

    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others~HowItWorks~Blog&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Howitwork = typeof items[9] !== "undefined" ? items[9] : null;
          var Blog = typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;
          var Others = typeof items[8] !== "undefined" ? items[8] : null;

          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem("translate", LangID);

          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            myjourney: Myjourney,
            howitworks: Howitwork,
            blog: Blog,

            beaparopakari: Beaparopakari,

            createacause: Createacause,
            invite: Invite,

            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            langID: LangID,
            llangID: LangID,
            lID: LID,
            others: Others,
          });

          //
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self~Family/Friends~Beneficiary Name&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Contactus = typeof items[0] !== "undefined" ? items[0] : null;
          var Termsandcondition =
            typeof items[1] !== "undefined" ? items[1] : null;
          var CopyRight = typeof items[2] !== "undefined" ? items[2] : null;
          var Causes = typeof items[3] !== "undefined" ? items[3] : null;
          var Beneficiaries = typeof items[4] !== "undefined" ? items[4] : null;
          var Paropakaris = typeof items[5] !== "undefined" ? items[5] : null;
          var Amountraised = typeof items[6] !== "undefined" ? items[6] : null;
          var RequiredFor = typeof items[7] !== "undefined" ? items[7] : null;
          var Self = typeof items[8] !== "undefined" ? items[8] : null;
          var FamilyandFriends =
            typeof items[9] !== "undefined" ? items[9] : null;
          var BeneficiaryName =
            typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem("translate", LangID);

          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            requiredforr: RequiredFor,
            self: Self,
            familyfriends: FamilyandFriends,
            langID: LangID,
            llangID: LangID,
            lID: LID,
            beneficiaryname: BeneficiaryName,
          });

          //
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Submit~Food and Nutrition~Health~Professional~Sports~Social Welfare~Education~Create Your Cause~Title~Description~Categories&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Submit = typeof items[0] !== "undefined" ? items[0] : null;

          var FoodandNutrtion =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Health = typeof items[2] !== "undefined" ? items[2] : null;
          var Professional = typeof items[3] !== "undefined" ? items[3] : null;
          var Sports = typeof items[4] !== "undefined" ? items[4] : null;
          var SocialWelfare = typeof items[5] !== "undefined" ? items[5] : null;
          var Educattion = typeof items[6] !== "undefined" ? items[6] : null;
          var CreateYourCause =
            typeof items[7] !== "undefined" ? items[7] : null;
          var Title = typeof items[8] !== "undefined" ? items[8] : null;
          var Description = typeof items[9] !== "undefined" ? items[9] : null;
          var Categories = typeof items[10] !== "undefined" ? items[10] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem("translate", LangID);

          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            createyourcause: CreateYourCause,
            categories: Categories,
            foodandnutrition: FoodandNutrtion,
            education: Educattion,
            health: Health,
            professional: Professional,
            sports: Sports,
            socialwefare: SocialWelfare,
            title: Title,
            description: Description,
            llangID: LangID,
            lID: LID,
            submit: Submit,
          });

          //
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Bank Account~Amount~Required For~Add Supporting Documents~IFSC Code~Confirm Bank Details~Place~80G will be provided~End Date~Tag Groups~Date is Required.&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var BankAccount = typeof items[0] !== "undefined" ? items[0] : null;
          var Amount = typeof items[1] !== "undefined" ? items[1] : null;
          var RequiredFor = typeof items[2] !== "undefined" ? items[2] : null;
          var AddSuppDocs = typeof items[3] !== "undefined" ? items[3] : null;
          var IFSCCode = typeof items[4] !== "undefined" ? items[4] : null;
          var ConfirmBankDetails =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Place = typeof items[6] !== "undefined" ? items[6] : null;
          var Available80G = typeof items[7] !== "undefined" ? items[7] : null;
          var EndDate = typeof items[8] !== "undefined" ? items[8] : null;
          var TagGroup = typeof items[9] !== "undefined" ? items[9] : null;
          var DateisRequired =
            typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem("translate", LangID);

          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            bankaccount: BankAccount,
            amount: Amount,
            requiredforr: RequiredFor,
            addsupportingdocuments: AddSuppDocs,
            ifscode: IFSCCode,
            confirmbankdetails: ConfirmBankDetails,
            place: Place,
            available80G: Available80G,
            enddate: EndDate,
            taggroup: TagGroup,
            DateisRequired: DateisRequired,
            llangID: LangID,
            lID: LID,
          });

          //
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=UPI ID~Create~Tag Friends~Enter~Select~Title is Required.~Description is Required.~Purpose is Required.~Amount is Required.~Please Confirm the Bank Details~Place is Required.~Status 80G is Required.&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var UPIID = typeof items[0] !== "undefined" ? items[0] : null;
          var Create = typeof items[1] !== "undefined" ? items[1] : null;
          var TagFriends = typeof items[2] !== "undefined" ? items[2] : null;
          var Enter = typeof items[3] !== "undefined" ? items[3] : null;
          var Select = typeof items[4] !== "undefined" ? items[4] : null;
          var TitleisRequired =
            typeof items[5] !== "undefined" ? items[5] : null;
          var DescriptionisRequired =
            typeof items[6] !== "undefined" ? items[6] : null;
          var PurposeisRequired =
            typeof items[7] !== "undefined" ? items[7] : null;
          var AmountisRequired =
            typeof items[8] !== "undefined" ? items[8] : null;
          var PleaseConfirmtheBankDetails =
            typeof items[9] !== "undefined" ? items[9] : null;
          var PlaceisRequired =
            typeof items[10] !== "undefined" ? items[10] : null;
          var GRequired = typeof items[11] !== "undefined" ? items[11] : null;

          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem("translate", LangID);

          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            upiid: UPIID,
            create: Create,
            tagfriends: TagFriends,
            enter: Enter,
            select: Select,
            TitleisRequired: TitleisRequired,
            DescriptionisRequired: DescriptionisRequired,
            PurposeisRequired: PurposeisRequired,
            AmountisRequired: AmountisRequired,
            PleaseConfirmtheBankDetails: PleaseConfirmtheBankDetails,
            PlaceisRequired: PlaceisRequired,
            GRequired: GRequired,

            llangID: LangID,
            lID: LID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Your Cause is on its way to bring happiness!~Privacy Policy~Include documents that support your cause~For Example: Medical Bills, Medical Certificates, School Register, Images etc~Close~No of Beneficiaries~Enter Valid IFSC Code~Enter valid bank account~Enter valid UPI~Provide Bank Details&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Causecreatedsuccessfully =
            typeof items[0] !== "undefined" ? items[0] : null;
          var PrivacyPolicy = typeof items[1] !== "undefined" ? items[1] : null;
          var IncludeDocs = typeof items[2] !== "undefined" ? items[2] : null;
          var ForEx = typeof items[3] !== "undefined" ? items[3] : null;
          var Close = typeof items[4] !== "undefined" ? items[4] : null;
          var NoOfBeneficiary =
            typeof items[5] !== "undefined" ? items[5] : null;
          var ValifIFSC = typeof items[6] !== "undefined" ? items[6] : null;
          var ValidBankAcc = typeof items[7] !== "undefined" ? items[7] : null;
          var ValidUPI = typeof items[8] !== "undefined" ? items[8] : null;
          var ProvideBankDet =
            typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem("translate", LangID);

          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            Causecreatedsuccessfully: Causecreatedsuccessfully,
            llangID: LangID,
            privacypolicy: PrivacyPolicy,
            includedocs: IncludeDocs,
            forex: ForEx,
            close: Close,
            validifsc: ValifIFSC,
            validbankacc: ValidBankAcc,
            validupi: ValidUPI,
            noofbeneficiary: NoOfBeneficiary,
            providebankdet: ProvideBankDet,
            lID: LID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=You are responsible to provide 80G~is not responsible to provide any 80G~You Agree to Provide 80G to The Donors~Confirm~Friends~Bank details are mandatory for monetary cause to get support directly from supporter to needy&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Youareresponsible =
            typeof items[0] !== "undefined" ? items[0] : null;
          var ParopakarinotResponsible =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Youagreetoprovide =
            typeof items[2] !== "undefined" ? items[2] : null;
          var Confirm = typeof items[3] !== "undefined" ? items[3] : null;
          var Friends = typeof items[4] !== "undefined" ? items[4] : null;
          var Paropakaribankpop =
            typeof items[5] !== "undefined" ? items[5] : null;
          // var bene_limit =typeof items[6] !== "undefined" ? items[6] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem("translate", LangID);

          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            youareresponsible: Youareresponsible,
            llangID: LangID,
            paropakarinotresponsible: ParopakarinotResponsible,
            youagreetoprovide: Youagreetoprovide,
            friends: Friends,
            confirm: Confirm,
            paropakaribankpop: Paropakaribankpop,
            // bene_limit:bene_limit,
            lID: LID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Maximum  amount limit is 1 lakh.~Please, Enter Valid amount~Please Enter Valid Number of Beneficiary~Try to fill all the fields and Retry~Minimum amount should be 100~Beneficiary is required&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var AmountLessthanLakh =
            typeof items[0] !== "undefined" ? items[0] : null;
          var valid_amnt = typeof items[1] !== "undefined" ? items[1] : null;
          var valid_benef = typeof items[2] !== "undefined" ? items[2] : null;
          var all_fields = typeof items[3] !== "undefined" ? items[3] : null;

          var Minimumamt = typeof items[4] !== "undefined" ? items[4] : null;

          var Benerequire = typeof items[5] !== "undefined" ? items[5] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem("translate", LangID);

          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            AmountLessthanLakh: AmountLessthanLakh,
            valid_amnt: valid_amnt,
            valid_benef: valid_benef,
            all_fields: all_fields,
            Minimumamt: Minimumamt,
            Benerequire: Benerequire,
            lID: LID,
          });
        });
    }

    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari~Yes~No~Add~Enter the Title~Enter the Description~Enter the Account number~Enter the IFSC number~Enter the place&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Yes = typeof items[2] !== "undefined" ? items[2] : null;
          var No = typeof items[3] !== "undefined" ? items[3] : null;
          var Add = typeof items[4] !== "undefined" ? items[4] : null;
          var EnterTitle = typeof items[5] !== "undefined" ? items[5] : null;
          var EnterDesc = typeof items[6] !== "undefined" ? items[6] : null;
          var EnterAccount = typeof items[7] !== "undefined" ? items[7] : null;
          var EnterIfsc = typeof items[8] !== "undefined" ? items[8] : null;
          var EnterPlace = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
            yes: Yes,
            no: No,
            Add: Add,
            EnterTitle: EnterTitle,
            EnterDesc: EnterDesc,
            EnterAccount: EnterAccount,
            EnterIfsc: EnterIfsc,
            EnterPlace: EnterPlace,
          });

          //
        });
    }
  }

  handleChanges(event) {
    this.setState({ value: event.target.value });

    // localStorage.setItem('menuid', event.target.value);
    let values = [
      "OurStory",
      "HowItWorks",
      "Blog",
      "Login",
      "BeaParopakari",
      "What We Do",
    ];

    let l_id = JSON.parse(localStorage.getItem("MenuID"));

    let LnID = event.target.value;
    if (LnID == "Kannada") {
      LnID = "kn";
    } else if (LnID == "Tamil") {
      LnID = "ta";
    } else if (LnID == "Telugu") {
      LnID = "te";
    } else if (LnID == "Hindi") {
      LnID = "hi";
    } else if (LnID == "Spanish") {
      LnID = "es";
    } else if (LnID == event.target.value) {
      LnID = event.target.value;
    }
    let MID = event.target.value;
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others~HowItWorks~Blog&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Howitwork = typeof items[9] !== "undefined" ? items[9] : null;
          var Blog = typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;
          var Others = typeof items[8] !== "undefined" ? items[8] : null;

          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem("translate", LangID);

          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            myjourney: Myjourney,
            howitworks: Howitwork,
            blog: Blog,

            beaparopakari: Beaparopakari,

            createacause: Createacause,
            invite: Invite,

            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,

            llangID: LangID,
            lID: LID,
            others: Others,
          });

          //
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self~Family/Friends~Beneficiary Name&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Contactus = typeof items[0] !== "undefined" ? items[0] : null;
          var Termsandcondition =
            typeof items[1] !== "undefined" ? items[1] : null;
          var CopyRight = typeof items[2] !== "undefined" ? items[2] : null;
          var Causes = typeof items[3] !== "undefined" ? items[3] : null;
          var Beneficiaries = typeof items[4] !== "undefined" ? items[4] : null;
          var Paropakaris = typeof items[5] !== "undefined" ? items[5] : null;
          var Amountraised = typeof items[6] !== "undefined" ? items[6] : null;
          var RequiredFor = typeof items[7] !== "undefined" ? items[7] : null;
          var Self = typeof items[8] !== "undefined" ? items[8] : null;
          var FamilyandFriends =
            typeof items[9] !== "undefined" ? items[9] : null;
          var BeneficiaryName =
            typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem("translate", LangID);

          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            requiredforr: RequiredFor,
            self: Self,
            familyfriends: FamilyandFriends,
            llangID: LangID,
            lID: LID,
            beneficiaryname: BeneficiaryName,
          });

          //
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Submit~Food and Nutrition~Health~Professional~Sports~Social Welfare~Education~Create Your Cause~Title~Description~Categories&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Submit = typeof items[0] !== "undefined" ? items[0] : null;

          var FoodandNutrtion =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Health = typeof items[2] !== "undefined" ? items[2] : null;
          var Professional = typeof items[3] !== "undefined" ? items[3] : null;
          var Sports = typeof items[4] !== "undefined" ? items[4] : null;
          var SocialWelfare = typeof items[5] !== "undefined" ? items[5] : null;
          var Educattion = typeof items[6] !== "undefined" ? items[6] : null;
          var CreateYourCause =
            typeof items[7] !== "undefined" ? items[7] : null;
          var Title = typeof items[8] !== "undefined" ? items[8] : null;
          var Description = typeof items[9] !== "undefined" ? items[9] : null;
          var Categories = typeof items[10] !== "undefined" ? items[10] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem("translate", LangID);

          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            createyourcause: CreateYourCause,
            categories: Categories,
            foodandnutrition: FoodandNutrtion,
            education: Educattion,
            health: Health,
            professional: Professional,
            sports: Sports,
            socialwefare: SocialWelfare,
            title: Title,
            description: Description,
            llangID: LangID,
            lID: LID,
            submit: Submit,
          });

          //
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Bank Account~Amount~Required For~Add Supporting Documents~IFSC Code~Confirm Bank Details~Place~80G will be provided~End Date~Tag Groups~Date is Required.&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var BankAccount = typeof items[0] !== "undefined" ? items[0] : null;
          var Amount = typeof items[1] !== "undefined" ? items[1] : null;
          var RequiredFor = typeof items[2] !== "undefined" ? items[2] : null;
          var AddSuppDocs = typeof items[3] !== "undefined" ? items[3] : null;
          var IFSCCode = typeof items[4] !== "undefined" ? items[4] : null;
          var ConfirmBankDetails =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Place = typeof items[6] !== "undefined" ? items[6] : null;
          var Available80G = typeof items[7] !== "undefined" ? items[7] : null;
          var EndDate = typeof items[8] !== "undefined" ? items[8] : null;
          var TagGroup = typeof items[9] !== "undefined" ? items[9] : null;
          var DateisRequired =
            typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem("translate", LangID);

          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            bankaccount: BankAccount,
            amount: Amount,
            requiredforr: RequiredFor,
            addsupportingdocuments: AddSuppDocs,
            ifscode: IFSCCode,
            confirmbankdetails: ConfirmBankDetails,
            place: Place,
            available80G: Available80G,
            enddate: EndDate,
            taggroup: TagGroup,
            DateisRequired: DateisRequired,
            llangID: LangID,
            lID: LID,
          });

          //
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=UPI ID~Create~Tag Friends~Enter~Select~Title is Required.~Description is Required.~Purpose is Required.~Amount is Required.~Please Confirm the Bank Details~Place is Required.~Status 80G is Required.&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var UPIID = typeof items[0] !== "undefined" ? items[0] : null;
          var Create = typeof items[1] !== "undefined" ? items[1] : null;
          var TagFriends = typeof items[2] !== "undefined" ? items[2] : null;
          var Enter = typeof items[3] !== "undefined" ? items[3] : null;
          var Select = typeof items[4] !== "undefined" ? items[4] : null;
          var TitleisRequired =
            typeof items[5] !== "undefined" ? items[5] : null;
          var DescriptionisRequired =
            typeof items[6] !== "undefined" ? items[6] : null;
          var PurposeisRequired =
            typeof items[7] !== "undefined" ? items[7] : null;
          var AmountisRequired =
            typeof items[8] !== "undefined" ? items[8] : null;
          var PleaseConfirmtheBankDetails =
            typeof items[9] !== "undefined" ? items[9] : null;
          var PlaceisRequired =
            typeof items[10] !== "undefined" ? items[10] : null;
          var GRequired = typeof items[11] !== "undefined" ? items[11] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem("translate", LangID);

          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            upiid: UPIID,
            create: Create,
            tagfriends: TagFriends,
            enter: Enter,
            select: Select,
            TitleisRequired: TitleisRequired,
            DescriptionisRequired: DescriptionisRequired,
            PurposeisRequired: PurposeisRequired,
            AmountisRequired: AmountisRequired,
            PleaseConfirmtheBankDetails: PleaseConfirmtheBankDetails,
            PlaceisRequired: PlaceisRequired,
            GRequired: GRequired,

            llangID: LangID,
            lID: LID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Your Cause is on its way to bring happiness!~Privacy Policy~Include documents that support your cause~For Example: Medical Bills, Medical Certificates, School Register, Images etc~Close~No of Beneficiaries~Enter Valid IFSC Code~Enter valid bank account~Enter valid UPI~Provide Bank Details&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Causecreatedsuccessfully =
            typeof items[0] !== "undefined" ? items[0] : null;
          var PrivacyPolicy = typeof items[1] !== "undefined" ? items[1] : null;
          var IncludeDocs = typeof items[2] !== "undefined" ? items[2] : null;
          var ForEx = typeof items[3] !== "undefined" ? items[3] : null;
          var Close = typeof items[4] !== "undefined" ? items[4] : null;
          var NoOfBeneficiary =
            typeof items[5] !== "undefined" ? items[5] : null;
          var ValifIFSC = typeof items[6] !== "undefined" ? items[6] : null;
          var ValidBankAcc = typeof items[7] !== "undefined" ? items[7] : null;
          var ValidUPI = typeof items[8] !== "undefined" ? items[8] : null;
          var ProvideBankDet =
            typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem("translate", LangID);

          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            Causecreatedsuccessfully: Causecreatedsuccessfully,
            llangID: LangID,
            privacypolicy: PrivacyPolicy,
            includedocs: IncludeDocs,
            forex: ForEx,
            close: Close,
            validifsc: ValifIFSC,
            validbankacc: ValidBankAcc,
            validupi: ValidUPI,
            noofbeneficiary: NoOfBeneficiary,
            providebankdet: ProvideBankDet,
            lID: LID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=You are responsible to provide 80G~is not responsible to provide any 80G~You Agree to Provide 80G to The Donors~Confirm~Friends~Bank details are mandatory for monetary cause to get support directly from supporter to needy&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Youareresponsible =
            typeof items[0] !== "undefined" ? items[0] : null;
          var ParopakarinotResponsible =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Youagreetoprovide =
            typeof items[2] !== "undefined" ? items[2] : null;
          var Confirm = typeof items[3] !== "undefined" ? items[3] : null;
          var Friends = typeof items[4] !== "undefined" ? items[4] : null;
          var Paropakaribankpop =
            typeof items[5] !== "undefined" ? items[5] : null;
          // var bene_limit =typeof items[6] !== "undefined" ? items[6] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem("translate", LangID);

          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            youareresponsible: Youareresponsible,
            llangID: LangID,
            paropakarinotresponsible: ParopakarinotResponsible,
            youagreetoprovide: Youagreetoprovide,
            friends: Friends,
            confirm: Confirm,
            paropakaribankpop: Paropakaribankpop,
            //  bene_limit:bene_limit,
            lID: LID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Maximum  amount limit is 1 lakh.~Please, Enter Valid amount~Please Enter Valid Number of Beneficiary~Try to fill all the fields and Retry~Minimum amount should be 100~Beneficiary is required&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var AmountLessthanLakh =
            typeof items[0] !== "undefined" ? items[0] : null;
          var valid_amnt = typeof items[1] !== "undefined" ? items[1] : null;
          var valid_benef = typeof items[2] !== "undefined" ? items[2] : null;
          var all_fields = typeof items[3] !== "undefined" ? items[3] : null;
          var Minimumamt = typeof items[4] !== "undefined" ? items[4] : null;
          var Benerequire = typeof items[5] !== "undefined" ? items[5] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem("translate", LangID);

          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            AmountLessthanLakh: AmountLessthanLakh,
            valid_amnt: valid_amnt,
            valid_benef: valid_benef,
            all_fields: all_fields,
            Minimumamt: Minimumamt,
            Benerequire: Benerequire,
            lID: LID,
          });
        });
    }

    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari~Yes~No~Add~Enter the Title~Enter the Description~Enter the Account number~Enter the IFSC number~Enter the place&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Yes = typeof items[2] !== "undefined" ? items[2] : null;
          var No = typeof items[3] !== "undefined" ? items[3] : null;
          var Add = typeof items[4] !== "undefined" ? items[4] : null;
          var EnterTitle = typeof items[5] !== "undefined" ? items[5] : null;
          var EnterDesc = typeof items[6] !== "undefined" ? items[6] : null;
          var EnterAccount = typeof items[7] !== "undefined" ? items[7] : null;
          var EnterIfsc = typeof items[8] !== "undefined" ? items[8] : null;
          var EnterPlace = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
            yes: Yes,
            no: No,
            Add: Add,
            EnterTitle: EnterTitle,
            EnterDesc: EnterDesc,
            EnterAccount: EnterAccount,
            EnterIfsc: EnterIfsc,
            EnterPlace: EnterPlace,
          });

          //
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Cancel&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;

          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;

          //localStorage.setItem("GoogleTranslate123",InviteFriendsDropdown)

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";
          } else if (datatranslirate == "Kannada") {
            datatransIDs = "/en/kn";
          } else if (datatranslirate == "Tamil") {
            datatransIDs = "/en/ta";
          } else if (datatranslirate == "Telugu") {
            datatransIDs = "/en/te";
          } else if (datatranslirate == "Hindi") {
            datatransIDs = "/en/hi";
          } else if (datatranslirate == "Spanish") {
            datatransIDs = "/en/es";
          } else if (datatranslirate == MID) {
            datatransIDs = MID;
          }
          let RefreshID = 0;
          window.sessionStorage.setItem("reload", RefreshID);
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
          window.sessionStorage.setItem(
            "responsepagereload",
            reloadsmyactivity
          );
          window.sessionStorage.setItem(
            "notificationpagereload",
            reloadsmyactivity
          );
          window.sessionStorage.setItem("Indexreload", reloadsmyactivity);
          window.sessionStorage.setItem("GoogleTranslateValues", datatransIDs);

          let googletrasvalues = localStorage.setItem(
            "GoogleTranslateValues",
            datatransIDs
          );

          Cookies.remove("googtrans", { domain: ".paropakari.com" });
          Cookies.remove("googtrans", { domain: "https://paropakari.com/" });

          Cookies.remove("googtrans", {
            domain: "https://www.paropakari.com/",
          });
          let usernames = localStorage.getItem(
            "GoogleTranslateValues",
            datatransIDs
          );
          let values = datatransIDs;

          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
            const expires = "expires=" + date.toUTCString();
            document.cookie =
              cName + "=" + cValue + "; " + expires + "; path=/";
            // window.location.reload();
          }

          Cookies.set("googtrans", datatransIDs, {
            domain: "https://test.paropakari.com/#/",
          });
          Cookies.set("googtrans", datatransIDs, { domain: ".paropakari.com" });
          Cookies.set("googtrans", datatransIDs, {
            domain: "https://www.paropakari.com/",
          });
          Cookies.set("googtrans", datatransIDs, {
            domain: "https://paropakari.com/",
          });
          setCookie("googtrans", datatransIDs, 30);
          setCookie(
            "googtrans",
            datatransIDs,
            "https://test.paropakari.com/#/"
          );
          setCookie("googtrans", datatransIDs, ".paropakari.com");
          setCookie("googtrans", datatransIDs, "https://www.paropakari.com/");
          setCookie("googtrans", datatransIDs, "https://paropakari.com/");
          let cookie = Cookies.get(
            "googtrans",
            "https://test.paropakari.com/#/"
          );

          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,
          });
        });
    }
  }

  notify = () => {
    let url = "https://testapi.paropakari.com/api/GetProfile/Notification_Count";
    let data = this.state;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          notify: data,
        });

        //
      });
  };

  handleothersClik() {
    this.setState({ disable: !this.state.disable });
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("username");
    localStorage.removeItem("user");

    localStorage.clear();
    window.location.href = "#/Login";
  };
  handleGameClik() {
    this.setState({ disabled: !this.state.disabled });
  }
  // submit =() =>{
  //   let url = "https://testapi.paropakari.com/api/GetProfile/getaccdetails";
  //   let data = this.state;
  // let user = JSON.parse(localStorage.getItem('user'));
  // const accessToken = user;
  //

  //   fetch(url, {
  //           method: 'GET',
  //           headers: {
  //               "Content-type": "application/json",
  //               "Accept": "application/json",
  //               Authorization: "Bearer " + accessToken,
  //               "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
  //           },
  //           //body:JSON.stringify(data)
  //       }).then(response => response.json())
  //       .then(data => {
  //           this.setState({
  //               emps: data
  //           });
  //
  //          //
  //       });
  // }

  componentDidMount() {
    // if(sessionStorage.getItem('translate') !== null){
    //   setTimeout(() => {

    //
    //     // window.alerthello();
    //     }, 3000);

    // }
    this.prevCountRef = this.state.imageName;
    window.addEventListener("load", this.notify);
    this.notify();
    window.addEventListener("load", this.Footer);
    this.Footer();
    window.addEventListener("load", this.ReloadPage);
    this.ReloadPage();
    window.addEventListener("load", this.changetextbox);
    this.changetextbox();
    const elements = document.getElementById('goog-gt-vt');
    if (elements) {
      elements.style.display = 'none'; // Hide the element if it exists
    }
    const element = document.querySelector(".VIpgJd-ZVi9od-ORHb-OEVmcd");
    if (
      element &&
      window.getComputedStyle(element).getPropertyValue("position") === "fixed"
    ) {
      element.style.position = "absolute";
    }
  }
  disabledSubmissionDate = (submissionValue) => {
    if (!submissionValue) {
      return false;
    }
    return (
      submissionValue.valueOf() < Date.now() ||
      submissionValue.valueOf() >= moment().add(1, "month")
    );
  };
  componentWillUnmount() {
    window.removeEventListener("load", this.notify);
    this.notify();
    window.removeEventListener("load", this.Footer);
    this.Footer();
    window.removeEventListener("load", this.changetextbox);
    this.changetextbox();
    window.removeEventListener("load", this.ReloadPage);
    this.ReloadPage();

    window.removeEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
    const elements = document.getElementById('goog-gt-vt');
    if (elements) {
      elements.style.display = 'none'; // Hide the element if it exists
    }
  }

  componentDidMount(props) {
    if (sessionStorage.getItem("translate") !== null) {
      let MID = sessionStorage.getItem("translate");

      if (
        MID == "Kannada" ||
        MID == "Tamil" ||
        MID == "Telugu" ||
        MID == "Hindi" ||
        MID == "Spanish"
      ) {
        setTimeout(() => {
          // window.alerthello();
        }, 3000);
      }
    }
    window.addEventListener("load", this.ReloadPage);
    this.ReloadPage();
    window.addEventListener("load", this.notify);
    this.notify();
    let user1 = JSON.parse(localStorage.getItem("id"));
    let benuser = JSON.parse(localStorage.getItem("benname"));

    if (user1 == 3) {
      let url = "https://testapi.paropakari.com/api/GetProfile/getaccdetails";
      let data = this.state;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
        //body:JSON.stringify(data)
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            emps: data,
          });

          //
        });
    }

    window.addEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
    const element = document.getElementById('goog-gt-vt');
    if (element) {
      element.style.display = 'none'; // Hide the element if it exists
    }

    // else {

    //       document.getElementById("upload_otherRelation").disabled = 'true';

    //       document.getElementById("upload_otherRelation1").disabled = 'true';
    //     }
  }
  changetextbox = () => {
    let user1 = JSON.parse(localStorage.getItem("id"));
    if (user1 == 3) {
      let url = "https://testapi.paropakari.com/api/GetProfile/getaccdetails";
      let data = this.state;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
        //body:JSON.stringify(data)
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            emps: data,
          });

          //
        });
    }

    // else if (this.props.location.state.data.id == "2")  {

    //   document.getElementById("upload_otherRelation").disabled = '';
    //   document.getElementById("upload_otherRelation1").disabled = '';
    // }
  };

  render() {
    const { file, modal, copyright_new, paropakari_new } = this.state;
    const {
      opErr,
      npErr,
      cnpErr,
      nbErr,
      upaErr,
      upalkhErr,
      befErr,
      uplErr,
      gErr,
      reqErr,
      ifscErr,
      bankErr,
      PurErr,
      ChckErr,
      RelaErr,
      UocErr,
      CheckErr,
      relErr,
    } = this.state.formErrors;
    const currentYear = new Date().getFullYear();
    var reqid = JSON.parse(localStorage.id);
    var Category_text = JSON.parse(localStorage.CauseForID);
    var benfname = localStorage.benname;
    var causeID = JSON.parse(localStorage.id);
    var CauseForID = JSON.parse(localStorage.getItem("CauseForID"));
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
    var yyyy = today.getFullYear();
    var yyyy2 = today.getFullYear() + 1;
    var mm2 = today.getMonth() + 2; //January is 0 so need to add 1 to make it 1!

    // if(localStorage.getItem('Images') != null)
    // {
    //   var Imagepath= JSON.parse(localStorage.getItem('Images')) ;
    // }

    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    today = yyyy + "-" + mm + "-" + dd;
    var nextmonth = yyyy + "-" + mm2 + "-" + dd;
    var nextYear = yyyy2 + "-" + mm + "-" + dd;

    const {
      emps,
      upload_amount,
      category_id,
      created_date,
      delete_status,
      device_type,
      ip_address,
      upload_num_of_beneficiaries,
      upload_place,
      relation_id,
      required_by,
      upload_title,
      upload_description,
      user_id,
      upload_otherCategory,
      upload_otherRelation,
      status_80G,
      prof_BankAccount,
    } = this.state;
    const {
      howitworks,
      blog,
      contactus,
      termsandcondition,
      causes,
      beneficiaries,
      paropakaris,
      amountraised,
      copyright,
      beaparopakari,
      myjourney,
      createacause,
      invite,
      profile,
      changepassword,
      group,
      Add,
      logout,
      others,
      requiredforr,
      familyfriends,
      self,
      submit,
      beneficiaryname,
      friends,
      createyourcause,
      title,
      description,
      categories,
      yes,
      no,
      bankaccount,
      enter,
      select,
      includedocs,
      forex,
      close,
      amount,
      addsupportingdocuments,
      tagfriends,
      EnterTitle,
      EnterDesc,
      EnterAccount,
      EnterIfsc,
      EnterPlace,
      ifscode,
      confirmbankdetails,
      validifsc,
      validbankacc,
      validupi,
      providebankdet,
      education,
      foodandnutrition,
      health,
      professional,
      sports,
      socialwefare,
      paropakarinotresponsible,
      youareresponsible,
      AmountLessthanLakh,
      confirm,
      youagreetoprovide,
      place,
      available80G,
      enddate,
      taggroup,
      upiid,
      create,
      privacypolicy,
      required_for,
      paropakaribankpop,
      langID,
      lID,
      TitleisRequired,
      DescriptionisRequired,
      PurposeisRequired,
      noofbeneficiary,
      AmountisRequired,
      PleaseConfirmtheBankDetails,
      PlaceisRequired,
      GRequired,
      Benerequire,
      DateisRequired,
      Causecreatedsuccessfully,
    } = this.state;

    const { notify } = this.state;
    let { foo, Menu } = this.state;
    var username = JSON.parse(localStorage.username);
    var user1 = JSON.parse(localStorage.getItem("id"));

    if (this.state.modal) {
      return (
        <div
          style={{
            position: "fixed",
            inset: "50% auto auto 50%",
            border: "none",
            background: "rgb(174, 230, 219)",
            overflow: "auto",
            borderRadius: "20px",
            outline: "none",
            padding: "20px",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            height: "200px",
            width: "350px",
          }}
        >
          <div className="modal-header">
            <button type="button" className="close" onClick={this.toggleModal}>
              ×
            </button>
          </div>
          <p className="modaltext">{youagreetoprovide.result}</p>
          {/* <p className="modaltext">You Agree to Provide 80G to The Donors</p> */}
          <div className="modalconfirmbtn">
            {/* {confirm.result} */}
            <button
              className="button-confirm"
              id="confirm80g"
              onClick={this.toggleConfirm}
            >
              {yes.result}
            </button>
            <button
              className="button-confirm"
              id="no80g"
              onClick={this.toggleModal}
            >
              {no.result}
            </button>
          </div>
        </div>
      );
    }
    if (this.state.loading) {
      return (
        <center className="notranslate">
          <img
            className="loading-img2 notranslate"
            src="https://testapi.paropakari.com/images/Paropakari.gif"
          />
        </center>
      );
    } else {
      return (
        <div
          className="VIpgJd-ZVi9od-ORHb-OEVmcd"
          style={{ position: "absolute" }}
        >
          <div
            style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}
            className="notranslate"
          >
            {/* <meta http-equiv="refresh" content="10"/>   */}
            <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
            <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
            <link href="./assets/css/lightbox.css" rel="stylesheet" />
            <link href="./assets/css/animate.min.css" rel="stylesheet" />
            <link href="./assets/css/main.css" rel="stylesheet" />
            {/*[if lt IE 9]>
              
              
          <![endif]*/}
            <link rel="shortcut icon" href="images/ico/favicon.ico" />
            <link
              rel="apple-touch-icon-precomposed"
              sizes="144x144"
              href="images/ico/apple-touch-icon-144-precomposed.png"
            />
            <link
              rel="apple-touch-icon-precomposed"
              sizes="114x114"
              href="images/ico/apple-touch-icon-114-precomposed.png"
            />
            <link
              rel="apple-touch-icon-precomposed"
              sizes="72x72"
              href="images/ico/apple-touch-icon-72-precomposed.png"
            />
            <link
              rel="apple-touch-icon-precomposed"
              href="images/ico/apple-touch-icon-57-precomposed.png"
            />
            {/*/head*/}
            {/* <div onClick={refreshPage}> */}
            <select
              className="form-control"
              style={{
                display: "inline",
                width: "10%",
                marginTop: "0",
                marginLeft: "0",
                float: "right",
                width: "fit-content",
              }}
              value={this.state.value}
              onChange={this.handleChanges}
            >
              <option value="English">Languages</option>
              <option value="Kannada">Kannada</option>
              <option value="Tamil">Tamil</option>
              <option value="Hindi">Hindi</option>
              <option value="Telugu">Telugu</option>
               {/* <option value="Spanish">Spanish</option> */}
              <option value="English" onClick={window["checkboxClickHandler"]}>
                English
              </option>
            </select>
            {/* </div> */}
            &nbsp;&nbsp; &nbsp;&nbsp;
            <div>
              <div className="mag notranslate">
                <div class="pro-home-nav">
                  <input
                    type="checkbox"
                    id="pro-home-nav-check"
                    onClick={this.checkfun}
                  />
                  <div class="pro-home-nav-header">
                    <div class="pro-home-nav-title notranslate">
                      <a className="navbar-brand" href="#/HomePage">
                        <h1 className="navbar-new-text">
                          <img
                            src="./assets/images/plogo.png"
                            alt="logo"
                            style={{
                              marginBottom: "17px",
                              width: "26%",
                              fontsize: "300px",
                            }}
                          />
                          AROPAKARI
                        </h1>
                      </a>
                    </div>
                  </div>

                  <div class="pro-home-nav-btn">
                    <label for="pro-home-nav-check">
                      <div className="pro-naav">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </label>
                  </div>

                  {langID == "English" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p class="pro-center">
                          <a className="new-design" href="#/CauseFor">
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-boxx1 parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/InviteFriend">{invite.result}</a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontsize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a>
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown" id="dropdownbox">
                              <li className="hower-text">
                                <a href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <LogoutLink logoutResult={logout.result} />
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : langID == "Hindi" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p class="pro-center">
                          <a className="new-design" href="#/CauseFor">
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-boxx parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/InviteFriend">{invite.result}</a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontsize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a>
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown">
                              <li className="hower-text">
                                <a href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <LogoutLink logoutResult={logout.result} />
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : langID == "Spanish" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p class="pro-center">
                          <a className="new-design" href="#/CauseFor">
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-boxx1 parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/InviteFriend">{invite.result}</a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontsize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a>
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown" id="dropdownbox">
                              <li className="hower-text">
                                <a href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <LogoutLink logoutResult={logout.result} />
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : langID == "Tamil" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p class="pro-center">
                          <a className="new-tamil-design" href="#/CauseFor">
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/InviteFriend">{invite.result}</a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontsize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a>
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown" style={{ height: "158px" }}>
                              <li className="hower-text">
                                <a href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <LogoutLink logoutResult={logout.result} />
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : langID == "Kannada" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p class="pro-center">
                          <a className="new-telugu-design" href="#/CauseFor">
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/InviteFriend">{invite.result}</a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontsize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a>
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown">
                              <li className="hower-text">
                                <a href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <LogoutLink logoutResult={logout.result} />
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : langID == "Telugu" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p class="pro-center">
                          <a className="new-telugu-design" href="#/CauseFor">
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/InviteFriend">{invite.result}</a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontsize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a>
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown" id="dropdownbox">
                              <li className="hower-text">
                                <a href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <LogoutLink logoutResult={logout.result} />
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>

              {/* <header id="header" style={{backgroundImage: "url(" + "./assets/images/10.jpg" + ")"}}>      
              <div className="container">
                <div className="row">
                
                </div>
              </div>
              <div className="navbar navbar-inverse" role="banner">
                <div className="container">
                  <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                   <a className="navbar-brand" href="#/HomePage">
                   <h1 style={{color:'blue',fontFamily:'Biysk-Regular'}}><img src="./assets/images/plogo.png" alt="logo" style={{marginBottom:'17px',width:'26%',fontsize:'52px'}}  />AROPAKARI</h1>
          </a>
          
                  </div>
                  
                  <div className="collapse navbar-collapse">
                  <ul className="nav navbar-nav navbar-right">
                    
                  {(() => {if (langID == 'Tamil' ) {return (
       <li id="paro" className="active"><a className="btn1 third" href="#/CauseFor" target="_blank" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',marginRight:'40px',marginLeft:'-274px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>
           
       {beaparopakari.result}
       </a></li>
);
}
                 else {return(

                  <li id="paro" className="active"><a className="btn1 third" href="#/CauseFor" target="_blank" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',marginRight:'100px',marginLeft:'-274px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>
           
           {beaparopakari.result}
           </a></li>

);}

})()}    
        
                    
                                                 
                            
                                   <li className=""><a href="#/MyActivity" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{myjourney.result}</a></li>     
                                   <li className="active"><a href="#/CauseFor" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{createacause.result}</a></li>     
                                   <li className=""><a href="#/InviteFriend" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{invite.result}</a></li>    
                                            
                                   <li className=""><a href="#/Notification" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>
                            
                            <div class="fa fa-bell" style={{fontsize:'24px'}}>{notify.map((notifyy) => (
                                                 <span class="-count"> 
                                  
                                
                                  {notifyy.noticount}
                                 
                                    </span> ))}</div>
                             </a></li>               
                                    <li class="dropdown"><a><b>{username}</b> <i class="fa fa-angle-down"></i></a>
                                     <ul role="menu" className="sub-menu">
                                       <li><a href="#/Profile">{profile.result}</a></li>
                                       <li><a href="#/ChangePassword">{changepassword.result}</a></li>
                                       <li><a href="#/Group">{group.result}</a></li>
                                       <li><a href="#/Login">{logout.result}</a></li>
                                      
                                     </ul>
                                   </li>    
           
                                         
                                 </ul>
                  </div>
                  
                </div>
              </div>
            </header> */}

              {/*/#header*/}

              {/* <div className="nav" >
  <input type="checkbox" id="nav-check" />
  <div className="nav-header">
    <div className="nav-title">
    <h1 style={{color:'blue',fontFamily:'Biysk-Regular',fontsize:'52px'}}><img src="./assets/images/plogo.png" alt="logo"
     style={{marginBottom:'17px',width:'23%',fontsize:'60px'}}  />AROPAKARI</h1>



    </div>
    
  </div>
 
  <div className="nav-btn">
    <label htmlFor="nav-check">
      <span />
      <span />
      <span />
    </label>
  </div>
  <div className="bea">
  <a class="btn1 third" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',
  marginRight:'552px ',marginLeft:'539px',fontSize:20,marginTop:'-82px',marginBottom:'-37px',textDecoration:'none',
  whiteSpace:'nowrap',fontFamily:'cuyabra',width:'281px'}}>
       
       Be a Paropakari
       </a>
</div>
  <div className="nav-links" >
    
  <a href="#/MyActivity" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>My Journey</a>
  <a href="#/Create" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>Create a Cause</a>
  <a href="#/InviteFriend" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>Invite</a>
                                         
  <a href="#/Notification" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>
                                     
                                     <div class="fa fa-bell" style={{fontsize:'24px'}}>{notify.map((notifyy) => (
                                                          <span class="-count"> 
                                           
                                         
                                           {notifyy.notificationCount}
                                          
                                             </span> ))}</div>
                                      </a>

                                             <li className="dropdown">  Heloo<i className="fa fa-angle-down" />
                                     <ul role="menu" className="sub-menu">
                                       <li><a href="#/Profile">My Profile</a></li>
                                       <li><a href="#/ChangePassword">Change Password</a></li>
                                       <li><a href="#/Group">Group</a></li>
                                       <li><a href="#/Login">Logout</a></li>
                                      
                                     </ul>
                                   </li>   
                                   </div>
                                   </div>   */}
              {/*/#page-breadcrumb*/}
              <section
                id="about-company"
                className={
                  this.state.movedown == true
                    ? "wow fadeInUp"
                    : "wow fadeInUp Tiptop"
                }
                data-wow-duration="400ms"
                data-wow-delay="400ms"
                style={{ backgroundColor: "#aee6db" }}
              >
                {/* Page Content */}
                <div className="create-container">
                  {/* Page Content */}

                  <div className="create-container2">
                    <button
                      id="EnglishButton"
                      onClick={window["checkboxClickHandler"]}
                      style={{ display: "none" }}
                    >
                      click
                    </button>

                    <h3 style={{ marginLeft: "17px" }}>
                      <b>{createyourcause.result}</b>
                    </h3>

                    <br />
                    <div>
                      {(() => {
                        if (Category_text == "0") {
                          return (
                            <>
                              {this.state.loading ? (
                                <center>
                                  <img
                                    className="loading-img2"
                                    src="https://testapi.paropakari.com/images/Paropakari.gif"
                                  />
                                </center>
                              ) : null}
                              <form
                                id="causeform"
                                autoComplete="off"
                                onSubmit={this.handleSubmit}
                              >
                                <div className="row" style={{ marginLeft: 3 }}>
                                  <input
                                    type="hidden"
                                    value={langID}
                                    id="lang_id"
                                    onChange={this.handleChange}
                                    name="langid"
                                  />
                                  <input
                                    type="hidden"
                                    value={lID}
                                    id="l_id"
                                    onChange={this.handleChange}
                                    name="langid"
                                  />
                                  <input
                                    type="hidden"
                                    value={CauseForID}
                                    id="Causefor_Id"
                                    onChange={this.handleChange}
                                    name="Causefor_Id"
                                  />

                                  <div
                                    className="col-lg-4" /* style={{maxWidth=""}} */
                                  >
                                    <label className htmlFor="upload_title">
                                      {title.result}
                                      <b style={{ color: "red" }}> *</b>
                                    </label>
                                    {/* {(() => {if (langID == 'English' ) {return (
                                  <label onClick={window['checkboxClickHandler']} style={{color:'red'}}> Click to set Default Language English </label>  
                                  );
                                  }               
                                  })()}   */}
                                    <input
                                      className="form-control"
                                      id="upload_title"
                                      maxLength={100}
                                      minLength={3}
                                      name="upload_title"
                                      value={this.state.upload_title}
                                      onChange={this.handleChange}
                                      placeholder={enter.result}
                                      size={100}
                                      type="text"
                                    />
                                    <div style={{}}>
                                      <span style={{ color: "red" }}>
                                        {" "}
                                        {` ${
                                          opErr
                                            ? TitleisRequired.result
                                              ? TitleisRequired.result
                                              : opErr
                                            : ""
                                        }`}
                                      </span>{" "}
                                    </div>
                                    <br />
                                    <label
                                      className
                                      htmlFor="upload_description"
                                    >
                                      {description.result}
                                      <b style={{ color: "red" }}> *</b>
                                    </label>
                                    <textarea
                                      className="form-control"
                                      cols={6}
                                      value={this.state.upload_description}
                                      onChange={this.handleChange}
                                      minLength={3}
                                      id="upload_description"
                                      name="upload_description"
                                      placeholder={enter.result}
                                      rows={6}
                                      style={{ height: "130px" }}
                                    />
                                    {/* <div style={{  }}>
                                    {npErr && <span style={{ color: "red" }}>{DescriptionisRequired.result ? DescriptionisRequired.result : npErr}</span>}
                                  </div> */}
                                    <div style={{}}>
                                      <span style={{ color: "red" }}>
                                        {" "}
                                        {` ${
                                          npErr
                                            ? DescriptionisRequired.result
                                              ? DescriptionisRequired.result
                                              : npErr
                                            : ""
                                        }`}
                                      </span>{" "}
                                    </div>
                                    <div>
                                      <label
                                        className
                                        htmlFor="category_id"
                                        style={{ marginTop: 19 }}
                                      >
                                        {bankaccount.result}
                                        <b style={{ color: "red" }}> *</b>
                                        <a
                                          href="#?id=collapseTwo"
                                          data-toggle="modal"
                                          data-target="#fileModal2"
                                          style={{
                                            textDecoration: "none",
                                            lineHeight: "1.2",
                                            marginTop: 300,
                                            fontSize: "1.5rem",
                                            color: "black",
                                          }}
                                        >
                                          &nbsp;&nbsp;<b>&#9432;</b>
                                          &nbsp;&nbsp;&nbsp;
                                        </a>
                                      </label>
                                      {(() => {
                                        if (user1 == 2 || user1 == 1) {
                                          return (
                                            <div>
                                              <input
                                                type="text"
                                                name="Bank_acc"
                                                id="Bank_acc"
                                                value-={this.state.Bank_acc}
                                                onChange={this.handleChange}
                                                maxLength={18}
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/[0-9]/.test(event.key)
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                className="form-control"
                                                placeholder={enter.result}
                                              />
                                              <div
                                                style={{ color: "red" }}
                                                id="bankalert"
                                              >
                                                {bankErr && (
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    {"Bank account required"
                                                      ? "Bank account required"
                                                      : bankErr}
                                                  </span>
                                                )}

                                                {bankErr && (
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    {validbankacc.result
                                                      ? validbankacc.result
                                                      : bankErr}
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div>
                                              {emps.map((emp) => (
                                               
                                                <input
                                                  type="text"
                                                  name="Bank_acc"
                                                  maxLength={18}
                                                  id="Bank_acc"
                                                  onChange={this.handleChange}
                                               
                                                
                                                  onKeyPress={(event) => {
                                                    if (
                                                      !/[0-9]/.test(event.key)
                                                    ) {
                                                      event.preventDefault();
                                                    }
                                                  }}
                                                  className="form-control"
                                                  defaultValue={
                                                    emp.prof_BankAccount
                                                  }
                                                  placeholder={enter.result}
                                                />
                                                
                                              ))}
                                              <div
                                                style={{ color: "red" }}
                                                id="bankalert"
                                              ></div>
                                            </div>
                                          );
                                        }
                                      })()}
                                    </div>
                                    <br />
                                    <label className>
                                      {addsupportingdocuments.result} &nbsp;{" "}
                                      <a
                                        href="#?id=collapseTwo"
                                        data-toggle="modal"
                                        data-target="#fileModal"
                                        style={{
                                          textDecoration: "none",
                                          lineHeight: "1.2",
                                          marginTop: 300,
                                          fontSize: "1.5rem",
                                          color: "black",
                                        }}
                                      >
                                        &nbsp;&nbsp;<b>&#9432;</b>
                                        &nbsp;&nbsp;&nbsp;
                                      </a>
                                    </label>
                                    {/* <MultiImage /> */}

                                    {/* <div className="App"> */}

                                    <div>
                                      {/* <tr><td><input type="text" label="Title" margin="normal" name="Title" value={this.state.Title} id="Title" size="small" onChange={this.TitleValue} variant="outlined" ></input></td></tr>
                       <tr><td><input type="text" label="Description" margin="normal" id="Description" size="small" variant="outlined" onChange={this.DespValue}></input></td></tr> */}

                                      <label className="form-control">
                                        + {Add.result}
                                        <input
                                          type="file"
                                          onChange={this.imageHandleChange.bind(
                                            this
                                          )}
                                          className="vg"
                                          multiple
                                          style={{ width: "0%" }}
                                          accept="image/png , image/jpeg ,image/jpg , image/webp, .doc, .docx, .xls, .xlsx, .pdf, video/*"
                                        />
                                      </label>
                                      <div
                                        className="multiimages"
                                        style={{
                                          overflowY: "scroll",
                                          maxHeight: "100px",
                                        }}
                                      >
                                        {this.state.imageName &&
                                          this.state.imageName.map(
                                            (image, index) => {
                                              return (
                                                <div key={index}>
                                                  <img src={image} />
                                                  <p>
                                                    {image.name}
                                                    <img
                                                      onClick={() =>
                                                        this.deleteimg(index)
                                                      }
                                                      style={{ width: "4%" }}
                                                      src="./assets/images/delete.png"
                                                    />
                                                  </p>
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>
                                      <br />
                                    </div>

                                    {/* </div> */}
                                    {/* <button
                                       type="button"
                                       className="btn btn-primary btn-block"
                                       onClick={upload}
                                       >
                                       Upload
                                       </button> */}

                                    {/* <div className=" preview" style={{ overflowY: 'scroll',  }}>
                                    {file.length > 0 &&
                                      file.map((item, index) => {
                                        return (
                                          <div key={item}>
                                         
                                            <p>{item}<button type="button" onClick={() => this.deleteFile(index)}>
                                              x
                                            </button></p>

                                          </div>
                                        );
                                      })}
                                  </div> */}

                                    {/* <input type="file" id="file_name"   onChange={this.fileChangedHandler} name="file_name" style={{ opacity:'1',color:'rgb(133,130,130);' }}
                    className="form-control" accept=".x-png,.gif,.jpeg,.jpg,.png,.pdf,.mp4,.mkv,.avi" multiple="multiple" />  */}

                                    {/* <input type="file" name="uploadfile"  id="file_name" accept="image/*"  name="file_name" className="form-control" multiple="multiple" onChange={this.ImagePreview} style={{display:'none'}}/>
                  <label for="file_name" className="form-control" style={{backgroundColor:'white',fontFamily:'cuyabra',width:'97%'}}><i style={{fontsize:'24px'}} class="fa">&#xf067;</i>  &nbsp;Add {this.state.ImageDisplay && (
                  <div style={{ textAlign: "left" }}>
                  <button className="remove" style={{display:'none'}} onClick={this.resetFile}><CancelIcon className="icon-x"></CancelIcon></button>
                  </div>
                  )}
                  <img id="groupimage" className="card-img2" 
                                       style={{width:"50%",height:"50%",float:'right',marginTop:'-15px'}} 
                                       src={this.state.ImageDisplay}  /></label> */}

                                    {/* {emps.map(emp=> */}

                                    {/* )} */}
                                  </div>

                                  <div className="col-lg-4">
                                    {/* <div className="row">
                                      <div className="col-lg-6">
                                      </div>
                                      <div className="col-lg-6">
                                      </div>
                                     </div> */}

                                    <div className="row">
                                      <div className="col-lg-6 col-sm-6  ">
                                        <label
                                          className
                                          htmlFor="upload_amount"
                                        >
                                          {amount.result}
                                          <b style={{ color: "red" }}> *</b>
                                          <a
                                            href="#?id=collapseTwo"
                                            data-toggle="modal"
                                            data-target="#fileModal1"
                                            style={{
                                              textDecoration: "none",
                                              lineHeight: "1.2",
                                              marginTop: 300,
                                              fontSize: "1.5rem",
                                              color: "black",
                                            }}
                                          >
                                            &nbsp;&nbsp;<b>&#9432;</b>
                                            &nbsp;&nbsp;&nbsp;
                                          </a>
                                        </label>
                                        <input
                                          className="form-control"
                                          value={this.state.upload_amount}
                                          onChange={this.handleChange}
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          id="upload_amount"
                                          name="upload_amount"
                                          min="100"
                                          placeholder="Rs"
                                          maxLength={6}
                                          type="text"
                                        />

                                        <div style={{}}>
                                          {upaErr && (
                                            <span style={{ color: "red" }}>
                                              {AmountisRequired.result
                                                ? AmountisRequired.result
                                                : upaErr}
                                            </span>
                                          )}
                                          {upalkhErr && (
                                            <span style={{ color: "red" }}>
                                              {AmountLessthanLakh.result
                                                ? AmountLessthanLakh.result
                                                : upalkhErr}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-sm-6">
                                        {(() => {
                                          if (langID == "Tamil") {
                                            return (
                                              <label
                                                className
                                                htmlFor="upload_num_of_beneficiaries"
                                                style={{ fontsize: 10 }}
                                              >
                                                {noofbeneficiary.result}
                                                <b style={{ color: "red" }}>
                                                  {" "}
                                                  *
                                                </b>
                                              </label>
                                            );
                                          } else {
                                            return (
                                              <label
                                                className
                                                htmlFor="upload_num_of_beneficiaries"
                                              >
                                                {noofbeneficiary.result}
                                                <b style={{ color: "red" }}>
                                                  {" "}
                                                  *
                                                </b>
                                              </label>
                                            );
                                          }
                                        })()}

                                        {(() => {
                                          if (causeID == "3") {
                                            return (
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder={enter.result}
                                                // value={this.state.upload_num_of_beneficiaries}
                                                value="1"
                                                onChange={this.handleChange}
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/[0-9]/.test(event.key)
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                id="upload_num_of_beneficiaries"
                                                readOnly="readonly"
                                                name="upload_num_of_beneficiaries"
                                                minLength={1}
                                                maxLength={7}
                                              />
                                            );
                                          } else {
                                            return (
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder={enter.result}
                                                value={
                                                  this.state
                                                    .upload_num_of_beneficiaries
                                                }
                                                onChange={this.handleChange}
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/[0-9]/.test(event.key)
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                id="upload_num_of_beneficiaries"
                                                name="upload_num_of_beneficiaries"
                                                minLength={1}
                                                maxLength={7}
                                              />
                                            );
                                          }
                                        })()}

                                        {/* <input type="text" className="form-control" placeholder="No of Beneficiaries"
                                           value="100"   
                                           onChange={this.handleChange}
                                           id="upload_num_of_beneficiaries" style={{width: '50%', marginTop: '0px', marginLeft: 175}}
                                           name="upload_num_of_beneficiaries" min={1} max={9999999} minLength={1} maxLength={7} /> */}

                                        <div style={{}}>
                                          {nbErr && (
                                            <span style={{ color: "red" }}>
                                              {Benerequire.result
                                                ? Benerequire.result
                                                : nbErr}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    {/* <label className htmlFor="upload_amount">{amount.result}<b style={{color:'red'}}> *</b></label>
     
          {(() => {if(langID == 'Tamil' ) {return (
              <label className htmlFor="upload_num_of_beneficiaries" style={{marginLeft: '212px',fontsize:10}}>{noofbeneficiary.result}<b style={{color:'red'}}> *</b></label>
            );}
                   else {return(
                    <label className htmlFor="upload_num_of_beneficiaries" style={{marginLeft: '178px'}}>{noofbeneficiary.result}<b style={{color:'red'}}> *</b></label>
            );}
            })()}        
        
                <input className="form-control" 
          value={this.state.upload_amount}    
          onChange={this.handleChange}
          id="upload_amount" name="upload_amount" min="0" placeholder="Rs" style={{width: '48%'}} type="number" />
          <div style={{ marginLeft: "7%"}}>
                {upaErr && <span style={{color:"red"}}>{ AmountisRequired.result ? AmountisRequired.result:upaErr}</span>} 
                </div>  
              <input type="number" className="form-control" placeholder={enter.result}
           value={this.state.upload_num_of_beneficiaries}    
           onChange={this.handleChange}
           id="upload_num_of_beneficiaries" style={{width: '50%', marginTop: '-41px', marginLeft: 225}}
             name="upload_num_of_beneficiaries" min={1} max={9999999} minLength={1} maxLength={7} />
               
                    
                    <div style={{ marginLeft: "7%"}}>
                {nbErr && <span style={{color:"red"}}>{ noofbeneficiary.result ? noofbeneficiary.result:nbErr}</span>} 
                </div>  
                       */}

                                    <label
                                      className
                                      htmlFor="relation_id"
                                      style={{ marginTop: 22 }}
                                    >
                                      {requiredforr.result}
                                    </label>
                                    <b style={{ color: "red" }}> *</b>
                                    {/* <select className="form-control"  id="relation_id" 
                value={this.state.relation_id}     onChange={this.handleChange}
              
                name="relation_id" >
                <option value>Select</option>
                  <option onClick={this.submit} value={3}>Self</option>
                  <option value={2}>Family/Friends</option>
                  <option value={1}
                  
                  >Others</option>
                </select> */}

                                    <input
                                      type="hidden"
                                      name="relation_id"
                                      defaultValue={reqid}
                                    />
                                    {(() => {
                                      if (user1 == 2 || user1 == 1) {
                                        return (
                                          <div>
                                            <input
                                              type="text"
                                              name="benfname"
                                              id="benfname"
                                              style={{ width: "100%" }}
                                              readOnly="readonly"
                                              placeholder="Specify Here"
                                              className="form-control"
                                              defaultValue={JSON.parse(
                                                benfname
                                              )}
                                            />
                                            <input
                                              type="hidden"
                                              id="required_for"
                                              name="required_for"
                                              defaultValue={JSON.parse(
                                                benfname
                                              )}
                                            />
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <div>
                                            {emps.map(
                                              (emp) => (
                                                <input
                                                  type="text"
                                                  name="required_for"
                                                  id="required_for"
                                                  style={{ width: "100%" }}
                                                  placeholder="Please Specify here"
                                                  readOnly="readonly"
                                                  className="form-control"
                                                  value={emp.profile_name}
                                                />
                                              )

                                              // <input type="hidden" id="required_for" name="required_for" defaultValue={emp.profile_name}/>
                                            )}
                                          </div>
                                        );
                                      }
                                    })()}

                                    <input
                                      type="hidden"
                                      id="upload_otherRelation"
                                      defaultValue="None"
                                      name="upload_otherRelation"
                                      placeholder="Enter Other Required For"
                                      className="form-control"
                                    />
                                    <br />

                                    <label
                                      className
                                      htmlFor="category_id"
                                      style={{ marginTop: 0 }}
                                    >
                                      {categories.result}
                                      <b style={{ color: "red" }}> *</b>
                                    </label>
                                    <select
                                      className="form-control"
                                      style={{ width: "100%" }}
                                      value={this.state.category_id}
                                      onChange={this.handleChange}
                                      id="category_id"
                                      name="category_id"
                                    >
                                      <option value>{select.result}</option>
                                      <option value={8}>
                                        {education.result}
                                      </option>
                                      <option value={7}>
                                        {foodandnutrition.result}
                                      </option>
                                      <option value={6}>{health.result}</option>
                                      <option value={5}>
                                        {professional.result}
                                      </option>
                                      <option value={4}>
                                        {socialwefare.result}
                                      </option>
                                      <option value={3}>{sports.result}</option>
                                      <option value={2}>{others.result}</option>
                                    </select>

                                    <input
                                      type="hidden"
                                      id="upload_otherCategory"
                                      name="upload_otherCategory"
                                      placeholder="Enter Other Category"
                                      defaultValue="None"
                                      className="form-control"
                                    />
                                    <div style={{}}>
                                      {PurErr && (
                                        <span style={{ color: "red" }}>
                                          {PurposeisRequired.result
                                            ? PurposeisRequired.result
                                            : PurErr}
                                        </span>
                                      )}
                                    </div>

                                    <br />

                                    <label
                                      className
                                      htmlFor="category_id"
                                      style={{ marginTop: 0 }}
                                    >
                                      {ifscode.result}
                                      <b style={{ color: "red" }}> *</b>
                                    </label>

                                    {(() => {
                                      if (user1 == 2 || user1 == 1) {
                                        return (
                                          <div>
                                            <input
                                              type="text"
                                              name="ifsc"
                                              id="ifsc"
                                              maxLength={11}
                                              value={this.state.ifsc}
                                              onChange={this.handleChange}
                                              style={{ width: "100%" }}
                                              className="form-control"
                                              placeholder={enter.result}
                                            />
                                            <div
                                              style={{ color: "red" }}
                                              id="ifscalert"
                                            >
                                              {ifscErr && (
                                                <span style={{ color: "red" }}>
                                                  {"IFSC required"
                                                    ? "IFSC required"
                                                    : ifscErr}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <div>
                                            {emps.map((emp) => (
                                              <input
                                                type="text"
                                                id="ifsc"
                                                onChange={this.handleChange}
                                                name="ifsc"
                                                maxLength={11}
                                                style={{ width: "100%" }}
                                                className="form-control"
                                                defaultValue={emp.prof_IFSC}
                                                placeholder={enter.result}
                                              />
                                            ))}
                                            <div
                                              style={{ color: "red" }}
                                              id="ifscalert"
                                            ></div>
                                          </div>
                                        );
                                      }
                                    })()}

                                    <br />

                                    <label>{tagfriends.result}</label>

                                    <Dropdowns
                                      Translated={
                                        select.result ? select.result : "select"
                                      }
                                    ></Dropdowns>

                                    {/* Dhinesh3 */}
                                    {this.state.confirmmodal ? (
                                      <div
                                        id="modalRegisterForm"
                                        tabindex="-1"
                                        role="dialog"
                                        style={{
                                          position: "fixed",
                                          width: "100%",
                                          height: "100%",
                                          zIndex: 1000,
                                          top: 0,
                                          left: 0,
                                          inset: "0px",
                                          backgroundColor:
                                            "rgba(255, 255, 255, 0.75)",
                                        }}
                                      >
                                        <div
                                          style={{
                                            position: "fixed",
                                            inset: "50% auto auto 50%",
                                            border: "none",
                                            backgroundColor:
                                              "rgb(174, 230, 219)",
                                            overflow: "auto",
                                            borderRadius: "20px",
                                            outline: "none",
                                            padding: "20px",
                                            marginRight: "-50%",
                                            transform: "translate(-50%, -50%)",
                                            height: "200px",
                                            width: "350px",
                                          }}
                                        >
                                          <div className="modal-header">
                                            <button
                                              type="button"
                                              className="close"
                                              onClick={this.toggleModal}
                                            >
                                              ×
                                            </button>
                                          </div>
                                          <p className="modaltext">
                                            {youagreetoprovide.result}
                                          </p>
                                          {/* <p className="modaltext">You Agree to Provide 80G to The Donors</p> */}
                                          <div className="modalconfirmbtn">
                                            {/* {confirm.result} */}
                                            <button
                                              className="button-confirm"
                                              id="confirm80g"
                                              onClick={this.confirmtoggleModal1}
                                            >
                                              {yes.result}
                                            </button>
                                            <button
                                              className="button-confirm"
                                              id="no80g"
                                              onClick={this.confirmtoggleModal}
                                            >
                                              {no.result}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

                                    {/* <input type="checkbox" required name="checkbox"/> Confirm Bank Details
            <div style={{ marginLeft: "7%"}}>
                {CheckErr && <span style={{color:"red"}}>{CheckErr}</span>} 
                </div>  */}

                                    {/* <label>
                                    <input type="checkbox" name="q1b" /> {confirmbankdetails.result}<b style={{ color: 'red' }}> *</b>
                                  </label>

                                  <div style={{ marginLeft: "-1%",display:"contents" }}>
                                    {ChckErr && <span style={{ color: "red" }}>{PleaseConfirmtheBankDetails.result ? PleaseConfirmtheBankDetails.result : ChckErr}</span>}

                                  </div> */}
                                  </div>

                                  <div className="col-lg-4">
                                    <input
                                      id="user_id"
                                      name="user_id"
                                      type="hidden"
                                      Value
                                    />
                                    <div
                                      className="form-group"
                                      style={{ marginTop: "-20px" }}
                                    >
                                      <br />
                                    </div>
                                    <div
                                      className="form-group"
                                      style={{
                                        marginTop: "-40px",
                                        paddingBottom: "5px",
                                      }}
                                    >
                                      <br />
                                      <label className htmlFor="upload_place">
                                        {place.result}
                                        <b style={{ color: "red" }}> *</b>
                                      </label>
                                      <input
                                        className="form-control"
                                        id="upload_place"
                                        maxLength={45}
                                        value={this.state.upload_place}
                                        minLength={3}
                                        onChange={this.handleChange}
                                        name="upload_place"
                                        placeholder={enter.result}
                                        type="text"
                                      />
                                      <div style={{}}>
                                        {uplErr && (
                                          <span style={{ color: "red" }}>
                                            {PlaceisRequired.result
                                              ? PlaceisRequired.result
                                              : uplErr}
                                          </span>
                                        )}
                                      </div>{" "}
                                      <br />
                                      <div>
                                        <label class="" For="status_80G">
                                          {available80G.result}{" "}
                                          <b style={{ color: "red" }}> *</b>
                                          <a
                                            href="#?id=collapseTwo"
                                            data-toggle="modal"
                                            data-target="#fileModal1"
                                            style={{
                                              textDecoration: "none",
                                              lineHeight: "1.2",
                                              marginTop: 300,
                                              fontSize: "1.5rem",
                                              color: "black",
                                            }}
                                          >
                                            &nbsp;&nbsp;<b>&#9432;</b>
                                            &nbsp;&nbsp;&nbsp;
                                          </a>
                                        </label>
                                        <select
                                          id="status_80G"
                                          name="status_80G"
                                          value={this.state.status_80G}
                                          onChange={this.handleChange}
                                          className="form-control"
                                        >
                                          <option value="">
                                            {select.result}
                                          </option>
                                          <option value={0}>{no.result}</option>
                                          <option value={1}>
                                            {yes.result}
                                          </option>
                                        </select>

                                        <div style={{}}>
                                          {gErr && (
                                            <span style={{ color: "red" }}>
                                              {GRequired.result
                                                ? GRequired.result
                                                : gErr}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                      <br />
                                      <label
                                        className
                                        htmlFor="required_by"
                                        style={{ marginTop: "2px" }}
                                      >
                                        {enddate.result}
                                        <b style={{ color: "red" }}> *</b>
                                      </label>
                                      <input
                                        type="date"
                                        className="form-control"
                                        id="required_by"
                                        name="required_by"
                                        style={{
                                          maxWidth: "100%",
                                        }}
                                        onChange={this.handleChange}
                                        value={this.state.required_by}
                                        min={today}
                                        max={nextYear}
                                      />
                                      <div style={{}}>
                                        {reqErr && (
                                          <span style={{ color: "red" }}>
                                            {DateisRequired.result
                                              ? DateisRequired.result
                                              : reqErr}
                                          </span>
                                        )}
                                      </div>{" "}
                                      <br />
                                      <div>
                                        <label
                                          className
                                          htmlFor="category_id"
                                          style={{ marginTop: 0 }}
                                        >
                                          {upiid.result}
                                        </label>

                                        {(() => {
                                          if (user1 == 2 || user1 == 1) {
                                            return (
                                              <div>
                                                <input
                                                  type="text"
                                                  name="upi_ID"
                                                  id="upi_ID"
                                                  value={this.state.upi_ID}
                                                  onChange={this.handleChange}
                                                  placeholder={enter.result}
                                                  className="form-control"
                                                  // placeholder="UPI ID"
                                                />
                                              </div>
                                            );
                                          } else {
                                            return (
                                              <div>
                                                {emps.map((emp) => (
                                                  <>
                                                    {emp.pro_UPIID == "NULL" ? (
                                                      <input
                                                        type="text"
                                                        id="upi_ID"
                                                        placeholder={
                                                          enter.result
                                                        }
                                                        name="upi_ID"
                                                        className="form-control"
                                                      />
                                                    ) : (
                                                      <input
                                                        type="text"
                                                        id="upi_ID"
                                                        placeholder={
                                                          enter.result
                                                        }
                                                        name="upi_ID"
                                                        className="form-control"
                                                        defaultValue={
                                                          emp.pro_UPIID
                                                        }
                                                      />
                                                    )}
                                                  </>
                                                ))}
                                              </div>
                                            );
                                          }
                                        })()}
                                      </div>
                                      <br />
                                      <label style={{ marginTop: "0px" }}>
                                        {taggroup.result}
                                      </label>
                                      <br />
                                      <Taggroup
                                        Translated={
                                          select.result
                                            ? select.result
                                            : "select"
                                        }
                                      ></Taggroup>
                                    </div>
                                    {emps.map((emp) => (
                                      <input
                                        type="hidden"
                                        value={emp.prof_benID}
                                        name="benId"
                                        id="benId"
                                      />
                                    ))}
                                  </div>
                                </div>
                                <center>
                                  <label>
                                    <input type="checkbox" name="q1b" />{" "}
                                    {confirmbankdetails.result}
                                    <b style={{ color: "red" }}> *</b>
                                  </label>

                                  <div
                                    style={{
                                      marginLeft: "-1%",
                                      display: "contents",
                                    }}
                                  >
                                    {ChckErr && (
                                      <span style={{ color: "red" }}>
                                        {PleaseConfirmtheBankDetails.result
                                          ? PleaseConfirmtheBankDetails.result
                                          : ChckErr}
                                      </span>
                                    )}
                                  </div>
                                </center>
                                <center>
                                  {(() => {
                                    if (
                                      langID == "Tamil" ||
                                      langID == "Hindi" ||
                                      langID == "Telugu"
                                    ) {
                                      return (
                                        <button
                                          type="submit"
                                          style={{
                                            width: "95px",
                                            Height: "9%",
                                            color: "white",
                                            backgroundColor: "rgb(102, 71, 18)",
                                          }}
                                          Value=""
                                          className="btn btn-primary"
                                        >
                                          {create.result}
                                        </button>
                                      );
                                    } else {
                                      return (
                                        <button
                                          type="submit"
                                          style={{
                                            width: "70px",
                                            Height: "9%",
                                            color: "white",
                                            backgroundColor: "rgb(102, 71, 18)",
                                          }}
                                          Value=""
                                          className="btn btn-primary"
                                        >
                                          {create.result}
                                        </button>
                                      );
                                    }
                                  })()}
                                </center>
                              </form>
                            </>
                          );
                        }
                      })()}
                    </div>

                    {(() => {
                      if (Category_text == "1") {
                        return (
                          <>
                            {this.state.loading ? (
                              <center>
                                <img
                                  className="loading-img2"
                                  src="https://testapi.paropakari.com/images/Paropakari.gif"
                                />
                              </center>
                            ) : null}

                            <form
                              autoComplete="off"
                              onSubmit={this.handleSubmitNonMonitory}
                            >
                              <div className="row" style={{ marginLeft: 3 }}>
                                <input
                                  type="hidden"
                                  value={langID}
                                  id="lang_id"
                                  onChange={this.handleChange}
                                  name="langid"
                                />
                                <input
                                  type="hidden"
                                  value={lID}
                                  id="l_id"
                                  onChange={this.handleChange}
                                  name="langid"
                                />
                                <input
                                  type="hidden"
                                  value={CauseForID}
                                  id="Causefor_Id"
                                  onChange={this.handleChange}
                                  name="Causefor_Id"
                                />

                                <div
                                  className="col-lg-4" /* style={{maxWidth=""}} */
                                >
                                  <label className htmlFor="upload_title">
                                    {title.result}
                                    <b style={{ color: "red" }}> *</b>
                                  </label>
                                  {/* {(() => {if (langID == 'English' ) {return (
      <label onClick={window['checkboxClickHandler']} style={{color:'red'}}> Click to set Default Language English </label>  
);
}
               

})()}   */}
                                  <input
                                    className="form-control"
                                    id="upload_title"
                                    maxLength={100}
                                    minLength={3}
                                    name="upload_title"
                                    value={this.state.upload_title}
                                    onChange={this.handleChange}
                                    placeholder={enter.result}
                                    size={100}
                                    type="text"
                                  />
                                  <div style={{}}>
                                    <span style={{ color: "red" }}>
                                      {" "}
                                      {` ${
                                        opErr
                                          ? TitleisRequired.result
                                            ? TitleisRequired.result
                                            : opErr
                                          : ""
                                      }`}
                                    </span>{" "}
                                  </div>
                                  <br />
                                  <label className htmlFor="upload_description">
                                    {description.result}
                                    <b style={{ color: "red" }}> *</b>
                                  </label>
                                  <textarea
                                    className="form-control"
                                    cols={6}
                                    value={this.state.upload_description}
                                    onChange={this.handleChange}
                                    id="upload_description"
                                    name="upload_description"
                                    placeholder={enter.result}
                                    rows={6}
                                    minLength={3}
                                    style={{ height: "130px" }}
                                  />
                                  <div style={{}}>
                                    {npErr && (
                                      <span style={{ color: "red" }}>
                                        {DescriptionisRequired.result
                                          ? DescriptionisRequired.result
                                          : npErr}
                                      </span>
                                    )}
                                  </div>
                                  <br />
                                  <label className>
                                    {addsupportingdocuments.result}{" "}
                                    <a
                                      href="#?id=collapseTwo"
                                      data-toggle="modal"
                                      data-target="#fileModal"
                                      style={{
                                        textDecoration: "none",
                                        lineHeight: "1.2",
                                        marginTop: 300,
                                        fontSize: "1.5rem",
                                        color: "black",
                                      }}
                                    >
                                      &nbsp;&nbsp;<b>&#9432;</b>
                                      &nbsp;&nbsp;&nbsp;
                                    </a>
                                  </label>
                                  {/* <MultiImage/> */}

                                  <div className="App">
                                    <div>
                                      {/* <tr><td><input type="text" label="Title" margin="normal" name="Title" value={this.state.Title} id="Title" size="small" onChange={this.TitleValue} variant="outlined" ></input></td></tr>
                       <tr><td><input type="text" label="Description" margin="normal" id="Description" size="small" variant="outlined" onChange={this.DespValue}></input></td></tr> */}

                                      <label className="form-control">
                                        + {Add.result}
                                        <input
                                          type="file"
                                          onChange={this.imageHandleChange.bind(
                                            this
                                          )}
                                          className="vg"
                                          multiple
                                          style={{ width: "0%" }}
                                          accept="image/png , image/jpeg ,image/jpg , image/webp, .doc, .docx, .xls, .xlsx, .pdf, video/*"
                                        />
                                      </label>
                                      <div
                                        className="multiimages"
                                        style={{
                                          overflowY: "scroll",
                                          maxHeight: "100px",
                                        }}
                                      >
                                        {this.state.imageName &&
                                          this.state.imageName.map(
                                            (image, index) => {
                                              return (
                                                <div key={index}>
                                                  <img src={image} />
                                                  <p>
                                                    {image.name}
                                                    <img
                                                      onClick={() =>
                                                        this.deleteimg(index)
                                                      }
                                                      style={{ width: "4%" }}
                                                      src="./assets/images/delete.png"
                                                    />
                                                  </p>
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>

                                      <br />
                                      {/* <button type="submit" margin="normal" variant="contained" onClick={this.savetoDB}>Upload Images</button> */}
                                    </div>
                                  </div>

                                  <div>
                                    {(() => {
                                      if (user1 == 2 || user1 == 1) {
                                        return (
                                          <div>
                                            <input
                                              type="hidden"
                                              name="Bank_acc"
                                              id="Bank_acc"
                                              maxLength={18}
                                              className="form-control"
                                              placeholder={enter.result}
                                            />
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <div>
                                            {emps.map((emp) => (
                                              <input
                                                type="hidden"
                                                name="Bank_acc"
                                                id="Bank_acc"
                                                maxLength={18}
                                                className="form-control"
                                                defaultValue={
                                                  emp.prof_BankAccount
                                                }
                                                placeholder={enter.result}
                                              />
                                            ))}
                                          </div>
                                        );
                                      }
                                    })()}
                                  </div>

                                  {/* {emps.map(emp=> */}

                                  {/* )} */}
                                </div>

                                <div className="col-lg-4">
                                  {(() => {
                                    if (langID == "Tamil") {
                                      return (
                                        <label
                                          className
                                          htmlFor="upload_num_of_beneficiaries"
                                          style={{ fontsize: 10 }}
                                        >
                                          {noofbeneficiary.result}
                                          <b style={{ color: "red" }}> *</b>
                                        </label>
                                      );
                                    } else {
                                      return (
                                        <label
                                          className
                                          htmlFor="upload_num_of_beneficiaries"
                                          style={{}}
                                        >
                                          {noofbeneficiary.result}
                                          <b style={{ color: "red" }}> *</b>
                                        </label>
                                      );
                                    }
                                  })()}

                                  {/* <label className htmlFor="upload_amount">{amount.result}</label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}

                                  {/* <input className="form-control" style={{width: '50%', marginTop: '0px', marginLeft: 175}}
                value={this.state.upload_amount}    
                onChange={this.handleChange} 
                id="upload_amount" name="upload_amount"  placeholder="Rs" type="number" />
                 */}
                                  <input
                                    className="form-control"
                                    value="1"
                                    onChange={this.handleChange}
                                    id="upload_amount"
                                    name="upload_amount"
                                    min="0"
                                    placeholder="Rs"
                                    style={{ width: "48%" }}
                                    type="hidden"
                                  />

                                  {(() => {
                                    if (causeID == "3") {
                                      return (
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder={enter.result}
                                          value="1"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={this.handleChange}
                                          readOnly="readonly"
                                          id="upload_num_of_beneficiaries"
                                          style={{
                                            width: "100%",
                                            marginLeft: 0,
                                          }}
                                          name="upload_num_of_beneficiaries"
                                          min={1}
                                          max={9999999}
                                          minLength={1}
                                          maxLength={7}
                                        />
                                      );
                                    } else {
                                      return (
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder={enter.result}
                                          value={
                                            this.state
                                              .upload_num_of_beneficiaries
                                          }
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={this.handleChange}
                                          id="upload_num_of_beneficiaries"
                                          style={{
                                            width: "100%",
                                            marginLeft: 0,
                                          }}
                                          name="upload_num_of_beneficiaries"
                                          min={1}
                                          max={9999999}
                                          minLength={1}
                                          maxLength={7}
                                        />
                                      );
                                    }
                                  })()}

                                  {/* <input type="text" className="form-control" placeholder="No of Beneficiaries"
                value="100"   
                onChange={this.handleChange}
                id="upload_num_of_beneficiaries" style={{width: '50%', marginTop: '0px', marginLeft: 175}}
                  name="upload_num_of_beneficiaries" min={1} max={9999999} minLength={1} maxLength={7} /> */}

                                  <div style={{}}>
                                    {nbErr && (
                                      <span style={{ color: "red" }}>
                                        {Benerequire.result
                                          ? Benerequire.result
                                          : nbErr}
                                      </span>
                                    )}
                                  </div>

                                  <br />

                                  <label
                                    className
                                    htmlFor="relation_id"
                                    style={{ marginTop: 0 }}
                                  >
                                    {requiredforr.result}
                                  </label>
                                  <b style={{ color: "red" }}> *</b>
                                  {/* <select className="form-control"  id="relation_id" 
                value={this.state.relation_id}     onChange={this.handleChange}
              
                name="relation_id" >
                <option value>Select</option>
                  <option onClick={this.submit} value={3}>Self</option>
                  <option value={2}>Family/Friends</option>
                  <option value={1}
                  
                  >Others</option>
                </select> */}

                                  <input
                                    type="hidden"
                                    name="relation_id"
                                    defaultValue={reqid}
                                  />

                                  {(() => {
                                    if (user1 == 2 || user1 == 1) {
                                      return (
                                        <div>
                                          <input
                                            type="text"
                                            name="benfname"
                                            id="benfname"
                                            className="form-control"
                                            defaultValue={JSON.parse(benfname)}
                                            placeholder="Required for"
                                            readOnly
                                          />
                                          <input
                                            type="hidden"
                                            id="required_for"
                                            name="required_for"
                                            defaultValue={JSON.parse(benfname)}
                                          />
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div>
                                          {emps.map((emp) => (
                                            <input
                                              type="text"
                                              name="required_for"
                                              id="required_for"
                                              className="form-control"
                                              value={emp.profile_name}
                                              placeholder="Bank Account Number"
                                            />
                                          ))}
                                        </div>
                                      );
                                    }
                                  })()}

                                  <input
                                    type="hidden"
                                    id="upload_otherRelation"
                                    defaultValue="None"
                                    name="upload_otherRelation"
                                    placeholder="Enter Other Required For"
                                    className="form-control"
                                  />

                                  {/* <input type="file" id="file_name"   onChange={this.fileChangedHandler} name="file_name" style={{ opacity:'1',color:'rgb(133,130,130);' }}
                    className="form-control" accept=".x-png,.gif,.jpeg,.jpg,.png,.pdf,.mp4,.mkv,.avi" multiple="multiple" />  */}
                                  <br />
                                  {/* <input type="file" name="uploadfile"  id="file_name" accept="image/*"  name="file_name" className="form-control" multiple="multiple" onChange={this.ImagePreview} style={{display:'none'}}/>
                  <label for="file_name" className="form-control" style={{backgroundColor:'white',fontFamily:'cuyabra'}}><i style={{fontsize:'24px'}} class="fa">&#xf067;</i>  &nbsp;Add {this.state.ImageDisplay && (
                  <div style={{ textAlign: "left" }}>
                  <button className="remove" style={{display:'none'}} onClick={this.resetFile}><CancelIcon className="icon-x"></CancelIcon></button>
                  </div>
                  )}
<img id="groupimage" className="card-img2" 
                                       style={{width:"50%",height:"50%",float:'right',marginTop:'-15px'}} 
                                       src={this.state.ImageDisplay}  /></label> */}

                                  <label
                                    className
                                    htmlFor="category_id"
                                    style={{ marginTop: 0 }}
                                  >
                                    {categories.result}
                                    <b style={{ color: "red" }}> *</b>
                                  </label>
                                  <select
                                    className="form-control"
                                    value={this.state.category_id}
                                    onChange={this.handleChange}
                                    id="category_id"
                                    name="category_id"
                                  >
                                    <option value>{select.result}</option>
                                    <option value={8}>
                                      {education.result}
                                    </option>
                                    <option value={7}>
                                      {foodandnutrition.result}
                                    </option>
                                    <option value={6}>{health.result}</option>
                                    <option value={5}>
                                      {professional.result}
                                    </option>
                                    <option value={4}>
                                      {socialwefare.result}
                                    </option>
                                    <option value={3}>{sports.result}</option>
                                    <option value={2}>{others.result}</option>
                                  </select>

                                  <input
                                    type="hidden"
                                    id="upload_otherCategory"
                                    name="upload_otherCategory"
                                    placeholder="Enter Other Category"
                                    defaultValue="None"
                                    className="form-control"
                                  />
                                  <div style={{}}>
                                    {PurErr && (
                                      <span style={{ color: "red" }}>
                                        {PurposeisRequired.result
                                          ? PurposeisRequired.result
                                          : PurErr}
                                      </span>
                                    )}
                                  </div>
                                  <br />
                                  <label>{tagfriends.result}</label>

                                  <TaggedFriendss
                                    Translated={
                                      select.result ? select.result : "select"
                                    }
                                  ></TaggedFriendss>

                                  {(() => {
                                    if (user1 == 2 || user1 == 1) {
                                      return (
                                        <div>
                                          <input
                                            type="hidden"
                                            name="upload_IFSC"
                                            id="ifsc"
                                            disabled={
                                              this.state.disabled
                                                ? "disabled"
                                                : ""
                                            }
                                            maxLength={11}
                                            className="form-control"
                                            placeholder={enter.result}
                                          />
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div>
                                          {emps.map((emp) => (
                                            <input
                                              type="hidden"
                                              name="upload_otherRelation1"
                                              id="ifsc"
                                              maxLength={11}
                                              //  name="upload_IFSC"
                                              className="form-control"
                                              defaultValue={emp.prof_IFSC}
                                              placeholder={enter.result}
                                            />
                                          ))}
                                        </div>
                                      );
                                    }
                                  })()}

                                  <br />

                                  {/* <input type="checkbox" required name="checkbox"/> Confirm Bank Details
            <div style={{ marginLeft: "7%"}}>
                {CheckErr && <span style={{color:"red"}}>{CheckErr}</span>} 
                </div>  */}
                                </div>

                                <div className="col-lg-4">
                                  <input
                                    id="user_id"
                                    name="user_id"
                                    type="hidden"
                                    Value
                                  />
                                  <div
                                    className="form-group"
                                    style={{ marginTop: "-20px" }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    className="form-group"
                                    style={{
                                      marginTop: "-40px",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    <br />
                                    <label className htmlFor="upload_place">
                                      {place.result}
                                      <b style={{ color: "red" }}> *</b>
                                    </label>
                                    <input
                                      className="form-control"
                                      id="upload_place"
                                      value={this.state.upload_place}
                                      maxLength={45}
                                      onChange={this.handleChange}
                                      minLength={3}
                                      name="upload_place"
                                      placeholder={enter.result}
                                      type="text"
                                    />
                                    <div style={{}}>
                                      {uplErr && (
                                        <span style={{ color: "red" }}>
                                          {PlaceisRequired.result
                                            ? PlaceisRequired.result
                                            : uplErr}
                                        </span>
                                      )}
                                    </div>{" "}
                                    <br />
                                    <div>
                                      <input
                                        type="hidden"
                                        id="status_80G"
                                        name="status_80G"
                                        value="0"
                                      />
                                      {/* <select id="status_80G" name="status_80G"
              value={this.state.status_80G}    
              onChange={this.handleChange}
              className="form-control">
                <option value={0}>{select.result}</option>
                <option value={0}>No</option>
                <option value={1}>Yes</option>
              </select> */}
                                    </div>
                                    {/* <br/> */}
                                    <label
                                      className
                                      htmlFor="required_by"
                                      style={{ marginTop: "2px" }}
                                    >
                                      {enddate.result}
                                      <b style={{ color: "red" }}> *</b>
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="required_by"
                                      name="required_by"
                                      style={{
                                        maxWidth: "100%",
                                      }}
                                      onChange={this.handleChange}
                                      value={this.state.required_by}
                                      min={today}
                                      max={nextYear}
                                    />
                                    <div style={{}}>
                                      {reqErr && (
                                        <span style={{ color: "red" }}>
                                          {DateisRequired.result
                                            ? DateisRequired.result
                                            : reqErr}
                                        </span>
                                      )}
                                    </div>{" "}
                                    <br />
                                    <label style={{ marginTop: "3px" }}>
                                      {taggroup.result}
                                    </label>
                                    <br />
                                    <Taggroup
                                      Translated={
                                        select.result ? select.result : "select"
                                      }
                                    ></Taggroup>
                                    <div>
                                      {(() => {
                                        if (user1 == 2 || user1 == 1) {
                                          return (
                                            <div>
                                              <input
                                                type="hidden"
                                                name="upi_ID"
                                                id="upi_ID"
                                                placeholder={enter.result}
                                                className="form-control"
                                              />
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div>
                                              {emps.map((emp) => (
                                                <input
                                                  type="hidden"
                                                  name="upi_ID"
                                                  id="upi_ID"
                                                  className="form-control"
                                                  defaultValue={emp.pro_UPIID}
                                                  placeholder="IFSC code"
                                                />
                                              ))}
                                            </div>
                                          );
                                        }
                                      })()}
                                    </div>
                                  </div>
                                  {emps.map((emp) => (
                                    <input
                                      type="hidden"
                                      value={emp.prof_benID}
                                      name="benId"
                                      id="benId"
                                    />
                                  ))}
                                </div>
                              </div>
                              <center>
                                <br />
                                {(() => {
                                  if (
                                    langID == "Tamil" ||
                                    langID == "Hindi" ||
                                    langID == "Telugu"
                                  ) {
                                    return (
                                      <button
                                        type="submit"
                                        style={{
                                          width: "95px",
                                          Height: "9%",
                                          color: "white",
                                          marginTop: "-10px",
                                          backgroundColor: "rgb(102, 71, 18)",
                                        }}
                                        Value=""
                                        className="btn btn-primary"
                                      >
                                        {create.result}
                                      </button>
                                    );
                                  } else {
                                    return (
                                      <button
                                        type="submit"
                                        style={{
                                          width: "70px",
                                          Height: "9%",
                                          color: "white",
                                          marginTop: "-10px",
                                          backgroundColor: "rgb(102, 71, 18)",
                                        }}
                                        Value=""
                                        className="btn btn-primary"
                                      >
                                        {create.result}
                                      </button>
                                    );
                                  }
                                })()}
                              </center>
                            </form>
                          </>
                        );
                      }
                    })()}

                    <br />
                  </div>
                </div>

                <br />

                <br />
              </section>
              <div className="modal fade" id="fileModal" role="dialog">
                <div className="modal-dialog">
                  {/* Modal content*/}
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        style={{ marginTop: "0px" }}
                      >
                        ×
                      </button>
                      <h5 className="modal-title">{includedocs.result}</h5>
                    </div>
                    <div className="modal-body">
                      {/* Go to www.addthis.com/dashboard to customize your tools */}
                      {forex.result}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-default"
                        data-dismiss="modal"
                      >
                        {close.result}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal fade" id="fileModal1" role="dialog">
                <div className="modal-dialog">
                  {/* Modal content*/}
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        style={{ marginTop: "0px" }}
                      >
                        ×
                      </button>
                      <h5 className="modal-title">
                        <b style={{ color: "red" }}>*</b>&nbsp;
                        {youareresponsible.result}
                      </h5>
                    </div>
                    <div className="modal-body">
                      {/* Go to www.addthis.com/dashboard to customize your tools */}
                      <b style={{ color: "red" }}>*</b>&nbsp;Paropakari&nbsp;
                      {paropakarinotresponsible.result}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-default"
                        data-dismiss="modal"
                      >
                        {close.result}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="fileModal2" role="dialog">
                <div className="modal-dialog">
                  {/* Modal content*/}
                  <div className="modal-content">
                    {/* <div className="modal-header">
                      <h5 className="modal-title"><b style={{ color: 'red' }}>*</b>&nbsp;</h5>
                      <button type="button" className="close" data-dismiss="modal">×</button>
                    </div> */}
                    <div className="modal-body">
                      {/* Go to www.addthis.com/dashboard to customize your tools */}
                      <b style={{ color: "red" }}>*</b>
                      {paropakaribankpop.result}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-default"
                        data-dismiss="modal"
                      >
                        {close.result}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/*/#team*/}
              <div style={{ backgroundColor: "#664712" }}>
                <footer id="footer" style={{ color: "white" }}>
                  <br />
                  <div className="container">
                    <div className="copyright">
                      <div className="row col-lg-12">
                        <div className="col-lg-9 mb-6">
                          {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}

                          <p style={{ marginLeft: "45px" }}>
                            {copyright_new.result} &nbsp;{currentYear} ©{" "}
                            {paropakari_new.result}&nbsp; |&nbsp;
                            <a
                              href="#/Contactuslogin"
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              {contactus.result}&nbsp;
                            </a>
                            |{" "}
                            <a
                              href="#/PrivacyPolicy"
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              &nbsp;{privacypolicy.result}&nbsp;
                            </a>
                            |
                            <a
                              href="#/TermsandCondition"
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              &nbsp;{termsandcondition.result}
                            </a>
                          </p>
                        </div>
                        <div className="col-lg-3 mb-3">
                          <div
                            className="social-icons"
                            style={{ color: "white", marginLeft: "118px" }}
                          >
                            <ul className="nav nav-pills">
                              <li>
                                <a
                                  target="_blank"
                                  href="https://www.facebook.com/beoneparopakari"
                                >
                                  <i className="fa fa-facebook" />
                                </a>
                              </li>
                              {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                              <li>
                                <a
                                  target="_blank"
                                  href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw"
                                >
                                  <i className="fa fa-youtube-play" />
                                </a>
                              </li>
                              <li>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/paropakari_beone/"
                                >
                                  <i className="fa fa-instagram" />
                                </a>
                              </li>
                              <li>
                                <a
                                  target="_blank"
                                  href="https://www.linkedin.com/company/paropakari/"
                                >
                                  <i className="fa fa-linkedin" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="containerss"></div>
                </footer>
              </div>
            </div>
            {/*/#footer*/}
            {/* <div>
{modal&&(
      <div  id="modalRegisterForm" tabindex="-1" role="dialog" style={{position: "fixed",
      top: 0,
      left: 0,
      zIndex: 1000,
      width: "100%",
      height: "100%",
      backgroundColor:"rgba(255, 255, 255, 0.75)",
      outline: 0}}>


        <div style={{ 
    position: "fixed",
    inset: "50% auto auto 50%",
    border: "none",
    background: "rgb(174, 230, 219)",
    overflow: "auto",
    borderRadius: "20px",
    outline: "none",
    padding: "20px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "480px",
    width: "405px"}}>
            <div className="modal-header">
                  <button type="button" className="close" onClick={this.toggleModal}>×</button>
                </div>

               <div>
                <div className="modal-header">
                  <button type="button" className="close" onClick={this.toggleModal}>×</button>
                </div>
                <p className="modaltext">{youagreetoprovide.result}</p>
                <div className="modalconfirmbtn">
                  <button className="button-confirm" id="confirm80g" onClick={this.toggleConfirm}>{confirm.result}</button>
                  <button className="button-confirm" id="no80g" onClick={this.toggleModal}>No</button>
                </div>
              </div>
          
    </div>

        </div>)}
        </div> */}
            {/* <Modal
              isOpen={this.state.setIsOpen}
              onRequestClose={this.toggleModal}
              contentLabel="My dialog"
              style={this.customStyles}
            >
              <div>
                <div className="modal-header">
                  <button type="button" className="close" onClick={this.toggleModal}>×</button>
                </div>
                <p className="modaltext">{youagreetoprovide.result}</p>
                <div className="modalconfirmbtn">
                  <button className="button-confirm" id="confirm80g" onClick={this.toggleConfirm}>{confirm.result}</button>
                  <button className="button-confirm" id="no80g" onClick={this.toggleModal}>No</button>
                </div>
              </div>
            </Modal> */}
          </div>
        </div>
      );
    }
  }
}
