import React, { Component } from 'react'

import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

import Script from 'react-load-script'
//import SideBars from './SideBars.js';


export default class Howitwork extends Component {


  constructor(props) {
    super(props);
    this.state = {

      GroupList: [],
      allPosts: [],
      notify: [],
      foo: [],

    }
    this.submit = this.submit.bind(this);
  }

  onKeyUp = e => {
    // filter post list by title using onKeyUp function
    const GroupList = this.state.allPosts.filter(item =>
      item.group_Name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ GroupList });
  };

  submit() {
    let url = "https://testapi.paropakari.com/api/Group/getallgroupsbyid";

    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          GroupList: data,
          allPosts: data
        });
        
        // 
      });

  }


  notify = () => {


    let url = "https://testapi.paropakari.com/api/GetProfile/Notification_Count";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          notify: data
        });
        
        // 
      });
  }


  DeleteGroup = (Group_Id) => {
    var x = window.confirm("Are you sure you want to delete this Group");
    if (x) {
      fetch('https://testapi.paropakari.com/api/Group/groupdelete?Group_Id=' + Group_Id,
        { method: 'DELETE' })

        .then(() => this.setState({ status: 'Delete successful' }));

      window.location.reload();
    }
    else {
      return false;
    }

  }

  Footer() {

    


    let url = "https://testapi.paropakari.com/api/Authenticate/Get_FooterDetails";
    let data = this.state;

    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          foo: data
        });
        
        // 
      });
  }




  componentDidMount() {
    window.addEventListener('load', this.submit);
    this.submit();
    window.addEventListener('load', this.notify);
    this.notify();
    window.addEventListener('load', this.Footer);
    this.Footer();
    const element = document.querySelector('.VIpgJd-ZVi9od-ORHb-OEVmcd');
    if (element && window.getComputedStyle(element).getPropertyValue('position') === 'fixed') {
      element.style.position = 'absolute';
    }
    document.title = "Step by Step Guide to Paropakari."
  }


  componentWillUnmount() {
    window.removeEventListener('load', this.submit);
    this.submit();
    window.removeEventListener('load', this.notify);
    this.notify();
    window.removeEventListener('load', this.Footer);
    this.Footer();
  }
  redirect = (e) => {
    e.preventDefault();
    var page = {
      pathname: './Login',

    }
    this.props.history.push(page)
  }

  handleonSubmit = (e) => {
    e.preventDefault()


    var page = {
      pathname: './Reset.js',
      pno: this.state.pno,

    }
    this.props.history.push(page)

  }

  handleScriptCreate() {
    this.setState({ scriptLoaded: false })
  }

  handleScriptError() {
    this.setState({ scriptError: true })
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true })
  }

  render() {
    const { GroupList } = this.state;
    const { notify } = this.state;
    let { foo } = this.state;
    var username = JSON.parse(localStorage.username);
    return (
      <div className="VIpgJd-ZVi9od-ORHb-OEVmcd" style={{ position: "absolute" }}>
        <div style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }} className="notranslate">
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="description" content />
          <meta name="author" content />
          <title>Blog Masonry | Triangle</title>
          <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
          <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
          <link href="./assets/css/lightbox.css" rel="stylesheet" />
          <link href="./assets/css/animate.min.css" rel="stylesheet" />
          <link href="./assets/css/main.css" rel="stylesheet" />

          {/*[if lt IE 9]>
	    
	    
    <![endif]*/}
          {/* <Script
      url="../assets/js/jquery.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />
     <Script
      url="../assets/js/bootstrap.min.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />
     <Script
      url="../assets/js/lightbox.min.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />
     <Script
      url="../assets/js/wow.min.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />
     <Script
      url="../assets/js/main.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />   */}
          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />
          {/*/head*/}
          {/* <header id="header" style={{backgroundImage: "url(" + "./assets/images/10.jpg" + ")"}}>      
  <div className="containerssheader">
              <div className="navbar navbar-inverse" role="banner">
                <div className="">
                  <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                   <a className="navbar-brand" href="#/HomePage">
                   <h1 style={{color:'blue',fontFamily:'Biysk-Regular'}}><img src="./assets/images/plogo.png" alt="logo" style={{marginBottom:'17px',width:'26%',fontsize:'52px'}}  />AROPAKARI</h1>
          </a>
          
                  </div>
                  
                  <div className="collapse navbar-collapse">
                  <ul className="nav navbar-nav navbar-right">
                    
                  <li className="active"><a class="btn1 third" href="#/Create" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',marginRight:'86px',marginLeft:'-274px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>
                    
                    Be a Paropakari
                    </a></li>
                    
                                                 
                            
                                   <li className=""><a href="#/MyActivity" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>My Journey</a></li>     
                                   <li className=""><a href="#/Create" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>Create a Cause</a></li>       
                                            
                                   <li className=""><a href="#/Notification" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>
                                     
                                     <div class="fa fa-bell" style={{fontsize:'24px'}}>{this.state.notify && this.state.notify.length > 0 && this.state.notify.map(notifyy =>(
                                                          <span class="-count"> 
                                           
                                         
                                           {notifyy.noticount}
                                          
                                             </span> ))}</div>
                                      </a></li>    
                                   <li className="dropdown"><a href="#/Profile" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>Profile <i className="fa fa-angle-down" /></a>
                                     <ul role="menu" className="sub-menu">
                                       <li><a href="#/Profile">My Profile</a></li>
                                       <li><a href="#/ChangePassword">Change Password</a></li>
                                       <li><a href="#/Group">Group</a></li>
                                       <li><a href="#/Login">Logout</a></li>
                                      
                                     </ul>
                                   </li>    
           
                                         
                                 </ul>
                  </div>
                 
                </div>
              </div>
              </div>
            </header> */}

          <header id="header" style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}>

            <div class="navbar navbar-inverse" role="banner">
              <div class="container">
                <div class="navbar-header">
                  <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                    <span class="sr-only">Toggle navigassstion</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                  </button>

                  <a class="navbar-brand" href="#/Homepage">
                    <h1 style={{ color: 'blue', fontFamily: 'Biysk-Regular' }}><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '52px' }} />AROPAKARI

                    </h1>
                  </a>

                </div>
                <div class="collapse navbar-collapse">
                  <ul class="nav navbar-nav navbar-right">
                    <li id="paro" className="active"><a class="btn1 third" href="#/Create" style={{ backgroundColor: 'rgb(102, 71, 18)', color: 'white', fontWeight: 'bold', marginRight: '160px', marginLeft: '-274px', fontSize: 16, marginTop: '2px', marginBottom: '-7px', textDecoration: 'none', whiteSpace: 'nowrap', fontFamily: 'cuyabra' }}>

                      Be a Paropakari
                    </a></li>

                    <li><a href="#/Myactivity"><b>My Journey</b></a></li>
                    <li><a href="#/Create"><b>Create a Cause</b></a></li>
                    <li><a href="#/InviteFriend"><b>Invite</b></a></li>
                    <li className=""><a href="#/Notification" style={{ fontFamily: 'cuyabra', fontWeight: 'bold' }}>

                      <div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                        <span class="-count">


                          {notifyy.noticount}

                        </span>))}</div>
                    </a></li>
                    {/* <li><a href="shortcodes.html ">Login</a></li>  */}
                    <li class="dropdown"><a><b>{username}</b> <i class="fa fa-angle-down"></i></a>
                      <ul role="menu" class="sub-menu">
                        <li><a href="#/Profile"><b>My Profile</b></a></li>
                        <li><a href="#/ChangePassword"><b>Change Password</b></a></li>
                        <li><a href="#/Group"><b>Geoup</b></a></li>
                        <li><a href="#/Login"><b>Logout</b></a></li>
                      </ul>
                    </li>
                    {/* <li><a href="shortcodes.html ">Shortcodes</a></li>                     */}
                  </ul>
                </div>
                {/* <div className="search">
                    <form role="form">
                        <i className="fa fa-search"></i>
                        <div className="field-toggle">
                            <input type="text" className="search-form" autocomplete="off" placeholder="Search"/>
                        </div>
                    </form>
                </div> */}
              </div>
            </div>
          </header>

          {/*/#header*/}

          {/*/#page-breadcrumb*/}
          <section id="blog" className="padding-bottom" style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}>
            <div className="containerss">
              <div className="row">
                <div className="timeline-blog overflow ">
                  <div className="timeline-date text-center">
                    <a href="#/Howitwork" className="btn btn-common uppercase">How It Works</a>
                  </div>
                  <div className="timeline-date text-center">
                    <a href="#/Manual" className="btn btn-common uppercase">User Manual</a>
                  </div>
                  <div className="timeline-divider overflow padding-bottom">
                    <div className="col-sm-6 padding-right arrow-right wow fadeInLeft" data-wow-duration="1000ms" data-wow-delay="300ms">
                      <div className="single-blog timeline">
                        <div className="single-blog-wrapper">
                          <div className="post-thumb">
                            <iframe src="https://www.youtube.com/embed/2ZxQl1QHQRA" />
                          </div>
                        </div>
                        <div className="post-content overflow">
                          <h2 className="post-title bold"><a href="blogdetails.html">Introduction</a></h2>
                          <p >#Register <a >(with your Mobile Phone or email)</a></p>
                          <p >#Login <a>(with your credentials)</a></p>
                          <p >#Update Profile <a >(Your Image/Date of Birth/<br />Date of Anniversary/Place)</a></p>
                          <a href="#" className="read-more"></a>
                          <div className="post-bottom overflow">
                            <span className="post-date pull-left"></span>
                            <span className="comments-number pull-right"><a href="#"></a></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 padding-left padding-top arrow-left wow fadeInRight" data-wow-duration="1000ms" data-wow-delay="300ms">
                      <div className="single-blog timeline">
                        <div className="single-blog-wrapper">
                          <div className="post-thumb">
                            <iframe src="https://www.youtube.com/embed/JfzEP-YvHFA" />
                          </div>
                        </div>
                        <div className="post-content overflow">
                          <h2 className="post-title bold"><a href="blogdetails.html#">Complete Profile</a></h2>
                          <p >#Register <a >(with your Mobile Phone or email)</a></p>
                          <p >#Login <a >(with your credentials)</a></p>
                          <p >#Update Profile <a >(Your Image/Date of Birth/<br />Date of Anniversary/Place)</a></p>
                          <a href="#" className="read-more"></a>
                          <div className="post-bottom overflow">
                            <span className="post-date pull-left"></span>
                            <span className="comments-number pull-right"><a href="#"></a></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 padding-right arrow-right wow fadeInLeft" data-wow-duration="1000ms" data-wow-delay="300ms">
                      <div className="single-blog timeline">
                        <div className="single-blog-wrapper">
                          <div className="post-thumb">
                            <iframe src="https://www.youtube.com/embed/5lZSVvU7oeM" />
                          </div>
                        </div>
                        <div className="post-content overflow">
                          <h2 className="post-title bold"><a href="blogdetails.html">Invite Your Friends</a></h2>
                          <p>#Invite </p>
                          <p>#Add Your Friend <a>(Name/Mobile/Email)</a></p>
                          <p>#Add Other Friends <a>(Name/Mobile/Email)</a></p>
                          <p>#Select Your Added Friends</p>
                          <p>#Select Your Causes</p>
                          <p>#Send Invitations</p>
                          <a href="#" className="read-more"></a>
                          <div className="post-bottom overflow">
                            <span className="post-date pull-left"></span>
                            <span className="comments-number pull-right"><a href="#"></a></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="timeline-blog overflow">

                  <div className="timeline-divider overflow padding-bottom">
                    <div className="col-sm-6 padding-right arrow-right wow fadeInLeft" data-wow-duration="1000ms" data-wow-delay="300ms">
                      <div className="single-blog timeline">
                        <div className="single-blog-wrapper">
                          <div className="post-thumb">
                            <iframe src="https://www.youtube.com/embed/8tP_6hKbuDA" />
                          </div>
                        </div>
                        <div className="post-content overflow">
                          <h2 className="post-title bold"><a href="blogdetails.html">Create Your Groups</a></h2>

                          <p>#Profile/Groups/Add My Groups</p>
                          <p>#Create Your Group <a>(Name/Icon)</a></p>
                          <p>#Add Members to Your Group <a>(Search by Name)</a></p>
                          <p>#Create Group</p>
                          <a href="#" className="read-more"></a>
                          <div className="post-bottom overflow">
                            <span className="post-date pull-left"></span>
                            <span className="comments-number pull-right"><a href="#"></a></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 padding-left padding-top arrow-left wow fadeInRight" data-wow-duration="1000ms" data-wow-delay="300ms">
                      <div className="single-blog timeline">
                        <div className="single-blog-wrapper">
                          <div className="post-thumb">
                            <iframe src="https://www.youtube.com/embed/kXqG3Fl4kL8" />
                          </div>
                        </div>
                        <div className="post-content overflow">
                          <h2 className="post-title bold"><a href="blogdetails.html#">Create Your Cause</a></h2>
                          <p>#Create Your Cause or Fundraising Need <a >(for eg: <br />Education (School, High School or College),<br /> Note Books, Text Books, Medical, etc)</a></p>
                          <p >#Describe <a >(Title/Description/Purpose/Beneficiaries/<br />Amount/Place/Upload Supporting Documents)</a></p>
                          <p>#Date Required By/Who Is It Required For</p>
                          <p>#Tag Friends <a >(By Name)</a></p>
                          <p >#Tag Your Groups <a >(By Name)</a></p>
                          <p >#Tax Benefits <a >(Mention if your cause can <br />provide Income tax benefits (80G) to the supporters)</a></p>
                          <a href="#" className="read-more"></a>
                          <div className="post-bottom overflow">
                            <span className="post-date pull-left"></span>
                            <span className="comments-number pull-right"><a href="#"></a></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 padding-right arrow-right wow fadeInLeft" data-wow-duration="1000ms" data-wow-delay="300ms">
                      <div className="single-blog timeline">
                        <div className="single-blog-wrapper">
                          <div className="post-thumb">
                            <iframe src="https://www.youtube.com/embed/hZqp-yEPL80" />
                          </div>
                        </div>
                        <div className="post-content overflow">
                          <h2 className="post-title bold"><a href="blogdetails.html">Share Your Cause</a></h2>
                          <p >#From Home Page <a >(FB/Twitter/LinkedIn/<br />Whatsapp/Email,etc)</a></p>
                          <p >#From My Journey Page <a >(FB/Twitter/LinkedIn/<br />Whatsapp/Email,etc)</a></p>
                          <br />
                          <a href="#" className="read-more"></a>
                          <div className="post-bottom overflow">
                            <span className="post-date pull-left"></span>
                            <span className="comments-number pull-right"><a href="#"></a></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="timeline-blog overflow">

                  <div className="timeline-divider overflow padding-bottom">
                    <div className="col-sm-6 padding-right arrow-right wow fadeInLeft" data-wow-duration="1000ms" data-wow-delay="300ms">
                      <div className="single-blog timeline">
                        <div className="single-blog-wrapper">
                          <div className="post-thumb">
                            <iframe src="https://www.youtube.com/embed/VRfHDLA07p8" />
                          </div>
                        </div>
                        <div className="post-content overflow">
                          <div className="post-content overflow">
                            <h2 className="post-title bold"><a href="blogdetails.html">Donate to a Cause</a></h2>
                            <p >#Home Page </p>
                            <p>#Select a Cause to Support </p>
                            <p>#Select Donate a Smile </p>
                            <p>#Enter the % of Your Support to the Cause</p>
                            <p>#Write Messege to the Creator of the Cause</p>
                            <p>#Submit Your Support <a><br />(You and the Creator of the cause will receive a<br /> text/email notification)</a></p><br />
                            <a href="#" className="read-more"></a>
                            <div className="post-bottom overflow">
                              <span className="post-date pull-left"></span>
                              <span className="comments-number pull-right"><a href="#"></a></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 padding-left padding-top arrow-left wow fadeInRight" data-wow-duration="1000ms" data-wow-delay="300ms">
                      <div className="single-blog timeline">
                        <div className="single-blog-wrapper">
                          <div className="post-thumb">
                            <iframe src="https://www.youtube.com/embed/ZBtH4hf_ktI" />
                          </div>
                        </div>
                        <div className="post-content overflow">
                          <h2 className="post-title bold"><a href="blogdetails.html">Response to Your Cause</a></h2>

                          <p>#My Journey/Your Cause Post/View Details</p>
                          <p>#Review the Level of Support <a ><br />(from single or multiple supporters)</a></p>
                          <p>#Confirm Income Tax Documents <a >(if applicable)</a></p>
                          <p>#Confirm Support <a>(after receipt of support.<br /> You and the Supporter of the cause will receive a text/email notification)</a></p>
                          <br />
                          <a href="#" className="read-more"></a>
                          <div className="post-bottom overflow">
                            <span className="post-date pull-left"></span>
                            <span className="comments-number pull-right"><a href="#"></a></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="timeline-blog overflow">

                  <div className="timeline-divider overflow padding-bottom">
                    <div className="col-sm-6 padding-right arrow-right wow fadeInLeft" data-wow-duration="1000ms" data-wow-delay="300ms">
                      <div className="single-blog timeline">
                        <div className="single-blog-wrapper">
                          <div className="post-thumb">
                            <iframe src="https://www.youtube.com/embed/VRfHDLA07p8" />
                          </div>
                        </div>
                        <div className="post-content overflow">
                          <h2 className="post-title bold"><a href="blogdetails.html">Manage Your Cause</a></h2>

                          <p>#My Journey/Your Cause Post/View Details</p>
                          <p>#Review the Progress of Your Cause <a><br />(Happiness Meter - % of Support Pledged or Received and Confirmed)</a></p>
                          <p>#View Paropakaris <a>(to your cause)</a></p>
                          <p>#View Likes/Comments/Tagged Persons</p>
                          <p>#Share Your Cause <a>(FB/Twitter/LinkedIn/Whatsapp,etc)</a></p>
                          <br />
                          <a href="#" className="read-more"></a>
                          <div className="post-bottom overflow">
                            <span className="post-date pull-left"></span>
                            <span className="comments-number pull-right"><a href="#"></a></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 padding-left padding-top arrow-left wow fadeInRight" data-wow-duration="1000ms" data-wow-delay="300ms">
                      <div className="single-blog timeline">
                        <div className="single-blog-wrapper">
                          <div className="post-thumb">
                            <iframe src="https://www.youtube.com/embed/XYxaRD7ZOzg" />
                          </div>
                        </div>
                        <div className="post-content overflow">
                          <h2 className="post-title bold"><a href="blogdetails.html">Review Your Notifications</a></h2>

                          <p>#Notifications Page </p>
                          <p>#Select the Notification</p>
                          <p>#You will be taken to My Journey/Your Cause Page</p>
                          <p>#Review Your Cause <a style={{ fontSize: 15 }} /></p>
                          <br />
                          <a href="#" className="read-more"></a>
                          <div className="post-bottom overflow">
                            <span className="post-date pull-left"></span>
                            <span className="comments-number pull-right"><a href="#"></a></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="timeline-blog overflow">

                  <div className="timeline-divider overflow padding-bottom">
                    <div className="col-sm-6 padding-right arrow-right wow fadeInLeft" data-wow-duration="1000ms" data-wow-delay="300ms">
                      <div className="single-blog timeline">
                        <div className="single-blog-wrapper">
                          <div className="post-thumb">
                            <iframe src="https://www.youtube.com/embed/DbZuZdZ5VHk" />
                          </div>
                        </div>
                        <div className="post-content overflow">
                          <h2 className="post-title bold"><a href="blogdetails.html">Edit/Delete Your Cause</a></h2>

                          <p>#You can only edit/delete causes that do not have any pledged support, likes or comments</p>
                          <p>#My Journey Page</p>
                          <p>#Select Edit in Your Cause</p>
                          <p>#Edit <a style={{ fontSize: 15 }}>(Title/Description/Purpose/Beneficiaries/<br />Amount/Place/Upload Supporting Documents/Date Required By/Who Is It Required For)</a></p>
                          <p>#Edit Tax Benefits </p>
                          <p>#Delete Your Cause <a>(Select Delete)</a></p>
                          <br />
                          <a href="#" className="read-more"></a>
                          <div className="post-bottom overflow">
                            <span className="post-date pull-left"></span>
                            <span className="comments-number pull-right"><a href="#"></a></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 padding-left padding-top arrow-left wow fadeInRight" data-wow-duration="1000ms" data-wow-delay="300ms">
                      <div className="single-blog timeline">
                        <div className="single-blog-wrapper">
                          <div className="post-thumb">
                            <iframe src="https://www.youtube.com/embed/MXMdBzZ-jgo" />
                          </div>
                        </div>
                        <div className="post-content overflow">
                          <h2 className="post-title bold"><a href="blogdetails.html">Home Page Navigation</a></h2>
                          <p>#Sort <a>(Recent/Purpose/Incomplete/80G, etc)</a></p>
                          <p>#Search <a>(by Typing)</a></p>
                          <p>#Filter By Purpose/Number of Beneficiaries </p>
                          <p>#Donate to a Cause <a>(Select Donate a Smile)</a></p>
                          <p>#Like, Comment, Tag and Share a Cause</p>
                          <br />
                          <a href="#" className="read-more"></a>
                          <div className="post-bottom overflow">
                            <span className="post-date pull-left"></span>
                            <span className="comments-number pull-right"><a href="#"></a></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </section>
          {/*/#blog*/}
          <footer id="footer" style={{ color: 'white' }}>
            <br />
            <div className="container">
              <div className="copyright">

                <div className="row col-lg-12">

                  <div className="col-lg-3 mb-5" style={{ textDecoration: 'none', color: 'white' }}>

                    <a href="#/Contactus" style={{ textDecoration: 'none', color: 'white' }}>Contact Us</a>
                    <br />
                    <a href="#/Howitwork" style={{ textDecoration: 'none', color: 'white' }}>How It Works</a>
                    <br />
                    <a href="#/Blog" style={{ textDecoration: 'none', color: 'white' }}>Blog</a>
                    <br />
                    <a href="#/TermsandCondition" style={{ textDecoration: 'none', color: 'white' }}>Terms and Conditions</a>
                  </div>
                  <div className="col-lg-5 mb-3">
                    {foo.map((fo) => (
                      <p className="m-0 text-center text-black" style={{ paddingTop: '-50px', fontSize: 12, color: 'white' }}>
                        <a className="blink_me" style={{ color: '#ffd9a2', fontSize: 18 }}> &nbsp;{fo.posts}</a>&nbsp;Causes
                        <a className="blink_me" style={{ color: '#ffd9a2', fontSize: 18 }}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
                        <a className="blink_me" style={{ color: '#ffd9a2', fontSize: 18 }}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
                        <a className="blink_me" style={{ color: '#ffd9a2', fontSize: 18 }}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
                      </p>
                    ))}
                    <div className="copyright" >
                      <center> <p>Copyright © 2021 Paropakari</p></center>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-3">
                    <br />
                    <div className="social-icons" style={{ color: 'white', marginLeft: '118px' }}>
                      <ul className="nav nav-pills">
                        <li><a href><i className="fa fa-facebook" /></a></li>
                        <li><a href><i className="fa fa-twitter" /></a></li>
                        <li><a href><i className="fa fa-google-plus" /></a></li>
                        <li><a href><i className="fa fa-dribbble" /></a></li>
                        <li><a href><i className="fa fa-linkedin" /></a></li>
                      </ul>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="containerss">

            </div>
          </footer>
          {/*/#footer*/}
        </div>
      </div>
    )
  }
}