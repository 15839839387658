
import React, { useState, useEffect } from 'react';
import Select from 'react-select';


function Taggroup({Translated}) {
  const [data, setData] = useState([]);

  const [selectedValue, setSelectedValue] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    await fetch('https://testapi.paropakari.com/api/Group/Getgroup', { headers: { "Authorization": `Bearer ${accessToken}` } })
      .then(response => response.json())
      .then(receivedData => setData(receivedData));

  }
  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);
  }
  const customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "transparent",
      color: "black",
    })
  };

  let options = data.map(function (item) {
    return { value: item.group_id, label: item.group_Name, image: item.imagePath };
  })

  let formatOptionLabel = ({ value, label, image }) => (
    <div className="grp-flex" style={{ display: 'flex' }}>
      <img className="grp-edit-img2" src={image} />
      <span>{label}</span>
    </div>
  );


  return (

    <div>

      <Select

        placeholder={Translated ? Translated : 'Select'}

        // menuPortalTarget={document.querySelector('body')}
        onChange={handleChange}
        options={options}
        formatOptionLabel={formatOptionLabel}
        //options={data.map(e => ({ label: e.group_Name, value: e.group_id}))}
        isMulti
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            neutral50: 'black',
          },
        })}

      />

      {selectedValue && <div style={{ marginTop: 20, lineHeight: '25px' }}>

        <input type="hidden" id="tag_grps" name="tag_grps" defaultValue={selectedValue} required />

      </div>}
    </div>



  );
}
export default Taggroup;