import React, { Component } from 'react'

import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';


//import SideBars from './SideBars.js';


export default class Login1 extends Component {


  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: ""
    };
    this.onchange = this.onchange.bind(this);
  }
  componentDidMount() {
    loadCaptchaEnginge(6);
  };
  onchange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  redirect = (e) => {
    e.preventDefault();
    var page = {
      pathname: './Login.js',

    }
    this.props.history.push(page)
  }


  performLogin = async event => {
    event.preventDefault();


    //  localStorage.setItem('document',JSON.stringify(this.state));
    var body = {
      password: this.state.password,
      username: this.state.username
    };
    const url = "https://testapi.paropakari.com/api/Authenticate/login";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

        "Accept": "application/json"
      },
      body: JSON.stringify(body)
    };


    try {
      const response = await fetch(url, options);
      const result = await response.json();
      localStorage.setItem('user', JSON.stringify(result.token));
      localStorage.setItem('username', JSON.stringify(result.name));
      ///localStorage.setItem('create', JSON.stringify(result.id));

      

      if (response.ok) {
        var page = {
          pathname: '#/MyActivity',
          state: {
            data: {
              password: this.state.password,
              username: this.state.username,

            }
          }
        }
        
        //window.alert("login succesful");
        //this.props.history.push(page)
        alert("Login Successful...");
        window.location.href = "#/MyActivity";

      } else {
        localStorage.setItem('error', 'Username and Password is incorrect');

        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  handleEmailChange = evt => {
    this.setState({ username: evt.target.value });
  };

  handlePasswordChange = evt => {
    this.setState({ password: evt.target.value });
  };

  handleSubmit = () => {
    const { username, password } = this.state;
    alert(`Signed up with username: ${username} password: ${password}`);
  };

  render() {
    const { username, password } = this.state;
    const isEnabled = username.length > 0 && password.length > 0;

    const error = localStorage.getItem('error');

    window.onload = window.localStorage.clear();
    const handleRoute = () => {

      this.props.history.push("#/MyActivity");
    }
    return (



      <div>
        <title>Login V5</title>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/*===============================================================================================*/}
        <link rel="icon" type="image/png" href="./assets/images/icons/favicon.ico" />

        {/*===============================================================================================*/}

        {/*===============================================================================================*/}
        <link rel="stylesheet" type="text/css" href="./assets/css/util.css" />
        <link rel="stylesheet" type="text/css" href="./assets/css/main1.css" />
        <link href="css/bootstrap.min.css" rel="stylesheet" />
        <link href="css/font-awesome.min.css" rel="stylesheet" />
        <link href="css/lightbox.css" rel="stylesheet" />
        <link href="css/animate.min.css" rel="stylesheet" />
        <link href="css/main.css" rel="stylesheet" />
        <link href="css/responsive.css" rel="stylesheet" />
        {/*[if lt IE 9]>
              
              
          <![endif]*/}
        <link rel="shortcut icon" href="images/ico/favicon.ico" />
        <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
        <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
        <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
        <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />
        {/*===============================================================================================*/}
        <header id="header" style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}>
          <div className="container">
            <div className="row">

            </div>
          </div>
          <div className="navbar navbar-inverse" role="banner">
            <div className="container">
              <div className="navbar-header">
                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                </button>
                <a className="navbar-brand" href="index.html">
                  <h1 style={{ color: 'blue', fontFamily: 'Biysk-Regular' }}><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '19%', fontsize: '52px' }} />AROPAKARI</h1>
                </a>

              </div>

              <div className="collapse navbar-collapse">
                <ul className="nav navbar-nav navbar-right">

                  <li className="active"><a className="example" href="#/Login1" style={{ backgroundColor: '#ffd9a2', color: 'white', fontWeight: 'bold', marginRight: '82px', marginLeft: '-251px', fontSize: 18, textDecoration: 'none', whiteSpace: 'nowrap', fontFamily: 'cuyabra' }}>

                    Be a Paropakari
                  </a></li>
                  <li><a href="#/Home" style={{ fontFamily: 'cuyabra', fontWeight: 'bold' }}>Home</a></li>
                  <li><a href="#/Ourstory" style={{ fontFamily: 'cuyabra', fontWeight: 'bold' }}>Our Story</a></li>
                  <li><a href="#/Howitworks" style={{ fontFamily: 'cuyabra', fontWeight: 'bold' }}>How It Works</a></li>
                  <li><a href="#/Howitworks" style={{ fontFamily: 'cuyabra', fontWeight: 'bold' }}>Blog</a></li>

                  <li className="active"><a href="#/Login1" style={{ fontFamily: 'cuyabra', fontWeight: 'bold' }}>Login</a></li>
                </ul>
              </div>
              <div className="search">
                <form role="form">
                  <i className="fa fa-search" />
                  <div className="field-toggle">
                    <input type="text" className="search-form" autoComplete="off" placeholder="Search" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </header>
        <div className="limiter">
          <div className="container-login100" style={{ backgroundImage: 'url("./assets/images/10.jpg")' }}>
            <div className="wrap-login100 p-l-110 p-r-110 p-t-62 p-b-33">
              <form className="login100-form validate-form flex-sb flex-w" role="form" onSubmit={this.handleSubmit} >
                <span className="login100-form-title p-b-53">
                  Sign In With
                </span>
                <a href="#" className="btn-face m-b-20">
                  <i className="fa fa-facebook-official" />
                  Facebook
                </a>
                <a href="#" className="btn-google m-b-20">
                  <img src="./assets/images/icons/icon-google.png" alt="GOOGLE" />
                  Google
                </a>
                <div className="p-t-31 p-b-9">
                  <span className="txt1">
                    Username
                  </span>
                </div>
                <div className="wrap-input100 validate-input" data-validate="Username is required">
                  <input className="input100" value={this.state.username} autoComplete="off" onChange={this.handleEmailChange}
                    id="handleEmailChange" maxLength={20} />
                  <span className="focus-input100" />
                </div>
                <div className="p-t-13 p-b-9">
                  <span className="txt1">
                    Password
                  </span>
                  <a href="#" className="txt2 bo1 m-l-5">
                    Forgot?
                  </a>
                </div>
                <div className="wrap-input100 validate-input" data-validate="Password is required">
                  <input className="input100" type="password" value={this.state.password} autoComplete="off" onChange={this.handlePasswordChange}
                    data-val-length-max={100} data-val-length-min={4} data-val-required="The Password field is required." id="Password" name="password" />
                  <span className="focus-input100" />

                </div>
                <br />
                <div className="p-t-13 p-b-9">
                  <span className="txt1">

                    <LoadCanvasTemplate />
                  </span>

                </div>
                <div className="wrap-input100 validate-input" data-validate="Password is required">
                  <input className="input100" placeholder="Enter Captcha Value" id="user_captcha_input" name="user_captcha_input" style={{ width: '50%' }} type="text"></input><span className="focus-input100" />

                </div>


                <div className="container-login100-form-btn m-t-17">
                  <button className="login100-form-btn" onClick={event => this.performLogin(event)} id="Reg">
                    Log In
                  </button>
                </div>
                <div className="w-full text-center p-t-55">
                  <span className="txt2">
                    Not a member?
                  </span>
                  <a href="./Register1.js" className="txt2 bo1">
                    Sign up now
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div style={{ backgroundImage: "url(" + "./assets/images/23.jpg" + ")" }}>
          <footer>
            <br />

            <div className="container" style={{ marginRight: '455px' }}>
              <div className="row">
                <div className="col-sm-12 text-center bottom-separator">

                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="testimonial bottom">
                    <h2 style={{ color: 'white' }}>Contacts</h2>
                    <address style={{ color: 'white' }}>
                      E-mail: <a href="mailto:someone@example.com" style={{ color: 'white' }}>paropakaribeone@gmail.com</a> <br />

                    </address>

                    <div className="media">
                      <div className="pull-left">

                      </div>
                      <div className="media-body">

                      </div>
                    </div>
                    <div className="media">
                      <div className="pull-left">

                      </div>
                      <div className="media-body">

                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="contact-info bottom">

                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="contact-form bottom">
                    <h2 style={{ color: 'white' }}>Send a message</h2>
                    <form id="main-contact-form" name="contact-form" method="post" action="sendemail.php">
                      <div className="form-group">
                        <input type="text" name="name" className="form-control" required="required" placeholder="Name" />
                      </div>
                      <div className="form-group">
                        <input type="email" name="email" className="form-control" required="required" placeholder="Email Id" />
                      </div>
                      <div className="form-group">
                        <textarea name="message" id="message" required="required" className="form-control" rows={8} placeholder="Your text here" defaultValue={""} />
                      </div>
                      <div className="form-group">
                        <input type="submit" name="submit" className="btn btn-submit" defaultValue="Submit" />
                      </div>
                    </form>
                  </div>
                </div>

                <div className="col-sm-12 overflow">

                  <div className="social-icons" style={{ marginLeft: '655px', color: 'white' }}>
                    <ul className="nav nav-pills">
                      <li><a href><i className="fa fa-facebook" /></a></li>
                      <li><a href><i className="fa fa-twitter" /></a></li>
                      <li><a href><i className="fa fa-google-plus" /></a></li>
                      <li><a href><i className="fa fa-dribbble" /></a></li>
                      <li><a href><i className="fa fa-linkedin" /></a></li>
                    </ul>
                  </div>

                </div>
                <div className="col-sm-12">

                  <div className="copyright-text text-center">
                    <p style={{ marginLeft: '355px', color: 'white' }}>Copyright © 2021 Paropakari</p>
                    <p><a target="_blank" href="https://paropakari.com/"></a></p>
                  </div>
                </div>


              </div>
            </div>

          </footer>
        </div>
        <div id="dropDownSelect1" />

      </div>


    )
  }
}