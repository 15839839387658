import React, { Component } from 'react'
import AddBoxIcon from '@material-ui/icons/AddBox';
//import GroupDetail from './GroupsDetail.js';
import { Link } from "react-router-dom";
import Script from 'react-load-script'
import { Backdrop } from '@material-ui/core';
import Cookies from 'js-cookie';

const refreshPage = () => {

  setTimeout(() => window.location.reload(), 2000);

}

export default class GroupsDetail extends Component {

  constructor(props) {

    super(props);
    
    this.state = {
      id: this.props.match.params.id,
      Details: [],
      notify: [],
      foo: [],
      Menu: [],
      beaparopakari: [],
      myjourney: [],
      createacause: [],
      invite: [],
      profile: [], changepassword: [], group: [], logout: [], privacypolicy: [],
      requiredforr: [], self: [], familyfriends: [], others: [], beneficiaryname: [], submit: [],
      causes: [],
      beneficiaries: [],
      paropakaris: [],
      amountraised: [],
      copyright: [],
      contactus: [],
      termsandcondition: [],
      howitworks: [],
      friends: [],
      blog: [], donateasmile: [], addnew: [], groupicon: [], name: [], noofmembers: [], action: [], search: [], edit: [], details: [], deletee: [],
      groupinfo: [], members: [], back: [],
      langID: [],
      movedown: true,
    }
    this.submit = this.submit.bind(this);
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);

  }
  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown
    })
  }

  submit = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("?Group_Id=");
    

    const queryParams = new URLSearchParams(window.location.search);
    const Group_Id = queryParams.get('group_Id');

    

    let url = 'https://testapi.paropakari.com/api/Group/GroupDetailsDisplay?GroupId=' + this.props.match.params.id;

    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },

    }).then(response => response.json())
      .then(data => {
        this.setState({
          Details: data
        });
        

      });
  }


  notify = () => {

    let url = "https://testapi.paropakari.com/api/GetProfile/Notification_Count";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          notify: data
        });
        
        // 
      });
  }
  Footer = () => {

    

    let url = "https://testapi.paropakari.com/api/Authenticate/Get_FooterDetails";
    let data = this.state;

    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          foo: data
        });
        
        // 
      });
  }


  DefaultMenu() {
    
    let reloadsmyactivity = 0;
    window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
    window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
    window.sessionStorage.setItem('reload', (reloadsmyactivity));
    let MID = "English";
    if (sessionStorage.getItem('translate') !== null) {
      MID = sessionStorage.getItem('translate');
    }
    else {
      MID = "English";
    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others~HowItWorks~Privacy Policy&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Detailss: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Howitwork = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy = typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword = typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;

          var Others = typeof items[8] !== "undefined" ? items[8] : null;

          var LangID = MID;
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));
          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            others: Others,
            howitworks: Howitwork,
            privacypolicy: PrivacyPolicy,
            langID: LangID
          });

        });
    }
    if (MID != null) {

      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Detailss: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Blog = typeof items[0] !== "undefined" ? items[0] : null;
          var Contactus = typeof items[1] !== "undefined" ? items[1] : null;
          var Termsandcondition = typeof items[2] !== "undefined" ? items[2] : null;
          var CopyRight = typeof items[3] !== "undefined" ? items[3] : null;
          var Causes = typeof items[4] !== "undefined" ? items[4] : null;
          var Beneficiaries = typeof items[5] !== "undefined" ? items[5] : null;
          var Paropakaris = typeof items[6] !== "undefined" ? items[6] : null;
          var Amountraised = typeof items[7] !== "undefined" ? items[7] : null;
          var RequiredFor = typeof items[8] !== "undefined" ? items[8] : null;
          var Self = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            requiredforr: RequiredFor,
            self: Self,
            langID: LangID
          });

        });
    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Family/Friends~Beneficiary Name~Submit~Add New~Group Icon~Name~No. of Members~Action~Search~Edit&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Detailss: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var FamilyandFriends = typeof items[0] !== "undefined" ? items[0] : null;
          var BeneficiaryName = typeof items[1] !== "undefined" ? items[1] : null;
          var Submit = typeof items[2] !== "undefined" ? items[2] : null;
          var AddNew = typeof items[3] !== "undefined" ? items[3] : null;
          var GroupIcon = typeof items[4] !== "undefined" ? items[4] : null;
          var Name = typeof items[5] !== "undefined" ? items[5] : null;
          var NoofMembers = typeof items[6] !== "undefined" ? items[6] : null;
          var Action = typeof items[7] !== "undefined" ? items[7] : null;
          var Search = typeof items[8] !== "undefined" ? items[8] : null;
          var Edit = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            familyfriends: FamilyandFriends,
            beneficiaryname: BeneficiaryName,
            submit: Submit,
            addnew: AddNew,
            groupicon: GroupIcon,
            name: Name,
            noofmambers: NoofMembers,
            action: Action,
            search: Search,
            edit: Edit,
            langID: LangID
          });
        });
    }
    if (MID != null) {

      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Details~Delete~Group Info~Members~Back~Friends&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Detailss: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Details = typeof items[0] !== "undefined" ? items[0] : null;
          var Delete = typeof items[1] !== "undefined" ? items[1] : null;
          var GroupInfo = typeof items[2] !== "undefined" ? items[2] : null;
          var Members = typeof items[3] !== "undefined" ? items[3] : null;
          var Back = typeof items[4] !== "undefined" ? items[4] : null;
          var Friends = typeof items[5] !== "undefined" ? items[5] : null;
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            details: Details,
            deletee: Delete,
            langID: LangID,
            groupinfo: GroupInfo,
            members: Members,
            back: Back,
            friends: Friends,

          });
        });
    }
  }


  handleChanges(event) {
    this.setState({ value: event.target.value });
    

    let values = ['OurStory', 'HowItWorks', 'Blog', 'Login', 'BeaParopakari', 'What We Do'];
    
    let MID = event.target.value;
    if (MID != null) {

      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others~HowItWorks~Privacy Policy&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Detailss: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Howitwork = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy = typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;


          var ChangePasssword = typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;

          var Others = typeof items[8] !== "undefined" ? items[8] : null;

          var LangID = MID;
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));
          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            others: Others,
            howitworks: Howitwork,
            privacypolicy: PrivacyPolicy,
            langID: LangID
          });
        });
    }
    if (MID != null) {

      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Detailss: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Blog = typeof items[0] !== "undefined" ? items[0] : null;
          var Contactus = typeof items[1] !== "undefined" ? items[1] : null;
          var Termsandcondition = typeof items[2] !== "undefined" ? items[2] : null;
          var CopyRight = typeof items[3] !== "undefined" ? items[3] : null;
          var Causes = typeof items[4] !== "undefined" ? items[4] : null;
          var Beneficiaries = typeof items[5] !== "undefined" ? items[5] : null;
          var Paropakaris = typeof items[6] !== "undefined" ? items[6] : null;
          var Amountraised = typeof items[7] !== "undefined" ? items[7] : null;
          var RequiredFor = typeof items[8] !== "undefined" ? items[8] : null;
          var Self = typeof items[9] !== "undefined" ? items[9] : null;


          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            requiredforr: RequiredFor,
            self: Self,
            langID: LangID
          });

        });
    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Family/Friends~Beneficiary Name~Submit~Add New~Group Icon~Name~No. of Members~Action~Search~Edit&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Detailss: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var FamilyandFriends = typeof items[0] !== "undefined" ? items[0] : null;
          var BeneficiaryName = typeof items[1] !== "undefined" ? items[1] : null;
          var Submit = typeof items[2] !== "undefined" ? items[2] : null;
          var AddNew = typeof items[3] !== "undefined" ? items[3] : null;
          var GroupIcon = typeof items[4] !== "undefined" ? items[4] : null;
          var Name = typeof items[5] !== "undefined" ? items[5] : null;
          var NoofMembers = typeof items[6] !== "undefined" ? items[6] : null;
          var Action = typeof items[7] !== "undefined" ? items[7] : null;
          var Search = typeof items[8] !== "undefined" ? items[8] : null;
          var Edit = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            familyfriends: FamilyandFriends,
            beneficiaryname: BeneficiaryName,
            submit: Submit,
            addnew: AddNew,
            groupicon: GroupIcon,
            name: Name,
            noofmambers: NoofMembers,
            action: Action,
            search: Search,
            edit: Edit,
            langID: LangID

          });
        });
    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Details~Delete~Group Info~Members~Back~Friends&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Detailss: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Details = typeof items[0] !== "undefined" ? items[0] : null;
          var Delete = typeof items[1] !== "undefined" ? items[1] : null;
          var GroupInfo = typeof items[2] !== "undefined" ? items[2] : null;
          var Members = typeof items[3] !== "undefined" ? items[3] : null;
          var Back = typeof items[4] !== "undefined" ? items[4] : null;
          var Friends = typeof items[5] !== "undefined" ? items[5] : null;
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            details: Details,
            deletee: Delete,
            langID: LangID,
            groupinfo: GroupInfo,
            members: Members,
            back: Back,
            friends: Friends,

          });
        });
    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Cancel&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;


          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;


          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;


          

          //localStorage.setItem("GoogleTranslate123",InviteFriendsDropdown)

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";
          }
          else

            if (datatranslirate == "Kannada") {
              datatransIDs = "/en/kn";
            }

            else
              if (datatranslirate == "Tamil") {
                datatransIDs = "/en/ta";
              }
              else
                if (datatranslirate == "Telugu") {
                  datatransIDs = "/en/te";
                }
                else
                  if (datatranslirate == "Hindi") {
                    datatransIDs = "/en/hi";
                  }
                  else
                  if (datatranslirate == "Spanish") {
                    datatransIDs = "/en/es";
                  }
                  else
                    if (datatranslirate == MID) {
                      datatransIDs = MID;
                    }
          let RefreshID = 0;
          window.sessionStorage.setItem('reload', (RefreshID));
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
          window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
          window.sessionStorage.setItem('GoogleTranslateValues', (datatransIDs));

          
          let googletrasvalues = localStorage.setItem("GoogleTranslateValues", datatransIDs);

          

          

          Cookies.remove('googtrans', { domain: '.paropakari.com' });
          Cookies.remove('googtrans', { domain: 'https://paropakari.com/' });

          Cookies.remove('googtrans', { domain: 'https://www.paropakari.com/' });
          let usernames = localStorage.getItem("GoogleTranslateValues", datatransIDs);
          let values = datatransIDs;
          
          

          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
            const expires = "expires=" + date.toUTCString();
            document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
            // window.location.reload();
          }

          Cookies.set('googtrans', datatransIDs, { domain: 'https://test.paropakari.com/#/' });
          Cookies.set('googtrans', datatransIDs, { domain: '.paropakari.com' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://www.paropakari.com/' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://paropakari.com/' });
          setCookie('googtrans', datatransIDs, 30);
          setCookie('googtrans', datatransIDs, 'https://test.paropakari.com/#/');
          setCookie('googtrans', datatransIDs, '.paropakari.com');
          setCookie('googtrans', datatransIDs, 'https://www.paropakari.com/');
          setCookie('googtrans', datatransIDs, 'https://paropakari.com/');
          let cookie = Cookies.get('googtrans', 'https://test.paropakari.com/#/');
          
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,
          });
        });
    }
  }
  
  componentDidMount() {
    window.addEventListener('load', this.submit);
    this.submit();
    window.addEventListener('load', this.notify);
    this.notify();
    window.addEventListener('load', this.Footer);
    this.Footer();
    window.addEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
    const hideGoogleTranslateDropdowns = () => {
      const googleTranslateDropdown = document.querySelector('.VIpgJd-ZVi9od-ORHb');
      if (googleTranslateDropdown) {
        googleTranslateDropdown.style.display = 'none';
      }
    };
    const element = document.querySelector('.VIpgJd-ZVi9od-ORHb-OEVmcd');
    if (element && window.getComputedStyle(element).getPropertyValue('position') === 'fixed') {
      element.style.position = 'absolute';
    }
    // Call the function to hide the dropdown when the component mounts
    hideGoogleTranslateDropdowns();
    document.title = "India's only expenseless, direct crowdfunding platform"
  }


  componentWillUnmount() {
    window.removeEventListener('load', this.submit);
    this.submit();
    window.removeEventListener('load', this.notify);
    this.notify();
    window.removeEventListener('load', this.Footer);
    this.Footer();
    window.removeEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
    
  }


  handleonSubmit = (e) => {
    e.preventDefault()


    var page = {
      pathname: './Reset.js',
      pno: this.state.pno,

    }
    this.props.history.push(page)

  }

  handleScriptCreate() {
    this.setState({ scriptLoaded: false })
  }

  handleScriptError() {
    this.setState({ scriptError: true })
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true })
  }

  render() {
    const { Details } = this.state;
    const { notify } = this.state;
    let { foo, Menu } = this.state;
    var username = JSON.parse(localStorage.username);
    const { howitworks, blog, contactus, termsandcondition, causes, beneficiaries, friends,paropakaris, amountraised, copyright, beaparopakari, myjourney, createacause, invite,
      profile, changepassword, group, logout, others, requiredforr, familyfriends, self, submit, beneficiaryname,
      addnew, groupicon, name, noofmembers, action, search, edit, details, deletee, groupinfo, members, back, privacypolicy,
      langID } = this.state;
    return (
      <div
      className="VIpgJd-ZVi9od-ORHb-OEVmcd"
      style={{ position: "absolute" }}
    >
      <div
        style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}
        className="notranslate"
      >
        {/* <meta http-equiv="refresh" content="10"/>   */}
        <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
        <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
        <link href="./assets/css/lightbox.css" rel="stylesheet" />
        <link href="./assets/css/animate.min.css" rel="stylesheet" />
        <link href="./assets/css/main.css" rel="stylesheet" />

        {/*[if lt IE 9]>
              
              
  <![endif]*/}
        <link rel="shortcut icon" href="images/ico/favicon.ico" />
        <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
        <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
        <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
        <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />
        {/*/head*/}

        <select className="form-control" style={{ display: 'inline', width: '10%', marginTop: '0', marginLeft: '0', float: 'right', width: 'fit-content' }} value={this.state.value} onClick={refreshPage} onChange={this.handleChanges}>
          <option value="English">Languages</option>
          <option value="Kannada">Kannada</option>
          <option value="Tamil">Tamil</option>
          <option value="Hindi">Hindi</option>
          <option value="Telugu">Telugu</option>
         
          <option value="English">English</option>

        </select>
        &nbsp;&nbsp; &nbsp;&nbsp;


        <div>
          {/* <header id="header" style={{backgroundImage: "url(" + "./assets/images/10.jpg" + ")"}}>    

<div class="navbar navbar-inverse" role="banner">
   <div class="container">
       <div class="navbar-header">
           <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
               <span class="sr-only">Toggle navigassstion</span>
               <span class="icon-bar"></span>
               <span class="icon-bar"></span>
               <span class="icon-bar"></span>
           </button>

           <a class="navbar-brand" href="#/Homepage">
             <h1 style={{color:'blue',fontFamily:'Biysk-Regular'}}><img src="./assets/images/plogo.png" alt="logo"style={{marginBottom:'17px',width:'26%',fontsize:'52px'}}  />AROPAKARI
                   
               </h1>
           </a>
           
       </div>
       <div class="collapse navbar-collapse">
           <ul class="nav navbar-nav navbar-right">
           
               
           <li><a href={"#/Myactivity"}><b>{myjourney.result}</b></a></li> 
               <li><a href={"#/CauseFor"}><b>{createacause.result}</b></a></li> 
               <li><a href={"#/InviteFriend"}><b>{invite.result}</b></a></li> 
               <li className=""><a href={"#/Notification"} style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>
                            
                          <div class="fa fa-bell" style={{fontsize:'24px'}}>{notify.map((notifyy) => (
                                               <span class="-count"> 
                                
                              
                                {notifyy.noticount}
                               
                                  </span> ))}</div>
                           </a></li> 
               <li class="dropdown"><a><b>{username}</b> <i class="fa fa-angle-down"></i></a>
                   <ul role="menu" class="sub-menu">
                       <li><a href={"#/Profile"}><b>{profile.result}</b></a></li>
                       <li><a href={"#/ChangePassword"}><b>{changepassword.result}</b></a></li>
                       <li className="active"><a href={"#/Group"}><b>{group.result}</b></a></li>
                       <li><a href={"#/Login"}><b>{logout.result}</b></a></li>
                   </ul>
               </li>                   
                                 
           </ul>
       </div>
   
   </div>
</div>
</header> */}

          <div className="mag notranslate">
            <div class="pro-home-nav">
              <input type="checkbox" id="pro-home-nav-check" onClick={this.checkfun} />
              <div class="pro-home-nav-header">
                <div class="pro-home-nav-title notranslate">
                  <a className="navbar-brand" href="#/HomePage">
                    <h1 className="navbar-new-text"><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '300px' }} />AROPAKARI
                    </h1>
                  </a>
                </div>
              </div>

              <div class="pro-home-nav-btn">
                <label for="pro-home-nav-check">
                  <div className="pro-naav">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </label>
              </div>

              {langID == "English" ? <>
                <div class="pro-home-nav-links pro-side pro-top">
                  <p class='pro-center'>
                    <a className="new-design" href="#/CauseFor">{beaparopakari.result}</a>
                  </p>
                  <ul className="parop-home-boxx parop-boxx-top">
                    <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                    <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                    <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                    <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                      <span class="-count">


                        {notifyy.noticount}

                      </span>))}</div></a></li>
                    <li className="parop-menu"><a className="text-parop-menu" ><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                      <ul class="dropdown">
                        <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                        <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                        <li className="hower-text"><a className="text-parop-menu" href="#/Group"><b>{group.result}</b></a></li>
                        <li className="hower-text"><a href="#/Friends"><b>{friends.result}</b></a></li>
                        <li className="hower-text"><a href="#/Login"><b>{logout.result}</b></a></li>
                      </ul>
                    </li>

                  </ul>
                </div>
              </> : langID == "Hindi" ? <>
                <div class="pro-home-nav-links pro-side pro-top">
                  <p class='pro-center'>
                    <a className="new-design" href="#/CauseFor">{beaparopakari.result}</a>
                  </p>
                  <ul className="parop-home-boxx parop-boxx-top">
                    <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                    <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                    <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                    <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                      <span class="-count">


                        {notifyy.noticount}

                      </span>))}</div></a></li>
                    <li className="parop-menu"><a className="text-parop-menu" ><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                      <ul class="dropdown">
                        <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                        <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                        <li className="hower-text"><a className="text-parop-menu" href="#/Group"><b>{group.result}</b></a></li>
                        <li className="hower-text"><a href="#/Friends"><b>{friends.result}</b></a></li>
                        <li className="hower-text"><a href="#/Login"><b>{logout.result}</b></a></li>
                      </ul>
                    </li>

                  </ul>
                </div>
                </> : langID == "Spanish" ? <>
                <div class="pro-home-nav-links pro-side pro-top">
                  <p class='pro-center'>
                    <a className="new-design" href="#/CauseFor">{beaparopakari.result}</a>
                  </p>
                  <ul className="parop-home-boxx parop-boxx-top">
                    <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                    <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                    <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                    <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                      <span class="-count">


                        {notifyy.noticount}

                      </span>))}</div></a></li>
                    <li className="parop-menu"><a className="text-parop-menu" ><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                      <ul class="dropdown">
                        <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                        <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                        <li className="hower-text"><a className="text-parop-menu" href="#/Group"><b>{group.result}</b></a></li>
                        <li className="hower-text"><a href="#/Friends"><b>{friends.result}</b></a></li>
                        <li className="hower-text"><a href="#/Login"><b>{logout.result}</b></a></li>
                      </ul>
                    </li>

                  </ul>
                </div>
              </> : langID == "Tamil" ? <>
                <div class="pro-home-nav-links pro-side pro-top">
                  <p class='pro-center'>
                    <a className="new-tamil-design" href="#/CauseFor">{beaparopakari.result}</a>
                  </p>
                  <ul className="parop-home-tamil-boxx parop-boxx-top">
                    <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                    <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                    <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                    <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                      <span class="-count">


                        {notifyy.noticount}

                      </span>))}</div></a></li>
                    <li className="parop-menu"><a className="text-parop-menu" ><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                      <ul class="dropdown">
                        <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                        <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                        <li className="hower-text"><a className="text-parop-menu" href="#/Group"><b>{group.result}</b></a></li>
                        <li className="hower-text"><a href="#/Friends"><b>{friends.result}</b></a></li>
                        <li className="hower-text"><a href="#/Login"><b>{logout.result}</b></a></li>
                      </ul>
                    </li>

                  </ul>
                </div>
              </> : langID == "Kannada" ? <>
                <div class="pro-home-nav-links pro-side pro-top">
                  <p class='pro-center'>
                    <a className="new-telugu-design" href="#/CauseFor">{beaparopakari.result}</a>
                  </p>
                  <ul className="parop-home-tamil-boxx parop-boxx-top">
                    <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                    <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                    <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                    <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                      <span class="-count">


                        {notifyy.noticount}

                      </span>))}</div></a></li>
                    <li className="parop-menu"><a className="text-parop-menu" ><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                      <ul class="dropdown">
                        <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                        <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                        <li className="hower-text"><a className="text-parop-menu" href="#/Group"><b>{group.result}</b></a></li>
                        <li className="hower-text"><a href="#/Friends"><b>{friends.result}</b></a></li>
                        <li className="hower-text"><a href="#/Login"><b>{logout.result}</b></a></li>
                      </ul>
                    </li>

                  </ul>
                </div>
              </> : langID == "Telugu" ? <>
                <div class="pro-home-nav-links pro-side pro-top">
                  <p class='pro-center'>
                    <a className="new-telugu-design" href="#/CauseFor">{beaparopakari.result}</a>
                  </p>
                  <ul className="parop-home-tamil-boxx parop-boxx-top">
                    <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                    <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                    <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                    <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                      <span class="-count">


                        {notifyy.noticount}

                      </span>))}</div></a></li>
                    <li className="parop-menu"><a className="text-parop-menu" ><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                      <ul class="dropdown">
                        <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                        <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                        <li className="hower-text"><a className="text-parop-menu" href="#/Group"><b>{group.result}</b></a></li>
                        <li className="hower-text"><a href="#/Friends"><b>{friends.result}</b></a></li>
                        <li className="hower-text"><a href="#/Login"><b>{logout.result}</b></a></li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </> : null}
            </div>
          </div>

          {/*/#header*/}

          {/*/#page-breadcrumb*/}
          <section id="about-company" className={this.state.movedown == true ? "wow fadeInUp" : "wow fadeInUp Tiptop"} data-wow-duration="400ms" data-wow-delay="400ms" style={{ backgroundColor: '#aee6db' }}>

            <div className="container">
              {/* Page Content */}


              <div className="row-d" >
                <div id="user" className="col-md-12" >
                  <div className="panel-body" style={{overflow:'auto'}}>
                    <div className="tab-content">
                      <div role="tabpanel" className="tab-pane active" id="list">
                        <h2 style={{ marginLeft: '0%', textDecoration: 'none', color: 'black', fontsize: '25px' }}>

                          <b>{groupinfo.result}</b>

                          <a className="btn btn-secondary " style={{ textDecoration: 'none', marginLeft: '83%', fontSize: '14px', backgroundColor: 'rgb(102, 71, 18)', color: 'white' }} href={"#/Group"}>
                            {back.result}        </a></h2>

                        <table className="table table-striped table-bordered table-list" id="myTable">
                          <thead align="center">
                            <tr align="center" style={{ borderLeftColor: 'white', borderRightColor: 'white', backgroundColor: 'rgb(127 207 191)' }}>
                              <th className="avatar" style={{ width: '10%', borderLeftColor: 'white', borderRightColor: 'white', textAlign: 'center' }}> {groupicon.result}</th>
                              <th align="center" style={{ width: '10%', borderLeftColor: 'white', borderRightColor: 'white', textAlign: 'center' }}> {name.result}</th>
                              <th align="center" style={{ width: '10%', borderLeftColor: 'white', borderRightColor: 'white', textAlign: 'center' }}> {members.result}</th>

                            </tr>
                          </thead>
                          <tbody>
                            {Details.map(Det =>
                              <tr className="ok">
                                <td className="avatar" align="center"><img src={Det.imagePath} alt="Group Icon" style={{ height: 110, width: 110, borderRadius: '50%', borderLeftColor: 'transparent', borderRightColor: 'transparent' }} /></td>
                                <td align="center" style={{ borderLeftColor: 'transparent', borderRightColor: 'transparent', paddingTop: 50 }}> {Det.group_Name} </td>
                                <td align="center" style={{ borderLeftColor: 'transparent', borderRightColor: 'transparent', paddingTop: 50 }}>{Det.friends_Name}</td>

                              </tr>
                            )}
                          </tbody>
                        </table>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
          </section>

          {/*/#footer*/}
          <footer id="footer" style={{ color: 'white' }}>
            <br />
            <div className="container">
              <div className="copyright">

                <div className="row col-lg-12">

                  <div className="col-lg-9 mb-6">
                    {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}


                    <p style={{ marginLeft: '45px' }}>Copyright © 2023 Paropakari&nbsp; |&nbsp;<a href="#/Contactus" style={{ textDecoration: 'none', color: 'white' }}>{contactus.result}&nbsp;</a>| <a href="#/PrivacyPolicy" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{privacypolicy.result}&nbsp;</a>|<a href="#/TermsandCondition" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{termsandcondition.result}</a></p>

                  </div>
                  <div className="col-lg-3 mb-3">

                    <div className="social-icons" style={{ color: 'white', marginLeft: '118px' }}>
                      <ul className="nav nav-pills">
                        <li><a target="_blank" href="https://www.facebook.com/beoneparopakari"><i className="fa fa-facebook" /></a></li>
                        {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                        <li><a target="_blank" href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw" ><i className="fa fa-youtube-play" /></a></li>
                        <li><a target="_blank" href="https://www.instagram.com/paropakari_beone/" ><i className="fa fa-instagram" /></a></li>
                        <li><a target="_blank" href="https://www.linkedin.com/company/paropakari/"><i className="fa fa-linkedin" /></a></li>
                      </ul>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="containerss">

            </div>
          </footer>

          {/*/#footer*/}

        </div>
</div>
      </div>
    )
  }
}