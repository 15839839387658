import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import Script from 'react-load-script'
import Cookies from 'js-cookie';
//import SideBars from './SideBars.js';
import DisplayingPopup from './DisplayingPopup';
import { event } from 'jquery';
import LogoutLink from "./LogoutLink";

const refreshPage = (e) => {
  setTimeout(() => window.location.reload(true), 2000);
  e.preventDefault();
  var banner = document.getElementById('#google_translate_element');
  if (banner) {
    banner.style.display = 'none';
  }
}






export default class Editpage extends Component {

  constructor(props) {


    super(props)

    this.state = {
      user_id: "",
      profile_imagePath: "",
      profile_name: "",
      imageName: '',
      upload_id: "",
      created_date: "",
      upload_description: "",
      upload_amount: "",
      upload_place: "",
      upload_title: "",
      status_80G: "",
      isModalOpen: false,
      upload_num_of_beneficiaries: "",
      category_id: "",
      validbankacc:[],
      validifsc:[],
      category_name: "",
      delete_status: false,
      file_name: "",
      today: new Date().toISOString().split('T')[0]
,      file_type: "",
      likes: 0,
      filecount: 0,
      uid: "",
      comments: 3,
      confirmed: 0,
      pending: 0,
      response_pledge: "",
      response_message: "",
      emps: [],
      select: [],
      cancel: [],
      defaultimg: [],
      comm: [],
      like: [],
      notify: [],
      paropakaris: [],
      Tagged: [],
      greaterzero: [],
      Liked: [],
      foo: [],
      selectValue: "",
      beaparopakari: [],
      copyright_new: [],
      paropakari_new: [],
      yes: [],
      no: [], loading: false,
      myjourney: [],
      createacause: [],
      invite: [],
      profile: [], changepassword: [], group: [], logout: [], search: [], categories: [], foodandnutrition: [], health: [], professional: [], sports: [], socialwefare: [], others: [],
      sort: [], mostrecent: [], mostpopular: [], available80g: [], amountdesc: [], amountasc: [], complete: [], incomplete: [], education: [],
      causes: [], privacypolicy: [],
      beneficiaries: [],
      paropakariss: [], friends: [],
      amountraised: [],
      Minimumamt:[],
      amt: [],
      copyright: [], includedocs: [], forex: [],
      contactus: [],
      termsandcondition: [],
      howitworks: [],
      blog: [], tag: [], likess: [], comment: [], donateasmile: [],
      year: [], activity: [], mycauses: [], likedcauses: [], commentedcauses: [], pledgedcauses: [],
      langID: [],
      foo: [],
      HappinessMeter: [],
      self: [],
      familyfriends: [],
      others: [],
      Achieved: [],
      OnItsWay: [],Back:[],
      Makeadifferencewithpledgeof: [],
      Writemessageto: [],
      Thecreatorcause: [],
      isresponsibleforproviding: [],

      Paropakaritakesnoresponsibility: [],
      DonateNow: [],
      ScanandDonatewithAPP: [],
      CommentHere: [],
      CauseUpdatedSuccessfully: [],
      YourMessagetotheNeedy: [],
      YourofSupport: [],

      Submit: [],
      // Cancel:[],
      EndDate: [],
      enddate: [],
      EditYourCause: [],
      Title: [],
      Description: [],
      IFSCCode: [],
      ConfirmBankDetails: [],
      UPIID: [],
      Category: [],
      Amount: [],
      RequiredFor: [],
      AddSupportinDocuments: [],
      noofbeneficiary: [],
      ifscode: [],
      bankaccount: [],

      upload_num_of_beneficiaries: "",
      Change: [],
      Place: [],
      movedown: true,
      Status80G: [],


      ConfirmBelow: [],
      Bankaccount: [],
      ifsc: [],
      Upiid: [],
      add: [],
      Iamresponsiblefor: [],
      Close: [],
      Professional: [],
      Health: [],
      ImageDisplay: '',

      visible: false,
      passing: '',
      dpassing: '',
      GValue: '',
      Gchange: false,
      eightyg: [],
      placeRequired: [],
      formErrors: {},
      TitleisRequired: [],
      DescriptionisRequired: [],
      PlaceisRequired: [],
      NoofBenefRequired: [],
      EndDateRequired: [],
      AmountRequired: [],
      BankAccRequired: [],
      IfscRequired: [],
      isSelf: false,
    }
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this)
   // this.handleDropdownChanges = this.handleDropdownChanges.bind(this)
    this.uploadSingleFile = this.uploadSingleFile.bind(this);

  }
  redirect = (e) => {
    e.preventDefault();
    var page = {
      pathname: '#/Login',

    }
    this.props.history.push(page)
  }

  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown
    })
  }
  visiblity(data, imgid) {
    this.setState({
      visible: true
    })
    this.setState({
      passing: data
    })
    this.setState({
      dpassing: imgid
    })
  }


  imageClick = (item) => {
    alert(`${item}`)

  }
   Cancel = (e) => {
    // 9638521470 //8798687564
    //6380645358
    //Shan#123
    // window.location.href = "#/HomePage";
    let required = document.getElementById('default_relation_id');
    if(required !== 3)
    {
      this.setState({ isSelf: false })
   
    
    const value = this.state.emps[0].upload_num_of_beneficiaries;
    const targetField = document.getElementById('default_upload_num_of_beneficiaries'); // Replace with your target field ID
    if (targetField) {
      targetField.value = value;
    }
  }
    document.getElementById('NonmandaryForm').reset();
  }
   Cancel1 = (e) => {
    let required = document.getElementById('default_relation_id');
    if(required !== 3)
    {
      this.setState({ isSelf: false })
   
    
    const value = this.state.emps[0].upload_num_of_beneficiaries;
    const targetField = document.getElementById('default_upload_num_of_beneficiaries'); // Replace with your target field ID
    if (targetField) {
      targetField.value = value;
    }
  }

    let originalDateValue = document.getElementById('remainssame').value;
    
    
    // Assuming originalDateValue is in the format "Apr 30 2024", convert it to "2024-04-30"
    let parsedDate = new Date(originalDateValue);
    let year = parsedDate.getFullYear();
    let month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
    let day = parsedDate.getDate().toString().padStart(2, '0');
    let formattedDate = `${year}-${month}-${day}`;
  
    
    //
    //this.setState({ isSelf: false })
    // Reset the form
    // if (required == 3) {
    //   this.setState({ isSelf: true })
    // }
    // else {
    //   this.setState({ isSelf: false })
    // }


    document.getElementById('Editform').reset();
    //if()
 
   // this.setState({ isSelf: !this.state.isSelf})
    // Assign the formatted date to the defaultValue of the input field
    document.getElementById('default_required_by').defaultValue = formattedDate;
  
    
    // window.location.href = "#/HomePage";
    // document.getElementById('Editform').reset();
  }
  toggleLoader = () => {
    if (!this.state.loading) {
      this.setState({ loading: true })
    } else {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000);
    }

  }

  submit1 = () => {

    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");
    
    
    
    let url = "https://testapi.paropakari.com/api/Create/Get_MultiImage?id=" + this.props.match.params.id;
    let data = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;


    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          defaultimg: data
        });

        const str = data;


        for (let i = 0; i < data.length; i++) {

          //alert("Called");
          const str = data[i]

          //
        }

        // expected output: "the lazy dog."


        //  
        //  localStorage.setItem('Monedaryfield', JSON.stringify(data[0].causefor_Id));
        // 
      });
  }


  submit = () => {

    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");
    
    
    
    let url = "https://testapi.paropakari.com/api/GetProfile/Get_ResponseDetails?id=" + this.props.match.params.id;
    let data = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;


    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          emps: data
        });


localStorage.setItem('relationship', JSON.stringify(data[0].relationship));
let relation = JSON.parse(localStorage.getItem('relationship'));
if (relation === "Self") {
  this.setState({ isSelf: true })          
}
        localStorage.setItem('Monedaryfield', JSON.stringify(data[0].causefor_Id));
        // 
      });
  }

  notify = () => {


    let url = "https://testapi.paropakari.com/api/GetProfile/Notification_Count";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;


    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          notify: data
        });

        // 
      });
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem('username')
    localStorage.removeItem('user')

    localStorage.clear();
    window.location.href = "#/Login";

  }
  uploadSingleFile1(e) {
    // setFile([...file, URL.createObjectURL(e.target.files[0])]);

    // this.setState({
    //     file:e.target.files[0].name
    //   });

    //     for (let i = 0; i < e.target.files.length; i++) {
    //      // file.push(URL.createObjectURL(e.target.files[i]));

    //      this.setState({
    //           file: [...this.state.file,e.target.files[i].name]

    //          })


    //  // this.setState([...file, e.target.files[0].name]);
    //   
    //   
    // }

    this.setState({ files: [...this.state.files, ...e.target.files] })
    this.setState({

      file: [...this.state.file, e.target.files[0].name],
    }, () => {

      localStorage.setItem('Images', JSON.stringify(this.state.file));
    });



    // let file = [];

    //   for (let i = 0; i < e.target.files.length; i++) {
    //     file.push([e.target.files[0].name]);


    //   this.setState({

    //     file: [ ...this.state.file, e.target.files[0].name]

    //   });
    // 
    //  }


    // this.setState({
    //   file: [...this.state.file, e.target.files[0].name]
    // })
    // 
  }

  uploadSingleFile(e) {



    let ImagesArray = Object.entries(e.target.files).map((e) =>
      //   URL.createObjectURL(e[1])
      e[1].name

      // e.target.files
    );
    // 
    // // 
    // setFile([ ...this.state.file, ...ImagesArray]);



    this.setState({

      file: [...this.state.file, ...ImagesArray],
    }, () => {

      // localStorage.setItem('Images', JSON.stringify(this.state.file));
    });

  }


  imageHandleChange(e) {
    if (e.target.files) {
      this.setState({
        imageName: [...e.target.files, ...this.prevCountRef]
      })
    }


  }
  deleteimg = (index) => {
    const updatedImages = this.state.imageName.filter((_, i) => i !== index);


    


    this.setState({
      imageName: updatedImages,
    });
  }
  componentDidUpdate(prevCountRef) {
    if (this.state.imageName !== this.prevCountRef) {
      this.prevCountRef = this.state.imageName;
    }
  }


  // deletearrayimg(index1) {
  //   this.state.defaultimg.splice(index1, 1)
  //   this.setState({
  //     defaultimg: [...this.state.defaultimg]
  //   })

  // }
  // componentDidUpdate(prevCountRef) {
  //   if (this.state.defaultimg !== this.prevCountRef) {
  //     this.prevCountRef = this.state.defaultimg;
  //   }
  // }




  upload(e) {
    e.preventDefault();

  }
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  deleteFile(e) {
    const s = this.state.file.filter((item, index) => index !== e);

    this.setState({
      file: s
    });
    //  this.setState(file:s);

  }



  ReloadPage = () => {
    let reloads = 0;
    // setTimeout(() => window.location.reload(), 2000);
    if (sessionStorage.getItem('reloadcreate') !== null) {

      reloads = sessionStorage.getItem('reloadcreate');
      reloads++;
      window.sessionStorage.setItem('reloadcreate', (reloads));

    }
    else {
      reloads = 1;
      window.sessionStorage.setItem('reloadcreate', (reloads));

    }

    if (reloads == 1) {
      window.location.reload();
    }


  }


  DefaultMenu = () => {

    let reloads = 0;
    window.sessionStorage.setItem('reload', (reloads));

    let reloadsmyactivity = 0;
    window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
    window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));


    let MID = sessionStorage.getItem('translate');
    //         if(sessionStorage.getItem('transliteration') !== null){
    //           MID= sessionStorage.getItem('transliteration');
    //         }
    // else
    if (sessionStorage.getItem('translate') !== null) {
      MID = sessionStorage.getItem('translate');
    }
    else {
      MID = "English";

    }


    if (MID === 'English') {
      //
      // alert("English");
      document.getElementById("EnglishButton").click();
    }

    let datatrans = MID;


    localStorage.setItem("GoogleTranslate123", this.state.googtrans);

    let GID = sessionStorage.getItem('translate');


    let GIDS = JSON.parse(localStorage.getItem('this.state.googtrans'));


    let datatransID = MID;
    if (datatrans == "kn") {

      datatransID = "Kannada";
    }
    else
      if (datatrans == "ta") {
        datatransID = "Tamil";
      }
      else
        if (datatrans == "te") {
          datatransID = "Telugu";
        }
        else
          if (datatrans == "hi") {
            datatransID = "Hindi";
          }
          else
          if (datatrans == "es") {
            datatransID = "Spanish";
          }
          else
            if (datatrans == MID) {
              datatransID = MID;
            }

    let LnID = MID;
    if (LnID == "Kannada") {

      LnID = "kn";
    }
    else
      if (LnID == "Tamil") {
        LnID = "ta";
      }
      else
        if (LnID == "Telugu") {
          LnID = "te";
        }
        else
          if (LnID == "Hindi") {
            LnID = "hi";
          }
          else
          if (LnID == "Spanish") {
            LnID = "es";
          }
          else
            if (LnID == MID) {
              LnID = MID;
            }




    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Search~Categories~Food and Nutrition~Your Cause Updated Successfully&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword = typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;
          var Search = typeof items[8] !== "undefined" ? items[8] : null;
          var Categories = typeof items[9] !== "undefined" ? items[9] : null;
          var FoodandNutrtion = typeof items[10] !== "undefined" ? items[10] : null;
          var CauseUpdatedSuccessfully = typeof items[11] !== "undefined" ? items[11] : null;


          var LangID = datatransID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({
            myjourney: Myjourney,

            beaparopakari: Beaparopakari,

            createacause: Createacause,
            invite: Invite,

            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            search: Search,
            categories: Categories,
            foodandnutrition: FoodandNutrtion,
            CauseUpdatedSuccessfully: CauseUpdatedSuccessfully,
            langID: LangID

          });
        });

    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Health~Professional~Sports~Social Welfare~Others~Sort~Most Recent~Most popular~80G will be provided~Amount Ascending~Your Cause Updated Successfully&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Health = typeof items[0] !== "undefined" ? items[0] : null;
          var Professional = typeof items[1] !== "undefined" ? items[1] : null;
          var Sports = typeof items[2] !== "undefined" ? items[2] : null;
          var SocialWelfare = typeof items[3] !== "undefined" ? items[3] : null;
          var Others = typeof items[4] !== "undefined" ? items[4] : null;
          var Sort = typeof items[5] !== "undefined" ? items[5] : null;
          var MostRecent = typeof items[6] !== "undefined" ? items[6] : null;
          var MostPopular = typeof items[7] !== "undefined" ? items[7] : null;
          var Availabl80G = typeof items[8] !== "undefined" ? items[8] : null;

          var AmountAsc = typeof items[9] !== "undefined" ? items[9] : null;
          var CauseUpdatedSuccessfully = typeof items[10] !== "undefined" ? items[10] : null;


          var LangID = datatransID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({

            health: Health,
            langID: LangID,
            professional: Professional,
            sports: Sports,
            socialwefare: SocialWelfare,
            others: Others,
            sort: Sort,
            mostrecent: MostRecent,
            mostpopular: MostPopular,
            available80g: Availabl80G,
            amountasc: AmountAsc,
            CauseUpdatedSuccessfully: CauseUpdatedSuccessfully


          });


        });

    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Amount Descending~Complete~Incomplete~HowItWorks~Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes~Beneficiaries~Your Cause Updated Successfully&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var AmountDesc = typeof items[0] !== "undefined" ? items[0] : null;
          var Complete = typeof items[1] !== "undefined" ? items[1] : null;
          var Incomplete = typeof items[2] !== "undefined" ? items[2] : null;

          var Howitwork = typeof items[3] !== "undefined" ? items[3] : null;
          var Blog = typeof items[4] !== "undefined" ? items[4] : null;
          var Contactus = typeof items[5] !== "undefined" ? items[5] : null;
          var Termsandcondition = typeof items[6] !== "undefined" ? items[6] : null;
          var CopyRight = typeof items[7] !== "undefined" ? items[7] : null;
          var Causes = typeof items[8] !== "undefined" ? items[8] : null;
          var Beneficiaries = typeof items[9] !== "undefined" ? items[9] : null;
          var CauseUpdatedSuccessfully = typeof items[10] !== "undefined" ? items[10] : null;


          var LangID = datatransID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({
            amountdesc: AmountDesc,
            complete: Complete,
            incomplete: Incomplete,
            howitworks: Howitwork,
            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            CauseUpdatedSuccessfully: CauseUpdatedSuccessfully,

            langID: LangID
          });

        });

    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Amount Raised~Education~End Date~Tag~Like~Comments~Donate a Smile~Year~Activity~My Causes~Your Cause Updated Successfully&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Amountraised = typeof items[0] !== "undefined" ? items[0] : null;
          var Educattion = typeof items[1] !== "undefined" ? items[1] : null;
          var Enddate = typeof items[2] !== "undefined" ? items[2] : null;
          var Tag = typeof items[3] !== "undefined" ? items[3] : null;
          var Like = typeof items[4] !== "undefined" ? items[4] : null;
          var Comment = typeof items[5] !== "undefined" ? items[5] : null;
          var Donateasmile = typeof items[6] !== "undefined" ? items[6] : null;
          var Year = typeof items[7] !== "undefined" ? items[7] : null;
          var Activity = typeof items[8] !== "undefined" ? items[8] : null;
          var Mycauses = typeof items[9] !== "undefined" ? items[9] : null;
          var CauseUpdatedSuccessfully = typeof items[10] !== "undefined" ? items[10] : null;



          var LangID = datatransID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({
            amountraised: Amountraised,
            education: Educattion,
            enddate: Enddate,
            tag: Tag,
            likess: Like,
            comment: Comment,
            donateasmile: Donateasmile,
            year: Year,
            activity: Activity,
            mycauses: Mycauses,

            langID: LangID,
            CauseUpdatedSuccessfully: CauseUpdatedSuccessfully
          });


        });

    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Liked Causes~Commented Causes~Pledged Causes~Happiness Meter~Achieved~On Its Way~Make a difference with pledge of~Write a message to~The creator of the cause~is responsible for providing any documents~Your Cause Updated Successfully&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Likedcauses = typeof items[0] !== "undefined" ? items[0] : null;
          var Commentedcauses = typeof items[1] !== "undefined" ? items[1] : null;
          var Pledgedcauses = typeof items[2] !== "undefined" ? items[2] : null;

          var HappinessMeter = typeof items[3] !== "undefined" ? items[3] : null;
          var Achieved = typeof items[4] !== "undefined" ? items[4] : null;
          var OnItsWay = typeof items[5] !== "undefined" ? items[5] : null;
          var Makeadifferencewithpledgeof = typeof items[6] !== "undefined" ? items[6] : null;
          var Writemessageto = typeof items[7] !== "undefined" ? items[7] : null;
          var Thecreatorcause = typeof items[8] !== "undefined" ? items[8] : null;
          var isresponsibleforproviding = typeof items[9] !== "undefined" ? items[9] : null;
          var CauseUpdatedSuccessfully = typeof items[10] !== "undefined" ? items[10] : null;



          var LangID = datatransID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({

            likedcauses: Likedcauses,
            commentedcauses: Commentedcauses,
            pledgedcauses: Pledgedcauses,

            HappinessMeter: HappinessMeter,
            Achieved: Achieved,
            OnItsWay: OnItsWay,
            Makeadifferencewithpledgeof: Makeadifferencewithpledgeof,
            Writemessageto: Writemessageto,
            Thecreatorcause: Thecreatorcause,
            isresponsibleforproviding: isresponsibleforproviding,
            langID: LangID,
            CauseUpdatedSuccessfully: CauseUpdatedSuccessfully

          });

        });

    }
    if (MID != null) {

      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Paropakari.com takes no responsibility for these documents~Donate Now~Scan and Donate with APP~Comment Here~Your Message to the Needy~Your of Support~Submit~Edit Your Cause~Title~Description~Your Cause Updated Successfully~Bank Account&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Paropakaritakesnoresponsibility = typeof items[0] !== "undefined" ? items[0] : null;
          var DonateNow = typeof items[1] !== "undefined" ? items[1] : null;
          var ScanandDonatewithAPP = typeof items[2] !== "undefined" ? items[2] : null;
          var CommentHere = typeof items[3] !== "undefined" ? items[3] : null;
          var YourMessagetotheNeedy = typeof items[4] !== "undefined" ? items[4] : null;
          var YourofSupport = typeof items[5] !== "undefined" ? items[5] : null;

          var Submit = typeof items[6] !== "undefined" ? items[6] : null;


          var EditYourCause = typeof items[7] !== "undefined" ? items[7] : null;
          var Title = typeof items[8] !== "undefined" ? items[8] : null;
          var Description = typeof items[9] !== "undefined" ? items[9] : null;
          var CauseUpdatedSuccessfully = typeof items[10] !== "undefined" ? items[10] : null;
          var BankAccount = typeof items[11] !== "undefined" ? items[11] : null;
          var LangID = datatransID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({

            Paropakaritakesnoresponsibility: Paropakaritakesnoresponsibility,
            DonateNow: DonateNow,
            ScanandDonatewithAPP: ScanandDonatewithAPP,
            CommentHere: CommentHere,
            YourMessagetotheNeedy: YourMessagetotheNeedy,
            YourofSupport: YourofSupport,

            Submit: Submit,

            EditYourCause: EditYourCause,
            Title: Title,
            Description: Description,
            bankaccount: BankAccount,
            langID: LangID,
            CauseUpdatedSuccessfully: CauseUpdatedSuccessfully

          });

        });

    }

    //new add
    if (MID != null) {

      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=No of Beneficiaries~IFSC Code~UPI ID~Confirm Bank Details~Cancel&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var NoofBeneficiaries = typeof items[0] !== "undefined" ? items[0] : null;
          var IFSCCode = typeof items[1] !== "undefined" ? items[1] : null;
          var UPIID = typeof items[2] !== "undefined" ? items[2] : null;
          var ConfirmBankDetails = typeof items[3] !== "undefined" ? items[3] : null;
          var Cancel = typeof items[4] !== "undefined" ? items[4] : null;
          var LangID = datatransID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({

            cancel: Cancel,
            noofbeneficiary: NoofBeneficiaries,
            IFSCCode: IFSCCode,
            UPIID: UPIID,
            ConfirmBankDetails: ConfirmBankDetails,
            langID: LangID,

          });

        });
    }

    //new add

    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Category~Amount~Required For~Add Supporting Documents~Change~Place~Status 80G~Confirm Below~I am responsible for providing 80G or any tax related documents to the supporters of the cause~Close~Your Cause Updated Successfully&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Categorys = typeof items[0] !== "undefined" ? items[0] : null;
          var Amount = typeof items[1] !== "undefined" ? items[1] : null;
          var RequiredFor = typeof items[2] !== "undefined" ? items[2] : null;
          var AddSupportinDocuments = typeof items[3] !== "undefined" ? items[3] : null;
          var Change = typeof items[4] !== "undefined" ? items[4] : null;
          var Place = typeof items[5] !== "undefined" ? items[5] : null;

          var Status80G = typeof items[6] !== "undefined" ? items[6] : null;


          var ConfirmBelow = typeof items[7] !== "undefined" ? items[7] : null;
          var Iamresponsiblefor = typeof items[8] !== "undefined" ? items[8] : null;
          var Close = typeof items[9] !== "undefined" ? items[9] : null;
          var CauseUpdatedSuccessfully = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = datatransID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({
            Category: Categorys,
            Amount: Amount,
            RequiredFor: RequiredFor,
            AddSupportinDocuments: AddSupportinDocuments,
            Change: Change,
            Place: Place,

            Status80G: Status80G,
            langID: LangID,

            ConfirmBelow: ConfirmBelow,
            Iamresponsiblefor: Iamresponsiblefor,
            Close: Close,
            CauseUpdatedSuccessfully: CauseUpdatedSuccessfully,

          });

        });

    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Paropakaris~Privacy Policy~Include documents that support your cause~For Example: Medical Bills, Medical Certificates, School Register, Images etc~Friends~Your Cause Updated Successfully&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Paropakaris = typeof items[0] !== "undefined" ? items[0] : null;
          var PrivacyPolicy = typeof items[1] !== "undefined" ? items[1] : null;
          var IncludeDocs = typeof items[2] !== "undefined" ? items[2] : null;
          var ForEx = typeof items[3] !== "undefined" ? items[3] : null;
          var Friends = typeof items[4] !== "undefined" ? items[4] : null;
          var CauseUpdatedSuccessfully = typeof items[5] !== "undefined" ? items[5] : null;
          var LangID = datatransID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({
            paropakaris: Paropakaris,
            privacypolicy: PrivacyPolicy,
            includedocs: IncludeDocs,
            forex: ForEx,
            friends: Friends,
            langID: LangID,
            CauseUpdatedSuccessfully: CauseUpdatedSuccessfully

          });

        });

    }

    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari~Self~Family/Friends~Others~Choose 80G&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;
          var self = typeof items[2] !== "undefined" ? items[2] : null;
          var familyfriends = typeof items[3] !== "undefined" ? items[3] : null;
          var others = typeof items[4] !== "undefined" ? items[4] : null;
          var EightyG = typeof items[5] !== "undefined" ? items[5] : null;
          // var Greaterzero = typeof items[7] !== "undefined" ? items[7] : null;
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
            self: self,
            familyfriends: familyfriends,
            others: others,
            eightyg: EightyG,
            //  greaterzero:Greaterzero
          });


          // 
        });
    }

    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Enter a number greater than zero~Enter the Bank Account~Enter the IFSC number~UPI Id~Add&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;


          var Greaterzero = typeof items[0] !== "undefined" ? items[0] : null;
          var Bankaccount = typeof items[1] !== "undefined" ? items[1] : null;

          var ifsc = typeof items[2] !== "undefined" ? items[2] : null;
          var Upiid = typeof items[3] !== "undefined" ? items[3] : null;
          var add = typeof items[4] !== "undefined" ? items[4] : null;
          //var EightyG = typeof items[5] !== "undefined" ? items[5] : null;
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({

            greaterzero: Greaterzero,
            Bankaccount: Bankaccount,
            ifsc: ifsc,
            Upiid: Upiid,
            add: add
          });


          // 
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Yes~No~Enter Amount~Select~Place is Required.~Minimum amount should be 100~Back&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;


          var yes = typeof items[0] !== "undefined" ? items[0] : null;
          var no = typeof items[1] !== "undefined" ? items[1] : null;
          var amt = typeof items[2] !== "undefined" ? items[2] : null;
          var Select = typeof items[3] !== "undefined" ? items[3] : null;
          var PlaceRequired = typeof items[4] !== "undefined" ? items[4] : null;
          var Minimumamt = typeof items[5] !== "undefined" ? items[5] : null;
          var Back = typeof items[6] !== "undefined" ? items[6] : null;
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({

            yes: yes,
            no: no,
            amt: amt,
            select: Select,
            placeRequired: PlaceRequired,
            Minimumamt:Minimumamt,
            Back:Back

          });


          // 
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Title is Required.~Description is Required.~Place is Required.~Number of beneficiaries is required~End Date is Required~Amount is Required.~Bank Account is Required.~IFSC code Required&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var TitleisRequired = typeof items[0] !== "undefined" ? items[0] : null;
          var DescriptionisRequired = typeof items[1] !== "undefined" ? items[1] : null;
          var PlaceisRequired = typeof items[2] !== "undefined" ? items[2] : null;
          var NoofBenefRequired = typeof items[3] !== "undefined" ? items[3] : null;
          var EndDateRequired = typeof items[4] !== "undefined" ? items[4] : null;
          var AmountRequired = typeof items[5] !== "undefined" ? items[5] : null;
          var BankAccRequired = typeof items[6] !== "undefined" ? items[6] : null;
          var IfscRequired = typeof items[7] !== "undefined" ? items[7] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            TitleisRequired: TitleisRequired,
            DescriptionisRequired: DescriptionisRequired,
            PlaceisRequired: PlaceisRequired,
            NoofBenefRequired: NoofBenefRequired,
            EndDateRequired: EndDateRequired,
            AmountRequired: AmountRequired,
            BankAccRequired: BankAccRequired,
            IfscRequired: IfscRequired,
            lID: LID
          });

        });
    }
  }



  handleChanges(event) {
    this.setState({ value: event.target.value });

    // localStorage.setItem('menuid', event.target.value);
    let values = ['OurStory', 'HowItWorks', 'Blog', 'Login', 'BeaParopakari', 'What We Do'];

    let l_id = JSON.parse(localStorage.getItem('MenuID'));

    let LnID = event.target.value;
    if (LnID == "Kannada") {

      LnID = "kn";
    }
    else
      if (LnID == "Tamil") {
        LnID = "ta";
      }
      else
        if (LnID == "Telugu") {
          LnID = "te";
        }
        else
          if (LnID == "Hindi") {
            LnID = "hi";
          }
          else
          if (LnID == "Spanish") {
            LnID = "es";
          }
          else
            if (LnID == event.target.value) {
              LnID = event.target.value;
            }
    let MID = event.target.value;
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Search~Categories~Food and Nutrition~Your Cause Updated Successfully&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword = typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;
          var Search = typeof items[8] !== "undefined" ? items[8] : null;
          var Categories = typeof items[9] !== "undefined" ? items[9] : null;
          var FoodandNutrtion = typeof items[10] !== "undefined" ? items[10] : null;
          var CauseUpdatedSuccessfully = typeof items[11] !== "undefined" ? items[11] : null;


          var LangID = MID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({
            myjourney: Myjourney,

            beaparopakari: Beaparopakari,

            createacause: Createacause,
            invite: Invite,

            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            search: Search,
            categories: Categories,
            foodandnutrition: FoodandNutrtion,
            langID: LangID,
            CauseUpdatedSuccessfully: CauseUpdatedSuccessfully,

          });
        });

    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Health~Professional~Sports~Social Welfare~Others~Sort~Most Recent~Most popular~80G will be provided~Amount Ascending~Your Cause Updated Successfully&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Health = typeof items[0] !== "undefined" ? items[0] : null;
          var Professional = typeof items[1] !== "undefined" ? items[1] : null;
          var Sports = typeof items[2] !== "undefined" ? items[2] : null;
          var SocialWelfare = typeof items[3] !== "undefined" ? items[3] : null;
          var Others = typeof items[4] !== "undefined" ? items[4] : null;
          var Sort = typeof items[5] !== "undefined" ? items[5] : null;
          var MostRecent = typeof items[6] !== "undefined" ? items[6] : null;
          var MostPopular = typeof items[7] !== "undefined" ? items[7] : null;
          var Availabl80G = typeof items[8] !== "undefined" ? items[8] : null;

          var AmountAsc = typeof items[9] !== "undefined" ? items[9] : null;
          var CauseUpdatedSuccessfully = typeof items[10] !== "undefined" ? items[10] : null;


          var LangID = MID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({

            health: Health,
            langID: LangID,
            professional: Professional,
            sports: Sports,
            socialwefare: SocialWelfare,
            others: Others,
            sort: Sort,
            mostrecent: MostRecent,
            mostpopular: MostPopular,
            available80g: Availabl80G,
            amountasc: AmountAsc,
            CauseUpdatedSuccessfully: CauseUpdatedSuccessfully,


          });


        });

    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Amount Descending~Complete~Incomplete~HowItWorks~Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes~Beneficiaries~Your Cause Updated Successfully&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var AmountDesc = typeof items[0] !== "undefined" ? items[0] : null;
          var Complete = typeof items[1] !== "undefined" ? items[1] : null;
          var Incomplete = typeof items[2] !== "undefined" ? items[2] : null;

          var Howitwork = typeof items[3] !== "undefined" ? items[3] : null;
          var Blog = typeof items[4] !== "undefined" ? items[4] : null;
          var Contactus = typeof items[5] !== "undefined" ? items[5] : null;
          var Termsandcondition = typeof items[6] !== "undefined" ? items[6] : null;
          var CopyRight = typeof items[7] !== "undefined" ? items[7] : null;
          var Causes = typeof items[8] !== "undefined" ? items[8] : null;
          var Beneficiaries = typeof items[9] !== "undefined" ? items[9] : null;
          var CauseUpdatedSuccessfully = typeof items[10] !== "undefined" ? items[10] : null;



          var LangID = MID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({
            amountdesc: AmountDesc,
            complete: Complete,
            incomplete: Incomplete,
            howitworks: Howitwork,
            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            CauseUpdatedSuccessfully: CauseUpdatedSuccessfully,

            langID: LangID
          });

        });

    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Amount Raised~Education~End Date~Tag~Like~Comments~Donate a Smile~Year~Activity~My Causes~Your Cause Updated Successfully&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Amountraised = typeof items[0] !== "undefined" ? items[0] : null;
          var Educattion = typeof items[1] !== "undefined" ? items[1] : null;
          var Enddate = typeof items[2] !== "undefined" ? items[2] : null;
          var Tag = typeof items[3] !== "undefined" ? items[3] : null;
          var Like = typeof items[4] !== "undefined" ? items[4] : null;
          var Comment = typeof items[5] !== "undefined" ? items[5] : null;
          var Donateasmile = typeof items[6] !== "undefined" ? items[6] : null;
          var Year = typeof items[7] !== "undefined" ? items[7] : null;
          var Activity = typeof items[8] !== "undefined" ? items[8] : null;
          var Mycauses = typeof items[9] !== "undefined" ? items[9] : null;
          var CauseUpdatedSuccessfully = typeof items[10] !== "undefined" ? items[10] : null;



          var LangID = MID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({
            amountraised: Amountraised,
            education: Educattion,
            enddate: Enddate,
            tag: Tag,
            likess: Like,
            comment: Comment,
            donateasmile: Donateasmile,
            year: Year,
            activity: Activity,
            mycauses: Mycauses,
            CauseUpdatedSuccessfully: CauseUpdatedSuccessfully,
            langID: LangID
          });


        });

    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Liked Causes~Commented Causes~Pledged Causes~Happiness Meter~Achieved~On Its Way~Make a difference with pledge of~Write a message to~The creator of the cause~is responsible for providing any documents~Your Cause Updated Successfully&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Likedcauses = typeof items[0] !== "undefined" ? items[0] : null;
          var Commentedcauses = typeof items[1] !== "undefined" ? items[1] : null;
          var Pledgedcauses = typeof items[2] !== "undefined" ? items[2] : null;

          var HappinessMeter = typeof items[3] !== "undefined" ? items[3] : null;
          var Achieved = typeof items[4] !== "undefined" ? items[4] : null;
          var OnItsWay = typeof items[5] !== "undefined" ? items[5] : null;
          var Makeadifferencewithpledgeof = typeof items[6] !== "undefined" ? items[6] : null;
          var Writemessageto = typeof items[7] !== "undefined" ? items[7] : null;
          var Thecreatorcause = typeof items[8] !== "undefined" ? items[8] : null;
          var isresponsibleforproviding = typeof items[9] !== "undefined" ? items[9] : null;
          var CauseUpdatedSuccessfully = typeof items[10] !== "undefined" ? items[10] : null;



          var LangID = MID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({

            likedcauses: Likedcauses,
            commentedcauses: Commentedcauses,
            pledgedcauses: Pledgedcauses,

            HappinessMeter: HappinessMeter,
            Achieved: Achieved,
            OnItsWay: OnItsWay,
            Makeadifferencewithpledgeof: Makeadifferencewithpledgeof,
            Writemessageto: Writemessageto,
            Thecreatorcause: Thecreatorcause,
            isresponsibleforproviding: isresponsibleforproviding,
            langID: LangID,
            CauseUpdatedSuccessfully: CauseUpdatedSuccessfully

          });

        });

    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Paropakari.com takes no responsibility for these documents~Donate Now~Scan and Donate with APP~Comment Here~Your Message to the Needy~Your of Support~Submit~Edit Your Cause~Title~Description&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Paropakaritakesnoresponsibility = typeof items[0] !== "undefined" ? items[0] : null;
          var DonateNow = typeof items[1] !== "undefined" ? items[1] : null;
          var ScanandDonatewithAPP = typeof items[2] !== "undefined" ? items[2] : null;
          var CommentHere = typeof items[3] !== "undefined" ? items[3] : null;
          var YourMessagetotheNeedy = typeof items[4] !== "undefined" ? items[4] : null;
          var YourofSupport = typeof items[5] !== "undefined" ? items[5] : null;

          var Submit = typeof items[6] !== "undefined" ? items[6] : null;

          var EditYourCause = typeof items[7] !== "undefined" ? items[7] : null;
          var Title = typeof items[8] !== "undefined" ? items[8] : null;
          var Description = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({
            Paropakaritakesnoresponsibility: Paropakaritakesnoresponsibility,
            DonateNow: DonateNow,
            ScanandDonatewithAPP: ScanandDonatewithAPP,
            CommentHere: CommentHere,
            YourMessagetotheNeedy: YourMessagetotheNeedy,
            YourofSupport: YourofSupport,
            Submit: Submit,
            EditYourCause: EditYourCause,
            Title: Title,
            Description: Description,
            langID: LangID,

          });

        });

    }

    //New Add

    if (MID != null) {

      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=No of Beneficiaries~IFSC Code~UPI ID~Confirm Bank Details~Cancel~Your Cause Updated Successfully~Bank Account&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var NoofBeneficiaries = typeof items[0] !== "undefined" ? items[0] : null;
          var IFSCCode = typeof items[1] !== "undefined" ? items[1] : null;
          var UPIID = typeof items[2] !== "undefined" ? items[2] : null;
          var ConfirmBankDetails = typeof items[3] !== "undefined" ? items[3] : null;
          var Cancel = typeof items[4] !== "undefined" ? items[4] : null;
          var CauseUpdatedSuccessfully = typeof items[5] !== "undefined" ? items[5] : null;
          var BankAccount = typeof items[6] !== "undefined" ? items[6] : null;
          var LangID = MID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({

            noofbeneficiary: NoofBeneficiaries,
            IFSCCode: IFSCCode,
            UPIID: UPIID,
            ConfirmBankDetails: ConfirmBankDetails,
            cancel: Cancel,
            bankaccount: BankAccount,
            CauseUpdatedSuccessfully: CauseUpdatedSuccessfully,
            langID: LangID

          });

        });

    }

    //New Add

    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Category~Amount~Required For~Add Supporting Documents~Change~Place~Status 80G~Confirm Below~I am responsible for providing 80G or any tax related documents to the supporters of the cause~Close~Your Cause Updated Successfully&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Categorys = typeof items[0] !== "undefined" ? items[0] : null;
          var Amount = typeof items[1] !== "undefined" ? items[1] : null;
          var RequiredFor = typeof items[2] !== "undefined" ? items[2] : null;
          var AddSupportinDocuments = typeof items[3] !== "undefined" ? items[3] : null;
          var Change = typeof items[4] !== "undefined" ? items[4] : null;
          var Place = typeof items[5] !== "undefined" ? items[5] : null;

          var Status80G = typeof items[6] !== "undefined" ? items[6] : null;


          var ConfirmBelow = typeof items[7] !== "undefined" ? items[7] : null;
          var Iamresponsiblefor = typeof items[8] !== "undefined" ? items[8] : null;
          var Close = typeof items[9] !== "undefined" ? items[9] : null;
          var CauseUpdatedSuccessfully = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({
            Category: Categorys,
            Amount: Amount,
            RequiredFor: RequiredFor,
            AddSupportinDocuments: AddSupportinDocuments,
            Change: Change,
            Place: Place,

            Status80G: Status80G,
            langID: LangID,

            ConfirmBelow: ConfirmBelow,
            Iamresponsiblefor: Iamresponsiblefor,
            Close: Close,
            CauseUpdatedSuccessfully: CauseUpdatedSuccessfully


          });

        });

    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Paropakaris~Privacy Policy~Include documents that support your cause~For Example: Medical Bills, Medical Certificates, School Register, Images etc~Friends~Your Cause Updated Successfully&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Paropakaris = typeof items[0] !== "undefined" ? items[0] : null;
          var PrivacyPolicy = typeof items[1] !== "undefined" ? items[1] : null;
          var IncludeDocs = typeof items[2] !== "undefined" ? items[2] : null;
          var ForEx = typeof items[3] !== "undefined" ? items[3] : null;
          var Friends = typeof items[4] !== "undefined" ? items[4] : null;
          var CauseUpdatedSuccessfully = typeof items[5] !== "undefined" ? items[5] : null;
          var LangID = MID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));


          this.setState({
            paropakaris: Paropakaris,
            privacypolicy: PrivacyPolicy,
            includedocs: IncludeDocs,
            forex: ForEx,
            friends: Friends,
            CauseUpdatedSuccessfully: CauseUpdatedSuccessfully,
            langID: LangID

          });

        });

    }

    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari~Self~Family/Friends~Others~Choose 80G~&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;

          var self = typeof items[2] !== "undefined" ? items[2] : null;
          var familyfriends = typeof items[3] !== "undefined" ? items[3] : null;
          var others = typeof items[4] !== "undefined" ? items[4] : null;
          var EightyG = typeof items[5] !== "undefined" ? items[5] : null;
          //  var Greaterzero = typeof items[7] !== "undefined" ? items[7] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
            self: self,
            familyfriends: familyfriends,
            others: others,
            eightyg: EightyG,
            //  greaterzero:Greaterzero
          });


          // 
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Enter a number greater than zero~Enter the Bank Account~Enter the IFSC number~UPI Id~Add&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;


          var Greaterzero = typeof items[0] !== "undefined" ? items[0] : null;
          var Bankaccount = typeof items[1] !== "undefined" ? items[1] : null;

          var ifsc = typeof items[2] !== "undefined" ? items[2] : null;
          var Upiid = typeof items[3] !== "undefined" ? items[3] : null;
          var add = typeof items[4] !== "undefined" ? items[4] : null;
          //var EightyG = typeof items[5] !== "undefined" ? items[5] : null;
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({

            greaterzero: Greaterzero,
            Bankaccount: Bankaccount,
            ifsc: ifsc,
            Upiid: Upiid,
            add: add
          });


          // 
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Yes~No~Enter Amount~Select~Place is Required.~Minimum amount should be 100~Back&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;


          var yes = typeof items[0] !== "undefined" ? items[0] : null;
          var no = typeof items[1] !== "undefined" ? items[1] : null;
          var amt = typeof items[2] !== "undefined" ? items[2] : null;
          var Select = typeof items[3] !== "undefined" ? items[3] : null;
          var PlaceRequired = typeof items[4] !== "undefined" ? items[4] : null;
          var Minimumamt = typeof items[5] !== "undefined" ? items[5] : null;
          var Back = typeof items[6] !== "undefined" ? items[6] : null;
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({

            yes: yes,
            no: no,
            amt: amt,
            select: Select,
            placeRequired: PlaceRequired,
            Minimumamt:Minimumamt,
            Back:Back
          });


          // 
        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Title is Required.~Description is Required.~Place is Required.~Number of beneficiaries is required~End Date is Required~Amount is Required.~Bank Account is Required.~IFSC code Required&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var TitleisRequired = typeof items[0] !== "undefined" ? items[0] : null;
          var DescriptionisRequired = typeof items[1] !== "undefined" ? items[1] : null;
          var PlaceisRequired = typeof items[2] !== "undefined" ? items[2] : null;
          var NoofBenefRequired = typeof items[3] !== "undefined" ? items[3] : null;
          var EndDateRequired = typeof items[4] !== "undefined" ? items[4] : null;
          var AmountRequired = typeof items[5] !== "undefined" ? items[5] : null;
          var BankAccRequired = typeof items[6] !== "undefined" ? items[6] : null;
          var IfscRequired = typeof items[7] !== "undefined" ? items[7] : null;
          var LangID = MID;
          var LID = LnID;
          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            TitleisRequired: TitleisRequired,
            DescriptionisRequired: DescriptionisRequired,
            PlaceisRequired: PlaceisRequired,
            NoofBenefRequired: NoofBenefRequired,
            EndDateRequired: EndDateRequired,
            AmountRequired: AmountRequired,
            BankAccRequired: BankAccRequired,
            IfscRequired: IfscRequired,
            lID: LID
          });

        });
    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Cancel~Enter valid bank account~Enter Valid IFSC Code&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;


          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;
          
          var ValidBankAcc = typeof items[1] !== "undefined" ? items[1] : null;
          var Validifsc = typeof items[2] !== "undefined" ? items[2] : null;
          

          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;



          //localStorage.setItem("GoogleTranslate123",InviteFriendsDropdown)

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";

          }
          else

            if (datatranslirate == "Kannada") {
              datatransIDs = "/en/kn";
            }

            else
              if (datatranslirate == "Tamil") {
                datatransIDs = "/en/ta";
              }
              else
                if (datatranslirate == "Telugu") {
                  datatransIDs = "/en/te";
                }
                else
                  if (datatranslirate == "Hindi") {
                    datatransIDs = "/en/hi";
                  }
                  else
                  if (datatranslirate == "Spanish") {
                    datatransIDs = "/en/es";
                  }
                  else
                    if (datatranslirate == MID) {
                      datatransIDs = MID;
                    }
          let RefreshID = 0;
          window.sessionStorage.setItem('reload', (RefreshID));
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
          window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
          window.sessionStorage.setItem('GoogleTranslateValues', (datatransIDs));


          let googletrasvalues = localStorage.setItem("GoogleTranslateValues", datatransIDs);







          Cookies.remove('googtrans', { domain: '.paropakari.com' });
          Cookies.remove('googtrans', { domain: 'https://paropakari.com/' });

          Cookies.remove('googtrans', { domain: 'https://www.paropakari.com/' });
          let usernames = localStorage.getItem("GoogleTranslateValues", datatransIDs);
          let values = datatransIDs;



          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
            const expires = "expires=" + date.toUTCString();
            document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
            // window.location.reload();
          }

          Cookies.set('googtrans', datatransIDs, { domain: 'https://test.paropakari.com/#/' });
          Cookies.set('googtrans', datatransIDs, { domain: '.paropakari.com' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://www.paropakari.com/' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://paropakari.com/' });
          setCookie('googtrans', datatransIDs, 30);
          setCookie('googtrans', datatransIDs, 'https://test.paropakari.com/#/');
          setCookie('googtrans', datatransIDs, '.paropakari.com');
          setCookie('googtrans', datatransIDs, 'https://www.paropakari.com/');
          setCookie('googtrans', datatransIDs, 'https://paropakari.com/');
          let cookie = Cookies.get('googtrans', 'https://test.paropakari.com/#/');

          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,
            validbankacc: ValidBankAcc,
            validifsc:Validifsc

          });

        });
    }

  }

  Changetextbox = e => {

    this.setState({ GValue: e.target.value });
    this.setState({ Gchange: true });
  }

  handleMonetaryValidation() {

    let title = document.getElementById('default_upload_title').value;
    let description = document.getElementById('upload_description').value;
    let noofBeneficiary = document.getElementById('default_upload_num_of_beneficiaries').value;
    let amount = document.getElementById('default_upload_amount').value;
    let place = document.getElementById('upload_place').value;
    let bnkacc = document.getElementById('upload_BankAccount').value;
    let ifsc = document.getElementById('upload_IFSC').value;
    let formErrors = {};
    let formIsValid = true;

    if (title == '' || title == null) {
      formIsValid = false;
      formErrors["ttlErr"] = "Title is Required.";
    }
    if (description == '' || description == null) {
      formIsValid = false;
      formErrors["descErr"] = "Description is Required.";
    }
    if (noofBeneficiary == '' || noofBeneficiary == null) {
      formIsValid = false;
      formErrors["nbErr"] = "Number of beneficiaries is Required.";
    }
    if (amount == '' || amount == null ) {
      formIsValid = false;
   //   document.getElementById('greaterzeroerror').innerHTML ="'hi'";
      formErrors["amtErr"] = "Amount is Required.";
      
    }
    else {

      const amountValue = parseFloat(amount);

      if (amountValue > 100000) {
        formIsValid = false;
        formErrors["upalkhErr"] = "Amount should not exceed 1 lakh.";
      }
    }
    if (place == '' || place == null) {
      formIsValid = false;
      formErrors["plcErr"] = "Place is Required.";
    }
    if (bnkacc == '' || bnkacc == null) {
      formIsValid = false;
      formErrors["bnkErr"] = "Bank Account is Required.";
    }
    if (ifsc == '' || ifsc == null) {
      formIsValid = false;
      formErrors["ifscErr"] = "IFSC Code Required.";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;

  }


  handleSubmitmonitoryevents = async event => {
    event.preventDefault();

    this.handleMonetaryValidation();
   
let valueee=document.getElementById('default_upload_num_of_beneficiaries').value;

    var upload_amt = document.getElementById("default_upload_amount").value;
    var upload_plc = document.getElementById("upload_place").value;

    var bankAccount = document.getElementById("upload_BankAccount").value;
    var ifsccode = document.getElementById("upload_IFSC").value;

    
    const ree = new RegExp('^[0-9]{9,18}$');
    const isOkk = ree.test(bankAccount);
    
    

    if (!isOkk) {
      
      //this.state.validifsc.result
      
      return alert(this.state.validbankacc.result ? this.state.validbankacc.result : 'Enter valid bank account');
    }


    const re = new RegExp('^[A-Za-z]{4}0[A-Z0-9a-z]{6}$');
    const isOk = re.test(ifsccode);
    
    
    if (!isOk) {
      
      return alert(this.state.validifsc.result ? this.state.validifsc.result : 'Enter valid IFSC');
    }


//upload_BankAccount
    // placeerror
    if (upload_amt == 0 && upload_amt != '' ) {
      // alert("called1");
    alert(this.state.greaterzero.result ? this.state.greaterzero.result : 'Enter a number greater than zero');
    //  this.greaterthan(event);
    }
   else if (upload_amt < 100) {
      // alert("called1");
      alert(
        this.state.Minimumamt.result
          ? this.state.Minimumamt.result
          : "Minimum amount should be 100");
  
      //this.greaterthan(event);
    }
    else if (upload_plc == '') {
      // document.getElementById('placeerror').innerHTML = this.state.placeRequired.result ? this.state.placeRequired.result : 'Place is Required.'     
    }
    if(this.state.GValue === "3")
    {

    }
    else {
      const { eightyg } = this.state;
      if (this.state.Gchange === false || this.state.GValue !== "2") {
        event.preventDefault();

        //alert("Monitorycalled");

        const data = new FormData(event.target);
        var upload_upi = document.getElementById("upload_UPIID").value;
        if (upload_upi === '' || upload_upi === 'NO UPI ID') {
          //  alert("alert");
          var defaultValue = 'NOUPIID';
          data.append('upload_UPIID', defaultValue);
          
        }
      else{

          const reeUPI = new RegExp('[a-zA-Z0-9.\-_]@[a-zA-Z]');
          const isOkkUPI = reeUPI.test(upload_upi);
          
          
          if (!isOkkUPI) {

            return alert('Enter valid UPI');

          }
        }
        let user = JSON.parse(localStorage.getItem('user'));

        data.append('default_file_name', this.state.imageName);

        for (let i = 0; i < this.state.imageName.length; i++) {
          data.append('default_file_name', this.state.imageName[i]);

        }
        
        if(this.state.isSelf === true)
        {
         
          data.append('default_upload_num_of_beneficiaries',1);
        }
        const accessToken = user;
        this.toggleLoader();
        const url = "https://testapi.paropakari.com/api/Create/EditMonitoryPage";
        const options = {
          method: "POST",
          headers: {

            Accept: 'application/json',
            Authorization: "Bearer " + accessToken
          },
          body: data,
        };
        //  this.setState(this.initialState)  
        const response = await fetch(url, options);
        const result = await response.json();

        if (response.ok) {
        //  var upload_title = document.getElementById("default_upload_title").value;
          alert(this.state.CauseUpdatedSuccessfully.result ? this.state.CauseUpdatedSuccessfully.result : 'Your Cause Updated Successfully');
          // alert('Your cause ' + upload_title + ' has been updated successfully');
          window.location.href = "#/MyActivity"
        //  window.location.reload();
        }
        else {
          alert("Please Choose End Date");
          // window.location.reload();
        }
        //this.setState(this.initialState)

      }
      else {
        alert(eightyg.result);
      }
    }
  };

  handleNonMonetaryValidation() {

    let title = document.getElementById('default_upload_title').value;
    let description = document.getElementById('upload_description').value;
    let noofBeneficiary = document.getElementById('default_upload_num_of_beneficiaries').value;
    let place = document.getElementById('default_upload_place').value;
    let endDate = document.getElementById('default_required_by').value;
    let formErrors = {};
    let formIsValid = true;

    if (title == '' || title == null) {
      formIsValid = false;
      formErrors["ttlErr"] = "Title is Required.";
    }
    if (description == '' || description == null) {
      formIsValid = false;
      formErrors["descErr"] = "Description is Required.";
    }

    if (noofBeneficiary == '' || noofBeneficiary == null) {
      formIsValid = false;
      formErrors["nbErr"] = "Number of beneficiaries is Required.";
    }
    if (place == '' || place == null) {
      formIsValid = false;
      formErrors["plcErr"] = "Place is Required.";
    }
    if (endDate == '' || endDate == null) {
      formIsValid = false;
      formErrors["enddtErr"] = "End Date is Required.";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;

  }

  handleSubmitevents = async event => {
    event.preventDefault();

    this.handleNonMonetaryValidation();

    let valueee=document.getElementById('default_upload_num_of_beneficiaries').value;

    const data = new FormData(event.target);
    let user = JSON.parse(localStorage.getItem('user'));

    data.append('default_file_name', this.state.imageName);
   
    for (let i = 0; i < this.state.imageName.length; i++) {
      data.append('default_file_name', this.state.imageName[i]);

    }
    //     if(this.state.defaultimg.length >0)

    //     {
    //       alert("dbvalue");
    // for(let i = 0; i < this.state.defaultimg.length; i++)
    // {
    //     data.append('default_file_name', this.state.defaultimg[i]);
    //     
    // }
    //     }
    if(this.state.isSelf === true)
    {
      
      data.append('default_upload_num_of_beneficiaries',1);
    }
    const accessToken = user;
    this.toggleLoader();
    const url = "https://testapi.paropakari.com/api/Create/EditPage";
    const options = {
      method: "POST",
      headers: {

        Accept: 'application/json',
        Authorization: "Bearer " + accessToken
      },
      body: data,
    };
    //  this.setState(this.initialState)  
    const response = await fetch(url, options);
    const result = await response.json();
    if (response.ok) {
      alert(this.state.CauseUpdatedSuccessfully.result ? this.state.CauseUpdatedSuccessfully.result : 'Your Cause Updated Successfully')
      window.location.href = "#/MyActivity"
     // window.location.reload();
    }
    else {
      // alert("Incorrect Current Password.");
      // window.location.reload();
    }
    this.setState(this.initialState)



  };

  Footer = () => {




    let url = "https://testapi.paropakari.com/api/Authenticate/Get_FooterDetails";
    let data = this.state;



    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          foo: data
        });

        // 
      });
  }
  greaterthan = (e) => {
    const value = e.target.value;
    // if(value == 0)
    // {
    //  // alert("called");
    //   document.getElementById('greaterzeroerror').innerHTML = this.state.greaterzero.result
    //  // var banner = document.getElementById('#google_translate_element');
    // }
    // Check if the entered value is a valid number greater than zero
    const isValid = !isNaN(value) && parseFloat(value) > 0;
    const error = this.state.greaterzero.result ? this.state.greaterzero.result : 'Enter a number greater than zero.'
    this.setState({
      inputValue: value,
      errorMessage: isValid ? '' : error,
    });
  };
  handleDropdownChange(e) {
    var upload_required = document.getElementById("default_relation_id").value; 
    
    if (e.target.value == 3) {
      this.setState({ isSelf: true })
    }
    else {
      this.setState({ isSelf: false })
    }
    // if(upload_required === "3")
    // {
    //   
    //   document.getElementById("default_upload_num_of_beneficiaries").disabled = true; 
    // }else{
    //   document.getElementById("default_upload_num_of_beneficiaries").disabled = false; 
    // }
    
      
    
    this.setState({ selectValue: e.target.value });
  }
  componentWillUnmount() {
    window.removeEventListener('load', this.submit);
    this.submit();
    window.removeEventListener('load', this.submit1);
    this.submit1();
    window.removeEventListener('load', this.notify);
    this.notify();
    window.removeEventListener('load', this.Footer);
    this.Footer();
    window.addEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
  }

  componentDidMount() {
    window.addEventListener('load', this.submit);
    this.submit();
    window.removeEventListener('load', this.submit1);
    this.submit1();
    window.addEventListener('load', this.notify);
    this.notify();
    window.addEventListener('load', this.Footer);
    this.Footer();
    window.removeEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
    const element = document.querySelector('.VIpgJd-ZVi9od-ORHb-OEVmcd');
    if (element && window.getComputedStyle(element).getPropertyValue('position') === 'fixed') {
      element.style.position = 'absolute';
    }
    document.title = "India's only expenseless, direct crowdfunding platform"

  }

  handleScriptCreate() {
    this.setState({ scriptLoaded: false })
  }

  handleScriptError() {
    this.setState({ scriptError: true })
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true })
  }

  render() {
    const { howitworks, blog, contactus, termsandcondition, causes, greaterzero, beneficiaries, paropakariss, amountraised,
      copyright, beaparopakari, myjourney, createacause, invite, profile, changepassword, group, logout, search,
      categories, education, foodandnutrition, health, professional, sports, socialwefare, others, privacypolicy,
      sort, mostrecent, mostpopular, available80g, amountdesc, amountasc, complete, incomplete, enddate, tag, likess,
      comment, donateasmile, year, activity, mycauses, likedcauses, commentedcauses, pledgedcauses, HappinessMeter,
      Achieved, forex, friends, CauseUpdatedSuccessfully, self,
      familyfriends,
      OnItsWay, includedocs, yes, no,
      Makeadifferencewithpledgeof,
      Writemessageto, select,
      Thecreatorcause,
      isresponsibleforproviding,
      YourMessagetotheNeedy,
      YourofSupport,
      Paropakaritakesnoresponsibility,
      DonateNow,
      ScanandDonatewithAPP,
      CommentHere, Submit, EndDate, langID, EditYourCause,
      Title,
      Description,
      Category, amt,
      cancel,
      Amount,
      Bankaccount,
      ifsc,
      Upiid,
      add,
      IFSCCode,
      ConfirmBankDetails,
      UPIID,
      RequiredFor,
      AddSupportinDocuments,
      bankaccount,
      noofbeneficiary,
      Change,
      Place,
      Back,
      Status80G,
      TitleisRequired,
      DescriptionisRequired,
      PlaceisRequired,
      NoofBenefRequired,
      EndDateRequired,
      AmountRequired,
      BankAccRequired,
      IfscRequired,
      ConfirmBelow,
      Iamresponsiblefor,
      Close,
      Professional,
      Health,
      Education, SocialWelfare, copyright_new,
      paropakari_new,isSelf } = this.state;
    const { emps } = this.state;
    const { ttlErr, descErr, nbErr, plcErr, enddtErr, amtErr, bnkErr, ifscErr, upalkhErr } = this.state.formErrors;
    const currentYear = new Date().getFullYear();
    const { defaultimg } = this.state;
    const { notify } = this.state;
    let { foo } = this.state;
    //  const today = new Date().toISOString().split('T')[0];
    var username = JSON.parse(localStorage.username);
    // var Category_text = JSON.parse(localStorage.CauseForID);

    var Category_text = JSON.parse(localStorage.getItem("Monedaryfield"));
    if (this.state.loading) {
      return (
        <center className="notranslate">
        <img className="loading-img2 notranslate" src="https://testapi.paropakari.com/images/Paropakari.gif" />
      </center>
      )
    } else {

    return (
      <div className="VIpgJd-ZVi9od-ORHb-OEVmcd" style={{ position: "absolute" }}>
        <div style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }} className="notranslate">
          {/* <meta http-equiv="refresh" content="10"/>   */}
          <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
          <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
          <link href="./assets/css/lightbox.css" rel="stylesheet" />
          <link href="./assets/css/animate.min.css" rel="stylesheet" />
          <link href="./assets/css/main.css" rel="stylesheet" />

          {/*[if lt IE 9]>
              
              
          <![endif]*/}
          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />
          {/*/head*/}
          {/*/head*/}

          {/* <div onClick={refreshPage}> */}

          <select className="form-control" style={{ display: 'inline', width: '10%', marginTop: '0', marginLeft: '0', float: 'right', width: 'fit-content' }} value={this.state.value} onChange={this.handleChanges}>
            <option value="English">Languages</option>
            <option value="Kannada">Kannada</option>
            <option value="Tamil">Tamil</option>
            <option value="Hindi">Hindi</option>
            <option value="Telugu">Telugu</option>
             {/* <option value="Spanish">Spanish</option> */}
            <option value="English" onClick={window['checkboxClickHandler']}>English</option>
          </select>
          &nbsp;&nbsp; &nbsp;&nbsp;

          {/* </div> */}

          <div className="mag notranslate">
            <div class="pro-home-nav">
              <input type="checkbox" id="pro-home-nav-check" onClick={this.checkfun} />
              <div class="pro-home-nav-header">
                <div class="pro-home-nav-title notranslate">
                  <a className="navbar-brand" href="#/HomePage">
                    <h1 className="navbar-new-text"><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '300px' }} />AROPAKARI
                    </h1>
                  </a>
                </div>
              </div>

              <div class="pro-home-nav-btn">
                <label for="pro-home-nav-check">
                  <div className="pro-naav">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </label>
              </div>

              {langID == "English" ? <>
                <div class="pro-home-nav-links pro-side pro-top">
                  <p class='pro-center'>
                    <a className="new-design" href="#/CauseFor">{beaparopakari.result}</a>
                  </p>
                  <ul className="parop-home-boxx parop-boxx-top">
                    <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                    <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                    <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                    <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                      <span class="-count">


                        {notifyy.notificationCount}

                      </span>))}</div></a></li>
                    <li className="parop-menu"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                      <ul class="dropdown">
                        <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                        <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                        <li className="hower-text"><a href="#/Group"><b>{group.result}</b></a></li>
                        <li className="hower-text"><a href="#/Friends"><b>{friends.result}</b></a></li>
                        <li className="hower-text"><LogoutLink logoutResult={logout.result} /></li>
                      </ul>
                    </li>

                  </ul>
                </div>
              </> : langID == "Hindi" ? <>
                <div class="pro-home-nav-links pro-side pro-top">
                  <p class='pro-center'>
                    <a className="new-design" href="#/CauseFor">{beaparopakari.result}</a>
                  </p>
                  <ul className="parop-home-boxx1 parop-boxx-top">
                    <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                    <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                    <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                   
                    <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                      <span class="-count">


                        {notifyy.notificationCount}

                      </span>))}</div></a></li>
                    <li className="parop-menu"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                      <ul class="dropdown">
                        <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                        <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                        <li className="hower-text"><a href="#/Group"><b>{group.result}</b></a></li>
                        <li className="hower-text"><a href="#/Friends"><b>{friends.result}</b></a></li>
                        <li className="hower-text"><LogoutLink logoutResult={logout.result} /></li>
                      </ul>
                    </li>

                  </ul>
                </div>
                </> : langID == "Spanish" ? <>
                <div class="pro-home-nav-links pro-side pro-top">
                  <p class='pro-center'>
                    <a className="new-tamil-design" href="#/CauseFor">{beaparopakari.result}</a>
                  </p>
                  <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                    <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                    <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                    <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                    <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                      <span class="-count">


                        {notifyy.notificationCount}

                      </span>))}</div></a></li>
                    <li className="parop-menu"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                      <ul class="dropdown">
                        <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                        <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                        <li className="hower-text"><a href="#/Group"><b>{group.result}</b></a></li>
                        <li className="hower-text"><a href="#/Friends"><b>{friends.result}</b></a></li>
                        <li className="hower-text"><LogoutLink logoutResult={logout.result} /></li>
                      </ul>
                    </li>

                  </ul>
                </div>
              </> : langID == "Tamil" ? <>
                <div class="pro-home-nav-links pro-side pro-top">
                  <p class='pro-center'>
                    <a className="new-tamil-design" href="#/CauseFor">{beaparopakari.result}</a>
                  </p>
                  <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                    <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                    <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                    <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                    <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                      <span class="-count">


                        {notifyy.notificationCount}

                      </span>))}</div></a></li>
                    <li className="parop-menu"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                      <ul class="dropdown">
                        <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                        <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                        <li className="hower-text"><a href="#/Group"><b>{group.result}</b></a></li>
                        <li className="hower-text"><a href="#/Friends"><b>{friends.result}</b></a></li>
                        <li className="hower-text"><LogoutLink logoutResult={logout.result} /></li>
                      </ul>
                    </li>

                  </ul>
                </div>
              </> : langID == "Kannada" ? <>
                <div class="pro-home-nav-links pro-side pro-top">
                  <p class='pro-center'>
                    <a className="new-telugu-design" href="#/CauseFor">{beaparopakari.result}</a>
                  </p>
                  <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                    <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                    <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                    <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                    <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                      <span class="-count">


                        {notifyy.notificationCount}

                      </span>))}</div></a></li>
                    <li className="parop-menu"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                      <ul class="dropdown">
                        <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                        <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                        <li className="hower-text"><a href="#/Group"><b>{group.result}</b></a></li>
                        <li className="hower-text"><a href="#/Friends"><b>{friends.result}</b></a></li>
                        <li className="hower-text"><LogoutLink logoutResult={logout.result} /></li>
                      </ul>
                    </li>

                  </ul>
                </div>
              </> : langID == "Telugu" ? <>
                <div class="pro-home-nav-links pro-side pro-top">
                  <p class='pro-center'>
                    <a className="new-telugu-design" href="#/CauseFor">{beaparopakari.result}</a>
                  </p>
                  <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                    <li className="parop-menu"><a href="#/Myactivity">{myjourney.result}</a></li>
                    <li className="parop-menu"><a href="#/CauseFor">{createacause.result}</a></li>
                    <li className="parop-menu"><a href="#/InviteFriend">{invite.result}</a></li>
                    <li className="parop-menu parop-notify"><a href="#/Notification"><div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                      <span class="-count">


                        {notifyy.notificationCount}

                      </span>))}</div></a></li>
                    <li className="parop-menu"><a><b>{username}</b> <i className="fa fa-angle-down"></i></a>
                      <ul class="dropdown">
                        <li className="hower-text"><a href="#/Profile"><b>{profile.result}</b></a></li>
                        <li className="hower-text"><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                        <li className="hower-text"><a href="#/Group"><b>{group.result}</b></a></li>
                        <li className="hower-text"><a href="#/Friends"><b>{friends.result}</b></a></li>
                        <li className="hower-text"><LogoutLink logoutResult={logout.result} /></li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </> : null}
            </div>
          </div>
          {/* <header id="header" style={{backgroundImage: "url(" + "./assets/images/10.jpg" + ")"}}>      
              <div className="container">
                <div className="row">
                
                </div>
              </div>
              <div className="navbar navbar-inverse" role="banner">
                <div className="container">
                  <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                   <a className="navbar-brand" href="#/HomePage">
                   <h1 style={{color:'blue',fontFamily:'Biysk-Regular'}}><img src="./assets/images/plogo.png" alt="logo" style={{marginBottom:'17px',width:'26%',fontsize:'52px'}}  />AROPAKARI</h1>
          </a>
          
                  </div>
                  
                  <div className="collapse navbar-collapse">
                  <ul className="nav navbar-nav navbar-right">
                    
                  {(() => {if (langID == 'Tamil' ) {return (
       <li id="paro" className="active"><a className="btn1 third" href="#/CauseFor" target="_blank" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',marginRight:'40px',marginLeft:'-274px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>
           
       {beaparopakari.result}
       </a></li>
);
}
                 else {return(

                  <li id="paro" className="active"><a className="btn1 third" href="#/CauseFor" target="_blank" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',marginRight:'100px',marginLeft:'-274px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>
           
           {beaparopakari.result}
           </a></li>

);}

})()}    
        
                    
                                                 
                            
                                   <li className=""><a href="#/MyActivity" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{myjourney.result}</a></li>     
                                   <li className="active"><a href="#/CauseFor" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{createacause.result}</a></li>     
                                   <li className=""><a href="#/InviteFriend" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{invite.result}</a></li>    
                                            
                                   <li className=""><a href="#/Notification" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>
                                     
                                   <div class="fa fa-bell" style={{fontsize:'24px'}}>{notify.map((notifyy) => (
                                                        <span class="-count"> 
                                         
                                       
                                         {notifyy.notificationCount}
                                        
                                           </span> ))}</div>
                                    </a></li>               
                                    <li class="dropdown"><a><b>{username}</b> <i class="fa fa-angle-down"></i></a>
                                     <ul role="menu" className="sub-menu">
                                       <li><a href="#/Profile">{profile.result}</a></li>
                                       <li><a href="#/ChangePassword">{changepassword.result}</a></li>
                                       <li><a href="#/Group">{group.result}</a></li>
                                       <li><a href="#/Login">{logout.result}</a></li>
                                      
                                     </ul>
                                   </li>    
           
                                         
                                 </ul>
                  </div>
                  
                </div>
              </div>
            </header> */}
          {/*/#header*/}

          {/*/#page-breadcrumb*/}
          <section id="about-company" className={this.state.movedown == true ? "wow fadeInUp" : "wow fadeInUp Tiptop"} data-wow-duration="400ms" data-wow-delay="400ms" style={{ backgroundColor: '#aee6db' }}>
            <div className="container">
              <div className='back'>

              </div>

              <button id="EnglishButton" onClick={window['checkboxClickHandler']} style={{ display: 'none' }}>click</button>
              {(() => {
                          if (langID == "Tamil") {
                            return (
              <a href="#/MyActivity" className="btn btn-secondary " style={{ textDecoration: 'none', marginLeft: '0%', fontSize: '14px', backgroundColor: 'rgb(102, 71, 18)', color: 'white', width: "88px" }}>
                {Back.result}</a>
                   );
                  } else {
                    return (
                      <a href="#/MyActivity" className="btn btn-secondary " style={{ textDecoration: 'none', marginLeft: '0%', fontSize: '14px', backgroundColor: 'rgb(102, 71, 18)', color: 'white', width: "50px" }}>
                      {Back.result}</a>
                       );
                      }
                    })()}
              <h3><b>{EditYourCause.result ? EditYourCause.result : 'Edit Your Cause'}</b></h3>
              <div className="">
                <div className="">
                  <div className="">
                    <div className="">

                      <div className="">

                        {(() => {
                          if (Category_text == '0') {
                            return (
                              <form action="post" onSubmit={this.handleSubmitmonitoryevents} id="Editform">

                                <input name="__RequestVerificationToken" type="hidden" defaultValue="kPG3wygn6fNp1Rjeiz40yH3LKUuuDF1iVHvwIgrKB8AAWbtbNFI4MDOouDw8PfRhfwEr8h3zS3JrKBkyfHMxIYv91IeHlxHPMhAwG4Qi2TA1" />    <div ng-app="myApp">
                                  <div ng-init="GetEditData();" ng-controller="responseCtrl">
                                    {emps.map((emp) => (

                                      <div className="row " ng-repeat="Post in editPost">

                                        <input type="hidden" id="myText" />
                                        <div className="col-lg-4">
                                          <br />

                                          <input type="hidden" className="form-control" value={emp.upload_id} placeholder={Title.result ? Title.result : 'Title'} id="dupload_title" name="dupload_title" />
                                          <label className htmlFor="upload_title">{Title.result ? Title.result : 'Title'}<b style={{ color: 'red' }}> *</b></label>
                                          <input type="text" className="form-control" placeholder={Title.result ? Title.result : 'Title'} defaultValue={emp.upload_title} autoComplete="off"
                                            id="default_upload_title"
                                            name="default_upload_title" />
                                          <div style={{}}>
                                            {ttlErr && <span style={{ color: "red" }}>{TitleisRequired.result ? TitleisRequired.result : ttlErr}</span>}
                                          </div>
                                          <br />

                                          <label className htmlFor="upload_description">{Description.result ? Description.result : 'Description'}<b style={{ color: 'red' }}> *</b></label>
                                          <textarea id="upload_description" name="upload_description"
                                            rows={6} className="form-control" placeholder={Description.result ? Description.result : 'Description'}
                                            defaultValue={emp.upload_description} />
                                          <div style={{}}>
                                            {descErr && <span style={{ color: "red" }}>{DescriptionisRequired.result ? DescriptionisRequired.result : descErr}</span>}
                                          </div>
                                          <br />

                                          <label className htmlFor="upload_title">{bankaccount.result ? bankaccount.result : 'bankaccount'}<b style={{ color: 'red' }}> *</b></label>
                                          <input type="text" className="form-control" placeholder={Bankaccount.result ? Bankaccount.result : 'Enter Bank Account'} defaultValue={emp.upload_BankAccount} autoComplete="off"
                                            id="upload_BankAccount"
                                            name="upload_BankAccount"maxLength={18} />
                                          {bnkErr && <span style={{ color: "red" }}>{BankAccRequired.result ? BankAccRequired.result : bnkErr}</span>}

                                          <br />
                                          <label className>{AddSupportinDocuments.result ? AddSupportinDocuments.result : 'Add Supporting Documents'}</label>

                                          <div>
                                            <input type="hidden" id="default_upload_otherRelation" name="default_upload_otherRelation"
                                              placeholder="Enter Other Required For" defaultValue="None" className="form-control" />
                                            {/* <tr><td><input type="text" label="Title" margin="normal" name="Title" value={this.state.Title} id="Title" size="small" onChange={this.TitleValue} variant="outlined" ></input></td></tr>
<tr><td><input type="text" label="Description" margin="normal" id="Description" size="small" variant="outlined" onChange={this.DespValue}></input></td></tr> */}

                                            <label className="form-control" >
                                              + {add.result}
                                              <input
                                                type="file"
                                                onChange={this.imageHandleChange.bind(this)}
                                                className="vg"
                                                multiple style={{ width: '0%' }}
                                                accept="image/png , image/jpeg ,image/jpg , image/webp, .doc, .docx, .xls, .xlsx, .pdf, video/*" />
                                            </label>
                                            <div className="multiimages" style={{ overflowY: 'scroll', maxHeight: '100px' }}>
                                              {this.state.imageName &&
                                                this.state.imageName.map((image, index) => {
                                                  return (
                                                    <div key={index}>
                                                      <img src={image} />
                                                      <p>{image.name}<img onClick={() => this.deleteimg(index)} style={{ width: '4%' }} src="./assets/images/delete.png" /></p>
                                                    </div>)
                                                })}


                                            </div>
                                            <br />

                                            {defaultimg.map((defaultimg) => (
                                              <div onClick={() => this.visiblity(defaultimg.file_name, defaultimg.file_id)}>
                                                {/* <div onClick={() => this.visiblity(defaultimg.file_name.slice(34))}> */}
                                                {defaultimg.file_name.slice(30)}
                                              </div>
                                            ))}

                                            {this.state.visible ? <DisplayingPopup passVal={this.state.passing} Translated={select.result ? select.result : 'select'} isModalOpen={true} passid={this.state.dpassing} /> : null}

                                          </div>
                                          <input type="hidden" name="default_upload_id" id="default_upload_id" defaultValue={emp.upload_id} />

                                        </div>

                                        <div className="col-lg-4">
                                          <br />
                                          <div className="row">
                                            <div className="col-lg-6 col-sm-6  ">
                                              <label className htmlFor="upload_amount">{Amount.result ? Amount.result : 'Amount'}<b style={{ color: 'red' }}> *</b></label>
                                              <input type="text" className="form-control" placeholder={amt.result ? amt.result : 'Enter Amount'} defaultValue={emp.amounts} maxLength={6} min="100" onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                                onChange={this.handleChange}
                                                id="default_upload_amount" autoComplete="off"
                                                name="default_upload_amount" />

                                              {amtErr && <span style={{ color: "red" }}>{AmountRequired.result ? AmountRequired.result : amtErr}</span>}
                                              {upalkhErr && <span style={{ color: "red" }}>"Amount should not exceed 1 lakh."</span>}

                                            </div>
                                            <div className="col-lg-6 col-sm-6  ">
                                              
                                              <label className htmlFor="upload_amount">{noofbeneficiary.result ? noofbeneficiary.result : 'No Of Beneficiaries'}<b style={{ color: 'red' }}> *</b></label>
                                              {isSelf === false ?
                                                <input type="number" className="form-control" defaultValue={emp.upload_num_of_beneficiaries} maxLength={11} onKeyPress={(event) => {
                                                  if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                  id="default_upload_num_of_beneficiaries" autoComplete="off"
                                                  name="default_upload_num_of_beneficiaries" min={1} max={9999999} minLength={1} />
                                                :
                                                <input type="text" className="form-control" defaultValue="1" value={1} maxLength={1} readOnly disabled
                                                  id="default_upload_num_of_beneficiaries" autoComplete="off"
                                                  name="default_upload_num_of_beneficiaries" />
                                              }

                                              {nbErr && <span style={{ color: "red" }}>{NoofBenefRequired.result ? NoofBenefRequired.result : nbErr}</span>}

                                            </div>
                                          </div>
                                          <div id="greaterzeroerror" style={{ color: 'red' }}></div>
                                          <br /><label className htmlFor="relation_id">{RequiredFor.result ? RequiredFor.result : 'Required For'}</label><b style={{ color: 'red' }}> *</b>

                                          <select className="form-control" data-val="true" data-val-number="The field Purpose must be a number."
                                            data-val-required="Purpose Required" name="default_relation_id"
                                            id="default_relation_id" onChange={this.handleDropdownChange}
                                            defaultValue={emp.relationship_id}>
                                            <option value={3} defaultValue="Edu"  >{self.result}</option>
                                            <option value={2}>{familyfriends.result}</option>
                                            <option value={1}>{others.result}</option>

                                          </select>

                                          <br />

                                          <label className htmlFor="category_id">{Category.result ? Category.result : 'Category'}<b style={{ color: 'red' }}> *</b></label>


                                          <select className="form-control" data-val="true" data-val-number="The field Purpose must be a number." data-val-required="Purpose Required"
                                            id="default_category_id" onChange={this.handleDropdownChange} name="default_category_id"
                                            defaultValue={emp.category_id}>
                                            <option value={8} defaultValue="Edu">{education.result ? education.result : 'Education'}</option>
                                            <option value={7}>{foodandnutrition.result ? foodandnutrition.result : 'Food and Nutrition'}</option>
                                            <option value={6}>{health.result ? health.result : 'Health'}</option>
                                            <option value={5}>{professional.result ? professional.result : 'Professional'}</option>
                                            <option value={4}>{socialwefare.result ? socialwefare.result : 'Social Welfare'}</option>
                                            <option value={3}>{sports.result ? sports.result : 'Sports'}</option>
                                            <option value={2}>{others.result ? others.result : 'Others'}</option>
                                          </select>


                                          <input type="hidden" id="upload_otherCategory" name="upload_otherCategory" placeholder="Enter Other Category"
                                            defaultValue="None" className="form-control" />
                                          <br />
                                          <label className htmlFor="upload_IFSC">{IFSCCode.result ? IFSCCode.result : 'IFSC Code'}<b style={{ color: 'red' }}> *</b></label>

                                          <input type="text" className="form-control" defaultValue={emp.upload_IFSC}
                                            placeholder={ifsc.result ? ifsc.result : 'Enter IFSc code'} autoComplete="off" id="upload_IFSC" style={{}} name="upload_IFSC" />

                                          {ifscErr && <span style={{ color: "red" }}>{IfscRequired.result ? IfscRequired.result : ifscErr}</span>}


                                          <input type="hidden" id="default_upload_otherRelation" name="default_upload_otherRelation"
                                            placeholder="Enter Other Required For" defaultValue="None" className="form-control" /><span className="field-validation-valid text-danger" data-valmsg-for="upload_otherRelation" data-valmsg-replace="true" />

                                        </div>
                                        <div className="col-lg-4">
                                          <br />
                                          <label className htmlFor="upload_place">{Place.result ? Place.result : 'Place'}<b style={{ color: 'red' }}> *</b></label>

                                          <input type="text" className="form-control" defaultValue={emp.upload_place} minLength={3} maxLength={45}
                                            placeholder={Place.result ? Place.result : 'Place'} autoComplete="off" id="upload_place" style={{}} name="upload_place" />
                                          {plcErr && <span style={{ color: "red" }}>{PlaceisRequired.result ? PlaceisRequired.result : plcErr}</span>}
                                          <br /><label className htmlFor="required_by">{enddate.result ? enddate.result : 'End Date'}<b style={{ color: 'red' }}> *</b></label>
                                          <input type="hidden" id="remainssame" value={emp.required_by}></input>
                                          <input defaultValue={emp.required_by} type="date"  name="default_required_by"
                                            id="default_required_by"
                                            dateFormat="yyyy-MM-dd"   onFocus={(e) => {
                                              e.target.min = new Date().toISOString().split('T')[0]; // Set minimum date to today
                                              e.target.max = '9999-12-31'; // Set maximum date to a far future date
                                            }}
                                            className="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-min ng-valid-max"
                                          />
                                          <br />

                                          <label className htmlFor="status_80G">{available80g.result ? available80g.result : '80G will be provided'}<b style={{ color: 'red' }}> *</b></label>


                                          <select className="form-control" data-val="true"
                                            data-val-number="The field Required For must be a number."
                                            data-val-required="Required for" id="status_80G" name="status_80G"
                                            onChange={this.Changetextbox} defaultValue={emp.status_80G}>
                                            <option value={2}>{select.result}</option>
                                            <option value={1}>{yes.result}</option>
                                            <option value={0}>{no.result}</option>
                                          </select>


                                          <br />

                                          <label className htmlFor="upload_UPIID">{UPIID.result ? UPIID.result : 'UPI ID'}</label>

                                          <input type="text" className="form-control" defaultValue={emp.upload_UPIID}
                                            placeholder={Upiid.result ? Upiid.result : 'Enter UPI ID'} autoComplete="off" id="upload_UPIID" style={{}} name="upload_UPIID" />
                                        </div>

                                      </div>

                                    ))}
                                    <center>
                                      <label>
                                        <input type="checkbox" name="q1b" required /> {ConfirmBankDetails.result ? ConfirmBankDetails.result : 'Confirm Bank Details'}<b style={{ color: 'red' }}> *</b>
                                      </label>

                                      {/* <div style={{ marginLeft: "-1%",display:"contents" }}>
                                    {ChckErr && <span style={{ color: "red" }}>{PleaseConfirmtheBankDetails.result ? PleaseConfirmtheBankDetails.result : ChckErr}</span>}

                                  </div> */}
                                    </center>
                                    <center> <button type="submit" Value="Update" onclick="myFunction()" className="btn btn-secondary" style={{ backgroundColor: 'rgb(102, 71, 18)', color: 'white' }} id="submitPost" >{Submit.result ? Submit.result : 'Submit'}</button>
                                      <button type="button" className="btn btn-secondary" Value='Cancel' onClick={this.Cancel1}
                                        style={{ width: 'max-content', padding: '8px 8px', margin: '25px 0', border: 'none', borderRadius: 4, backgroundColor: 'rgb(102, 71, 18)', color: 'white', marginTop: '34px' }}> {cancel.result ? cancel.result : 'Cancel'}</button>
                                      {/* <br></br> */}

                                    </center>

                                    <br />
                                  </div>

                                </div>
                              </form>
                            );
                          }

                        })()}
                        {/* Non Mandatory */}
                        {(() => {
                          if (Category_text == '1') {
                            return (
                              <form action="post" onSubmit={this.handleSubmitevents} id='NonmandaryForm' >

                                <input name="__RequestVerificationToken" type="hidden" defaultValue="kPG3wygn6fNp1Rjeiz40yH3LKUuuDF1iVHvwIgrKB8AAWbtbNFI4MDOouDw8PfRhfwEr8h3zS3JrKBkyfHMxIYv91IeHlxHPMhAwG4Qi2TA1" /><div ng-app="myApp">
                                  <div ng-init="GetEditData();" ng-controller="responseCtrl">
                                    {emps.map((emp) => (

                                      <div className="row " ng-repeat="Post in editPost">

                                        <input type="hidden" id="myText" />
                                        <div className="col-lg-4">
                                          <br />

                                          <input type="hidden" className="form-control" value={emp.upload_id} placeholder={Title.result ? Title.result : 'Title'} id="dupload_title" name="dupload_title" />
                                          <label className htmlFor="upload_title">{Title.result ? Title.result : 'Title'}<b style={{ color: 'red' }}> *</b></label>
                                          <input type="text" className="form-control" defaultValue={emp.upload_title} autoComplete="off" placeholder={Title.result ? Title.result : 'Title'}
                                            id="default_upload_title"
                                            name="default_upload_title" />
                                          <div style={{}}>
                                            {ttlErr && <span style={{ color: "red" }}>{TitleisRequired.result ? TitleisRequired.result : ttlErr}</span>}
                                          </div>
                                          <br />

                                          <label className htmlFor="upload_description">{Description.result ? Description.result : 'Description'}<b style={{ color: 'red' }}> *</b></label>
                                          <textarea id="upload_description" name="upload_description"
                                            rows={6} className="form-control" placeholder={Description.result ? Description.result : 'Description'}
                                            defaultValue={emp.upload_description} />
                                          <div style={{}}>
                                            {descErr && <span style={{ color: "red" }}>{DescriptionisRequired.result ? DescriptionisRequired.result : descErr}</span>}
                                          </div>
                                          <br />

                                          <br />

                                        </div>

                                        <div className="col-lg-4">

                                          <br />
                                          <label className htmlFor="upload_amount">{noofbeneficiary.result ? noofbeneficiary.result : 'No Of Beneficiaries'}<b style={{ color: 'red' }}> *</b></label>
                                          {isSelf === false ?
                                             <input type="number" className="form-control" defaultValue={emp.upload_num_of_beneficiaries} maxLength={11} onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                              id="default_upload_num_of_beneficiaries" autoComplete="off"
                                              name="default_upload_num_of_beneficiaries" min={1} max={9999999} minLength={1} />
  
                                                :
                                                <input type="text" className="form-control" defaultValue='1' value='1' readOnly maxLength={1} disabled
                                                  id="default_upload_num_of_beneficiaries" autoComplete="off"
                                                  name="default_upload_num_of_beneficiaries" />
                                              }
                                        
                                          <div style={{}}>
                                            {nbErr && <span style={{ color: "red" }}>{NoofBenefRequired.result ? NoofBenefRequired.result : nbErr}</span>}
                                          </div>
                                          <br />

                                          <label className htmlFor="relation_id">{RequiredFor.result ? RequiredFor.result : 'Required For'}</label><b style={{ color: 'red' }}> *</b>

                                          <select className="form-control" data-val="true" data-val-number="The field Purpose must be a number."
                                            data-val-required="Purpose Required" name="default_relation_id"
                                            id="default_relation_id" onChange={this.handleDropdownChange}
                                            defaultValue={emp.relationship_id}>
                                            <option value={3} defaultValue="Edu"  >{self.result}</option>
                                            <option value={2}>{familyfriends.result}</option>
                                            <option value={1}>{others.result}</option>

                                          </select>

                                          <br />
                                          <label className htmlFor="category_id">{Category.result ? Category.result : 'Category'}<b style={{ color: 'red' }}> *</b></label>


                                          <select className="form-control" data-val="true" data-val-number="The field Purpose must be a number." data-val-required="Purpose Required"
                                            id="default_category_id" onChange={this.handleDropdownChange} name="default_category_id"
                                            defaultValue={emp.category_id}>
                                            <option value={8} defaultValue="Edu"  >{education.result ? education.result : 'Education'}</option>
                                            <option value={7}>{foodandnutrition.result ? foodandnutrition.result : 'Food and Nutrition'}</option>
                                            <option value={6}>{health.result ? health.result : 'Health'}</option>
                                            <option value={5}>{professional.result ? professional.result : 'Professional'}</option>
                                            <option value={4}>{socialwefare.result ? socialwefare.result : 'Social Welfare'}</option>
                                            <option value={3}>{sports.result ? sports.result : 'Sports'}</option>
                                            <option value={2}>{others.result ? others.result : 'Others'}</option>
                                          </select>


                                          <input type="hidden" name="default_upload_id" id="default_upload_id" defaultValue={emp.upload_id} />

                                          <input type="hidden" id="upload_otherCategory" name="upload_otherCategory" placeholder="Enter Other Category"
                                            defaultValue="None" className="form-control" />

                                          <br />
                                          <input type="hidden" id="upload_otherRelation" name="default_upload_otherRelation"
                                            placeholder="Enter Other Required For" defaultValue="None" className="form-control" />


                                        </div>
                                        <div className="col-lg-4">
                                          <br />
                                          <label className htmlFor="upload_place">{Place.result ? Place.result : 'Place'}<b style={{ color: 'red' }}> *</b></label>

                                          <input type="text" className="form-control" defaultValue={emp.upload_place} minLength={3} maxLength={45}
                                            placeholder={Place.result ? Place.result : 'Place'} autoComplete="off" id="default_upload_place" style={{}} name="upload_place" />
                                          <div style={{}}>
                                            {plcErr && <span style={{ color: "red" }}>{PlaceisRequired.result ? PlaceisRequired.result : plcErr}</span>}
                                          </div>

                                          <br /><label className htmlFor="required_by">{enddate.result ? enddate.result : 'End Date'}<b style={{ color: 'red' }}> *</b></label>

                                          <input defaultValue={emp.required_by} type="text" name="default_required_by"
                                            min="1920-01-01" onFocus={(e) => e.target.type = 'date'} id="default_required_by"
                                            dateFormat="yyyy-MM-dd"
                                            className="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-min ng-valid-max"
                                            max="Today" />
                                          <div style={{}}>
                                            {enddtErr && <span style={{ color: "red" }}>{EndDateRequired.result ? EndDateRequired.result : enddtErr}</span>}
                                          </div>
                                          <br />

                                          <label className>{AddSupportinDocuments.result ? AddSupportinDocuments.result : 'Add Supporting Documents'}</label>

                                          {/* <a href="#?id=collapseTwo" data-toggle="modal" data-target="#fileModal" style={{textDecoration: 'none',  lineHeight: '1.2', marginTop: 300, fontSize: '1.5rem', color: 'black'}}>&nbsp;&nbsp;<b>&#9432;</b>&nbsp;&nbsp;&nbsp;</a>
            <input type="file" id="default_file_name" name="default_file_name" className="form-control" accept=".x-png,.gif,.jpeg,.jpg,.png,.pdf,.mp4,.mkv,.avi" multiple="multiple" style={{display:'none'}}/> <br />
         
            <label for="default_file_name" className="form-control" style={{backgroundColor:'white',fontFamily:'cuyabra'}}><i style={{fontsize:'24px'}} class="fa">&#xf067;</i>  &nbsp;{Change.result?Change.result:'Change'}</label>
            <br/>
            <img style={{width: '99%', height: 88}} src={emp.file_name}  />
            <br />
            <input type="hidden" id="default_upload_otherRelation" name="default_upload_otherRelation" 
            placeholder="Enter Other Required For" defaultValue="None" className="form-control" /><span className="field-validation-valid text-danger" data-valmsg-for="upload_otherRelation" data-valmsg-replace="true" /> */}

                                          <label className="form-control" >
                                            + {add.result}
                                            <input
                                              type="file"
                                              onChange={this.imageHandleChange.bind(this)}
                                              className="vg"
                                              multiple style={{ width: '0%' }}
                                              accept="image/png , image/jpeg ,image/jpg , image/webp, .doc, .docx, .xls, .xlsx, .pdf, video/*" />
                                          </label>
                                          <div className="multiimages" style={{ overflowY: 'scroll', maxHeight: '100px' }}>
                                            {this.state.imageName &&
                                              this.state.imageName.map((image, index) => {
                                                return (
                                                  <div key={index}>
                                                    <img src={image} />
                                                    <p>{image.name}<img onClick={() => this.deleteimg(index)} style={{ width: '4%' }} src="./assets/images/delete.png" /></p>
                                                  </div>)
                                              })}


                                          </div>
                                          <br />

                                          {defaultimg.map((defaultimg) => (
                                            <div onClick={() => this.visiblity(defaultimg.file_name, defaultimg.file_id)}>
                                              {/* <div onClick={() => this.visiblity(defaultimg.file_name.slice(34))}> */}
                                              {defaultimg.file_name.slice(30)}
                                            </div>
                                          ))}

                                          {this.state.visible ? <DisplayingPopup passVal={this.state.passing} isModalOpen={true} passid={this.state.dpassing} /> : null}
                                          <br />
                                        </div>
                                      </div>
                                    ))}
                                    <center> <button type="submit" Value="Update" onclick="myFunction()" className="btn btn-secondary" style={{ backgroundColor: 'rgb(102, 71, 18)', color: 'white' }} id="submitPost" >{Submit.result ? Submit.result : 'Submit'}</button> &nbsp;&nbsp;
                                      {/* <button className="btn btn-secondary" style={{ backgroundColor: 'rgb(102, 71, 18)', color: 'white' }}>Cancel </button> */}
                                      {/* cancel.result ? cancel.result : 'Submit' */}
                                      <button type="button" className="btn btn-secondary" Value='Cancel' onClick={this.Cancel}
                                        style={{ width: 'max-content', padding: '8px 8px', margin: '25px 0', border: 'none', borderRadius: 4, backgroundColor: 'rgb(102, 71, 18)', color: 'white', marginTop: '34px' }} >{cancel.result ? cancel.result : 'Cancel'} </button>
                                    </center>

                                    <br />
                                  </div>
                                </div>
                              </form>
                            );
                          }

                        })()}
                        <div className="modal fade" id="fileModal" role="dialog">
                          <div className="modal-dialog">
                            {/* Modal content*/}
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">{includedocs.result}</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                              </div>

                              <div className="modal-body">
                                {/* Go to www.addthis.com/dashboard to customize your tools */}
                                {forex.result}
                              </div>
                              <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal">{Close.result}</button>

                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
                <div id="styleSelector">
                </div>
              </div>
            </div>
          </section>
          {/*/#blog*/}
          <div style={{ backgroundColor: '#664712' }}>
            <footer id="footer" style={{ color: 'white' }}>
              <br />
              <div className="container">
                <div className="copyright">

                  <div className="row col-lg-12">

                    <div className="col-lg-9 mb-6">
                      {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}

                      <p style={{ marginLeft: '45px' }}>{copyright_new.result} &nbsp;{currentYear} © {paropakari_new.result}&nbsp; |&nbsp;<a href="#/Contactuslogin" style={{ textDecoration: 'none', color: 'white' }}>{contactus.result}&nbsp;</a>| <a href="#/PrivacyPolicyLogin" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{privacypolicy.result}&nbsp;</a>|<a href="#/TermsandConditionLogin" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{termsandcondition.result}</a></p>

                    </div>
                    <div className="col-lg-3 mb-3">

                      <div className="social-icons" style={{ color: 'white', marginLeft: '118px' }}>
                        <ul className="nav nav-pills">
                          <li><a target="_blank" href="https://www.facebook.com/beoneparopakari"><i className="fa fa-facebook" /></a></li>
                          {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                          <li><a target="_blank" href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw" ><i className="fa fa-youtube-play" /></a></li>
                          <li><a target="_blank" href="https://www.instagram.com/paropakari_beone/" ><i className="fa fa-instagram" /></a></li>
                          <li><a target="_blank" href="https://www.linkedin.com/company/paropakari/"><i className="fa fa-linkedin" /></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="containerss">

              </div>
            </footer>

          </div>

          {/*/#footer*/}
        </div>
      </div>
    )
  }
  }
}