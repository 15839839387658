
import React, { Component } from 'react';
import { Link } from "react-router-dom";
// import '../assets/css/bootstrap.min.css';


export default class HomeSort extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // id : this.props.match.params.id,
      filter: "",
      Details: [],
      Detailss: [],
      foo: [],
      Menu: [],
      beaparopakari: [],
      myjourney: [],
      category: [],
      createacause: [],
      invite: [],
      profile: [], changepassword: [], group: [], logout: [], search: [], categories: [], foodandnutrition: [], health: [], professional: [], sports: [], socialwefare: [], others: [],
      sort: [], mostrecent: [], mostpopular: [], available80g: [], amountdesc: [], amountasc: [], complete: [], incomplete: [],
      causes: [],
      beneficiaries: [],
      paropakaris: [],
      amountraised: [],
      copyright: [],
      contactus: [],
      termsandcondition: [],
      howitworks: [],
      blog: [],
      langID: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.handleChangess = this.handleChangess.bind(this);
    // this.handleMenuChanges = this.handleMenuChanges.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {


    this.setState({ value: event.target.value });

    let url =
      "https://testapi.paropakari.com/api/GetProfile/Get_HomeCategoryFilter?id=0" +
      // this.state.Sort.value + 
      //"&Sort=" +
      //  this.state.Years.value +
      "&CategoryID=" +
      event.target.value

      ;


    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    

    //

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      }

      //body:JSON.stringify(data)

    })
      .then((response) => response.json())
      .then((data) => {

        this.setState({



          Details: data

        });

        
        // 
      });


  }

  handleChanges(event) {


    this.setState({ value: event.target.value });

    let url =
      "https://testapi.paropakari.com/api/GetProfile/Get_HomeCategoryFilter?id=" +
      event.target.value +
      // this.state.Sort.value + 
      //"&Sort=" +
      //  this.state.Years.value +
      "&CategoryID=0"


      ;


    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    

    //

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      }

      //body:JSON.stringify(data)

    })
      .then((response) => response.json())
      .then((data) => {

        this.setState({



          Details: data

        });

        
        // 
      });


  }
  handleSubmit(event) {
    alert('Your favorite flavor is: ' + this.state.value);
    event.preventDefault();
  }

  DefaultMenu() {
    //   

    // let l_id = JSON.parse(localStorage.getItem('menuid'));

    //localStorage.setItem('menuid', l_id);   
    // if(l_id == null)
    // {
    //   l_id='English'
    // }
    // else
    // {
    //   l_id = JSON.parse(localStorage.getItem('menuid'));
    // }
    //   

    let l_id = JSON.parse(localStorage.getItem('MenuID'));
    
    let url =
      "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Search~Categories~Food and Nutrition~Health~Professional~Sports~Social Welfare~Others~Sort~Most Recent~Most popular~80G Available~Amount Ascending~Amount Descending~Complete~Incomplete~HowItWorks~Blog~Contact Us~Terms and Conditions~Copyright © 2021 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised&lang=" + l_id;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    
    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      }
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Detailss: data
        });
        
        const longArray = data;
        const size = 1;
        var ary = data;

        // localStorage.setItem('menuid', 'English');
        const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
          .map(function () { return this.splice(0, size) }, longArray.slice());
        var items = data;
        var Howitwork = typeof items[0] !== "undefined" ? items[0] : null;
        var Blog = typeof items[20] !== "undefined" ? items[20] : null;
        var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
        var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
        var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
        var Invite = typeof items[3] !== "undefined" ? items[3] : null;
        var Profile = typeof items[4] !== "undefined" ? items[4] : null;
        var Contactus = typeof items[5] !== "undefined" ? items[5] : null;
        var Termsandcondition = typeof items[6] !== "undefined" ? items[6] : null;
        var CopyRight = typeof items[7] !== "undefined" ? items[7] : null;
        var Causes = typeof items[8] !== "undefined" ? items[8] : null;
        var Beneficiaries = typeof items[9] !== "undefined" ? items[9] : null;
        var Paropakaris = typeof items[10] !== "undefined" ? items[10] : null;
        var Amountraised = typeof items[11] !== "undefined" ? items[11] : null;
        var ChangePasssword = typeof items[5] !== "undefined" ? items[5] : null;
        var Group = typeof items[6] !== "undefined" ? items[6] : null;
        var Logout = typeof items[7] !== "undefined" ? items[7] : null;
        var Search = typeof items[8] !== "undefined" ? items[8] : null;
        var Categories = typeof items[9] !== "undefined" ? items[9] : null;
        var FoodandNutrtion = typeof items[10] !== "undefined" ? items[10] : null;
        var Health = typeof items[11] !== "undefined" ? items[11] : null;
        var Professional = typeof items[12] !== "undefined" ? items[12] : null;
        var Sports = typeof items[13] !== "undefined" ? items[13] : null;
        var SocialWelfare = typeof items[14] !== "undefined" ? items[14] : null;
        var Others = typeof items[2] !== "undefined" ? items[2] : null;
        var Sort = typeof items[18] !== "undefined" ? items[18] : null;
        var MostRecent = typeof items[16] !== "undefined" ? items[16] : null;
        var MostPopular = typeof items[17] !== "undefined" ? items[17] : null;
        var Availabl80G = typeof items[15] !== "undefined" ? items[15] : null;
        var AmountDesc = typeof items[18] !== "undefined" ? items[18] : null;
        var AmountAsc = typeof items[20] !== "undefined" ? items[20] : null;
        var Incomplete = typeof items[20] !== "undefined" ? items[20] : null;
        var Complete = typeof items[20] !== "undefined" ? items[20] : null;
        var Category = typeof items[20] !== "undefined" ? items[20] : null;
        var Login = typeof items[19] !== "undefined" ? items[19] : null;
        var LangID = "English";


        this.setState({
          myjourney: Myjourney,
          howitworks: Howitwork,
          blog: Blog,
          login: Login,
          beaparopakari: Beaparopakari,
          contactus: Contactus,
          termsandcondition: Termsandcondition,
          copyright: CopyRight,
          createacause: Createacause,
          invite: Invite,
          causes: Causes,
          beneficiaries: Beneficiaries,
          paropakaris: Paropakaris,
          amountraised: Amountraised.result,
          profile: Profile,
          changepassword: ChangePasssword,
          group: Group,
          logout: Logout,
          search: Search,
          categories: Categories,
          foodandnutrition: FoodandNutrtion,
          category: Category,
          health: Health,
          langID: LangID,
          professional: Professional,
          sports: Sports,
          socialwefare: SocialWelfare,
          others: Others,
          sort: Sort,
          mostrecent: MostRecent,
          mostpopular: MostPopular,
          available80g: Availabl80G,
          amountdesc: AmountDesc,
          amountasc: AmountAsc,
          complete: Complete,
          incomplete: Incomplete,

        });

        
        
        
        
        // alert(firstItem);
        const trans = "Vijayaasri";
        // let l_name =this.state.newArray[0];
        // let l_names =l_name;

        // 
        // 
        const n = 2;
        const firstNElements = longArray.slice(0, n).map(({ result }) => {
          return (<p >{result}</p>)
        });
        // 
      });
  }


  handleChangess(event) {
    this.setState({ value: event.target.value });
    
    // localStorage.setItem('menuid', event.target.value);
    let values = ['OurStory', 'HowItWorks', 'Blog', 'Login', 'BeaParopakari', 'What We Do'];
    
    let url =
      "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Search~Categories~Food and Nutrition~Health~Professional~Sports~Social Welfare~Others~Sort~Most Recent~Most popular~80G Available~Amount Ascending~Amount Descending~Complete~Incomplete~HowItWorks~Blog~Contact Us~Terms and Conditions~Copyright © 2021 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised&lang=" + event.target.value;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    
    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      }
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Detailss: data
        });
        
        const longArray = data;
        const size = 1;
        var ary = data;

        const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
          .map(function () { return this.splice(0, size) }, longArray.slice());
        var items = data;
        var Howitwork = typeof items[0] !== "undefined" ? items[0] : null;
        var Blog = typeof items[20] !== "undefined" ? items[20] : null;
        var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
        var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
        var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
        var Invite = typeof items[3] !== "undefined" ? items[3] : null;
        var Profile = typeof items[4] !== "undefined" ? items[4] : null;
        var Contactus = typeof items[5] !== "undefined" ? items[5] : null;
        var Termsandcondition = typeof items[6] !== "undefined" ? items[6] : null;
        var CopyRight = typeof items[7] !== "undefined" ? items[7] : null;
        var Causes = typeof items[8] !== "undefined" ? items[8] : null;
        var Beneficiaries = typeof items[9] !== "undefined" ? items[9] : null;
        var Paropakaris = typeof items[10] !== "undefined" ? items[10] : null;
        var Amountraised = typeof items[11] !== "undefined" ? items[11] : null;
        var ChangePasssword = typeof items[5] !== "undefined" ? items[5] : null;
        var Group = typeof items[6] !== "undefined" ? items[6] : null;
        var Logout = typeof items[7] !== "undefined" ? items[7] : null;
        var Search = typeof items[8] !== "undefined" ? items[8] : null;
        var Categories = typeof items[9] !== "undefined" ? items[9] : null;
        var FoodandNutrtion = typeof items[10] !== "undefined" ? items[10] : null;
        var Health = typeof items[11] !== "undefined" ? items[11] : null;
        var Professional = typeof items[12] !== "undefined" ? items[12] : null;
        var Sports = typeof items[13] !== "undefined" ? items[13] : null;
        var SocialWelfare = typeof items[14] !== "undefined" ? items[14] : null;
        var Others = typeof items[2] !== "undefined" ? items[2] : null;
        var Sort = typeof items[18] !== "undefined" ? items[18] : null;
        var MostRecent = typeof items[16] !== "undefined" ? items[16] : null;
        var MostPopular = typeof items[17] !== "undefined" ? items[17] : null;
        var Availabl80G = typeof items[15] !== "undefined" ? items[15] : null;
        var AmountDesc = typeof items[18] !== "undefined" ? items[18] : null;
        var AmountAsc = typeof items[20] !== "undefined" ? items[20] : null;
        var Incomplete = typeof items[20] !== "undefined" ? items[20] : null;
        var Complete = typeof items[20] !== "undefined" ? items[20] : null;
        var Category = typeof items[20] !== "undefined" ? items[20] : null;
        var Login = typeof items[19] !== "undefined" ? items[19] : null;
        var LangID = event.target.value;


        this.setState({
          myjourney: Myjourney,
          howitworks: Howitwork,
          blog: Blog,
          login: Login,
          beaparopakari: Beaparopakari,
          contactus: Contactus,
          termsandcondition: Termsandcondition,
          copyright: CopyRight,
          createacause: Createacause,
          invite: Invite,
          causes: Causes,
          beneficiaries: Beneficiaries,
          paropakaris: Paropakaris,
          amountraised: Amountraised.result,
          profile: Profile,
          changepassword: ChangePasssword,
          group: Group,
          logout: Logout,
          search: Search,
          categories: Categories,
          foodandnutrition: FoodandNutrtion,
          category: Category,
          health: Health,
          langID: LangID,
          professional: Professional,
          sports: Sports,
          socialwefare: SocialWelfare,
          others: Others,
          sort: Sort,
          mostrecent: MostRecent,
          mostpopular: MostPopular,
          available80g: Availabl80G,
          amountdesc: AmountDesc,
          amountasc: AmountAsc,
          complete: Complete,
          incomplete: Incomplete,

        });


        
        
        
        
        // alert(firstItem);
        const trans = "Vijayaasri";
        // let l_name =this.state.newArray[0];
        // let l_names =l_name;

        // 
        // 
        const n = 2;
        const firstNElements = longArray.slice(0, n).map(({ result }) => {
          return (<p >{result}</p>)
        });
        //alert('hi'+data);
        // this.setState({ newArray: newArray });
      });

  }





  AddLiking = async event => {
    event.preventDefault();


    const data = new FormData(event.target);
    let user = JSON.parse(localStorage.getItem('user'));

    const accessToken = user;
    const url = "https://testapi.paropakari.com/api/GetProfile/Add_LikeDetails";
    const optionss = {
      method: "POST",
      headers: {

        Accept: 'application/json',
        Authorization: "Bearer " + accessToken
      },
      body: data,
    };
    this.setState(this.initialState)
    const response = await fetch(url, optionss);
    const result = await response.json();
    if (response.ok) {

      // alert(' success')


      window.location.reload();

    }
    this.setState(this.initialState)
    

    

  }

  componentDidMount() {

    window.addEventListener('load', this.DefaultMenu);
    this.DefaultMenu();

  }

  componentWillUnmount() {

    window.removeEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
  }


  render() {
    let { filter, Details, Detailss, Menu } = this.state;
    const { category, howitworks, blog, contactus, termsandcondition, causes, beneficiaries, paropakaris, amountraised, copyright, beaparopakari, myjourney, createacause, invite, profile, changepassword, group, logout, search, categories, foodandnutrition, health, professional, sports, socialwefare, others,
      sort, mostrecent, mostpopular, available80g, amountdesc, amountasc, complete, incomplete, langID } = this.state;
    return (


      <div className="col-md-9 col-sm-7">


        <div className="row">


          <select className="form-control" style={{ display: 'inline', width: '25%', marginTop: '1%', marginLeft: '1%' }} value={this.state.value} onChange={this.handleChange}>
            <option value="0">{category.result}</option>

            <option value="7">{foodandnutrition.result}</option>
            <option value="6">{health.result}</option>
            <option value="5">{professional.result}</option>
            <option value="3">{sports.result}</option>
            <option value="4">{socialwefare.result}</option>
            <option value="2">{others.result}</option>


          </select>
          &nbsp;&nbsp; &nbsp;&nbsp;

          <select className="form-control" style={{ display: 'inline', width: '25%' }} value={this.state.value} onChange={this.handleChangess}>
            <option value="0">{sort.result}</option>
            <option value="11">{mostrecent.result}</option>
            <option value="12">{mostpopular.result}</option>
            <option value="13">{available80g.result}</option>
            <option value="14">{amountasc.result}</option>
            <option value="15">{amountdesc.result}</option>
            {/* <option value="16">Number of beneficiaries</option> */}
            {/* <option value="17">Place</option> */}
            <option value="18">{complete.result}</option>
            <option value="19">{incomplete.result}</option>
            {/* <option value="22">Purpose</option> */}
          </select>


          {Details.map((emp) => (
            <div className="col-lg-12 col-md-12">



              <h3 style={{ fontsize: '15px' }}><a href={"#/ResponsePage/" + emp.upload_id} style={{ fontsize: '20px', textDecoration: 'none', color: 'black' }}><b>{emp.upload_title}</b></a></h3>

              <div className="row">
                <div className="col-lg-4"><p>{emp.category_name}</p></div>
                <div className="col-lg-4"><p style={{ float: 'right' }}>{emp.upload_place}</p></div>
                <div className="col-lg-4"><p></p></div>

              </div>
              <div className="row">
                <div className="col-lg-8">
                  {(() => {
                    if (emp.file_type == '.png' || emp.file_type == '.jpg' || emp.file_type == '.JPG' || emp.file_type == '.jpeg') {
                      return (
                        <a href={"#/ResponsePage/" + emp.upload_id}><img src={emp.file_name} className="img-responsive" alt /></a>



                      );
                    }
                    else {
                      return (
                        <a href={"#/ResponsePage/" + emp.upload_id}>
                          <iframe src={emp.file_name} style={{ width: '100%', height: '300px' }} />
                        </a>
                      );
                    }

                  })()}

                </div>

                <div className="col-lg-4"><p></p></div>

              </div>

              <div className="row">
                <div className="col-lg-4"><p>{emp.profile_name}</p></div>
                <div className="col-lg-4"><p style={{ float: 'right' }}>{emp.created_date}</p></div>
                <div className="col-lg-4"><p></p></div>

              </div>

              {/* 
          <div className="row">

         
       
          <div className="col-sm-6 col-md-6">
          <p style={{width:'700px'}}><a style={{width:'50%',textDecoration:'none',color:'black'}}>{emp.category_name}</a>
         </p>
            </div>
            <div className="col-sm-6 col-md-6">
          <p style={{width:'700px'}}><a style={{width:'50%',textDecoration:'none',color:'black', marginLeft:'2%'}}>{emp.upload_place}</a>

         </p>
            </div>
            </div> */}
              <div className="single-blog single-column">


                <div className="post-content overflow">


                  <p style={{ width: '700px' }}>{emp.upload_description}</p>
                  <p><b>End Date</b> &nbsp;{emp.required_by}</p>

                  {/* <a onClick={this.topFunction} id="myBtn" title="Go to top" style={{display: 'block',borderColor: 'transparent',textAlign:'right',fontsize:'60px'}}>

<i class="fa fa-arrow-circle-up" style={{fontsize:'18px',color:'rgb(102, 71, 18)'}}></i>
</a> */}
                  <div className="post-bottom overflow">
                    <ul className="nav navbar-nav post-nav">
                      <li><a href={"#/ResponsePage/" + emp.upload_id}><i className="fa fa-tag" />{emp.tagcount} &nbsp;Tag</a></li>
                      <li><a href="#">




                        <form onSubmit={this.AddLiking.bind(this)}>



                          <input type="hidden" name="id" onChange={this.handlebenfChange} id="id" defaultValue={emp.upload_id} />

                          <button type="submit" className="fa" style={{ display: 'contents', backgroundColor: 'rgb(174, 230, 219)', fontsize: 'inherit', textRendering: 'auto', WebkitFontSmoothing: 'antialiased', transform: 'translate(0, 0)', fontFamily: 'cuyabra' }}>

                            {/* <img src='https://paropakari.com/Images/like.png' style={{height:"40px",width:"50px"}}   /> <br></br>
    <img src='https://paropakari.com/Images/liked.png' style={{height:"40px",width:"50px"}} /> */}


                            {(() => {
                              if (emp.uid == 'true') {
                                return (

                                  <img onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut} src="https://paropakari.com/Images/like.png" className="fa" style={{ display: 'inline-block', fontsize: 'inherit', textRendering: 'auto', WebkitFontSmoothing: 'antialiased', transform: 'translate(0, 0)', height: "20px", width: "25px", border: 'none' }} />
                                );
                              }
                              else {
                                return (

                                  <img onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut} src="https://paropakari.com/Images/liked.png" className="fa" style={{ display: 'inline-block', fontsize: 'inherit', textRendering: 'auto', WebkitFontSmoothing: 'antialiased', transform: 'translate(0, 0)', height: "20px", width: "25px", border: 'none', outline: 'none' }} />);
                              }



                            })()}

                            &nbsp;{emp.likes} &nbsp;Like
                          </button>


                        </form>
                      </a></li>
                      <li><a href={"#/ResponsePage/" + emp.upload_id}><i className="fa fa-comments" />{emp.comments} Comments</a></li>
                      <li id="btn1" class="btn1 third" style={{ alignContent: 'space-around', marginTop: "", fontSize: 12 ,backgroundColor: 'rgb(102, 71, 18)', color: 'white', textDecoration: 'none', margin: '-6px -11px -11px 98px' }}><Link to={"./ResponsePage/" + emp.upload_id} style={{ backgroundColor: 'rgb(102, 71, 18)', color: 'white', fontsize: '14px', textDecoration: 'none' }}
                       >
                        {/* <input type="image" alt="" src="assets/images/p3.png" /> */}
                        &nbsp;<b style={{ color: 'white' }}>Donate a Smile</b>

                      </Link></li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>

          ))
          }
        </div>



      </div>

    );
  }
}