import React, { Component } from 'react';
// import '../assets/css/bootstrap.min.css';
import ScriptTag from 'react-script-tag'; 
import {Helmet} from "react-helmet";
import Script from 'react-load-script'


export default class TagEdit extends Component {

    handleScriptCreate() {
        this.setState({ scriptLoaded: false })
      }
       
      handleScriptError() {
        this.setState({ scriptError: true })
      }
       
      handleScriptLoad() {
        this.setState({ scriptLoaded: true })
      }

    //   componentDidMount () {
    //     const script = document.createElement("script");
    
        
    //     script.src = "../assets/js/jquery.js";
    //     script.src = "../assets/js/bootstrap.min.js";
    //     script.src = "../assets/js/lightbox.min.js";
    //     script.src = "../assets/js/wow.min.js";
    //     script.src = "../assets/js/main.js";
    //     script.async = true;
    //     // script.onload = () => this.scriptLoaded();
    //     document.body.appendChild(script);
    // }
    // scriptLoaded() {
    //     window.A.sort();
    //   }
    render() {
       
       return (
       
<div>
{/* <link href="css/bootstrap.min.css" rel="stylesheet">
    <link href="css/font-awesome.min.css" rel="stylesheet">
    <link href="css/animate.min.css" rel="stylesheet"> 
    <link href="css/lightbox.css" rel="stylesheet"> 
	<link href="css/main.css" rel="stylesheet">
	<link href="css/responsive.css" rel="stylesheet"></link> */}
{/* 
 <ScriptTag isHydrating={true} type="text/javascript" src="../assets/js/jquery.js" /> 
    <ScriptTag isHydrating={true} type="text/javascript" src="../assets/js/bootstrap.min.js" /> 
    <ScriptTag isHydrating={true} type="text/javascript" src="../assets/js/lightbox.min.js" /> 
    <ScriptTag isHydrating={true} type="text/javascript" src="../assets/js/wow.min.js" /> 
    <ScriptTag isHydrating={true} type="text/javascript" src="../assets/js/main.js" />   */}

    <Script
      url="../assets/js/jquery.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />
     <Script
      url="../assets/js/bootstrap.min.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />
     <Script
      url="../assets/js/lightbox.min.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />
     <Script
      url="../assets/js/wow.min.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />
     <Script
      url="../assets/js/main.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />
 <Helmet>
              {/* <script src="/path/to/resource.js" type="text/javascript" /> */}
              {/* <script src='../assets/js/jquery.js'></script>
<script src='../assets/js/bootstrap.min.js'></script>
<script src='../assets/js/lightbox.min.js'></script>
<script src='../assets/js/wow.min.js'></script>
<script src='../assets/js/main.js'></script> */}
{/* <script type="application/ld+json">{`
        {
            "@context": "../assets/js/jquery.js"
        }
    `}</script>
    <script type="application/ld+json">{`
        {
            "@context": "../assets/js/bootstrap.min.js"
        }
    `}</script>
    <script type="application/ld+json">{`
        {
            "@context": "../assets/js/lightbox.min.js"
        }
    `}</script>
    <script type="application/ld+json">{`
        {
            "@context": "../assets/js/wow.min.js"
        }
    `}</script>
    <script type="application/ld+json">{`
        {
            "@context": "../assets/js/main.js"
        }
    `}</script> */}
            </Helmet>
<header id="header">     
<div className="container">
            <div className="row">
                <div className="col-sm-12 overflow">
                   <div className="social-icons pull-right">
                        <ul className="nav nav-pills">
                            <li><a href=""><i className="fa fa-facebook"></i></a></li>
                            <li><a href=""><i className="fa fa-twitter"></i></a></li>
                            <li><a href=""><i className="fa fa-google-plus"></i></a></li>
                            <li><a href=""><i className="fa fa-dribbble"></i></a></li>
                            <li><a href=""><i className="fa fa-linkedin"></i></a></li>
                        </ul>
                    </div> 
                </div>
             </div>
        </div>
         <div className="navbar navbar-inverse" role="banner">
            <div className="container">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                        <span className="sr-only">Toggle navigassstion</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>

                    <a className="navbar-brand" href="index.html">
                    	<h1><img src="images/logo.png" alt="logo">
                            </img>
                        </h1>
                    </a>
                    
                </div>
                <div className="collapse navbar-collapse">
                    <ul className="nav navbar-nav navbar-right">
                        <li className="active"><a href="index.html">Home11</a></li>
                        <li className="dropdown"><a href="#">Pages <i className="fa fa-angle-down"></i></a>
                            <ul role="menu" className="sub-menu">
                                <li><a href="aboutus.html">About</a></li>
                                <li><a href="aboutus2.html">About 2</a></li>
                                <li><a href="service.html">Services</a></li>
                                <li><a href="pricing.html">Pricing</a></li>
                                <li><a href="contact.html">Contact us</a></li>
                                <li><a href="contact2.html">Contact us 2</a></li>
                                <li><a href="404.html">404 error</a></li>
                                <li><a href="coming-soon.html">Coming Soon</a></li>
                            </ul>
                        </li>                    
                        <li className="dropdown"><a href="blog.html">Blog <i className="fa fa-angle-down"></i></a>
                            <ul role="menu" className="sub-menu">
                                <li><a href="blog.html">Blog Default</a></li>
                                <li><a href="blogtwo.html">Timeline Blog</a></li>
                                <li><a href="blogone.html">2 Columns + Right Sidebar</a></li>
                                <li><a href="blogthree.html">1 Column + Left Sidebar</a></li>
                                <li><a href="blogfour.html">Blog Masonary</a></li>
                                <li><a href="blogdetails.html">Blog Details</a></li>
                            </ul>
                        </li>
                        <li className="dropdown"><a href="portfolio.html">Portfolio <i className="fa fa-angle-down"></i></a>
                            <ul role="menu" className="sub-menu">
                                <li><a href="portfolio.html">Portfolio Default</a></li>
                                <li><a href="portfoliofour.html">Isotope 3 Columns + Right Sidebar</a></li>
                                <li><a href="portfolioone.html">3 Columns + Right Sidebar</a></li>
                                <li><a href="portfoliotwo.html">3 Columns + Left Sidebar</a></li>
                                <li><a href="portfoliothree.html">2 Columns</a></li>
                                <li><a href="portfolio-details.html">Portfolio Details</a></li>
                            </ul>
                        </li>                         
                        <li><a href="shortcodes.html ">Shortcodes</a></li>                    
                    </ul>
                </div>
                <div className="search">
                    <form role="form">
                        <i className="fa fa-search"></i>
                        <div className="field-toggle">
                            <input type="text" className="search-form" autoComplete="off" placeholder="Search"/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
</header>


    <footer id="footer">
        <div className="container">
            <div className="row">
                <div className="col-sm-12 text-center bottom-separator">
                    <img src="images/home/under.png" className="img-responsive inline" alt=""/>
                </div>
                <div className="col-md-4 col-sm-6">
                    <div className="testimonial bottom">
                        <h2>Testimonial</h2>
                        <div className="media">
                            <div className="pull-left">
                                <a href="#"><img src="images/home/profile1.png" alt=""/></a>
                            </div>
                            <div className="media-body">
                                <blockquote>Nisi commodo bresaola, leberkas venison eiusmod bacon occaecat labore tail.</blockquote>
                                <h3><a href="#">- Jhon Kalis</a></h3>
                            </div>
                         </div>
                        <div className="media">
                            <div className="pull-left">
                                <a href="#"><img src="images/home/profile2.png" alt=""/></a>
                            </div>
                            <div className="media-body">
                                <blockquote>Capicola nisi flank sed minim sunt aliqua rump pancetta leberkas venison eiusmod.</blockquote>
                                <h3><a href="">- Abraham Josef</a></h3>
                            </div>
                        </div>   
                    </div> 
                </div>
               
           
                <div className="col-sm-12">
                    <div className="copyright-text text-center">
                        <p>&copy; Your Company 2014. All Rights Reserved.</p>
                        <p>Designed by <a target="_blank" href="http://www.themeum.com">Themeum</a></p>
                    </div>
                </div>
            </div>
        </div>
    </footer>

  {/* <script type="text/javascript" src="js/jquery.js"></script> */}
    
    {/* <script type='text/javascript' src='./assets/js/jquery.js'/>
    <script type="text/javascript" src='./assets/js/bootstrap.min.js'></script>
    <script type="text/javascript" src="./assets/js/lightbox.min.js"></script>
    <script type="text/javascript" src='./assets/js/wow.min.js'></script>
    <script type="text/javascript" src='./assets/js/main.js'></script>    */}

 
</div>


)
}
}
