import React, { Component } from 'react'

import { BrowserRouter as Router, Switch, Route, Link,NavLink } from "react-router-dom";



import Script from 'react-load-script'


//import SideBars from './SideBars.js';


export default class Payment extends Component {
  handleScriptCreate() {
    this.setState({ scriptLoaded: false })
  }
   
  handleScriptError() {
    this.setState({ scriptError: true })
  }
   
  handleScriptLoad() {
    this.setState({ scriptLoaded: true })
  }
    render(){

          return (
    <div>
  <meta charSet="utf-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <meta name="description" content />
  <meta name="author" content />
  {/* <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
  <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
  <link href="./assets/css/lightbox.css" rel="stylesheet" /> 
  <link href="./assets/css/animate.min.css" rel="stylesheet" /> 
  <link href="./assets/css/main.css" rel="stylesheet" />
  <link href="./assets/css/Login2.css" rel="stylesheet" /> */}
  <link href="./assets/css/Payment.css" rel="stylesheet" />
  <link href="./assets/js/Payment.js" rel="stylesheet" />
  {/*[if lt IE 9]>
              
              
          <![endif]*/}  
    <Script
      url="../assets/js/Payment.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />   
{/* 
<Script
      url="../assets/js/jquery.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />
     <Script
      url="../assets/js/bootstrap.min.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />
     <Script
      url="../assets/js/lightbox.min.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />
     <Script
      url="../assets/js/wow.min.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />
     <Script
      url="../assets/js/main.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />  */}
  <link rel="shortcut icon" href="images/ico/favicon.ico" />
  <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
  <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
  <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
  <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />
 <div className="container-fluid px-0" id="bg-div">
  <div className="row justify-content-center">
    <div className="col-lg-9 col-12">
      <div className="card card0">
        <div className="d-flex" id="wrapper">
          {/* Sidebar */}
          <div className="bg-light border-right" id="sidebar-wrapper">
            <div className="sidebar-heading pt-5 pb-4"><strong>PAY WITH</strong></div>
            <div className="list-group list-group-flush"> <a data-toggle="tab" href="#menu1" id="tab1" className="tabs list-group-item bg-light">
                <div className="list-div my-2">
                  <div className="fa fa-home" /> &nbsp;&nbsp; Bank
                </div>
              </a> <a data-toggle="tab" href="#menu2" id="tab2" className="tabs list-group-item active1">
                <div className="list-div my-2">
                  <div className="fa fa-credit-card" /> &nbsp;&nbsp; Card
                </div>
              </a> <a data-toggle="tab" href="#menu3" id="tab3" className="tabs list-group-item bg-light">
                <div className="list-div my-2">
                  <div className="fa fa-qrcode" /> &nbsp;&nbsp;&nbsp; Visa QR <span id="new-label">NEW</span>
                </div>
              </a> </div>
          </div> {/* Page Content */}
          <div id="page-content-wrapper">
            <div className="row pt-3" id="border-btm">
              <div className="col-4"> <button className="btn btn-success mt-4 ml-3 mb-3" id="menu-toggle">
                  <div className="bar4" />
                  <div className="bar4" />
                  <div className="bar4" />
                </button> </div>
              <div className="col-8">
                <div className="row justify-content-right">
                  <div className="col-12">
                    <p className="mb-0 mr-4 mt-4 text-right">customer@email.com</p>
                  </div>
                </div>
                <div className="row justify-content-right">
                  <div className="col-12">
                    <p className="mb-0 mr-4 text-right">Pay <span className="top-highlight">$ 100</span> </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="text-center" id="test">Pay</div>
            </div>
            <div className="tab-content">
              <div id="menu1" className="tab-pane">
                <div className="row justify-content-center">
                  <div className="col-11">
                    <div className="form-card">
                      <h3 className="mt-0 mb-4 text-center">Enter bank details to pay</h3>
                      <form onsubmit="event.preventDefault()">
                        <div className="row">
                          <div className="col-12">
                            <div className="input-group"> <input type="text" id="bk_nm" placeholder="BBB Bank" /> <label>BANK NAME</label> </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="input-group"> <input type="text" name="ben_nm" id="ben-nm" placeholder="John Smith" /> <label>BENEFICIARY NAME</label> </div>
                          </div>
                          <div className="col-12">
                            <div className="input-group"> <input type="text" name="scode" placeholder="ABCDAB1S" className="placeicon" minLength={8} maxLength={11} /> <label>SWIFT CODE</label> </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12"> <input type="submit" defaultValue="Pay $ 100" className="btn btn-success placeicon" /> </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <p className="text-center mb-5" id="below-btn"><a href="#">Use a test card</a></p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div id="menu2" className="tab-pane in active">
                <div className="row justify-content-center">
                  <div className="col-11">
                    <div className="form-card">
                      <h3 className="mt-0 mb-4 text-center">Enter your card details to pay</h3>
                      <form onsubmit="event.preventDefault()">
                        <div className="row">
                          <div className="col-12">
                            <div className="input-group"> <input type="text" id="cr_no" placeholder="0000 0000 0000 0000" minLength={19} maxLength={19} /> <label>CARD NUMBER</label> </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group"> <input type="text" name="exp" id="exp" placeholder="MM/YY" minLength={5} maxLength={5} /> <label>CARD EXPIRY</label> </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group"> <input type="password" name="cvcpwd" placeholder="●●●" className="placeicon" minLength={3} maxLength={3} /> <label>CVV</label> </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12"> <input type="submit" defaultValue="Pay $ 100" className="btn btn-success placeicon" /> </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <p className="text-center mb-5" id="below-btn"><a href="#">Use a test card</a></p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div id="menu3" className="tab-pane">
                <div className="row justify-content-center">
                  <div className="col-11">
                    <h3 className="mt-0 mb-4 text-center">Scan the QR code to pay</h3>
                    <div className="row justify-content-center">
                      <div id="qr"> <img src="https://i.imgur.com/DD4Npfw.jpg" width="200px" height="200px" /> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  </div>


)
}
}