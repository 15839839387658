import React, { Component } from 'react'
import Script from 'react-load-script'
import Cookies from 'js-cookie';

export default class Reset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // id : this.props.match.params.id,
      CurrentPassword: '',
      NewPassword: '',
      ConfirmPassword: '',

      foo: [],
      Details: [],
      newArray: [],
      ourstory: [],
      howitworks: [],
      beaparopakari: [],
      whatwedo: [],
      blog: [],
      login: [],
      indiaexpensless: [],
      oppertunitybringsmile: [],
      contactus: [],
      termsandcondition: [],
      causes: [],
      beneficiaries: [],
      paropakaris: [],
      amountraised: [],
      copyright: [],
      whatwedodesc: [],
      welcome: [],
      usernames: [],
      passwords: [],
      forgotpassword: [],
      signin: [],
      donthaveacc: [],
      signup: [],
      entercaptchvalue: [],
      enter: [],
      langID: [],
      or: [],
      emails: [],
      mobilenumber: [],
      submit: [],
      enterotp: [],
      resetpassword: [], newpassword: [], confirmnewpassword: [], confirmpassword: [],
      NewPasswordisRequired: [], incorrectcurrentpwd: [],
      ConfirmNewPasswordisRequired: [],
      PasswordandConfirmPasswordmustbesame: [],
      PasswordMustContain: [],
      privacypolicy: [],
      formErrors: {},
      movedown: true,
      isPasswordShown: false,
      isPasswordShowns: false,
      copyright_new: [],
      paropakari_new: [],

    };
    this.initialState = this.state;
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
  }

  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown
    })
  }

  togglePasswordVisiblity = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };

  togglePasswordVisiblity1 = () => {
    const { isPasswordShowns } = this.state;
    this.setState({ isPasswordShowns: !isPasswordShowns });
  };

  redirect = (e) => {
    e.preventDefault();
    var page = {
      pathname: './Login.js',

    }
    this.props.history.push(page)
  }
  handleFormValidation() {
    const { NewPassword, ConfirmPassword } = this.state;
    let formErrors = {};
    let formIsValid = true;
    const passwordTest = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;



    if (!NewPassword || NewPassword.trim() === "") {

      formIsValid = false;
      formErrors["npErr"] = " • New Password is Required!";

    }
    else if (NewPassword.trim().length <= 1) {
      formIsValid = false;
      formErrors["npErrr"] = " • Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character.";
    }
    if (!ConfirmPassword || ConfirmPassword.trim() === "") {
      formIsValid = false;
      formErrors["cnpErr"] = " • Confirm New Password is Required!";
    }
    else if (ConfirmPassword != NewPassword) {
      formIsValid = false;
      formErrors["cnpErrr"] = " • New Password and Confirm Password doesnt match".trim();
    }


    this.setState({ formErrors: formErrors });
    return formIsValid;
  }


  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    
  }
  handleSubmitevents = (event) => {
    event.preventDefault();

    if (this.handleFormValidation()) {
     // alert(' success')
      this.setState(this.initialState)
      
    }
    

  };
  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem('username')
    localStorage.removeItem('user')

    localStorage.clear();
    window.location.href = "#/Login";

  }


  handleSubmitevents = async event => {
    event.preventDefault();

    if (this.handleFormValidation()) {

      const { NewPassword } = this.state;
      const re = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$");
      const isOk = re.test(NewPassword);
      
      

      if (!isOk) {
        // window.location.reload();
        return alert(this.state.PasswordMustContain.result ? this.state.PasswordMustContain.result : 'Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character');

        window.location.reload();
      }
      //  window.location.reload();
      //alert('Strong Password.');

      // make API call

      const data = new FormData(event.target);
      let user = JSON.parse(localStorage.getItem('user'));

      const accessToken = user;
      const url = "https://testapi.paropakari.com/api/otp/VerifyOTP";
      const options = {
        method: "POST",
        headers: {

          Accept: 'application/json',
          Authorization: "Bearer " + accessToken
        },
        body: data,
      };
      this.setState(this.initialState)
      const response = await fetch(url, options);
      const result = await response.json();
      if (response.ok) {
        // alert('Reset Successfully')
        window.location.href = "#/Login";
        let Pno = JSON.parse(localStorage.getItem('Pno'));
      }
      else {
        alert(this.state.incorrectcurrentpwd.result);
        // window.location.reload();   
      }
      this.setState(this.initialState)
    }

    
  };


  Footer() {

    


    let url = "https://testapi.paropakari.com/api/Authenticate/Get_FooterDetails";
    let data = this.state;

    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          foo: data
        });
        
        // 
      });
  }

  DefaultMenu() {
    //   
    let RefreshID = 0;
    window.sessionStorage.setItem('reload', (RefreshID));
    let reloadsmyactivity = 0;
    window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
    window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
    let MID = "English";
    if (sessionStorage.getItem('translate') !== null) {
      MID = sessionStorage.getItem('translate');
    }
    else {
      MID = "English";
    }

    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~WhatWeDo~Opportunity to Bring a Smile~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~PrivacyPolicy&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Ourstory = typeof items[0] !== "undefined" ? items[0] : null;
          var Howitwork = typeof items[1] !== "undefined" ? items[1] : null;
          var Blog = typeof items[2] !== "undefined" ? items[2] : null;
          var Login = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[7] !== "undefined" ? items[7] : null;
          var Termsandcondition = typeof items[8] !== "undefined" ? items[8] : null;
          var CopyRight = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy = typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[4] !== "undefined" ? items[4] : null;
          var OppertunitytoBSmile = typeof items[6] !== "undefined" ? items[6] : null;
          var Whatwedo = typeof items[5] !== "undefined" ? items[5] : null;


          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            ourstory: Ourstory,
            howitworks: Howitwork,
            blog: Blog,
            login: Login,
            beaparopakari: Beaparopakari,
            whatwedo: Whatwedo,
            oppertunitybringsmile: OppertunitytoBSmile,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            privacypolicy: PrivacyPolicy,
            langID: LangID
          });

        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Causes~Beneficiaries~Paropakaris~Amount Raised~Welcome~Username~Password~Forgot password~Login~Dont have an account Please&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Causes = typeof items[0] !== "undefined" ? items[0] : null;
          var Beneficiaries = typeof items[1] !== "undefined" ? items[1] : null;
          var Paropakaris = typeof items[2] !== "undefined" ? items[2] : null;
          var Amountraised = typeof items[3] !== "undefined" ? items[3] : null;

          var Welcome = typeof items[4] !== "undefined" ? items[4] : null;
          var Usernames = typeof items[5] !== "undefined" ? items[5] : null;
          var Passwords = typeof items[6] !== "undefined" ? items[6] : null;
          var ForgotPassword = typeof items[7] !== "undefined" ? items[7] : null;
          var Signin = typeof items[8] !== "undefined" ? items[8] : null;
          var DonthavAcc = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            welcome: Welcome,
            usernames: Usernames,
            passwords: Passwords,
            forgotpassword: ForgotPassword,
            signin: Signin,
            donthaveacc: DonthavAcc,
            langID: LangID


          });

        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Sign Up~Enter Captcha Value~Enter~Or~Email~Mobile Number~Submit~Enter OTP~Reset Password~New Password&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Signup = typeof items[0] !== "undefined" ? items[0] : null;
          var Entercaptchvalue = typeof items[1] !== "undefined" ? items[1] : null;
          var Enter = typeof items[2] !== "undefined" ? items[2] : null;
          var Or = typeof items[3] !== "undefined" ? items[3] : null;
          var Email = typeof items[4] !== "undefined" ? items[4] : null;
          var MobileNumber = typeof items[5] !== "undefined" ? items[5] : null;
          var Submit = typeof items[6] !== "undefined" ? items[6] : null;
          var EnterOTP = typeof items[7] !== "undefined" ? items[7] : null;
          var ResetPassword = typeof items[8] !== "undefined" ? items[8] : null;
          var NewPassword = typeof items[9] !== "undefined" ? items[9] : null;


          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            signup: Signup,
            entercaptchvalue: Entercaptchvalue,
            enter: Enter,
            langID: LangID,
            or: Or,
            email: Email,
            mobilenumber: MobileNumber,
            submit: Submit,
            enterotp: EnterOTP,
            resetpassword: ResetPassword,
            newpassword: NewPassword
          });

        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Confirm New Password~Confirm Password~New Password is Required!~Confirm New Password is Required!~Password and Confirm Password Must Be the Same!~Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character!~Incorrect Current Password!&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var ConfirmNewPassword = typeof items[0] !== "undefined" ? items[0] : null;
          var ConfirmPassword = typeof items[1] !== "undefined" ? items[1] : null;
          var NewPasswordisRequired = typeof items[2] !== "undefined" ? items[2] : null;
          var ConfirmNewPasswordisRequired = typeof items[3] !== "undefined" ? items[3] : null;
          var PasswordandConfirmPasswordmustbesame = typeof items[4] !== "undefined" ? items[4] : null;
          var PasswordMustContain = typeof items[5] !== "undefined" ? items[5] : null;
          var IncorrectCurrentPwd = typeof items[6] !== "undefined" ? items[6] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            confirmnewpassword: ConfirmNewPassword,
            confirmpassword: ConfirmPassword,
            NewPasswordisRequired: NewPasswordisRequired,
            ConfirmNewPasswordisRequired: ConfirmNewPasswordisRequired,
            PasswordandConfirmPasswordmustbesame: PasswordandConfirmPasswordmustbesame,
            langID: LangID,
            incorrectcurrentpwd: IncorrectCurrentPwd,
            PasswordMustContain: PasswordMustContain


          });

        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;
        
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,            
          });
        });
    }
  }


  handleChanges(event) {
    this.setState({ value: event.target.value });
    
    localStorage.setItem('menuid', event.target.value);
    let values = ['OurStory', 'HowItWorks', 'Blog', 'Login', 'BeaParopakari', 'What We Do'];
    
    let MID = event.target.value;
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~WhatWeDo~Opportunity to Bring a Smile~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Privacy Policy&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Ourstory = typeof items[0] !== "undefined" ? items[0] : null;
          var Howitwork = typeof items[1] !== "undefined" ? items[1] : null;
          var Blog = typeof items[2] !== "undefined" ? items[2] : null;
          var Login = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[7] !== "undefined" ? items[7] : null;
          var Termsandcondition = typeof items[8] !== "undefined" ? items[8] : null;
          var CopyRight = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy = typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[4] !== "undefined" ? items[4] : null;
          var OppertunitytoBSmile = typeof items[6] !== "undefined" ? items[6] : null;
          var Whatwedo = typeof items[5] !== "undefined" ? items[5] : null;


          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            ourstory: Ourstory,
            howitworks: Howitwork,
            blog: Blog,
            login: Login,
            beaparopakari: Beaparopakari,
            whatwedo: Whatwedo,
            oppertunitybringsmile: OppertunitytoBSmile,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            privacypolicy: PrivacyPolicy,
            langID: LangID
          });

        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Causes~Beneficiaries~Paropakaris~Amount Raised~Welcome~Username~Password~Forgot password~Login~Dont have an account Please&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Causes = typeof items[0] !== "undefined" ? items[0] : null;
          var Beneficiaries = typeof items[1] !== "undefined" ? items[1] : null;
          var Paropakaris = typeof items[2] !== "undefined" ? items[2] : null;
          var Amountraised = typeof items[3] !== "undefined" ? items[3] : null;

          var Welcome = typeof items[4] !== "undefined" ? items[4] : null;
          var Usernames = typeof items[5] !== "undefined" ? items[5] : null;
          var Passwords = typeof items[6] !== "undefined" ? items[6] : null;
          var ForgotPassword = typeof items[7] !== "undefined" ? items[7] : null;
          var Signin = typeof items[8] !== "undefined" ? items[8] : null;
          var DonthavAcc = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            welcome: Welcome,
            usernames: Usernames,
            passwords: Passwords,
            forgotpassword: ForgotPassword,
            signin: Signin,
            donthaveacc: DonthavAcc,
            langID: LangID


          });

        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Sign Up~Enter Captcha Value~Enter~Or~Email~Mobile Number~Submit~Enter OTP~Reset Password~New Password&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Signup = typeof items[0] !== "undefined" ? items[0] : null;
          var Entercaptchvalue = typeof items[1] !== "undefined" ? items[1] : null;
          var Enter = typeof items[2] !== "undefined" ? items[2] : null;
          var Or = typeof items[3] !== "undefined" ? items[3] : null;
          var Email = typeof items[4] !== "undefined" ? items[4] : null;
          var MobileNumber = typeof items[5] !== "undefined" ? items[5] : null;
          var Submit = typeof items[6] !== "undefined" ? items[6] : null;
          var EnterOTP = typeof items[7] !== "undefined" ? items[7] : null;
          var ResetPassword = typeof items[8] !== "undefined" ? items[8] : null;
          var NewPassword = typeof items[9] !== "undefined" ? items[9] : null;


          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            signup: Signup,
            entercaptchvalue: Entercaptchvalue,
            enter: Enter,
            langID: LangID,
            or: Or,
            email: Email,
            mobilenumber: MobileNumber,
            submit: Submit,
            enterotp: EnterOTP,
            resetpassword: ResetPassword,
            newpassword: NewPassword
          });

        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Confirm New Password~Confirm Password~New Password is Required!~Confirm New Password is Required!~Password and Confirm Password Must Be the Same!~Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character!~Incorrect Current Password!&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var ConfirmNewPassword = typeof items[0] !== "undefined" ? items[0] : null;
          var ConfirmPassword = typeof items[1] !== "undefined" ? items[1] : null;
          var NewPasswordisRequired = typeof items[2] !== "undefined" ? items[2] : null;
          var ConfirmNewPasswordisRequired = typeof items[3] !== "undefined" ? items[3] : null;
          var PasswordandConfirmPasswordmustbesame = typeof items[4] !== "undefined" ? items[4] : null;
          var PasswordMustContain = typeof items[5] !== "undefined" ? items[5] : null;
          var IncorrectCurrentPwd = typeof items[6] !== "undefined" ? items[6] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            confirmnewpassword: ConfirmNewPassword,
            confirmpassword: ConfirmPassword,
            NewPasswordisRequired: NewPasswordisRequired,
            ConfirmNewPasswordisRequired: ConfirmNewPasswordisRequired,
            PasswordandConfirmPasswordmustbesame: PasswordandConfirmPasswordmustbesame,
            langID: LangID,
            incorrectcurrentpwd: IncorrectCurrentPwd,
            PasswordMustContain: PasswordMustContain


          });

        });
    }
    if (MID != null) {


      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Cancel&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;


          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;


          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;


          



          //localStorage.setItem("GoogleTranslate123",InviteFriendsDropdown)

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";

          }
          else

            if (datatranslirate == "Kannada") {
              datatransIDs = "/en/kn";
            }

            else
              if (datatranslirate == "Tamil") {
                datatransIDs = "/en/ta";
              }
              else
                if (datatranslirate == "Telugu") {
                  datatransIDs = "/en/te";
                }
                else
                  if (datatranslirate == "Hindi") {
                    datatransIDs = "/en/hi";
                  }
                  else
                  if (datatranslirate == "Spanish") {
                    datatransIDs = "/en/es";
                  }
                  else
                    if (datatranslirate == MID) {
                      datatransIDs = MID;
                    }
          let RefreshID = 0;
          window.sessionStorage.setItem('reload', (RefreshID));
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
          window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
          window.sessionStorage.setItem('GoogleTranslateValues', (datatransIDs));

          
          let googletrasvalues = localStorage.setItem("GoogleTranslateValues", datatransIDs);

          


          


          Cookies.remove('googtrans', { domain: '.paropakari.com' });
          Cookies.remove('googtrans', { domain: 'https://paropakari.com/' });

          Cookies.remove('googtrans', { domain: 'https://www.paropakari.com/' });
          let usernames = localStorage.getItem("GoogleTranslateValues", datatransIDs);
          let values = datatransIDs;
          
          

          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
            const expires = "expires=" + date.toUTCString();
            document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
            // window.location.reload();
          }

          Cookies.set('googtrans', datatransIDs, { domain: 'https://test.paropakari.com/#/' });
          Cookies.set('googtrans', datatransIDs, { domain: '.paropakari.com' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://www.paropakari.com/' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://paropakari.com/' });
          setCookie('googtrans', datatransIDs, 30);
          setCookie('googtrans', datatransIDs, 'https://test.paropakari.com/#/');
          setCookie('googtrans', datatransIDs, '.paropakari.com');
          setCookie('googtrans', datatransIDs, 'https://www.paropakari.com/');
          setCookie('googtrans', datatransIDs, 'https://paropakari.com/');
          let cookie = Cookies.get('googtrans', 'https://test.paropakari.com/#/');
          
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,


          });


        });
    }
    if (MID != null) {
      let url =
        "https://testapi.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;
        
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,            
          });
        });
    }
  }
  renderIcon() {
    const longArray = [1, 2, 3];
    const size = 1;

    const newArray = new Array(Math.ceil(longArray.length / size)).fill("")
      .map(function () { return this.splice(0, size) }, longArray.slice());

    
    return (
      <div>{newArray}</div>
    )
  }




  handleScriptCreate() {
    this.setState({ scriptLoaded: false })
  }

  handleScriptError() {
    this.setState({ scriptError: true })
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true })
  }
  componentDidMount() {

    window.addEventListener('load', this.Footer);
    this.Footer();
    window.addEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
    const element = document.querySelector('.VIpgJd-ZVi9od-ORHb-OEVmcd');
    if (element && window.getComputedStyle(element).getPropertyValue('position') === 'fixed') {
      element.style.position = 'absolute';
    }
    document.title = "India's only expenseless, direct crowdfunding platform"
  }


  componentWillUnmount() {

    window.removeEventListener('load', this.Footer);
    this.Footer();
    window.removeEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
  }

  render() {
    const { isPasswordShown } = this.state;
    const { isPasswordShowns } = this.state;
    const { opErr, npErr, cnpErr, cnpErrr } = this.state.formErrors;
    const { newArray, howitworks, beaparopakari, whatwedo, blog, login, indiaexpensless, ourstory, oppertunitybringsmile,
      contactus, termsandcondition, causes, beneficiaries, paropakaris, amountraised, copyright, whatwedodesc, langID,
      welcome, usernames, passwords, forgotpassword, signin, donthaveacc, signup, entercaptchvalue, enter, or, emails, mobilenumber,
      submit, enterotp, resetpassword, newpassword, confirmnewpassword, confirmpassword, NewPasswordisRequired,
      ConfirmNewPasswordisRequired, privacypolicy, incorrectcurrentpwd,
      PasswordandConfirmPasswordmustbesame,
      PasswordMustContain, npErrr,copyright_new, paropakari_new } = this.state;
      const currentYear = new Date().getFullYear();
    var Pno = JSON.parse(localStorage.Pno);
    let { foo } = this.state;
    return (
      <div className="VIpgJd-ZVi9od-ORHb-OEVmcd" style={{ position: "absolute" }}>

        <div style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }} className="notranslate">
          {/* <meta http-equiv="refresh" content="10"/>   */}
          <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
          <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
          <link href="./assets/css/lightbox.css" rel="stylesheet" />
          <link href="./assets/css/animate.min.css" rel="stylesheet" />
          <link href="./assets/css/main.css" rel="stylesheet" />

          {/*[if lt IE 9]>
              
              
          <![endif]*/}
          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />
          {/*/head*/}


          <select className="form-control" style={{ display: 'inline', width: '10%', marginTop: '0', marginLeft: '0', float: 'right', width: 'fit-content' }} value={this.state.value} onChange={this.handleChanges}>
            <option value="English">Languages</option>
            <option value="Kannada">Kannada</option>
            <option value="Tamil">Tamil</option>
            <option value="Hindi">Hindi</option>
            <option value="Telugu">Telugu</option>
             {/* <option value="Spanish">Spanish</option> */}
            <option value="English">English</option>
          </select>
          &nbsp;&nbsp; &nbsp;&nbsp;
          {/*/head*/}
          <div>
            {/* <header id="header" style={{backgroundImage: "url(" + "./assets/images/10.jpg" + ")"}}>    

         <div class="navbar navbar-inverse notranslate" role="banner">
            <div class="container">
                <div class="navbar-header">
                    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                        <span class="sr-only">Toggle navigassstion</span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>

                    <a class="navbar-brand" href="#/Index1">
                    	<h1 style={{color:'blue',fontFamily:'Biysk-Regular'}}><img src="./assets/images/plogo.png" alt="logo"style={{marginBottom:'17px',width:'26%',fontsize:'52px'}}  />AROPAKARI
                            
                        </h1>
                    </a>
                    
                </div>
                <div class="collapse navbar-collapse">
                <ul className="nav navbar-nav navbar-right">

                    


<li id="paro" className="active">
{(() => {if (langID == 'Tamil' ) {return (
  <a class="btn1 third" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',marginRight:'15px',marginLeft:'-195px',fontSize:10,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>

{beaparopakari.result}
</a>

);
}
else {return(

<a class="btn1 third" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',marginRight:'100px',marginLeft:'-274px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>

{beaparopakari.result}
</a>

);}

})()} 

</li>

      <li>
      {(() => {if (langID == 'Tamil' ) {return (
<a href="#/Ourstory" style={{fontFamily:'cuyabra',fontsize:10,fontWeight: 'bold'}}>{ourstory.result}</a>

);
}
else {return(

<a href="#/Ourstory" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{ourstory.result}</a>

);}

})()} 
      
        
        </li> 
  <li>
  {(() => {if (langID == 'Tamil' ) {return (
<a href="#/Howitworks" style={{fontFamily:'cuyabra',fontsize:10,fontWeight: 'bold'}}>{howitworks.result}
    
</a>

);
}
else {return(

<a href="#/Howitworks" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{howitworks.result}
    
    </a>

);}

})()} 
    </li> 
  <li>
  {(() => {if (langID == 'Tamil' ) {return (
<a href="#/Blog" style={{fontFamily:'cuyabra',fontWeight: 'bold',fontsize:10}}>{blog.result}</a>

);
}
else {return(

<a href="#/Blog" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{blog.result}</a>

);}

})()} 
  
    
    </li>         
                     
  <li className="active">
  {(() => {if (langID == 'Tamil' ) {return (
<a href="#/Login" style={{fontFamily:'cuyabra',fontWeight: 'bold',fontsize:10}}>{login.result}</a>

);
}
else {return(

<a href="#/Login" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{login.result}</a>

);}

})()} 

    
    
    </li>                    
</ul>
          
                </div>
            </div>
        </div>
</header> */}

            <div className="mag notranslate">
              <div class="pro-nav">
                <input type="checkbox" id="pro-nav-check" onClick={this.checkfun} />
                <div class="pro-nav-header">
                  <div class="pro-nav-title notranslate">
                    <a className="navbar-brand" href="#/Index1">
                      <h1 className="navbar-new-text"><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '300px' }} />AROPAKARI
                      </h1>
                    </a>
                  </div>
                </div>

                <div class="pro-nav-btn">
                  <label for="pro-nav-check">
                    <div className="pro-naav">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </label>
                </div>

                {langID == "English" ? <>
                  <div class="pro-nav-links pro-side">
                    <p class='pro-center'>
                      <a className="new-design" href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-boxx parop-boxx-top">
                      <a href="#/Ourstory" className="parop-menu">{ourstory.result}</a>
                      <a href="#/Howitworks" className="parop-menu">{howitworks.result}</a>
                      <a href="#/Blog" className="parop-menu">{blog.result}</a>
                      <a href="#/Login" className="parop-menu">{login.result}</a>
                    </div>
                  </div>
                </> : langID == "Hindi" ? <>
                  <div class="pro-nav-links pro-side">
                    <p class='pro-center'>
                      <a className="new-hindi-design" href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-boxx parop-boxx-top">
                      <a href="#/Ourstory" className="parop-hindi-menu">{ourstory.result}</a>
                      <a href="#/Howitworks" className="parop-hindi-menu">{howitworks.result}</a>
                      <a href="#/Blog" className="parop-hindi-menu">{blog.result}</a>
                      <a href="#/Login" className="parop-hindi-menu">{login.result}</a>
                    </div>
                  </div>
                </> : langID == "Kannada" ? <>
                  <div class="pro-nav-links pro-side">
                    <p class='pro-center'>
                      <a className="new-kannada-design" href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-kannada-boxx parop-boxx-top">
                      <a href="#/Ourstory" className="parop-kannada-menu">{ourstory.result}</a>
                      <a href="#/Howitworks" className="parop-kannada-menu">{howitworks.result}</a>
                      <a href="#/Blog" className="parop-kannada-menu">{blog.result}</a>
                      <a href="#/Login" className="parop-kannada-menu">{login.result}</a>
                    </div>
                  </div>
                  </> : langID == "Spanish" ? <>
                  <div class="pro-nav-links pro-side">
                    <p class='pro-center'>
                      <a className="new-kannada-design" href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-kannada-boxx parop-boxx-top">
                      <a href="#/Ourstory" className="parop-kannada-menu">{ourstory.result}</a>
                      <a href="#/Howitworks" className="parop-kannada-menu">{howitworks.result}</a>
                      <a href="#/Blog" className="parop-kannada-menu">{blog.result}</a>
                      <a href="#/Login" className="parop-kannada-menu">{login.result}</a>
                    </div>
                  </div>
                </> : langID == "Tamil" ? <>
                  <div class="pro-nav-links pro-side">
                    <p class='pro-center'>
                      <a className="new-tamil-design" href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-tamil-boxx parop-boxx-top">
                      <a href="#/Ourstory" className="parop-tamil-menu">{ourstory.result}</a>
                      <a href="#/Howitworks" className="parop-tamil-menu">{howitworks.result}</a>
                      <a href="#/Blog" className="parop-tamil-menu">{blog.result}</a>
                      <a href="#/Login" className="parop-tamil-menu">{login.result}</a>
                    </div>
                  </div>
                </> : <>
                  <div class="pro-nav-links pro-side">
                    <p class='pro-center'>
                      <a className="new-telugu-design" href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-boxx parop-boxx-top">
                      <a href="#/Ourstory" className="parop-telugu-menu">{ourstory.result}</a>
                      <a href="#/Howitworks" className="parop-telugu-menu">{howitworks.result}</a>
                      <a href="#/Blog" className="parop-telugu-menu">{blog.result}</a>
                      <a href="#/Login" className="parop-telugu-menu">{login.result}</a>
                    </div>
                  </div>
                </>}
              </div>
            </div>

            {/*/#header*/}

            {/*/#page-breadcrumb*/}
            <section id="about-company" className={this.state.movedown == true ? "wow fadeInUp" : "wow fadeInUp Tiptop"} data-wow-duration="400ms" data-wow-delay="400ms" style={{ backgroundColor: '#aee6db' }}>
              {/* Page Heading/Breadcrumbs */}
              {/* Content Row */}

            
              <div className="container">


              <div className="pcoded-content">
                  <div className="pcoded-inner-content">
                    <div className="main-body">
                      <div className="page-wrapper">
                        <div className="page-body">
                        <br />
                        <br />
                      <center> <h2 style={{ color: 'black' }}><b>{resetpassword.result}</b></h2></center> 
                        <br />
                 
                  <div >

                    <form onSubmit={this.handleSubmitevents} method="post"
                      role="form" noValidate="novalidate">

                      <div className="row">
                      <div class="form-group col-sm-4 col-lg-4">

                      </div>
                      <div class="form-group col-sm-4 col-lg-4">
                      <label className htmlFor="NewPassword">{newpassword.result}</label>
                      {/* <i  style={{top:"20px"}}
                              className={isPasswordShown ? "fa fa-eye-slash" : "fa fa-eye password-icon"}
                              onClick={this.togglePasswordVisiblity}
                            />
                      <input className="form-control" id="NewPassword" onChange={this.handleChange} name="NewPassword" placeholder={newpassword.result} type={isPasswordShown ? "text" : "password"} style={{ width: '50%', marginLeft: '25%' }}  /> */}
                     
                      <input className="form-control" id="NewPassword" onChange={this.handleChange} name="NewPassword" placeholder={newpassword.result} type={isPasswordShown ? "text" : "password"} style={{ width: '100%',marginLeft: '%' }}
                              />
                            <i style={{ position: "relative", zIndex: 2, float: "right", marginTop: "-25px", marginRight: "0px" }}
                              className={isPasswordShown ? "fa fa-eye-slash" : "fa fa-eye password-icon"}
                              onClick={this.togglePasswordVisiblity}
                            />
                           

                      {npErr && <span style={{ color: "red" }}>{NewPasswordisRequired.result ? NewPasswordisRequired.result : npErr}</span>}  <br />

                      {npErrr && <span style={{ color: "red" }}>{PasswordMustContain.result ? PasswordMustContain.result : npErrr}</span>}  <br />

                </div>
                <div class="form-group col-sm-4 col-lg-4">

                </div>
              </div>
                  <div className="row">
                <div class="form-group col-sm-4 col-lg-4">
        
                  </div>
                  <div class="form-group col-sm-4 col-lg-4">
                  <label className htmlFor="ConfirmPassword">{confirmnewpassword.result}</label>
                      <input className="form-control" id="ConfirmPassword" onChange={this.handleChange} name="ConfirmPassword" placeholder={confirmpassword.result} type={isPasswordShowns ? "text" : "password"} style={{ width: '100%', marginLeft: '%' }} />


                      <i style={{ position: "relative", zIndex: 2, float: "right", marginTop: "-25px", marginRight: "0px" }}
                              className={isPasswordShowns ? "fa fa-eye-slash" : "fa fa-eye password-icon"}
                              onClick={this.togglePasswordVisiblity1}
                            />
                           

                      {cnpErr &&
                        <span style={{ color: "red" }}>
                          {ConfirmNewPasswordisRequired.result ? ConfirmNewPasswordisRequired.result : cnpErr}
                        </span>}

                      {cnpErrr &&
                        <span style={{ color: "red" }}>
                          {PasswordandConfirmPasswordmustbesame.result ? PasswordandConfirmPasswordmustbesame.result : cnpErrr}
                        </span>}


                      <br />
                        </div>
                        <div class="form-group col-sm-4 col-lg-4">

                        </div>
                  </div>
                        <div className="row">
                        <div class="form-group col-sm-4 col-lg-4">

                        </div>
                        <div class="form-group col-sm-4 col-lg-4">
                        <input type="hidden" value={Pno} name="pno" />
                                  <br />
                          <center>   
                           <button type="submit" Value={resetpassword.result} className="btn btn-secondary" style={{ backgroundColor: 'rgb(102, 71, 18)', color: 'white', marginLeft: '0%' }} >{submit.result}</button>
                          </center>  
                          </div>
                          <div class="form-group col-sm-4 col-lg-4">

                          </div>
                        </div>

                  
                    



                    
                    </form>

                  </div>



                </div>

              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              </div>
              </div>
              </div></div>
            </section>

            {/*/#team*/}
            <footer id="footer" style={{ color: 'white' }}>
              <br />
              <div className="container">
                <div className="copyright">

                  <div className="row col-lg-12">

                    <div className="col-lg-9 mb-6">
                      {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}


                      <p style={{ marginLeft: '45px' }}>{copyright_new.result} &nbsp;{currentYear} © {paropakari_new.result} |&nbsp;<a href="#/Contactus" style={{ textDecoration: 'none', color: 'white' }}>{contactus.result}&nbsp;</a>| <a href="#/PrivacyPolicy" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{privacypolicy.result}&nbsp;</a>|<a href="#/TermsandCondition" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{termsandcondition.result}</a></p>

                    </div>
                    <div className="col-lg-3 mb-3">

                      <div className="social-icons" style={{ color: 'white', marginLeft: '118px' }}>
                        <ul className="nav nav-pills">
                          <li><a target="_blank" href="https://www.facebook.com/beoneparopakari"><i className="fa fa-facebook" /></a></li>
                          {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                          <li><a target="_blank" href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw" ><i className="fa fa-youtube-play" /></a></li>
                          <li><a target="_blank" href="https://www.instagram.com/paropakari_beone/" ><i className="fa fa-instagram" /></a></li>
                          <li><a target="_blank" href="https://www.linkedin.com/company/paropakari/"><i className="fa fa-linkedin" /></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="containerss">

              </div>
            </footer>

          </div>
          {/*/#footer*/}
        </div>
      </div>
    )
  }
}