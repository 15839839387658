
import React, { useState, useEffect } from 'react';
import Select from 'react-select';


function Causes({Translated}) {
  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  let options = data.map(function (item) {
    return { value: item.id, label: item.name, image: item.profile_imagePath };
  })

  let formatOptionLabel = ({ value, label, image }) => (
    <div className="grp-flex" style={{ display: 'flex' }}>
      <img className="grp-edit-img2" src={image} />
      <span>{label}</span>
    </div>
  );

  useEffect(() => {
    loadData();

  }, []);

  const loadData = async () => {
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    await fetch('https://testapi.paropakari.com/api/Invite/GetInviteuser', { headers: { "Authorization": `Bearer ${accessToken}` } })
      .then(response => response.json())
      .then(receivedData => setData(receivedData));
    
    // alert(data)
   
  }
  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);

  }
  const customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "transparent",
      width: '97%',
      color: "black",
    })
  };
  return (

    <div>

      <Select

        placeholder={Translated ? Translated : 'Select'}

        // menuPortalTarget={document.querySelector('body')}
        onChange={handleChange}
        options={options}
        formatOptionLabel={formatOptionLabel}
        //options={data.map(e => ({ label: e.name, value: e.id}))}
        isMulti

        theme={theme => ({
          ...theme,

          colors: {
            ...theme.colors,
            neutral50: 'black',
          },
        })}

      />

      {selectedValue && <div style={{ marginTop: 20, lineHeight: '25px' }}>

        <input type="hidden" id="tag_fnds" name="tag_fnds" defaultValue={selectedValue} required />

      </div>}
    </div>



  );
}
export default Causes;